// @flow

import { ACTION_TYPES } from 'app/constants';
import { IP_FROM, PERSON_STATUS, TERMS_OF_ACCESS } from 'app/pages/teams/teamsConstants';
import type { CheckedValue, EditPeopleState } from './editPeopleTypes';

const defaultEditPeople: EditPeopleState = {
  isLoaded: false,
  roomRole: {
    checked: false,
    value: '',
  },
  qnaRole: {
    checked: false,
    value: '',
  },
  subTeam: {
    checked: false,
    value: '',
  },
  activityReport: {
    checked: false,
    value: false,
  },
  status: {
    checked: false,
    value: PERSON_STATUS.DISABLED,
  },
  ipAccessList: {
    checked: false,
    value: {
      ipAccessFrom: IP_FROM.ANY,
      userIpAccessList: '',
    },
  },
  selectedPeopleIds: [],
  isSubmitting: false,
  hasBeenUpdated: false,
  errors: {},
  termsOfAccess: {
    checked: false,
    value: TERMS_OF_ACCESS.ONCE.toString(),
  },
};
const extendState = (state: EditPeopleState, propName: string, data: CheckedValue<*>) => {
  return {
    ...state,
    [propName]: {
      ...state[propName],
      ...data,
    },
  };
};
const extendRoomRole = (state: EditPeopleState, roomRole: *) => {
  const newState = extendState(state, 'roomRole', roomRole);
  newState.qnaRole.value = '';
  if (!newState.roomRole.checked) {
    newState.qnaRole.checked = false;
  } else if (newState.roomRole.value === 'ADM') {
    newState.subTeam = {
      checked: false,
      value: '',
    };
  }
  return newState;
};
const extendTeam = (state: EditPeopleState, team: *) => {
  const newState = extendState(state, 'team', team);
  return newState;
};
const editPeopleReducer = (
  state: EditPeopleState = defaultEditPeople,
  action: Object,
): EditPeopleState => {
  switch (action.type) {
    case ACTION_TYPES.EDIT_PEOPLE.SET_IS_LOADED:
      return {
        ...state,
        isLoaded: true,
      };
    case ACTION_TYPES.EDIT_PEOPLE.SET_ROOM_ROLE:
      return extendRoomRole(state, action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_QNA_ROLE:
      return extendState(state, 'qnaRole', action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_TEAM:
      return extendTeam(state, action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_SUB_TEAM:
      return extendState(state, 'subTeam', action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_ACTIVITY_LOG:
      return extendState(state, 'activityReport', action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_STATUS:
      return extendState(state, 'status', action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_TERMS_OF_ACCESS:
      return extendState(state, 'termsOfAccess', action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_IP_ACCESS_LIST:
      return extendState(state, 'ipAccessList', action.data);
    case ACTION_TYPES.EDIT_PEOPLE.SET_ERRORS:
      return {
        ...state,
        errors: action.data,
      };
    case ACTION_TYPES.EDIT_PEOPLE.SELECT_PEOPLE:
      return {
        ...state,
        selectedPeopleIds: action.data,
      };
    case ACTION_TYPES.EDIT_PEOPLE.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.data,
      };
    case ACTION_TYPES.EDIT_PEOPLE.SET_UPDATED:
      return {
        ...state,
        hasBeenUpdated: true,
      };
    case ACTION_TYPES.EDIT_PEOPLE.RESET:
      return defaultEditPeople;
    default:
      return state;
  }
};

export default editPeopleReducer;
