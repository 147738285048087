// @flow

import { ACTION_TYPES } from 'app/constants';
import type {
  SubjectSecurityChanges,
  SubjectOrGroup,
} from 'app/pages/security/subjectSecurityPage/subjectSecurityPageTypes';

type State = {
  subjects: Array<SubjectOrGroup>,
  displaySubjects: Array<SubjectOrGroup>,
  changes: SubjectSecurityChanges,
  loaded: boolean,
};

const defaultState = {
  subjects: [],
  displaySubjects: [],
  changes: {},
  loaded: false,
  isSaving: false,
};

type Action = {
  type: string,
  data: any,
};

const setAllGroupsExpandedState = (displaySubjects, expanded) =>
  displaySubjects.map(subject => {
    if (subject.isGroup) {
      return {
        ...subject,
        expanded,
      };
    }
    return {
      ...subject,
      visible: expanded,
    };
  });

const subjectsSecurityReducer = (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.SUBJECTS.LOADED:
      return {
        ...state,
        subjects: action.data,
        displaySubjects: action.data,
        loaded: true,
      };

    case ACTION_TYPES.SECURITY.SUBJECTS.SET_ACCESS: {
      const newChanges = {
        ...state.changes,
        [action.data.id]: action.data.allowAccess,
      };
      const newDisplaySubjects = state.displaySubjects.map(subject => {
        if (subject.id === action.data.id) {
          return {
            ...subject,
            allowAccess: action.data.allowAccess,
          };
        }

        if (subject.parentId === action.data.id) {
          // This is a child subject of the subject group that was changed
          // Unset any previously set changes for this item
          delete newChanges[subject.id];

          return {
            ...subject,
            allowAccess: action.data.allowAccess,
          };
        }

        return subject;
      });

      return {
        ...state,
        displaySubjects: newDisplaySubjects,
        changes: newChanges,
      };
    }

    case ACTION_TYPES.SECURITY.SUBJECTS.SET_GROUP_EXPANDED: {
      const newDisplaySubjects = state.displaySubjects.map(subject => {
        if (subject.isGroup && subject.id === action.data.id) {
          return {
            ...subject,
            expanded: action.data.expanded,
          };
        }
        if (subject.parentId === action.data.id) {
          return {
            ...subject,
            visible: action.data.expanded,
          };
        }
        return subject;
      });

      return {
        ...state,
        displaySubjects: newDisplaySubjects,
      };
    }

    case ACTION_TYPES.SECURITY.SUBJECTS.EXPAND_ALL_GROUPS:
      return {
        ...state,
        displaySubjects: setAllGroupsExpandedState(state.displaySubjects, true),
      };

    case ACTION_TYPES.SECURITY.SUBJECTS.COLLAPSE_ALL_GROUPS:
      return {
        ...state,
        displaySubjects: setAllGroupsExpandedState(state.displaySubjects, false),
      };

    case ACTION_TYPES.SECURITY.SUBJECTS.SAVING:
      return {
        ...state,
        isSaving: true,
      };

    case ACTION_TYPES.SECURITY.SUBJECTS.SAVED:
      return {
        ...state,
        isSaving: false,
      };

    case ACTION_TYPES.SECURITY.SUBJECTS.SAVE_CHANGES_IN_STORE:
      return {
        ...state,
        subjects: state.displaySubjects,
        changes: {},
      };

    case ACTION_TYPES.SECURITY.CLEAR_CHANGES:
      return {
        ...state,
        displaySubjects: state.subjects,
        changes: {},
      };

    default:
      return state;
  }
};

export default subjectsSecurityReducer;
