export const FILTER_TYPES = Object.freeze({
  LOGGED_IN_TO_DATE: '0',
  NEVER_LOGGED_IN: '1',
  VERIFIED: '2',
  NOT_VERIFIED: '3',
  INVITED: '4',
  NOT_INVITED: '5',
  ALL: '6',
});

export const EMAIL_IMPORTANCE = {
  LOW: 'low', // TODO: Confirm with API what these values should be
  NORMAL: 'normal',
  HIGH: 'high',
};
