// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'app/store';
import SiteDialog from 'app/layout/siteDialog/SiteDialog';
import { ACTION_TYPES } from 'app/constants';

const exposeDialogFunction = () => {
  // It will open security dialog in manage documents
  window.dialog = (itemId: number, itemType: string) =>
    store.dispatch({
      type: ACTION_TYPES.SECURITY.OPEN_SECURITY_DIALOG,
      data: {
        itemId,
        itemType,
      },
    });
};

const renderSecurityDialog = () => {
  let dialog = document.getElementById('security-dialog');

  if (!dialog) {
    dialog = document.createElement('div');
    dialog.id = 'security-dialog';
  }

  // $FlowFixMe Flow thinks there might not be a document.body
  document.body.appendChild(dialog);

  ReactDOM.render(
    <Provider store={store}>
      <SiteDialog />
    </Provider>,
    dialog,
  );

  exposeDialogFunction();
};

renderSecurityDialog();
