// @flow

import { downloadExcel, isAdministrator } from 'app/utils/helpers';
import { getTeamData } from 'app/pages/teams/teamsHelpers';
import * as actions from 'app/components/bannerMessages/bannerMessagesActions';
import { ACTION_TYPES, TEAM_DIALOG_TYPES, USER_TYPE_CODES } from 'app/constants';
import { personSagas } from 'app/pages/teams/peopleList/person/personSagas';
import { ModelError } from 'app/utils/error';
import type { Person } from 'app/types/personTypes';
import type {
  TeamFormData,
  CopyTeamSecurityDataType,
  TeamDataTransferType,
  DeleteTeamDataType,
} from 'app/pages/teams/teamsTypes';
import * as roomService from 'app/services/dataRoom';
import * as personService from 'app/services/person';
import * as teamService from 'app/services/team';
import * as securityService from 'app/services/security';
import { safeRequest } from 'app/utils/saga-helpers';
import { put, select, all, call, spawn, takeEvery } from 'redux-saga/effects';
import store from 'app/store';
import type { ThirdPartyIntegrations } from 'app/types/teamsTypes';
import type { SessionType } from 'app/types/globalTypes';
import { getFeature2Data } from 'app/utils/feature2';
import * as teamHelpers from './teamsHelpers';

export function* copyTeamSecurity(copyData: CopyTeamSecurityDataType): Generator<*, *, *> {
  const { session } = yield select();
  const [, errorMessage] = yield safeRequest(securityService.copySecurity, session, copyData);
  if (errorMessage) {
    yield put(actions.displayInfoMessage(errorMessage));
  }
}

export function* copySubteamSecurity(
  subteam: Object,
  teamId: number,
  isCopy: boolean,
): Generator<*, *, *> {
  const { session } = yield select();
  const [subteamResult, error] = yield safeRequest(
    teamService.createSubteam,
    session,
    subteam,
    teamId,
    isCopy,
  );
  if (!error) {
    yield copyTeamSecurity({
      fromSubteamId: subteam.subteamId,
      toSubteamId: subteamResult.subteamId,
      fromTeamName: subteam.name,
    });
  }
}

export function* copyTeamAndSubteams(formData: TeamFormData, teamData: Object): Generator<*, *, *> {
  yield call(copyTeamSecurity, {
    fromSubteamId: formData.primarySubteamId,
    toSubteamId: teamData.PrimarySubteamId,
    fromTeamName: formData.name,
  });
  // Copy subteams
  if (formData.subteams && formData.subteams.length) {
    yield all(
      formData.subteams.map(subteam =>
        call(copySubteamSecurity, subteam, teamData.teamId, formData.isCopy),
      ),
    );
  }
}

export function* saveTeamResult(
  formData: TeamFormData,
  teamData: Object,
  data: TeamDataTransferType,
): Generator<*, *, *> {
  const { session } = yield select();
  if (formData.type === TEAM_DIALOG_TYPES.EDIT && formData.subteams) {
    yield all(
      formData.subteams.map(subteam =>
        safeRequest(teamService.updateSubteam, session, subteam, data),
      ),
    );
  }
  if (formData.type === TEAM_DIALOG_TYPES.COPY) {
    yield call(copyTeamAndSubteams, formData, teamData);
  }
  yield put({
    type: ACTION_TYPES.TEAMS.SET_FOCUS_TEAM,
    data: teamData,
  });
  yield put({ type: ACTION_TYPES.DIALOG_TOGGLE });
  yield put({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
}

export function* saveTeamThirdPartyIntegrations(
  subteamId: number,
  session: SessionType,
  thirdPartyIntegrations: ThirdPartyIntegrations[],
): Generator<*, *, *> {
  const [, error] = yield safeRequest(
    teamService.saveTeamThirdPartyIntegrations,
    session,
    subteamId,
    thirdPartyIntegrations,
  );

  if (!error) {
    yield put({
      type:
        ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS
          .UPDATE_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP,
      data: {
        thirdPartyIntegrations,
        subteamId,
      },
    });
  } else if (error instanceof ModelError) {
    yield put({ type: ACTION_TYPES.TEAMS.TEAM_FORM_ERRORS, data: error.errors });
  }
}

export function* saveTeam(action: Object): Generator<*, *, *> {
  const formData: TeamFormData = action.data;
  const { teams, session } = yield select();
  const { isGuest } = teams.props;

  const data = getTeamData(isGuest, formData);
  const [teamData, error] = yield safeRequest(teamService.saveTeam, session, formData, isGuest);
  const isThirdPartyIntegrationsEnabled = getFeature2Data(
    'dil-758-add-allow-third-party-integrations-checkbox-to-team-sub-team-settings',
  );

  if (!error) {
    yield call(saveTeamResult, formData, teamData, data);

    if (isThirdPartyIntegrationsEnabled) {
      const newFormData = teamService.mapTeamType(teamData);
      const { thirdPartyIntegrations } = formData;
      if (formData.type === TEAM_DIALOG_TYPES.CREATE) {
        yield call(
          saveTeamThirdPartyIntegrations,
          newFormData.subteamId,
          session,
          thirdPartyIntegrations,
        );
      } else {
        const { thirdPartyIntegrationsSecurityGroups } = store.getState().teams.pageData;
        const shouldUpdate =
          formData.type === TEAM_DIALOG_TYPES.COPY ||
          teamHelpers.shouldUpdateThirdPartyIntegrationsSecurityGroup(
            thirdPartyIntegrations,
            thirdPartyIntegrationsSecurityGroups,
            newFormData.subteamId,
          );

        if (shouldUpdate) {
          yield call(
            saveTeamThirdPartyIntegrations,
            newFormData.subteamId,
            session,
            thirdPartyIntegrations,
          );
        }
      }
    }
  } else if (error instanceof ModelError) {
    yield put({ type: ACTION_TYPES.TEAMS.TEAM_FORM_ERRORS, data: error.errors });
  }
}

type DeleteTeamAction = {|
  data: DeleteTeamDataType,
|};

type FetchThirdPartyIntegrationsForSecurityGroupAction = {|
  data: { subteamId: number, type: string },
|};

function* deleteTeam(action: DeleteTeamAction): Generator<*, *, *> {
  const { data } = action;
  const { session } = yield select();
  const [, error] = yield safeRequest(teamService.deleteTeam, session, data);
  if (!error) {
    yield put({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
  }
}

export function* savePeople(): Generator<*, *, *> {
  const { teams, session } = yield select();
  const formData = teams.viewData.peopleForm.data;
  const [, error] = yield safeRequest(personService.savePeople, session, {
    ...formData,
    canEditTenderSubmissionBeforeClose:
      formData.userTypeCode === USER_TYPE_CODES.GUE
        ? true
        : formData.canEditTenderSubmissionBeforeClose,
  });

  if (!error) {
    yield put({ type: ACTION_TYPES.DIALOG_TOGGLE });
    yield put({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
    yield put({ type: ACTION_TYPES.TEAMS.PEOPLE_SAVE_SUCCESSFULLY });
  } else if (error instanceof ModelError) {
    yield put({ type: ACTION_TYPES.TEAMS.PEOPLE_FORM_ERRORS, data: error.errors });
  }
}

export function* toggleDisablePerson(action: Object): Generator<*, *, *> {
  const personData: Person = action.data;
  const { session } = yield select();
  const [, error] = yield safeRequest(personService.toggleDisablePerson, session, personData);
  if (!error) {
    yield put({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
  }
}

export function* fetchTeams(): Generator<*, *, *> {
  const state = yield select();
  const { session } = state;
  const { isGuest } = state.teams.props;
  const [teams, error] = yield safeRequest(teamService.getTeams, session, { isGuest });
  if (!error) {
    yield put({
      type: ACTION_TYPES.TEAMS.VIEW_DATA_UPDATE,
      data: { teams },
    });
    yield put({
      type: ACTION_TYPES.TEAMS.FILTER_DATA_MAP,
      data: { teams },
      isGuest,
    });
  }
}

export function* fetchBidders(): Generator<*, *, *> {
  const session = yield select(state => state.session);
  if (session.evaluationToolEnabled && isAdministrator(session.userTypeCode)) {
    const [bidders, error] = yield safeRequest(teamService.getBidders, session);
    if (!error) {
      yield put({
        type: ACTION_TYPES.TEAMS.VIEW_DATA_UPDATE,
        data: { bidders },
      });
    }
  }
}

export function* exportUsers(): Generator<*, *, *> {
  const { session } = yield select();
  const [result, error] = yield safeRequest(personService.getExportLink, session);
  if (!error) {
    downloadExcel(result);
  }
}

export function* fetchPeople(): Generator<*, *, *> {
  const { teams, session } = yield select();
  const { isGuest } = teams.props;
  const [rawPeople, error] = yield safeRequest(personService.fetchPeople, session, isGuest);
  const people = rawPeople.filter(person => person.isGuest === isGuest);
  if (!error) {
    yield put({ type: ACTION_TYPES.TEAMS.VIEW_DATA_UPDATE, data: { people } });
    yield put({
      type: ACTION_TYPES.TEAMS.FILTER_DATA_MAP,
      data: {
        people,
      },
      isGuest,
    });
  }
}

export function* fetchRemoteIP(): Generator<*, *, *> {
  const { session } = yield select();
  if (session.isUserIpAccessListAllowed && isAdministrator(session.userTypeCode)) {
    const [remoteIP, error] = yield safeRequest(personService.fetchRemoteIp, session);
    if (!error) {
      yield put({
        type: ACTION_TYPES.PAGE.SET_REMOTE_IP,
        data: remoteIP,
      });
    }
  }
}

export function* fetchLicences(): Generator<*, *, *> {
  const { session } = yield select();
  const [licences, error] = yield safeRequest(roomService.fetchLicences, session);
  if (!error) {
    yield put({
      type: ACTION_TYPES.PAGE.SET_LICENSES,
      data: { ...licences, isTendersFreemium: false },
    });
  }
}

export function* fetchThirdPartyIntegrationsForDataRoom(): Generator<*, *, *> {
  const { session } = yield select();
  const [thirdPartyIntegrationsDataRoom, error] = yield safeRequest(
    roomService.fetchThirdPartyIntegrationsForDataRoom,
    session,
  );

  if (!error) {
    yield put({
      type: ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_DATA_ROOM.SET_DATA,
      data: { thirdPartyIntegrationsDataRoom },
    });
  }
}

export function* fetchThirdPartyIntegrationsForSecurityGroup(
  action: FetchThirdPartyIntegrationsForSecurityGroupAction,
): Generator<*, *, *> {
  const { session } = yield select();
  const { subteamId } = action.data;

  const [thirdPartyIntegrations, error] = yield safeRequest(
    roomService.fetchThirdPartyIntegrationsForSecurityGroup,
    session,
    subteamId,
  );

  if (!error) {
    yield put({
      type:
        ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS
          .UPDATE_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP,
      data: { subteamId, thirdPartyIntegrations },
    });
  }
}

export function* fetchData(action: Object): Generator<*, *, *> {
  const { isGuest } = action.data;
  const collapseAllTeamsByDefaultEnabled = getFeature2Data(
    'idy-1306-collapse-all-teams-by-default',
  );
  const allowThirdPartyIntegrationsEnabled = getFeature2Data(
    'dil-758-add-allow-third-party-integrations-checkbox-to-team-sub-team-settings',
  );

  let initialTasks = [
    put({ type: ACTION_TYPES.USER_TYPES_FETCH, data: { isGuest } }),
    put({ type: ACTION_TYPES.USER_PERMISSIONS_FETCH }),
    call(fetchRemoteIP),
    call(fetchTeams),
    call(fetchPeople),
    call(fetchLicences),
    call(fetchBidders),
  ];

  yield put({ type: ACTION_TYPES.TEAMS.SET_PROPS, data: action.data });
  yield put({ type: ACTION_TYPES.LOADINGBAR_LOADING_TOGGLE });

  if (allowThirdPartyIntegrationsEnabled) {
    initialTasks = [...initialTasks, call(fetchThirdPartyIntegrationsForDataRoom)];
  }

  yield all(initialTasks);

  if (collapseAllTeamsByDefaultEnabled) {
    yield put({ type: ACTION_TYPES.TEAMS.COLLAPSE_ALL });
  }

  yield put({ type: ACTION_TYPES.LOADINGBAR_LOADING_TOGGLE });
}

export function* fetchInitial(): Generator<*, *, *> {
  yield spawn(fetchTeams);
  yield spawn(fetchPeople);
  yield spawn(fetchLicences);
  if (
    getFeature2Data('dil-758-add-allow-third-party-integrations-checkbox-to-team-sub-team-settings')
  ) {
    yield spawn(fetchThirdPartyIntegrationsForDataRoom);
  }
}

export function* fetchIpList(action: Object): Generator<*, *, *> {
  const { userId } = action.data;
  const { session } = yield select();
  const [{ ipAccessFrom, userIpAccessList }, error] = yield safeRequest(
    personService.fetchIpList,
    session,
    userId,
  );
  if (!error) {
    yield put({
      type: ACTION_TYPES.TEAMS.PEOPLE_FORM_SET_IP_INFO,
      data: {
        ipAccessFrom,
        userIpAccessList,
      },
    });
  }
}

export function* collapseAllTeams(): Generator<*, *, *> {
  const state = yield select();
  const { teams } = state.teams.pageData;
  let collapsedTeams = {};

  if (teams) {
    teams.forEach(team => {
      const teamExpandId = team.subteamId;
      collapsedTeams = {
        [teamExpandId]: true,
        ...collapsedTeams,
      };
      if (team.subteams) {
        team.subteams.forEach(subteam => {
          const subteamExpandId = subteam.subteamId;
          collapsedTeams = {
            [subteamExpandId]: true,
            ...collapsedTeams,
          };
        });
      }
    });
  }

  yield put({
    type: ACTION_TYPES.TEAMS.COLLAPSED_ALL,
    data: { collapsedTeams },
  });
}

function wrapDialogTransaction(task: (action: Object) => *) {
  return function* withDialogProgress(action): Generator<*, *, *> {
    yield all([
      put({ type: ACTION_TYPES.CLEAR_ERRORS }),
      put({ type: ACTION_TYPES.DIALOG_SAVE_PROGRESS_BEGIN }),
    ]);
    yield call(task, action);
    yield put({ type: ACTION_TYPES.DIALOG_SAVE_PROGRESS_END });
  };
}

export const teamsSagas = [
  ...personSagas,
  takeEvery(ACTION_TYPES.TEAMS.TEAM_SAVE, wrapDialogTransaction(saveTeam)),
  takeEvery(ACTION_TYPES.TEAMS.PEOPLE_SAVE, wrapDialogTransaction(savePeople)),
  takeEvery(ACTION_TYPES.TEAMS.PERSON_TOGGLE_DISABLE, toggleDisablePerson),
  takeEvery(ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL, fetchInitial),
  takeEvery(ACTION_TYPES.TEAMS.DATA_FETCH, fetchData),
  takeEvery(
    ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS
      .FETCH_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP,
    fetchThirdPartyIntegrationsForSecurityGroup,
  ),
  takeEvery(ACTION_TYPES.TEAMS.EXPORT_USERS, exportUsers),
  takeEvery(ACTION_TYPES.TEAMS.DELETE_TEAM, deleteTeam),
  takeEvery(ACTION_TYPES.TEAMS.PEOPLE_FETCH_IPLIST, fetchIpList),
  takeEvery(ACTION_TYPES.TEAMS.COLLAPSE_ALL, collapseAllTeams),
  takeEvery(ACTION_TYPES.TEAMS.PEOPLE_FORM_ADD, fetchLicences),
  takeEvery(ACTION_TYPES.SEND_INVITATION.DATA_FETCH_INITIAL, fetchLicences),
  takeEvery(ACTION_TYPES.LICENSE.DATA_FETCH_INITIAL, fetchLicences),
];
