// @flow

import { editPeopleSagas } from 'app/pages/editPeople/editpeopleSagas';
import { emailPeopleSagas } from 'app/pages/emailPeople/emailPeopleSagas';
import { sendInvitationSagas } from 'app/pages/sendInvitation/sendInvitationSagas';
import { locationSagas } from 'app/sagas/locationSagas';
import { eventSagas } from 'app/sagas/eventSagas';
import { teamsSagas } from 'app/pages/teams/teamsSagas';
import { securitySagas } from 'app/pages/security/securitySagas';
import { bannerMessagesSagas } from 'app/components/bannerMessages/bannerMessagesSagas';
import { notificationSagas } from 'app/pages/notification/notificationSagas';
import { teamHistorySagas } from 'app/pages/teamHistory/teamHistorySaga';
import { personHistorySagas } from 'app/pages/personHistory/personHistorySagas';
import { selfRegistrationSagas } from 'app/layout/selfRegistration/selfRegistrationSagas';
import { manageRegistrationSagas } from 'app/pages/teams/teamList/registrationPanel/manageRegistrationSagas';
import { verifyEmailDialogSaga } from 'app/pages/teams/verifyEmailDialog/verifyEmailDialogSaga';
import { all } from 'redux-saga/effects';
import { globalSagas } from './globalSagas';

export default function* rootSagas(): Generator<*, *, *> {
  yield all([
    ...globalSagas,
    ...locationSagas,
    ...teamsSagas,
    ...securitySagas,
    ...bannerMessagesSagas,
    ...notificationSagas,
    ...teamHistorySagas,
    ...personHistorySagas,
    ...sendInvitationSagas,
    ...editPeopleSagas,
    ...emailPeopleSagas,
    ...selfRegistrationSagas,
    ...manageRegistrationSagas,
    ...verifyEmailDialogSaga,
    ...eventSagas,
  ]);
}
