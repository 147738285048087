// @flow

import editPeopleReducer from 'app/pages/editPeople/editPeopleReducer';
import { combineReducers } from 'redux';
import bannerMessagesReducer from 'app/components/bannerMessages/bannerMessagesReducer';
import featuresReducer from 'app/reducers/featuresReducer';
import notificationReducer from 'app/pages/notification/notificationReducer';
import pageReducer from 'app/reducers/pageReducer';
import resourcesReducer from 'app/reducers/resourcesReducer';
import securityReducer from 'app/pages/security/securityReducer';
import sessionReducer from 'app/reducers/sessionReducer';
import licenseReducer from 'app/reducers/licenseReducer';
import siteDialogReducer from 'app/layout/siteDialog/siteDialogReducer';
import teamsReducer from 'app/pages/teams/reducers/teamsPageReducer';
import teamHistoryReducer from 'app/pages/teamHistory/teamHistoryReducer';
import sendInvitationReducer from 'app/pages/sendInvitation/sendInvitationReducer';
import personHistoryReducer from 'app/pages/personHistory/personHistoryReducer';
import emailPeopleReducer from 'app/pages/emailPeople/emailPeopleReducer';
import selfRegistrationReducer from 'app/layout/selfRegistration/selfRegistrationReducer';
import manageRegistrationReducer from 'app/pages/teams/teamList/registrationPanel/manageRegistrationReducer';
import verifyEmailReducer from 'app/pages/teams/verifyEmailDialog/verifyEmailReducer';
import ToastMessageReducer from 'app/components/toast-message/reducer';

const rootReducer = combineReducers<*, *>({
  dialog: siteDialogReducer,
  features: featuresReducer,
  navigationLinks: (data = {}) => data,
  notifications: combineReducers({
    bannerMessages: bannerMessagesReducer,
  }),
  notification: notificationReducer,
  page: pageReducer,
  resources: resourcesReducer,
  security: securityReducer,
  session: sessionReducer,
  teams: teamsReducer,
  teamHistory: teamHistoryReducer,
  sendInvitation: sendInvitationReducer,
  personHistory: personHistoryReducer,
  editPeople: editPeopleReducer,
  emailPeople: emailPeopleReducer,
  selfRegistration: selfRegistrationReducer,
  manageRegistration: manageRegistrationReducer,
  toastMessage: ToastMessageReducer,
  verifyEmail: verifyEmailReducer,
  license: licenseReducer,
});

export default rootReducer;
