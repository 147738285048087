// @flow

import * as R from 'ramda';
import { TERMS_OF_ACCESS, IP_FROM } from 'app/pages/teams/teamsConstants';
import { ACTION_TYPES, PEOPLE_DIALOG_TYPES, USER_TYPE_CODES } from 'app/constants';
import type { PeopleFormType } from 'app/pages/teams/teamsTypes';

const initialState = {
  data: {
    type: PEOPLE_DIALOG_TYPES.CREATE,
    emails: [],
    sameEmail: false,
    userTypeCode: '',
    qnaRoleCode: '',
    isSubteam: false,
    subteamId: null,
    termsOfAccess: TERMS_OF_ACCESS.ONCE,
    disabled: false,
    sendEmailInvitations: false,
    sendEmailInvitation: false,
    showInvitationOnEdit: false,
    ipAccessFrom: IP_FROM.ANY,
    userIpAccessList: '',
    canEditTenderSubmissionBeforeClose: null,
    canEditTenderSubmissionAfterClose: null,
  },
  errors: {},
};
const peopleFormReducer = (state?: PeopleFormType = initialState, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.PEOPLE_FORM_CHANGE: {
      const isAdmin =
        action.data.key === 'userTypeCode' && action.data.value === USER_TYPE_CODES.ADM;
      return {
        data: {
          ...state.data,
          subteamId: isAdmin ? null : state.data.subteamId,
          sendEmailInvitation:
            action.data.key === 'showInvitationOnEdit' &&
            action.data.value &&
            !state.data.showInvitationOnEdit
              ? true
              : state.data.sendEmailInvitation,
          [action.data.key]: action.data.value,
        },
        errors: R.omit([action.data.key], state.errors),
      };
    }

    case ACTION_TYPES.TEAMS.PEOPLE_FORM_SET_IP_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };

    case ACTION_TYPES.TEAMS.PEOPLE_FORM_EDIT:
      return {
        ...state,
        data: {
          ...action.data,
          type: PEOPLE_DIALOG_TYPES.EDIT,
        },
      };

    case ACTION_TYPES.TEAMS.PEOPLE_FORM_ADD:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          isSubteam: action.data.isSubteam,
          teamId: action.data.teamId,
          subteamId: action.data.subteamId,
          termsOfAccess: action.data.termsOfAccess,
          userTypeCode: action.data.isGuest ? USER_TYPE_CODES.GUE : initialState.data.userTypeCode,
        },
      };

    case ACTION_TYPES.TEAMS.PEOPLE_FORM_ERRORS:
      return {
        ...state,
        errors: action.data,
      };
    case ACTION_TYPES.CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
};

export default peopleFormReducer;
