// @flow

// prettier-ignore

export default {
  Accept: '受け入れる',
  AcceptRegistration: '登録を受け入れる',
  AcceptTermsOfAccess_SentenceCase: 'アクセス条件に同意する',
  Access: 'アクセス',
  AccessAllowed: 'アクセスが許可されています',
  AccessDenied: 'アクセスが拒否されました',
  AccessedAgo: '[時間]前にアクセスしました',
  Actions: 'アクション',
  Add: '追加',
  AddAndInvite: '追加して招待する',
  AddAndInvitePeople: '人を追加して招待する',
  AdditionalRecipients: '追加の受信者',
  AdditionalRecipients_SentenceCase: '追加の受信者',
  AdditionalRecipientsHint: '各メールアドレスを別々の行に入力します',
  AddPeople: '人を追加する',
  AddPeopleTo: '人を追加',
  AddPeopleToSubteam: '[チーム]/[サブチーム]に人を追加する',
  AddPeopleToTeam: '[チーム]に人を追加する',
  AddPeopleWillHaveTheSameAccess: '追加されたユーザーは同じアクセス権を持ちます',
  AddToExistingTeam: '既存のチームに追加する',
  Administration: '管理',
  AdminSideAudit: 'チーム',
  AdminSideBankruptcy: 'セルサイド',
  AdminSideBankruptcy_EmailHeading: '販売側の人にメールを送信する',
  AdminSideBankruptcy_InviteHeading: '販売サイドの人に招待を送信する',
  AdminSideDebt: '発行者',
  AdminSideDebt_EmailHeading: '電子メールの発行者',
  AdminSideDebt_InviteHeading: '発行者に招待状を送信する',
  AdminSideDocumentRepository: '内部',
  AdminSideDocumentRepository_EmailHeading: '社内メール送信',
  AdminSideDocumentRepository_InviteHeading: '内部関係者に招待を送信する',
  AdminSideFundraising: 'クライアント',
  AdminSideFundraising_EmailHeading: '電子メールクライアント',
  AdminSideFundraising_InviteHeading: 'クライアントに招待状を送信する',
  AdminSideInternalAudit: 'チーム',
  AdminSideJointVenture: '内部',
  AdminSideJointVenture_EmailHeading: '社内メール送信',
  AdminSideJointVenture_InviteHeading: '内部関係者に招待を送信する',
  AdminSideMerger: '販売側',
  AdminSideMerger_EmailHeading: '販売側の人にメールを送信する',
  AdminSideMerger_InviteHeading: '販売サイドの人に招待を送信する',
  AdminSideOther: '内部',
  AdminSideOther_EmailHeading: '社内メール送信',
  AdminSideOther_InviteHeading: '内部関係者に招待を送信する',
  AdminSidePlacement: '発行者',
  AdminSidePlacement_EmailHeading: '電子メールの発行者',
  AdminSidePlacement_InviteHeading: '発行者に招待状を送信する',
  AdminSidePublicOffer: '内部',
  AdminSidePublicOffer_EmailHeading: '社内メール送信',
  AdminSidePublicOffer_InviteHeading: '内部関係者に招待を送信する',
  AdminSideTargeted: '社内チーム',
  AdminSideTargeted_EmailHeading: '社内チームに電子メールを送信する',
  AdminSideTargeted_InviteHeading: '社内チームに招待状を送信する',
  AdminSideTax: 'チーム',
  AdminSideTender: 'クライアント',
  AdminSideTender_EmailHeading: '電子メールクライアント',
  AdminSideTender_InviteHeading: 'Send invites to client',
  All: 'すべて',
  Allow: '許可する',
  Allowed: '許可された',
  And_SentenceCase: 'そして',
  AndGoLive: 'そしてライブに行きます',
  AreYouSureToDeclineRegistration: '登録者[登録者]を拒否してもよろしいですか?',
  AreYouSureToDeleteRegistration: '登録者[登録者]を削除してもよろしいですか?',
  AreYouSureToDeleteRequest: 'リクエスト[登録者]を削除してもよろしいですか?',
  At: 'で',
  Attachments: '添付ファイル',
  BackToTeamsPage: 'チームページに戻る',
  BannerMessage: "[TeamName] の設定で作成されたチーム",
  BatchOperationCantBePerformed:
'このデータ ルームにはユーザーが存在しないため一括処理を実効できません。まずユーザーを入力してください。',
  BeforeYouCanEmail:'[teamType] にメールを送信する前に、メールを確認する必要があります。',
  BeforeYouCanSendInvites:'[teamType] に招待状を送信する前に、メールアドレスを確認する必要があります。',
  BillingCommencesWhenTheFirstGuestIsInvited:'最初のゲストが招待されたときに課金が開始されます。',
  BulkDownload_SentenceCase: '一括ダウンロード',
  BulkDownloadText:
    'ユーザーが単一の .zip ファイルで複数のファイルをダウンロードできるようにします。 ZIP ダウンロードは期間限定でのみ利用可能です。',
  BuySide: 'バイサイド',
  BuySide_InviteHeading: '招待状を送信してサイドメンバーを購入する',
  BuySide_SentenceCase: 'バイサイド',
  BySubmittingThisForm: 'このフォームを送信すると、次のことに同意したものとみなされます',
  Cancel: 'キャンセル',
  Change: '変更',
  ChangeTeamAccess: 'チームアクセスの変更',
  ChooseLanguage: '言語を選択',
  ClickAndPreview: '2. クリックしてプレビューします',
  Close: '閉じる',
  CollapseAll_SentenceCase: 'すべて折りたたむ',
  Colour: '色:',
  Company: '会社',
  Confirm: '確認する',
  ContactUs: 'お問い合わせ',
  Copied: 'コピーされました',
  CopiedSharedLinkInfo: 'リンクを外部入札者チームまたはゲストと共有して、登録できるようにします。',
  CopyEmailsOfSelectedUsers: '選択したユーザーのメールをコピーする',
  CopyEmailsOfSelectedUsersSec2: '選択したメールをコピーする',
  CopyingAllowed: 'コピー許可',
  CopyingDenied: 'コピーが拒否されました',
  CopyingSecuritySettings: 'セキュリティ設定をコピーする',
  CopyLink: 'リンクをコピーする',
  CopyOf: 'のコピー',
  CopyRegistrationLink: '登録リンクをコピーする',
  CopySecurity: 'コピーセキュリティ',
  CopySharedLinkToClipboard: 'リンクをクリップボードにコピー',
  CopyTeam: 'コピーチーム',
  CreateArchive: 'アーカイブの作成',
  CreateNewTeam: '新しいチームを作成する',
  CreatePeopleWithSameEmail: '既存のユーザーと同じメールアドレスを持つユーザーを作成する',
  CreateSideTeam: '[サイド]チームを作成する',
  CreateSubteam: 'サブチームの作成',
  CreateTeam: 'チームを作成する',
  CreateTeamEmptyState: 'チームを作成して人々を組織し、アクセスを設定する',
  CreateWatermark: '透かしを作成する',
  Daily: '毎日',
  DataRoom: 'データルーム',
  DataroomRole: '部屋の役割',
  DataRooms: 'データ ルーム',
  DataRoomStatus: 'データルームのステータス',
  DataRoomUserNotVerified1: 'データ ルームにアクセスするには:',
  DataRoomUserNotVerified2: '招待メールを開きます',
  DataRoomUserNotVerified3: '「詳細を確認する」リンクをクリックします',
  DataRoomUserNotVerified4: 'ログイン',
  DataRoomUserNotVerified5:
    "招待メールを受け取っていない場合は、データ ルーム管理者にお問い合わせください。",
  DataRoomUserNotVerifiedTitle: "アクセスが認証されていません",
  DateModified: '日付が変更されました',
  DateSent: '送信日',
  Decline: '衰退',
  Declined: '拒否されました',
  DeclineRegistration: '登録を拒否する',
  Delete: '削除',
  DeleteBidderInformation:
    "チームは、評価プロセスで適格な入札者ではない場合にのみ削除できます。このチームを適格入札者リストから削除します。",
  DeleteBidderTeamInformationLine1:
    "チームは空であり、評価プロセスで適格な入札者ではない場合にのみ削除できます。",
  DeleteBidderTeamInformationLine2:
    'このチームからユーザーを移動し、資格のある入札者リストからこのチームを削除します。',
  DeletePerson: '人物を削除する',
  DeletePersonConfirm: '人物を削除する',
  DeletePersonHeader: '[メール]を削除しますか?',
  DeletePersonMessage:
    '[メール] を削除すると、ルーム、すべてのアクティビティ レポート、履歴から永久に削除されます。この操作は元に戻すことができません。',
  DeleteRegistration: '登録を削除する',
  DeleteRequest: '削除リクエスト',
  DeleteSubteam: 'サブチームの削除',
  DeleteSubteamInformation:
    'サブチームは空の場合にのみ削除できます。このサブチームを削除するには、このサブチームから人を移動させてください。',
  DeleteTeam: 'チームを削除する',
  DeleteTeamInformation:
    'チームは空の場合のみ削除できます。このチームを削除するには、このチームからユーザーを移動してください。',
  DeletingTeamContainsPeople:
    'チームは空の場合のみ削除できます。このチームを削除するには、このチームからユーザーを移動してください。',
  DemoAllCaps: 'デモ',
  Deny: '拒否',
  DenyCopyingDisabled: 'コピー拒否が無効になっています',
  DenyEditingDisabled: '編集の拒否が無効になっています',
  DenyPrintingDisabled: '印刷拒否が無効になっています',
  DenySavingDisabled: '保存の拒否が無効になっています',
  DidntReceiveTheEmail: "メールを受信しませんでしたか?",
  Disable: '無効にする',
  DisableAccess: 'アクセスを無効にする',
  Disabled: '無効',
  DisabledUser: '障害のあるユーザー',
  DisableRegistrations: '登録を無効にする',
  DisableRegistrations_SentenceCase: '登録を無効にする',
  DisableRegistrationsAction: '無効にする',
  DisableRegistrationsInformation: '無効にすると、登録リンクはアクティブでなくなります。',
  Disallowed: '許可されていない',
  Document: 'ドキュメント',
  DocumentIndex: 'ドキュメント索引',
  DocumentIndexSecurity: 'ドキュメントインデックスのセキュリティ',
  Documents: 'ドキュメント',
  Down: '下',
  Draft: '下書き',
  Edit: '編集',
  EditAfterCloseDate: '締切日後に提出インデックスを編集する',
  EditDocumentSecurityTeam:
    'このチームには、印刷または保存のセキュリティ制御、アクセス、ウォーターマークが設定されていません。',
  EditDocumentSecurityTeamSubteam:
    'このサブチームには、印刷または保存のセキュリティ制御、アクセス、ウォーターマークが設定されていません。',
  EditingAllowed: '編集許可',
  EditingDenied: '編集が拒否されました',
  EditMultipleUsers: '複数のユーザーを編集',
  EditPeople: '人物を編集する',
  EditPerson: '人物を編集する',
  EditPersonFormInvitationMessage:
    '下のチェックボックスをクリックして、更新された電子メール アドレスに新しい招待状を送信します。',
  EditProfile: 'プロファイルの編集',
  EditSecurity_SentenceCase: 'セキュリティの編集',
  EditSecurityFor: 'セキュリティを編集する',
  EditSubteam: 'サブチームの編集',
  EditTeam: 'チームを編集する',
  Email: '電子メール',
  EmailAddress: '電子メールアドレス',
  EmailCanNotBeEmpty: '電子メールを空にすることはできません',
  EmailHasBeenSent: 'メールが送信されました。',
  EmailMessage: '電子メールメッセージは必須フィールドです',
  EmailNotifications: '電子メール通知',
  EmailNotValid: '電子メールが無効です',
  EmailPeople: '人々に電子メールを送信する',
  EmailPeopleSubjectRequiredSentenceCase: 'メールの件名は必須です',
  Emails: 'メール',
  EmailsNotValid: '電子メール [電子メール] が無効です',
  EmailSuccessMessage: 'メールは正常に送信されました。',
  EnableAccess: 'アクセスを有効にする',
  Enabled: '有効',
  EnableRegistrations: '登録を有効にする',
  EnableRegistrations_SentenceCase: '登録を有効にする',
  EnableRegistrationsAction: '有効にする',
  EnableRegistrationsInformation:
    '有効にすると、登録リンクがアクティブになり、パブリックに共有できます。',
  EnterIpAddressesRanges: 'IP アドレスまたは IP アドレス範囲を Enter キーで区切って入力します。',
  EnterOneOrMoreEmailAddresses: '各人へのメール',
  EnterYourLocationOrClosestCity: '現在地または最寄りの都市を入力してください',
  Every: '毎',
  ExpandAll_SentenceCase: 'すべて展開',
  ExportToExcel_SentenceCase: 'Excel にエクスポート',
  FieldIsRequired: '[フィールドは必須項目です',
  FieldMustBeAtMostMaxCharacters: '[フィールド] は最大 [max] 文字でなければなりません。',
  FileExtensionIsInList:
'ファイルの拡張子がこのデータ ルームで禁止されているファイル拡張子のリストにあるため、ファイル "[FileName]" をアップロードできません。\r\n\r\n[FileTypeList]\r\n\r\nこのファイルを許可されている形式に変換してください。',
  FileExtensionIsNotInList:
'ファイルの拡張子がこのデータ ルームで許可されているファイル拡張子のリストにないため、ファイル "[FileName]" をアップロードできません。\r\n\r\n[FileTypeList]\r\n\r\nこのファイルを許可されている形式に変換してください。',
  Filter: 'フィルタ',
  FirstName: 'ファーストネーム',
  Folder: 'フォルダ',
  ForRangesSeparateStartIp:
'範囲の場合、開始 IP と終了 IP をダッシュで区切ります。例: <b>1.1.1.1-1.1.1.6</b>',
  Friday: '金曜日',
  From: '開始日:',
  FullName: '氏名',
  FullText: '全文検索',
  FullTextText: 'ユーザーがドキュメント内を検索できるようにします。',
  GDPRInformation: 'GDPR情報',
  GlobalUnexpectedErrorMessage:
    "データルームに問題があります。当社のクライアントサービスチームにお問い合わせください。",
  GlobalUnexpectedErrorWithIdMessage:
    "データルームに問題があります。このコード &lt;[ErrorId]&gt; をクライアント サービス チームに引用してください。",
  GotIt: 'わかった',
  Guest: 'ゲスト',
  GuestUserRegistration: 'ゲストユーザー登録',
  GuideToUsingLink: 'リンクのご利用ガイド',
  Help: 'ヘルプ',
  HelpAndSupport: 'ヘルプサポート',
  HideMenu: 'メニューを隠す',
  High: '高い',
  History: '歴史',
  Hourly: '毎時',
  ID: 'ID',
  Importance: '重要度:',
  Important:'重要！',
  Information: '情報',
  Interval: '間隔',
  InvalidEmailAddress: '無効な電子メール アドレスです。',
  InvalidField: '無効な[フィールド]',
  InvalidPdf: 'エラー: このファイルは有効な PDF ドキュメントではありません。別のファイルで再試行してください。',
  Invitation: '招待',
  InvitationEmail: '招待メール',
  InvitationSuccessMessage: '招待状は正常に送信されました。',
  Invite: '招待する',
  InviteAGuest: 'ゲストを招待する',
  Invited: '招待されました',
  Invited_Users_: '招待済み',
  InvitePeople: '人を招待する',
  IpAccessList: 'IPアクセスリスト',
  IpAccessList_SentenceCase: 'IPアクセスリスト',
  IpAccessListCanNotBeEmpty: 'IP アクセス リストを空にすることはできません',
  IpAddressInvalidIpAccessList: "IP アクセス リストの IP '[IpAddress]' は有効な IP アドレスではありません。",
  IpAddressInvalidRangeIpAccessList:
"IP アクセス リストの IP 範囲 '[IpRange]' の開始アドレスが終了 IP アドレスよりも後になっています。開始 IP は終了 IP 以前でなければなりません。",
  IpLimitRangeIpAccessList:
"IP アクセス リストの IP 範囲 '[IpRange]' は [NumOfChars] 文字以内で入力してください。[UsedNumOfChars] 文字で入力されました。",
  IpUserAccessListLimit:
'IP アクセス リストを [MaxNumberOfCharacters] 文字以内で入力してください。現在 [EnteredNumberOfCharacters] 文字で入力されています。',
  IWillInviteLater:"後で招待します",
  JobTitle: '役職',
  JobTitleProfession: '役職・職業',
  Last30days: '過去 30 日間',
  Last7days: '過去 7 日間',
  LastMonth: '先月',
  LastName: '苗字',
  LastWeek: '先週',
  LearnMore: 'もっと詳しく知る',
  LicenceChatHelp: '専門家に相談してください。',
  LicenceErrorHelp: '助けが必要？ ',
  LicenceErrorMessage:
    'あなたは免許を持っていません。それを超えると、サブスクリプションがアップグレードされます。',
  LicencePack: 'ユーザーチーム前払いパック',
  LicencePrompt:
    '保留中の招待がある場合、ライセンスは招待が受け入れられた後にのみ使用されます。それを超えると、サブスクリプションがアップグレードされます。',
  LicenceSectionPlan: 'あなたの計画',
  LicenceTooltip: 'ライセンスは、人がプラットフォームに存在すると次のように消費されます。',
  LicenceTooltipStep1: 'プラットフォームパスウェイのみ',
  LicenceTooltipStep2: 'プラットフォーム データ ルームのみ',
  LicenceTooltipStep3: '両方',
  LicenseMessage:
    '承認された招待には、サブスクリプションのライセンスが使用されます。それを超えると、サブスクリプションがアップグレードされます。',
  LimitedCharacterLength: '[1] は [0] 文字を超えることはできません。',
  LinkCopied: 'リンクがコピーされました',
  LinkCopiedToClipboard: 'リンクがクリップボードにコピーされました',
  LinkedDocuments: 'リンクされたドキュメント',
  Loading: '読み込み中',
  LoadingThreeDots: 'ロードしています…',
  Location: '位置',
  LoggedInToDate_SentenceCase: '今日までにログインしました',
  LoginIpAccess: 'ログインIPアクセス',
  LoginLowerCase: 'ログイン',
  LoginsLowerCase: 'ログイン',
  Logout: 'ログアウト',
  Low: '低い',
  Manage: '管理',
  ManageQnaSubjects: 'Q&amp;Aの件名を管理する',
  Medium: '中くらい',
  Message: 'メッセージ',
  MessageEmpty: 'メッセージを空にすることはできません',
  Minute: '分',
  Minutes: '分',
  ModifiedAgo: '[時間]前に変更されました',
  ModifiedBy: 'によって変更',
  Monday: '月曜日',
  MyAccount: '私のアカウント',
  Name: '名前',
  NavigateAwayConfirm: '離れる',
  NavigateAwayWarning: 'ページから出る？',
  NavigateAwayWarningMessage: '加えた変更は保存されません。',
  Never: '許可しない',
  NeverLoggedIn_SentenceCase: 'ログインしたことがありません',
  No: 'いいえ',
  NoCompanyContact: '会社からの連絡はありません',
  NoDemonstration: 'デモンストレーションなし',
  NonAdminSideBankruptcy: 'バイサイド',
  NonAdminSideBankruptcy_EmailHeading: '購入側の人にメールを送信する',
  NonAdminSideBankruptcy_InviteHeading: '招待状を送信してサイドメンバーを購入する',
  NonAdminSideDebt: '投資家',
  NonAdminSideDebt_EmailHeading: '投資家にメールを送信する',
  NonAdminSideDebt_InviteHeading: '投資家に招待状を送信する',
  NonAdminSideDocumentRepository: '外部の',
  NonAdminSideDocumentRepository_EmailHeading: '外部に電子メールを送信する',
  NonAdminSideDocumentRepository_InviteHeading: '外部に招待状を送信する',
  NonAdminSideFundraising: '金融業者',
  NonAdminSideFundraising_EmailHeading: '金融業者に電子メールを送信する',
  NonAdminSideFundraising_InviteHeading: '投資家に招待状を送信する',
  NonAdminSideJointVenture: '外部の',
  NonAdminSideJointVenture_EmailHeading: '外部に電子メールを送信する',
  NonAdminSideJointVenture_InviteHeading: '外部に招待状を送信する',
  NonAdminSideMerger: 'バイサイド',
  NonAdminSideMerger_EmailHeading: '購入側の人にメールを送信する',
  NonAdminSideMerger_InviteHeading: '招待状を送信してサイドメンバーを購入する',
  NonAdminSideOther: '外部の',
  NonAdminSideOther_EmailHeading: '外部に電子メールを送信する',
  NonAdminSideOther_InviteHeading: '外部に招待状を送信する',
  NonAdminSidePlacement: '投資家',
  NonAdminSidePlacement_EmailHeading: '投資家にメールを送信する',
  NonAdminSidePlacement_InviteHeading: '投資家に招待状を送信する',
  NonAdminSidePublicOffer: '外部の',
  NonAdminSidePublicOffer_EmailHeading: '外部に電子メールを送信する',
  NonAdminSidePublicOffer_InviteHeading: '外部に招待状を送信する',
  NonAdminSideTargeted: '対象チーム',
  NonAdminSideTargeted_EmailHeading: '対象チームにメールを送信する',
  NonAdminSideTargeted_InviteHeading: '対象チームに招待を送信する',
  NonAdminSideTender: '入札者',
  NonAdminSideTender_EmailHeading: '入札者にメールを送信する',
  NonAdminSideTender_InviteHeading: '入札者に招待状を送信する',
  None: 'なし',
  NoQuestionPermission: "質問の件名を設定する権限がありません。",
  NoQuestionSubjects: "まだ質問の件名がありません。",
  NoRecordsFound: 'レコードが見つかりません。',
  Normal: '普通',
  NoRooms: "現在ルームがありません",
  NoRoomsMatch: '部屋が見つかりませんでした',
  NoScorecards: "現在スコアカードがありません",
  NoScorecardsMatch: 'スコアカードが見つかりません',
  Notes: 'ノート',
  Notifications: '通知',
  NotInvited: '招待されていません',
  NotInvited_Users__SentenceCase: '招待されていません',
  NotVerified_Users__SentenceCase: '検証されていない',
  Off: 'オフ',
  Ok: 'わかりました',
  OK: 'わかりました',
  OkAllCaps: 'OK',
  On: 'の上',
  OnceOnlyAndWhenTermsAreUpdated: '条件が更新されたときに 1 回のみ',
  OnEveryLogin: '毎回ログイン時',
  OnfirstLogin: '初回ログイン時',
  OnlyEnabledUsersCanBeInvited: '有効なユーザーのみを招待できます。',
  OnlyEnabledUsersCanBeInvitedSec2: 'アクセスが有効になっている人のみを招待できます。',
  Optional: 'オプション',
  Or: 'または',
  OtherTeamMembers: '[サイズ] 他のチームメンバー',
  Pending: '保留中',
  PendingScorecards: '保留中のスコアカード',
  PeopleFormWarningText:
    "ルームの役割「管理者」を適用すると、ユーザーに無制限のアクセスが許可されます。チームのセキュリティ設定による制限を受けません",
  PeopleLowerCase: '人々',
  PerDay: '1日あたり',
  Period: '期間',
  PerMonth: '月あたり',
  PersonHistory: '人物歴',
  PersonHistoryFor: 'の歴史',
  PersonLowerCase: '人',
  PerWeek: '週あたり',
  Phone: '電話',
  PhoneNumber: '電話番号',
  PhoneNumber_SentenceCase: '電話番号',
  PrintingAllowed: '印刷許可',
  PrintingDenied: '印刷は拒否されました',
  Priority: '優先度',
  PrivacyPolicy: 'プライバシーポリシー',
  Profession: '職業',
  Profile: 'プロフィール',
  Properties: 'プロパティ',
  ProvideBilling:'請求の詳細を入力してください',
  ProvidePayment:'お支払いの詳細を入力してください',
  Qna: 'Q&amp;A',
  QnaNotificationSwitchOff: 'メールが届きません',
  QnaNotificationSwitchOn: 'すぐに更新情報を受け取ることができます',
  QnaRole: 'Q&A ロール',
  QnaRole_SentenceCase: 'Q&amp;Aの役割',
  QnaSubjects: 'Q&amp;Aの件名',
  QnaSubjectSecurity: 'Q&amp;A テーマ セキュリティ',
  QnaSubmissionsLimit: '質問の提出制限',
  QnaSubmissionsOnWeekends: '週末の質問提出',
  Ready: '準備ができて',
  ReasonForRequest: 'リクエストの理由',
  Recipients: '受信者',
  RecipientsOfEmailNote_Gen2:
'この<nowrap>電子メール</nowrap>の受信者には他の受信者のアドレスが表示されないため、別のユーザー グループのユーザーに送信する安全な方法です。',
  Register: '登録する',
  RegisterYourInterest: '興味のあることを登録してください',
  RegisterYourInterest_SentenceCase:
    '審査のために<strong>[dataRoomName]</strong>管理者にリクエストを送信します。登録が承認されると、プロジェクトに招待されます。',
  Registration: '登録',
  Registration_LowerCase: '登録',
  RegistrationDetails: '登録内容',
  RegistrationLinkDisabled: '登録リンクが無効です',
  RegistrationRequestSubmitted: '登録リクエストが送信されました',
  Registrations: '登録',
  Registrations_LowerCase: '登録',
  RegistrationTextCopy:
  '入札者チームまたはゲストが興味を登録できるようにしました。提供されたリンクをコピーして、ユーザーの登録を許可します。新しい登録者がこのリストに表示され、確認して招待できます。このリスト内のユーザーは、招待してチームに追加するまでアクセスできません。',
  Request: 'リクエスト',
  RequestAccess: 'アクセスのリクエスト',
  RequestAccessDialogDescription: '追加のアクセスが必要なユーザーに詳細を提供します。あなたのリクエストは管理者によって検討されます。',
  RequestAccessDialogTitle: '追加アクセスのリクエスト',
  RequestAccessEmptyStateText: '新しい登録者がこのリストに表示され、確認できるようになります。このリスト内のユーザーは、管理者がチームに追加するまでアクセスできません。',
  RequestDetails: 'リクエストの詳細',
  RequestorEmail: 'リクエスタのメールアドレス',
  Required: '必須',
  ResendEmail: 'メールを再送',
  ResendVerificationLink: '確認リンクを再送信する',
  Reset: 'リセット',
  Review: 'レビュー',
  RoomRole_SentenceCase: '部屋の役割',
  RoomRoleAdminSubteamHelp: '管理者をサブチームに追加することはできません。',
  RoomRoleHelp: "ルームの役割は、ルーム内でのユーザーのアクセス レベルを決定します。",
  Rooms: '部屋',
  RoomsAdvert1:
    "Dropbox Showcase を使用すると、パートナーやクライアントと作業を美しく共有し、進捗状況を追跡し、誰が何を見たのかをすべて 1 か所で把握できます。",
  RoomsAdvert2: 'Showcase は Dropbox Business Advanced および Enterprise ユーザーが利用できます。',
  RoomsAdvertLink: 'もっと詳しく知る',
  RoomsAdvertTitle: 'パフォーマンスの高い取引を実行する',
  Saturday: '土曜日',
  Save: '保存',
  SaveAndInvitePeople: '保存して人を招待する',
  Saving: '保存',
  SavingAllowed: '保存可能',
  SavingDenied: '保存は拒否されました',
  SavingFailed: '保存に失敗しました',
  Scorecards: 'スコアカード',
  ScorecardsAdvert1:
    "Dropbox Showcase を使用すると、パートナーやクライアントと作業を美しく共有し、進捗状況を追跡し、誰が何を見たのかをすべて 1 か所で把握できます。",
  ScorecardsAdvert2: 'Showcase は Dropbox Business Advanced および Enterprise ユーザーが利用できます。',
  ScorecardsAdvertLink: 'もっと詳しく知る',
  ScorecardsAdvertTitle: 'パフォーマンスの高い取引を実行する',
  Search: '検索',
  Secure: '安全な',
  SecureByDefault: 'デフォルトで安全',
  SecureOffice: '安全なオフィス',
  SecureOfficeText:
    'Secure Office はビューを追跡し、Microsoft Word、Excel、PowerPoint ファイルの保存、コピー、印刷を無効にすることができます。',
  SecureOfficeTitle: 'Microsoft Officeドキュメントのセキュリティ（Secure Office）',
  SecureOfficeWarning:
    'Secure Office は、Apple iOS や Android デバイスなどの多くの一般的なデバイスと互換性がありません。この設定により、互換性のあるシステムを持たないユーザーはこれらのファイルを表示できなくなります。',
  Security: 'セキュリティ',
  SecurityControls: 'セキュリティ管理',
  SecurityControlsOff: 'セキュリティ制御がオフになっている',
  SecurityControlsOffDescription:
    'セキュリティ制御がオフの場合、ダウンロード後のこれらのドキュメントの使用は制限されません。',
  SecurityControlsOn: 'セキュリティ制御がオンになっています',
  SecurityControlsOnDescription:
    'セキュリティ制御がオンになっている場合、Microsoft Office および PDF ドキュメントの使用状況が追跡されます。チームのアクセスが取り消された場合、またはルームが無効になった場合、保存されたコピーはリモートで無効になります。',
  SecurityControlsOnExtraDescription:
    '保存、印刷、編集、コピーを制限することもできます (コピー制限は Microsoft Office ドキュメントにのみ使用できます)。',
  SecurityGroup_SentenceCase: 'セキュリティグループ',
  SecurityPermissions: 'セキュリティ権限',
  Select: '選択',
  SelectPeople_SentenceCase: '人を選択する',
  SelectSubTeam: 'サブチームを選択してください。',
  SelectTeam: 'チームを選択してください。',
  SelectUsers_SentenceCase: '人を選択する',
  SelectUserType: 'ユーザー タイプを選択してください。',
  SelfRegistrationHeader: '自己登録',
  SelfRegistrationToolIntroDialogHeading:
    'ゲストユーザーの登録を許可する',
  SelfRegistrationToolIntroDialogHelpLink:
    '入札者登録に関するヘルプとサポートを表示する',
  SelfRegistrationToolIntroDialogTitle1:
    '購入側/入札者ページのアクション メニューでゲスト ユーザー登録を有効にします。',
  SelfRegistrationToolIntroDialogTitle2:
    '提供されたリンクを Web ページまたは作成した電子メールで共有します。',
  SelfRegistrationToolIntroDialogTitle3:
    '登録ユーザーはレビューして招待できます。',
  SellSide: 'セルサイド',
  SellSide_InviteHeading: '販売サイドの人に招待を送信する',
  SellSide_SentenceCase: 'セルサイド',
  Send: '送信',
  SendEmailInvitation: '招待メールを送信する',
  SendEmailInvitations: '招待メールを送信する',
  SendGuestInvitation:'ゲストユーザーの招待状を送信する',
  SendInvitationSubjectRequiredSentenceCase: '招待メールには件名が必要です',
  SendInvite_SentenceCase: '招待状を送信する',
  SendInvites: '招待状を送信する',
  SendInviteToSubscription: 'サブスクリプションに招待する',
  SendUpdates: '更新情報を送信する',
  SentAgo: '[時間]前に送信されました',
  SentDocumentChanged: 'ドキュメントが追加または変更されたときに送信されます',
  SessionTimeout: 'セッションタイムアウト',
  SetSecurityControls: 'セキュリティ制御を設定する',
  SetSecurityControlsBeforeAddingPeople: '人を追加する前にセキュリティ制御を設定します。',
  SetSubmissionLimits: '送信制限を設定する',
  SetSubmissionLimitsDescription:
    'チームが 1 日、1 週間、または 1 か月あたりに質問できる質問の数を制限する',
  ShouldBeEmailEmail: '入力した電子メールは有効な電子メール アドレスではありません。',
  ShowActivityInReports_SentenceCase: 'アクティビティをレポートに表示する',
  ShowDetails: '詳細を表示',
  ShowMore: 'もっと見せる',
  Status: 'ステータス',
  StayOnPage: '設定がコピーされている間、この画面をそのままにしておいてください',
  Subject: '主題',
  Subject_Email_: '主題',
  SubjectEmailHint: 'メールの件名は必須フィールドです',
  SubjectGroupNotSet: '対象グループが設定されていません',
  SubmissionIndex: '提出インデックス',
  SubmissionIndexesSecurity: '送信インデックスのセキュリティ',
  SubmissionIndexTopLevelFoldersSecurity: '送信インデックスの最上位フォルダーのセキュリティ',
  Submissions: '提出物',
  SubTeam: 'サブチーム',
  SubteamName: 'サブチーム名',
  SubTeamNameAlreadyExists: '同じ名前のサブチームがすでに存在します。',
  SubteamNameValidation: 'サブチーム名を空にすることはできません',
  Success: '成功',
  Sunday: '日曜日',
  SwitchTeam_Confirm: 'はい、チームを変えます',
  SwitchTeam_Title: 'チームを切り替える',
  SwitchTeam_WarningMessage:
    'いくつかの変更を加えたようです。保存せずに終了すると、変更内容は失われます。',
  SydneyTime: 'シドニー時間',
  Team: 'チーム',
  TeamColour: 'チームカラー – [色]',
  TeamHistory: 'チームの歴史',
  TeamHistoryFor: 'チームの歴史',
  TeamMemberEmails: 'チームメンバーのメールアドレス',
  TeamMemberEmailsInfo: 'セミコロンまたはカンマで区切られたチームメンバーの電子メール。登録が承認されると、全員が招待されます。',
  TeamName: 'チームの名前',
  TeamNameAlreadyExists: '同名のチームが既に存在します。',
  TeamNameValidation: 'チーム名を空にすることはできません',
  TeamPermissions: 'チームの権限',
  TermsAndConditions: '利用規約',
  TheSafestWayToEnterEmails:'電子メールを入力する最も安全な方法は、コピーして貼り付けることです。各電子メール アドレスがカンマ、セミコロン、スペース、または改行で区切られていることを確認してください。',
  ThirdPartyIntegrations_SentenceCase: 'サードパーティとの統合',
  ThirdPartyIntegrationsText:'サードパーティの統合をすべて許可するか、一部許可するか、またはまったく許可しません。',
  ThisSubTeamCannotAccess: 'このサブチームはフォルダーやドキュメントにアクセスできません',
  ThisTeamCannotAccess: 'このチームはフォルダーやドキュメントにアクセスできません',
  Thursday: '木曜日',
  Time: '時間',
  TimePeriodAm: '午前',
  TimePeriodPm: '午後',
  To: '終了日:',
  ToBeAbleToInvitePeopleToYourDataRoom:'データ ルームに人々を招待できるようになります。',
  Today: '今日',
  ToInviteFirstGuestAndGoLive:'最初のゲスト ユーザーを招待してライブを開始するには',
  Topic: '【トピックス】トピックス',
  Topics: '【トピックス】トピックス',
  Total: '合計',
  Tuesday: '火曜日',
  Unknown: '未知',
  Up: '上',
  Update: 'アップデート',
  UserCanLogInFromAnyIp: 'このユーザーはすべての IP アドレスからログインできます。',
  UserCanLogInOnlyFromIpsBelow: 'このユーザーは次に指定した IP アドレスからのみログインできます。',
  UserExistsWithEmail: 'ユーザーは電子メール [0] で存在します',
  UserGroup: 'ユーザー グループ',
  UserInvitationLinkNote_Gen2:
    '「[invitation_link]」タグは、電子メールの送信時に生成されたハイパーリンクに置き換えられます。メールの本文に記載する必要があります。',
  UserInvitationLinkTagIsMissing:
    'アクセスリンクがないと招待メールを送信できません。 [invitation_link] を括弧付きでコピーし、受信者がアクセス権を作成するためのメッセージに追加します。',
  UserProperties: 'ユーザー プロパティ',
  UserStatus: 'ユーザーステータス',
  UserType: 'ユーザータイプ',
  UserTypeError: 'ルームロールが必要です',
  UserUpdated: '人物は正常に更新されました。',
  Verified: '確認済み',
  Verified_Users_: '確認済み',
  VerifyYourEmail:'あなたの電子メールを確認します',
  VerifyYourEmailAddressToInviteUsers: 'ユーザーを招待するにはメール アドレスを確認してください。',
  View: '表示',
  ViewDetails: '詳細を見る',
  ViewIndexAsGhost: '彼らは何を見ることができるのでしょうか？',
  ViewSecurity: 'セキュリティを表示する',
  Warning: '警告',
  Watermark: 'ウォーターマーク',
  Wednesday: '水曜日',
  Weekly: '毎週',
  WeHaveSentTheVerificationLinkTo:"確認リンクを[メール]に送信しました",
  Yes: 'はい',
  YouCanAddDescriptionsAfterComma:
'説明を付け加える場合はカンマで区切ります。例: <b>1.1.1.1, London office IP</b>',
  YourCurrentIpAddress:
'<b>メモ:</b> 現在の IP アドレスは [IP Address] です。<a [href]>ここをクリック</a>すると、現在の IP アドレスがリストに追加されます。',
  YourCurrentIpAddressSec2: '現在の IP アドレスをホワイトリストに追加します。',
  YouWillReceiveAConfirmationInYourEmailAboutNextSteps:
    "次のステップについての確認メールが届きます。",
};
