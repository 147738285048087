// @flow

import type { MessageContentType } from 'app/components/bannerMessageContent/bannerMessageContentTypes';

export const BANNER_MESSAGE_TYPES = Object.freeze({
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARN: 'warning',
});

export type BannerMessageType = $Values<typeof BANNER_MESSAGE_TYPES>;

export type BannerMessageDataType = {
  type: BannerMessageType,
  timeStamp: number,
  id: string,
  autoDismiss?: boolean,
  autoDismissDelay?: number,
  content?: MessageContentType,
};

export type BannerMessagesDataType = Array<BannerMessageDataType>;
