// @flow

import * as R from 'ramda';
import { USER_TYPE_CODES } from 'app/constants';
import { getFilteredTeamData } from 'app/pages/teams/teamsHelpers';
import type { People } from 'app/types/personTypes';
import type { Team, Teams, Subteam } from 'app/types/teamsTypes';
import type { TeamsFilter } from 'app/pages/teams/filter/filterTypes';
import type { DisplayType } from 'app/components/autocompleteLegacy';
import type { FilterItem } from 'app/components/multiSelect/multiSelectTypes';

export const randomArrayValue = <T>(arr: Array<T>): ?T =>
  arr.length ? arr[Math.floor(Math.random() * arr.length)] : null;

export const isAdministrator = (code: string): boolean => code === USER_TYPE_CODES.ADM;

export const hasPeopleFilter = (filters: TeamsFilter): boolean =>
  !!(
    filters.emailFilter.selected.length ||
    filters.fullNameFilter.selected.length ||
    filters.jobTitleProfessionFilter.selected.length ||
    filters.qnaFilter.selected.length ||
    filters.statusFilter.selected.length ||
    filters.userTypeFilter.selected.length
  );

export const filterActive = (filters: TeamsFilter) =>
  !!Object.keys(filters).reduce(
    (arr, filter) => arr.concat(filters[filter].selected.map(item => item.id)),
    [],
  ).length;

export const lozengeActive = (filters: TeamsFilter) =>
  Object.keys(filters).reduce(
    (acc, filter) =>
      // $FlowFixMe
      acc.concat(
        filters[filter].selected.map(item => ({
          filterName: filter,
          item,
        })),
      ),
    [],
  );

// FIXME: Flow complains about People | Teams I created a second function
export const getFilteredData = (data: Array<Object>, filter: DisplayType): Array<Object> => {
  const filterOn = filter.selected.length > 0;
  return filterOn
    ? data.filter(item =>
        filter.selected.some(filteredItem => {
          const key = filteredItem.data && filteredItem.data.key;
          return filteredItem.id === item[key];
        }),
      )
    : data;
};

export const getFilteredStatusData = (data: People, filteredItems: Array<FilterItem>): People =>
  filteredItems.length
    ? data.filter(item =>
        filteredItems.some(filteredItem => {
          switch (filteredItem.id) {
            case 'enabled':
              return !item.disabled;
            case 'disabled':
              return !!item.disabled;
            case 'invited':
              return !!item.invited;
            case 'notInvited':
              return !item.invited;
            default:
              return false;
          }
        }),
      )
    : data;

export const getFilteredPeopleData = (data: People, filters: TeamsFilter) => {
  let filteredPeople = getFilteredData(data, filters.userTypeFilter);
  filteredPeople = getFilteredData(filteredPeople, filters.qnaFilter);
  filteredPeople = getFilteredData(filteredPeople, filters.teamsFilter);
  filteredPeople = getFilteredData(filteredPeople, filters.fullNameFilter);
  filteredPeople = getFilteredData(filteredPeople, filters.emailFilter);
  filteredPeople = getFilteredData(filteredPeople, filters.jobTitleProfessionFilter);
  /* $FlowFixMe: getFilteredStatusData data is incorrect in some cases */
  filteredPeople = getFilteredStatusData(filteredPeople, filters.statusFilter.selected);
  return filteredPeople;
};

export const getDocumentType = (extension: ?string) => {
  switch (extension) {
    case 'pdf':
      return 'acrobat';
    case 'mp3':
    case 'wav':
    case 'mid':
      return 'audio';
    case 'xls':
    case 'xlsx':
    case 'xltx':
    case 'xlsb':
    case 'xlt':
    case 'xlw':
    case 'xsl':
    case 'xlsm':
    case 'xlst':
      return 'excel';
    case 'crd':
      return 'contact';
    case 'eml':
    case 'msg':
    case 'oft':
      return 'email';
    case 'bmp':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'gif':
    case 'mht':
    case 'mhmt':
    case 'tiff':
      return 'image';
    case 'dwg':
    case 'psd':
    case 'indd':
    case 'dxf':
    case 'shx':
      return 'layoutanddesign';
    case 'potm':
    case 'ppsm':
    case 'pptm':
    case 'potx':
    case 'pptx':
    case 'pot':
    case 'pps':
    case 'ppt':
      return 'powerpoint';
    case 'txt':
    case 'log':
    case 'rtf':
    case 'csv':
      return 'text';
    case 'avi':
    case 'mov':
    case 'mpg':
    case 'mp4':
      return 'video';
    case 'vdx':
    case 'vst':
    case 'vsx':
    case 'vsd':
    case 'vss':
    case 'vtx':
      return 'visio';
    case 'doc':
    case 'docx':
    case 'docm':
    case 'dot':
    case 'dotm':
      return 'word';
    case 'css':
    case 'js':
    case 'dtd':
    case 'cp':
    case 'xml':
      return 'code';
    default:
      return 'generic';
  }
};

const valueToQuery = (key: string, value: Array<string | number> | string | number | boolean) => {
  if (value instanceof Array) {
    return value.reduce((accumulator, current) => [...accumulator, `${key}=${current}`], []);
  }
  return [`${key}=${value.toString()}`];
};

export const formQueryString = (params: Object): string => {
  if (params) {
    const keys = Object.keys(params);
    const query = keys.reduce((accumulator, key) => {
      const newQueries = valueToQuery(key, params[key]);
      return [...accumulator, ...newQueries];
    }, []);
    return query.length > 1 ? query.join('&') : query.join('');
  }
  return '';
};

export const sortByNameEmail = R.sortWith([R.ascend(R.prop('name')), R.ascend(R.prop('email'))]);

export const getPeopleInTeam = (
  people: People,
  group: Team | Subteam,
  isSubteam: boolean,
): People => {
  if (isSubteam) {
    return (
      people
        .filter(person => person.subteamId === group.subteamId)
        // Never show administrators in sub teams
        .filter(person => !isAdministrator(person.userTypeCode))
    );
  }

  return people
    .filter(person => person.teamId === group.teamId)
    .filter(person => {
      // If a user is an administrator, show them at the team level
      if (isAdministrator(person.userTypeCode)) {
        return true;
      }
      return person.subteamId === group.subteamId;
    });
};

// This function prepares teams/people data for use in components
// - if there is a team name filter but no people-related filters, it filters the
//   teams to show only matching teams
// - if there are people-related filters, it filters the teams to only those
//   with matching people, and also filters the people visible within each team
// - regardless of any filters, it combines the team, subteam and people into the shape
//   required by the components
export const getFilteredTeamAndPeopleData = (
  filters: TeamsFilter,
  allTeams: Teams,
  allPeople: People,
): * => {
  // First, filter the people array if necessary
  let filteredPeople = hasPeopleFilter(filters)
    ? getFilteredPeopleData(allPeople, filters)
    : allPeople;

  filteredPeople = sortByNameEmail(filteredPeople);

  // Second, filter the team array if necessary, including selecting teams where
  // a subteam is selected. Also, while looping over teams, add people array to teams
  const filteredTeams = getFilteredTeamData(allTeams, filters.teamsFilter);

  let teamsWithPeople = filteredTeams.map(team => {
    const hasSubteams = !!(team.subteams && team.subteams.length);

    const result = {
      ...team,
      isSubteam: false,
      hasSubteams,
      people: getPeopleInTeam(filteredPeople, team, false),
      // We want to store a count of the people before the filter is applied
      countPeople: allPeople.filter(person => person.teamId === team.teamId).length,
    };

    if (hasSubteams) {
      result.subteams = result.subteams.map(subteam => ({
        ...subteam,
        isSubteam: true,
        colour: team.colour,
        people: getPeopleInTeam(filteredPeople, subteam, true),
        // We want to store a count of the people before the filter is applied
        countPeople: allPeople.filter(person => person.subteamId === subteam.subteamId).length,
      }));
    }

    return result;
  });

  // Third, if there is a people filter, remove any teams where the team and subteams have no people
  if (hasPeopleFilter(filters)) {
    teamsWithPeople = teamsWithPeople
      .map(team => {
        const result = { ...team };

        // If there are subteams, filter out any subteams that don't have the right people
        if (team.subteams && team.subteams.length) {
          result.subteams = team.subteams.filter(
            subteam => subteam.people && subteam.people.length,
          );
        }

        // Now filter out any teams that have no subteams and no people
        const hasNoPeople = !(team.people && team.people.length);
        const hasNoSubteams = !(result.subteams && result.subteams.length);
        if (hasNoPeople && hasNoSubteams) return false;

        return result;
      })
      .filter(Boolean);
  }

  return teamsWithPeople;
};

// TODO: rewrite to be more testable;
const convertToArrayBuffer = /* istanbul ignore next */ (s: any) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; i += 1) {
    view[i] = s.charCodeAt(i) & 0xff; // eslint-disable-line no-bitwise
  }
  return buf;
};

export const decode = (content: string) => window.atob(content);

// TODO: rewrite to be more testable;
export const downloadExcel = /* istanbul ignore next */ (result: Object) => {
  const decodedData = decode(result.data.FileContents);
  const blob = new Blob([convertToArrayBuffer(decodedData)], {
    type: `${result.data.ContentType}`,
  });
  /* istanbul ignore if */
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, result.data.FileDownloadName);
  } else {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = result.data.FileDownloadName;
    a.click();
  }
};

export const isShiftCmdCtrlKey = (e: SyntheticMouseEvent<*>): boolean =>
  e.metaKey || e.ctrlKey || e.shiftKey;

export const convertToNumber = (numberInString: ?string) => parseInt(numberInString, 10);

export const wait = (ms: number) => new Promise<void>(resolve => setTimeout(resolve, ms));
