// @flow
import { ACTION_TYPES } from 'app/constants';
import { BANNER_MESSAGE_TYPES } from 'app/components/bannerMessage/bannerMessageTypes';
import type { BannerMessagesDataType } from 'app/components/bannerMessage/bannerMessageTypes';

const bannerMessagesReducer = (
  state: BannerMessagesDataType = [],
  action: Object,
): BannerMessagesDataType => {
  switch (action.type) {
    case ACTION_TYPES.BANNER_MESSAGES.ITEM_DISMISSED: {
      const { data } = action;
      return state.filter(error => error.id !== data);
    }

    case ACTION_TYPES.CLEAR_ERRORS: {
      return state.filter(x => x.type !== BANNER_MESSAGE_TYPES.ERROR);
    }

    case ACTION_TYPES.BANNER_MESSAGES.CLEAR_ALL: {
      return [];
    }

    case ACTION_TYPES.BANNER_MESSAGES.ITEM_ADD: {
      return [...state, action.data];
    }

    case ACTION_TYPES.BANNER_MESSAGES.SERVER_ERRORS_ADD: {
      return [...state, ...action.data];
    }

    default:
      return state;
  }
};

export default bannerMessagesReducer;
