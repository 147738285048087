// @flow

import type { PermissionsType } from 'app/types/permissionsTypes';

export const isTestEnv = process.env.NODE_ENV === 'test';

export const objectCodes = {
  ATT: 'attachment',
  BEM: 'bulkEmail',
  BSR: 'buySideReports',
  DOC: 'document',
  DRM: 'dataRoom',
  QSG: 'questionSubjectGroup',
  QUS: 'questionSubject',
  RPT: 'report',
  SEG: 'securityGroup',
  SEP: 'securityPolicy',
  USG: 'userGroup',
  USR: 'user',
  WMK: 'watermark',
};

export const actionCodes = {
  VIE: 'view',
  SEL: 'select',
  MOD: 'modify',
  SIT: 'selectIfTeamMember',
  UPD: 'update',
};

export const userPermissionDefaults: PermissionsType = {
  attachment: {
    update: false,
  },
  bulkEmail: {
    view: false,
  },
  buySideReports: {
    view: false,
  },
  document: {
    view: false,
    select: false,
    modify: false,
  },
  dataRoom: {
    modify: false,
    select: false,
  },
  report: {
    view: false,
  },
  securityGroup: {
    select: false,
    modify: false,
  },
  securityPolicy: {
    modify: false,
    select: false,
  },
  userGroup: {
    modify: false,
    select: false,
  },
  user: {
    modify: false,
    select: false,
    selectIfTeamMember: false,
  },
  watermark: {
    select: false,
    modify: false,
  },
};

export const OTHER_JOB_TITLE_ID: number = 0;
