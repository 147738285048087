// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Dialog as ACEDialog, Button } from '@ansarada/ace-react';
import { ACTION_TYPES, SITE_DIALOG_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import type { DialogProps } from './dialogTypes';

export const Dialog = (props: DialogProps) => {
  if (!props.data.open) return null;

  const {
    data,
    dispatch,
    triggerButton,
    triggerCloseAction,
    size = 'Medium',
    children,
    siteDialogType,
    showCloseButton = true,
    closeButtonText,
    extraAction,
    sensitiveContent,
    onClose,
  } = props;

  const footerPrimaryActions = [];

  if (showCloseButton) {
    footerPrimaryActions.push(
      <Button
        data-test-id="btnClose"
        key="closeButton"
        variant={!triggerButton && !extraAction ? 'Primary' : undefined}
        onClick={() => {
          dispatch({ type: triggerCloseAction || ACTION_TYPES.DIALOG_TOGGLE });
          dispatch({ type: ACTION_TYPES.CLEAR_ERRORS });
          // FIXME: move the below logic out of this component;
          if (siteDialogType === SITE_DIALOG_TYPES.SINGLE_ITEM_EDIT) {
            dispatch({ type: ACTION_TYPES.SECURITY.ITEM_EDITED_CLEAR });
          }
          if (typeof onClose === 'function') {
            onClose();
          }
        }}
      >
        {closeButtonText || translate('Cancel')}
      </Button>,
    );
  }

  if (triggerButton) {
    footerPrimaryActions.push(
      <Button
        key="triggerButton"
        onClick={triggerButton.onClick}
        data-test-id={
          triggerButton['data-test-id'] ? triggerButton['data-test-id'] : 'triggerButton'
        }
        loading={data.isSaving}
        variant={triggerButton.variant}
        disabled={!!triggerButton.disabled}
      >
        {triggerButton.children}
      </Button>,
    );
  }

  if (extraAction) {
    footerPrimaryActions.push(
      <Button key="extraAction" onClick={extraAction.onClick} variant={extraAction.variant}>
        {extraAction.children}
      </Button>,
    );
  }

  return (
    <ACEDialog
      data-test-id={props['data-test-id']}
      data-ansarada-ccd={sensitiveContent || undefined}
      title={data.title}
      size={size}
      onClose={() => {
        dispatch({ type: triggerCloseAction || ACTION_TYPES.DIALOG_TOGGLE });
        if (typeof onClose === 'function') {
          onClose();
        }
      }}
      footerPrimaryActions={footerPrimaryActions}
    >
      {children}
    </ACEDialog>
  );
};

const mapStateToProps = (state: Object): Object => ({
  data: state.page.dialog,
});

export default connect<_, _, *, *>(mapStateToProps)(Dialog);
