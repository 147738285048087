// @flow

import { ACTION_TYPES } from 'app/constants';
import { FILTER_TYPES } from 'app/components/emailForm/constants';
import type { SendInvitationState } from './sendInvitationTypes';

const defaultSendInvitation: SendInvitationState = {
  isLoaded: false,
  subject: '',
  body: '',
  attachments: [],
  selectedPeopleIds: [],
  teams: [],
  people: [],
  userTypeMap: {},
  qnaRoleMap: {},
  filterType: FILTER_TYPES.ALL,
  isSubmitting: false,
  message: '',
  isUploadingAttachment: false,
  hasBeenSent: false,
};

const SendInvitationReducer = (
  state: SendInvitationState = defaultSendInvitation,
  action: Object,
): SendInvitationState => {
  switch (action.type) {
    case ACTION_TYPES.SEND_INVITATION.PEOPLE_UPDATE:
      return {
        ...state,
        people: action.data,
      };
    case ACTION_TYPES.SEND_INVITATION.TEAMS_UPDATE:
      return {
        ...state,
        teams: action.data,
      };
    case ACTION_TYPES.SEND_INVITATION.SET_USER_TYPES_AND_QNA_TYPES:
      return {
        ...state,
        userTypeMap: action.data.userTypeMap,
        qnaRoleMap: action.data.qnaRoleMap,
      };
    case ACTION_TYPES.SEND_INVITATION.SET_DEFAULT_INVITE:
      return {
        ...state,
        subject: action.data.subject,
        message: action.data.body,
        attachments: action.data.attachments,
      };
    case ACTION_TYPES.SEND_INVITATION.SET_IS_LOADED:
      return {
        ...state,
        isLoaded: true,
      };
    case ACTION_TYPES.SEND_INVITATION.ADD_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.concat(action.data),
      };
    case ACTION_TYPES.SEND_INVITATION.REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(attachment => attachment.id !== action.data),
      };
    case ACTION_TYPES.SEND_INVITATION.SELECT_PEOPLE:
      return {
        ...state,
        selectedPeopleIds: action.data,
      };
    case ACTION_TYPES.SEND_INVITATION.CHANGE_FILTER:
      return {
        ...state,
        filterType: action.data,
        selectedPeopleIds: [],
      };
    case ACTION_TYPES.SEND_INVITATION.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.data,
      };
    case ACTION_TYPES.SEND_INVITATION.SET_IS_UPLOADING_ATTACHMENT:
      return {
        ...state,
        isUploadingAttachment: action.data,
      };
    case ACTION_TYPES.SEND_INVITATION.SET_SENT:
      return {
        ...state,
        hasBeenSent: true,
      };
    case ACTION_TYPES.SEND_INVITATION.RESET:
      return defaultSendInvitation;
    default:
      return state;
  }
};

export default SendInvitationReducer;
