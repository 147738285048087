// @flow
import type { SessionType } from 'app/types/globalTypes';
import { get } from './request/api';
import { API3B_ROOT } from './request/constants';

export const getRootFolders = (session: SessionType) => {
  const { dataroomId, userId } = session;
  return get(
    session,
    `${API3B_ROOT}/datarooms/${dataroomId}/users/${userId}/tendersubmissionindex/infos`,
  );
};
