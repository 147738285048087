// @flow

import * as React from 'react';
import { icon, Tooltip } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import IconView from 'app/pages/security/securityPolicyToggles/iconView/IconView';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { SecurityPolicy, FileExtType } from 'app/pages/security/securityTypes';
import styles from './securityPolicyToggles.scss';

type SecurityPolicyTogglesPropType = {
  documentIndexItemId: string,
  subteamId: number,
  securityPolicy: SecurityPolicy,
  itemIsSecurable: boolean,
  fileExt: FileExtType,
  permissions: PermissionsType,
  useSod: boolean,
  setSecuritySettings: Function,
};

class SecurityPolicyToggles extends React.Component<SecurityPolicyTogglesPropType> {
  shouldComponentUpdate(nextProps: SecurityPolicyTogglesPropType) {
    const policy = this.props.securityPolicy;
    const nextPolicy = nextProps.securityPolicy;
    return (
      policy.tracking !== nextPolicy.tracking ||
      policy.allowPrinting !== nextPolicy.allowPrinting ||
      policy.allowCopying !== nextPolicy.allowCopying ||
      policy.allowEditing !== nextPolicy.allowEditing ||
      policy.allowSaving !== nextPolicy.allowSaving ||
      this.props.useSod !== nextProps.useSod ||
      this.props.documentIndexItemId !== nextProps.documentIndexItemId ||
      this.props.subteamId !== nextProps.subteamId
    );
  }

  render() {
    const {
      documentIndexItemId,
      subteamId,
      securityPolicy,
      permissions,
      itemIsSecurable,
      fileExt,
      useSod,
      setSecuritySettings,
    } = this.props;
    const uniqueId = `policy-${documentIndexItemId}-${subteamId}`;

    const onUpdate = (attr: string, value: boolean) => {
      const payload = {
        documentIndexItemId,
        subteamId,
        attr,
        value,
      };
      setSecuritySettings(payload);
    };

    const toggleText = securityPolicy.tracking ? 'On' : 'Off';
    const displayCopyIcon =
      useSod && itemIsSecurable && (!fileExt || fileExt.toLowerCase() !== 'pdf');
    // $FlowFixMe Flow can't quite work this out
    const title = translate(`SecurityControls${toggleText}`);

    return (
      <div
        className={`${styles.securityPolicyContainer} transition-blur`}
        data-test-id="securityPolicyToggles"
      >
        <Tooltip id={title} defaultOpen={false} text={title}>
          <button
            className={`${styles.trackingControl} ${
              securityPolicy.tracking ? styles.trackingOn : styles.trackingOff
            }`}
            type="button"
            data-test-id="securityControlsButton"
            onClick={() => onUpdate('tracking', !securityPolicy.tracking)}
            disabled={!permissions.securityPolicy.modify}
          >
            {translate(toggleText)}
          </button>
        </Tooltip>

        <IconView
          key={`${uniqueId}-saving`}
          glyph={icon.Glyphs.ActionSaveEnabled}
          glyphDisabled={icon.Glyphs.ActionSaveDisabled}
          glyphInactive={icon.Glyphs.ActionSaveInactive}
          policyValue={securityPolicy.allowSaving}
          className="dr-spa-security-policy-icons"
          text={securityPolicy.allowSaving ? translate('SavingAllowed') : translate('SavingDenied')}
          textDisabled={translate('DenySavingDisabled')}
          disabled={!permissions.securityPolicy.modify}
          displayed={itemIsSecurable}
          isTracking={securityPolicy.tracking}
          onClick={() => onUpdate('allowSaving', !securityPolicy.allowSaving)}
        />

        <IconView
          key={`${uniqueId}-printing`}
          glyph={icon.Glyphs.ActionPrintEnabled}
          glyphDisabled={icon.Glyphs.ActionPrintDisabled}
          glyphInactive={icon.Glyphs.ActionPrintInactive}
          policyValue={securityPolicy.allowPrinting}
          className="dr-spa-security-policy-icons"
          text={
            securityPolicy.allowPrinting
              ? translate('PrintingAllowed')
              : translate('PrintingDenied')
          }
          textDisabled={translate('DenyPrintingDisabled')}
          disabled={!permissions.securityPolicy.modify}
          displayed={itemIsSecurable}
          isTracking={securityPolicy.tracking}
          onClick={() => onUpdate('allowPrinting', !securityPolicy.allowPrinting)}
        />

        <IconView
          key={`${uniqueId}-edit`}
          glyph={icon.Glyphs.ActionEditEnabled}
          glyphDisabled={icon.Glyphs.ActionEditDisabled}
          glyphInactive={icon.Glyphs.ActionEditInactive}
          policyValue={securityPolicy.allowEditing}
          className="dr-spa-security-policy-icons"
          text={
            securityPolicy.allowEditing ? translate('EditingAllowed') : translate('EditingDenied')
          }
          textDisabled={translate('DenyEditingDisabled')}
          disabled={!permissions.securityPolicy.modify}
          displayed={itemIsSecurable}
          isTracking={securityPolicy.tracking}
          onClick={() => onUpdate('allowEditing', !securityPolicy.allowEditing)}
        />

        <IconView
          key={`${uniqueId}-copy`}
          glyph={icon.Glyphs.ActionCopyEnabled}
          glyphDisabled={icon.Glyphs.ActionCopyDisabled}
          glyphInactive={icon.Glyphs.ActionCopyInactive}
          policyValue={securityPolicy.allowCopying}
          className="dr-spa-security-policy-icons"
          text={
            securityPolicy.allowCopying ? translate('CopyingAllowed') : translate('CopyingDenied')
          }
          textDisabled={translate('DenyCopyingDisabled')}
          disabled={!permissions.securityPolicy.modify}
          displayed={displayCopyIcon}
          isTracking={securityPolicy.tracking}
          onClick={() => onUpdate('allowCopying', !securityPolicy.allowCopying)}
        />
      </div>
    );
  }
}

export default SecurityPolicyToggles;
