// @flow

import { ACTION_TYPES } from 'app/constants';
import type { SelfRegistrationStateType } from './selfRegistrationTypes';

export const defaultSelfRegistration: SelfRegistrationStateType = {
  jobTitleGroups: [],
  locations: [],
  locationsLoading: false,
  submitRegistrationLoading: false,
  registrationStatus: 'pending',
  registrationErrors: {},
};

const selfRegistrationReducer = (
  state: SelfRegistrationStateType = defaultSelfRegistration,
  action: Object,
): SelfRegistrationStateType => {
  switch (action.type) {
    case ACTION_TYPES.SELF_REGISTRATION.JOB_TITLE_GROUPS.SET:
      return {
        ...state,
        jobTitleGroups: action.data,
      };
    case ACTION_TYPES.SELF_REGISTRATION.JOB_TITLE_GROUPS.RESET:
      return {
        ...state,
        jobTitleGroups: [],
      };

    case ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.LOADING:
      return {
        ...state,
        locationsLoading: action.data,
      };
    case ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.SET:
      return {
        ...state,
        locations: action.data,
      };
    case ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.RESET:
      return {
        ...state,
        locations: [],
      };

    case ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.LOADING:
      return {
        ...state,
        submitRegistrationLoading: action.data,
      };
    case ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.STATUS:
      return {
        ...state,
        registrationStatus: action.data,
      };
    case ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.SET_ERROR:
      return {
        ...state,
        registrationErrors: action.data,
      };
    case ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.RESET_ERROR:
      return {
        ...state,
        registrationErrors: {},
      };

    default:
      return state;
  }
};

export default selfRegistrationReducer;
