// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from 'app/sagas/rootSagas';
import reducers from 'app/reducers/rootReducer';

// Store and Sagas
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore<any, any, any>(
  reducers,
  window.__INITIAL_STATE__,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

if (process.env.NODE_ENV === 'development') {
  window.__REDUX_STORE__ = store;
}

sagaMiddleware.run(sagas);

export default store;
