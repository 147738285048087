// @flow

import { ACTION_TYPES } from 'app/constants';

const initialState = {
  permissions: false,
};
const resourcesReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.USER_PERMISSIONS_UPDATE:
      return {
        ...state,
        permissions: true,
      };
    case ACTION_TYPES.USER_PERMISSIONS_FETCH:
      return {
        ...state,
        permissions: false,
      };
    default:
      return state;
  }
};

export default resourcesReducer;
