// @flow

import * as uuid from 'uuid';
import { BANNER_MESSAGE_TYPES } from 'app/components/bannerMessage/bannerMessageTypes';
import { Date } from 'app/config';
import { ACTION_TYPES } from 'app/constants';
import type { BannerMessageType } from 'app/components/bannerMessage/bannerMessageTypes';
import type { MessageContentType } from 'app/components/bannerMessageContent/bannerMessageContentTypes';

export type BannerMessageCreatorType = {
  autoDismiss?: boolean,
  type?: BannerMessageType,
  content: MessageContentType,
  autoDismissDelay?: number,
};

export const bannerMessageCreator = (data: BannerMessageCreatorType) => {
  const { autoDismiss = true, type = null, content, autoDismissDelay } = data;
  const id = uuid.v1();

  return {
    type: ACTION_TYPES.BANNER_MESSAGES.ITEM_ADD,
    data: {
      id,
      timeStamp: Date.now(),
      autoDismissDelay,
      autoDismiss,
      type: type || BANNER_MESSAGE_TYPES.INFO,
      content,
    },
  };
};

export const displayInfoMessage = (infoMessage: string, code: string = '') =>
  bannerMessageCreator({
    autoDismiss: true,
    type: BANNER_MESSAGE_TYPES.INFO,
    content: {
      description: infoMessage,
      code,
    },
  });
