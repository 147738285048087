// @flow

import * as React from 'react';
import { Icon, icon } from '@ansarada/ace-react';

const StatusSwatch = (props: { isTeam?: boolean, name: string, colour: string }) =>
  props.isTeam ? (
    <Icon glyph={icon.Glyphs.StatusSwatch} text={props.name} colour={props.colour} />
  ) : (
    <Icon glyph={icon.Glyphs.NOGLYPH} />
  );

export default StatusSwatch;
