// @flow

import { put, takeEvery, select } from 'redux-saga/effects';
import { ACTION_TYPES } from 'app/constants';
import { safeRequest } from 'app/utils/saga-helpers';
import * as selfRegistrationService from 'app/services/selfRegistration';
import type { SelfRegistrationErrorsType } from 'app/layout/selfRegistration/selfRegistrationTypes';

export const formatRegistrationErrors = (
  registrationErrors: SelfRegistrationErrorsType,
): ?SelfRegistrationErrorsType => {
  const errorKeys = Object.keys(registrationErrors);
  if (errorKeys.length > 0) {
    return errorKeys.reduce((acc, key) => {
      if (registrationErrors[key]?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        acc[key] = registrationErrors[key][0];
      }
      return acc;
    }, {});
  }
  return null;
};

export function* fetchJobTitleGroups(): Generator<*, *, *> {
  const { session } = yield select();
  const [data] = yield safeRequest(selfRegistrationService.fetchJobTitleGroups, session);
  if (Array.isArray(data?.data?.jobTitleGroups)) {
    yield put({
      type: ACTION_TYPES.SELF_REGISTRATION.JOB_TITLE_GROUPS.SET,
      data: data.data.jobTitleGroups,
    });
  }
}

export function* searchLocations(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.LOADING, data: true });
  const [data] = yield safeRequest(selfRegistrationService.searchLocations, session, action.data);
  if (Array.isArray(data?.data?.locations)) {
    yield put({ type: ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.SET, data: data.data.locations });
  }
  yield put({ type: ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.LOADING, data: false });
}

export function* submitRegistration(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.LOADING, data: true });
  const res = yield safeRequest(selfRegistrationService.submitRegistration, session, action.data);
  const errors = res[1];
  if (errors) {
    if (errors?.details) {
      const formattedErrors = formatRegistrationErrors(errors.details);
      if (formattedErrors) {
        yield put({
          type: ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.SET_ERROR,
          data: formattedErrors,
        });
      }
    }
  } else {
    yield put({ type: ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.STATUS, data: 'success' });
  }
  yield put({ type: ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.LOADING, data: false });
}

export const selfRegistrationSagas = [
  takeEvery(ACTION_TYPES.SELF_REGISTRATION.JOB_TITLE_GROUPS.FETCH, fetchJobTitleGroups),
  takeEvery(ACTION_TYPES.SELF_REGISTRATION.LOCATIONS.FETCH, searchLocations),
  takeEvery(ACTION_TYPES.SELF_REGISTRATION.SUBMIT_REGISTRATION.FETCH, submitRegistration),
];
