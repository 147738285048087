// @flow

import { window } from 'app/config';

export const openWindow = (url: string, name: string) => {
  const width = window.innerWidth * 0.8;
  const height = window.innerHeight * 0.8;

  const left = window.innerWidth / 2 - width / 2 + window.screenX;
  const top = window.innerHeight / 2 - height / 2 + window.screenY;
  const params = [
    'location=0',
    'menubar=0',
    'resizable=1',
    'scrollbars=1',
    'status=1',
    'toolbar=0',
    `width=${width}`,
    `height=${height}`,
    `top=${top}`,
    `left=${left}`,
  ].join();
  window.open(url, name, params).focus();
};
