// @flow

import * as React from 'react';
import { Button, Icon, icon, Tooltip } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';

type AccessTogglePropType = {|
  'data-test-id'?: string,
  id: string,
  disabled?: boolean,
  isAllowed: boolean,
  onToggle: () => void,
|};

function AccessToggle(props: AccessTogglePropType) {
  const { id, isAllowed, disabled, onToggle } = props;
  const title = isAllowed ? translate('AccessAllowed') : translate('AccessDenied');
  return (
    <Tooltip id={id} text={title} defaultOpen={false}>
      <Button
        className="transition-blur"
        disabled={disabled}
        variant="Ghost"
        size="Small"
        data-test-id={props['data-test-id']}
        icon={
          <Icon
            glyph={isAllowed ? icon.Glyphs.StatusYes : icon.Glyphs.StatusNo}
            size="Medium"
            text={title}
          />
        }
        onClick={onToggle}
      />
    </Tooltip>
  );
}

export default AccessToggle;
