// prettier-ignore

export default {
  Access: '存取',
  Actions: '操作',
  AdminSideTender_InviteHeading: 'Send invites to client',
  All: '全部',
  Attachments: '附件',
  BatchOperationCantBePerformed: '無法執行批次處理操作，因為此資料室中沒有用戶。請首先輸入用戶。',
  Cancel: '取消',
  Change: '變更',
  Close: '關閉',
  Colour: '顏色：',
  DataRooms: '資料室',
  Delete: '刪除',
  DemoAllCaps: '演示',
  Document: '文檔',
  DocumentIndex: '文檔索引',
  Documents: '文檔',
  Edit: '編輯',
  EditMultipleUsers: '編輯多個用戶',
  EditProfile: '編輯配置檔',
  Email: '電子郵件',
  EnterIpAddressesRanges: '輸入 IP 地址或由 Enter 鍵分隔的 IP 地址範圍',
  FileExtensionIsInList: '無法上傳檔案 "[FileName]"，因為其副檔名在此資料室不允許的檔案副檔名清單中：\r\n\r\n[FileTypeList]\r\n\r\n請將此檔案轉換為一種允許的格式。',
  FileExtensionIsNotInList: '無法上傳檔案 "[FileName]"，因為其副檔名不在此資料室允許的檔案副檔名清單中：\r\n\r\n[FileTypeList]\r\n\r\n請將此檔案轉換為一種允許的格式。',
  Filter: '篩選器',
  Folder: '檔案夾',
  ForRangesSeparateStartIp: '對於範圍，用一個破折號分隔開始 IP 和結束 IP，如 <b>1.1.1.1-1.1.1.6</b>',
  From: '從：',
  FullName: '全名',
  Help: '説明',
  Importance: '重要：',
  InvalidEmailAddress: '無效的電子郵件地址',
  InvalidPdf: '錯誤：此檔不是有效的 pdf 文檔。請嘗試另一個檔。',
  Invited_Users_: '已邀請',
  IpAccessList: 'IP 存取清單',
  IpAddressInvalidIpAccessList: "IP 訪問列表中的 IP '[IpAddress]' 不是有效的 IP 地址。",
  IpAddressInvalidRangeIpAccessList: "IP 訪問列表中的 IP 範圍 '[IpRange]' 的開始 IP 地址大於結束 IP 地址。開始 IP 應小於或等於結束 IP。",
  IpLimitRangeIpAccessList: "請將 IP 訪問列表中的 IP 範圍 '[IpRange]' 的說明限制為 [NumOfChars] 個字元。您使用了 [UsedNumOfChars] 個字元。",
  IpUserAccessListLimit: '請將 IP 存取清單限制為 [MaxNumberOfCharacters] 個字元。您輸入了 [EnteredNumberOfCharacters] 個字元。',
  LastMonth: '上月',
  LoadingThreeDots: '正在載入...',
  Logout: '登出',
  Manage: '管理',
  Message: '訊息',
  Never: '絕不',
  No: '否',
  None: '無',
  NoRecordsFound: '未找到記錄。',
  Off: '關',
  OkAllCaps: '確定',
  OnEveryLogin: '在每次登入時',
  Or: '或',
  PrintingDenied: '列印被拒絕',
  QnaRole: 'Q&A 角色',
  RecipientsOfEmailNote_Gen2: '此<nowrap>電子郵件</nowrap>的收件人將無法看到彼此的地址，因此將它發送給來自不同用戶組的用戶是安全的。',
  Reset: '重設',
  Save: '儲存',
  SavingDenied: '保存被拒絕',
  Search: '搜索',
  Security: '安全',
  Select: '選擇',
  SelectUserType: '請選擇用戶類型。',
  Send: '發送',
  ShouldBeEmailEmail: '您輸入的電子郵件地址無效。',
  Status: '狀態',
  SubTeam: '子團隊',
  Team: '團隊',
  To: '到：',
  Today: '今天',
  UserCanLogInFromAnyIp: '此用戶可從任何 IP 位址登入',
  UserCanLogInOnlyFromIpsBelow: '此用戶只能從下麵指定的 IP 地址登入',
  UserGroup: '用戶組',
  UserProperties: '用戶屬性',
  Verified_Users_: '已驗證',
  View: 'View',
  Watermark: '浮水印',
  Yes: '是',
  YouCanAddDescriptionsAfterComma: '也可在逗號後添加描述，如 <b>1.1.1.1，倫敦辦事處 IP</b>',
  YourCurrentIpAddress: '<b>注：</b>您當前的 IP 地址是 [IP Address]。<a [href]>按一下此處</a>將它添加到清單。',
};
