// @flow

import * as R from 'ramda';
import { document, window } from 'app/config';
import translate from 'app/i18n/translate';
import { UNCHANGED_BOOL, UNCHANGED_INT } from 'app/constants';
import type {
  SecurityPolicy,
  Team,
  ItemSecurity,
  Item,
  SubteamSetting,
  SecurityApiPayload,
  EditableAttributes,
  Tree,
} from 'app/pages/security/securityTypes';

export const mapToSecurity = (securities: Array<*>) => {
  const result = {};
  /* Due to the potential size of this array,
    we are using a higher performance structured loop
    instead of "reduce"/"spread" conventions.
    Recommend considering Immutable.JS in the future.
  */

  let len = securities.length;
  while (len > 0) {
    len--; // eslint-disable-line no-plusplus
    const current = securities[len];
    const documentIndexItemId = current.DocumentIndexItemCompoundId;
    const subteamId = current.SecurityGroupId;

    const permissions = current.Permissions || {};

    const allowPrinting = permissions.AllowPrinting;
    const allowEditing = permissions.AllowEditing;
    const allowSaving = permissions.AllowSaving;
    const allowCopying = permissions.AllowCopying;
    const tracking = permissions.Tracking;
    const watermarkId = current.WatermarkId;
    const allowAccess = current.AccessAllowed;

    result[`${documentIndexItemId}-${subteamId}`] = {
      subteamId,
      documentIndexItemId,
      allowPrinting: allowPrinting !== undefined && allowPrinting !== null ? allowPrinting : true,
      allowEditing: allowEditing !== undefined && allowEditing !== null ? allowEditing : true,
      allowSaving: allowSaving !== undefined && allowSaving !== null ? allowSaving : true,
      allowCopying: allowCopying !== undefined && allowCopying !== null ? allowCopying : true,
      tracking: tracking !== undefined && tracking !== null ? tracking : true,
      watermarkId,
      allowAccess,
    };
  }
  return result;
};

export const mapToDocumentIndex = (index: Array<*>) =>
  // $FlowFixMe
  index.map(item => ({
    id: item.Id,
    name: item.Name,
    documentIndexItemId: `${item.IsFolder ? 'f' : 'd'}-${item.Id}`,
    folderCanBeExpanded: item.FolderCanBeExpanded,
    isDisabled: item.IsDisabled,
    parentId: item.ParentId,
    isFolder: item.IsFolder,
    fileExtension: item.FileExtension,
    number: item.Number,
    isSecurable: item.IsSecurable,
    isWatermarkable: item.CanApplyWatermark,
  }));

export const mapSecurityToApi = (
  itemIds: EditableAttributes,
  tree: Tree,
  forEventTracking: boolean = false,
) => {
  // There is a separate API for items that have had 'allowAccess' changed
  // vs any other security property. So each item might go to one or both APIs.
  const itemsWithChangedAccess: Array<SecurityApiPayload> = [];
  const itemsWithChangedSecurity: Array<SecurityApiPayload> = [];

  Object.keys(itemIds).forEach(itemId => {
    let accessChanged = false;
    let securityChanged = false;

    const [itemType, itemIdString, subteamIdString] = itemId.split('-'); // eg ['f', '1234', '5678']
    const documentIndexItemCompoundId = `${itemType}-${itemIdString}`; // e.g. f-1234
    const node = tree[documentIndexItemCompoundId];
    const subteamId = Number(subteamIdString);

    // Convert the 'edited' object to a map (to easily check for the existence of keys)
    const editedSecurities: Map<string, any> = new Map(Object.entries(node.edited[subteamId]));

    // The backend expects that we send through all properties, and explicitly mark
    // the new value, or that the prop has not changed
    const item: SecurityApiPayload = {
      name: node.name,
      depth: node.depth,
      isFolder: node.isFolder,
      securityGroupId: subteamId,
      documentIndexItemId: Number(itemIdString),
      cascadeChange: true,
      allowAccess: UNCHANGED_BOOL,
      permissions: {
        tracking: UNCHANGED_BOOL,
        allowSaving: UNCHANGED_BOOL,
        allowPrinting: UNCHANGED_BOOL,
        allowEditing: UNCHANGED_BOOL,
        allowCopying: UNCHANGED_BOOL,
      },
      watermarkId: UNCHANGED_INT,
      isSecurityUpdated: false,
      isAccessUpdated: false,
      isWatermarkUpdated: false,
    };

    const originalSecurities: Map<string, any> = new Map(
      Object.entries(node.securities[subteamId]),
    );

    // only need to map with orginal securities when call this function for mapping changes for event tracking
    if (forEventTracking) {
      for (const key in item.permissions) {
        if (Object.prototype.hasOwnProperty.call(item.permissions, key)) {
          item.permissions[key] = originalSecurities.get(key);
        } else {
          item.permissions[key] = true;
        }
      }

      if (Object.prototype.hasOwnProperty.call(item, 'watermarkId')) {
        item.watermarkId = originalSecurities.get('watermarkId');
      }
    }

    if (editedSecurities.has('allowAccess')) {
      accessChanged = true;
      item.allowAccess = editedSecurities.get('allowAccess');
    }

    if (editedSecurities.has('tracking')) {
      securityChanged = true;
      item.permissions.tracking = editedSecurities.get('tracking');
    }

    // if tracking has been set to false, don't send through any changes to controls
    if (editedSecurities.get('tracking') !== false) {
      if (editedSecurities.has('allowSaving')) {
        securityChanged = true;
        item.permissions.allowSaving = editedSecurities.get('allowSaving');
      }
      if (editedSecurities.has('allowPrinting')) {
        securityChanged = true;
        item.permissions.allowPrinting = editedSecurities.get('allowPrinting');
      }
      if (editedSecurities.has('allowEditing')) {
        securityChanged = true;
        item.permissions.allowEditing = editedSecurities.get('allowEditing');
      }
      if (editedSecurities.has('allowCopying')) {
        securityChanged = true;
        item.permissions.allowCopying = editedSecurities.get('allowCopying');
      }
    }

    if (editedSecurities.has('watermarkId')) {
      securityChanged = true;
      item.watermarkId = editedSecurities.get('watermarkId');
    }

    const checkPropsUpdated = (checkingEditedSecurities, checkingOriginalSecurities, props) => {
      let isUpdated = false;
      props.forEach(prop => {
        isUpdated =
          isUpdated ||
          (checkingEditedSecurities.has(prop) &&
            checkingEditedSecurities.get(prop) !== checkingOriginalSecurities.get(prop));
      });
      return isUpdated;
    };

    item.isSecurityUpdated = checkPropsUpdated(editedSecurities, originalSecurities, [
      'tracking',
      'allowCopying',
      'allowEditing',
      'allowPrinting',
      'allowSaving',
    ]);
    item.isAccessUpdated = checkPropsUpdated(editedSecurities, originalSecurities, ['allowAccess']);
    item.isWatermarkUpdated = checkPropsUpdated(editedSecurities, originalSecurities, [
      'watermarkId',
    ]);

    // The same payload is sent to both APIs, but only
    // part of the payload is used by each API
    if (accessChanged) itemsWithChangedAccess.push(item);
    if (securityChanged) itemsWithChangedSecurity.push(item);
  });

  const sortByDepth = R.sortBy(R.prop('depth'));

  return {
    itemsWithChangedAccess: sortByDepth(itemsWithChangedAccess),
    itemsWithChangedSecurity: sortByDepth(itemsWithChangedSecurity),
  };
};

export const mapItemSecurityToApi = (
  item: Item,
  securities: Array<ItemSecurity>,
  editedSubteams: Object,
) => {
  const itemSecuritiesToPost = [];
  let accessUpdated = false;
  let securityUpdated = false;
  Object.keys(editedSubteams).forEach(subteamId => {
    const edited = editedSubteams[subteamId];
    const hasAllowAccess = R.has('allowAccess');
    let isSecurityUpdated = false;
    const isAccessUpdated = hasAllowAccess(edited);
    accessUpdated = accessUpdated || hasAllowAccess(edited);
    if (!R.isEmpty(R.omit(['allowAccess'], edited))) {
      isSecurityUpdated = true;
      securityUpdated = true;
    }

    const itemSecurity = securities.filter(s => s.subteamId === parseInt(subteamId, 10))[0];
    const isTrackingOn =
      editedSubteams[subteamId].tracking === undefined || editedSubteams[subteamId].tracking;

    itemSecuritiesToPost.push({
      isAccessUpdated,
      isSecurityUpdated,
      documentIndexItemCompoundId: item.documentIndexItemId,
      documentIndexItemId: item.id,
      isFolder: item.isFolder,
      securityGroupId: parseInt(subteamId, 10),
      watermarkId: itemSecurity.watermarkId,
      permissions: {
        allowCopying: isTrackingOn ? itemSecurity.allowCopying : true,
        allowEditing: isTrackingOn ? itemSecurity.allowEditing : true,
        allowSaving: isTrackingOn ? itemSecurity.allowSaving : true,
        allowPrinting: isTrackingOn ? itemSecurity.allowPrinting : true,
        tracking: itemSecurity.tracking,
      },
      allowAccess: itemSecurity.allowAccess,
      cascadeChange: true,
    });
  });
  return {
    accessUpdated,
    securityUpdated,
    security: itemSecuritiesToPost,
  };
};

export const getPolicy = (security: ?SecurityPolicy): SecurityPolicy =>
  security || {
    allowSaving: true,
    allowPrinting: true,
    allowEditing: true,
    allowCopying: true,
    tracking: false,
  };

export const getText = (data: Object, attr: string) => {
  const {
    FolderCount,
    DocumentsCanBeWatermarkedCount,
    DocumentCount,
    DocumentsWithSecurityPolicyCount,
  } = data;

  const folderCount = FolderCount;
  let securityText;
  let documentCount;
  switch (attr) {
    case 'watermarkId':
      securityText = translate('Watermark');
      documentCount = DocumentsCanBeWatermarkedCount;
      break;
    case 'allowAccess':
      securityText = translate('Access');
      documentCount = DocumentCount;
      break;
    default:
      securityText = translate('SecurityPermissions');
      documentCount = DocumentsWithSecurityPolicyCount;
  }

  const folderText = folderCount === 1 ? '1 folder' : `${folderCount} folders`;
  const documentText = documentCount === 1 ? '1 document' : `${documentCount} documents`;

  let text = '';
  if (folderCount && documentCount) {
    text = `${securityText} updated for ${folderText} and ${documentText}`;
  } else if (!folderCount && documentCount) {
    text = `${securityText} updated for ${documentText}`;
  } else if (folderCount && !documentCount) {
    text = `${securityText} updated for ${folderText}`;
  }
  return text;
};

export const fetchAllUnfoldedChildrenIds = (
  folderId: number,
  node: Object,
  expanded: Array<number>,
  tree: Object,
): ?Array<number> => {
  const ids = node.children.length
    ? node.children.reduce((acc, current) => {
        const currentId = tree[current].id;
        if (expanded.includes(currentId)) {
          acc.push(currentId);
          const childrenId = fetchAllUnfoldedChildrenIds(currentId, tree[current], expanded, tree);
          return acc.concat(childrenId);
        }
        return acc;
      }, [])
    : null;
  return ids;
};

export const isItemSecurable = (fileExtension: string, isSecurable: boolean, useSod: boolean) => {
  if (fileExtension.toLowerCase() === 'pdf') return true;
  return useSod && isSecurable;
};

export const getItemSecurity = (
  serverSecurity: ?Object,
  subteam: SubteamSetting,
  documentIndexItemId: string,
) => {
  if (serverSecurity) {
    const policy = serverSecurity.Permissions
      ? {
          allowPrinting: serverSecurity.Permissions.AllowPrinting,
          allowEditing: serverSecurity.Permissions.AllowEditing,
          allowSaving: serverSecurity.Permissions.AllowSaving,
          allowCopying: serverSecurity.Permissions.AllowCopying,
          tracking: serverSecurity.Permissions.Tracking,
        }
      : getPolicy(null);
    return {
      subteamId: subteam.id,
      documentIndexItemId,
      watermarkId: serverSecurity.WatermarkId || -1,
      allowAccess: serverSecurity.AccessAllowed,
      useSod: subteam.useSod,
      ...policy,
    };
  }
  return {
    subteamId: subteam.id,
    documentIndexItemId,
    watermarkId: -1,
    allowAccess: false,
    useSod: subteam.useSod,
    ...getPolicy(null),
  };
};

export const getEditedItemSecurity = (
  securities: Array<ItemSecurity>,
  attr: string,
  value: boolean,
  subteamId: number,
): Array<ItemSecurity> =>
  securities.map(item => {
    if (item.subteamId === subteamId) {
      return {
        ...item,
        [attr]: value,
      };
    }
    return item;
  });

export const getExpandedFolderIds = (indexData: Object): Array<number> => {
  const ids = Object.keys(indexData).filter(key => key !== '0');
  return ids.map(key => parseInt(key, 10));
};

export const getExpandedChildren = (folderIds: Array<number>, indexData: Object): Object =>
  folderIds.reduce(
    (acc, key) =>
      Object.assign(acc, {
        [key]: mapToDocumentIndex(indexData[key]),
      }),
    {},
  );

export const getPageSubteam = (team: Team): SubteamSetting => {
  if (team.subteams && team.subteams.length) {
    return {
      id: team.subteams[0].id,
      useSod: team.subteams[0].useSod,
    };
  }
  return {
    id: team.subteamId,
    useSod: team.useSod,
  };
};

export const getTeamSubTeamSecurities = (
  item: Object,
  teams: Object,
  itemSecurities: Object,
): Array<Object> =>
  teams.reduce((acc, team) => {
    const teamSubteamSecurities = team.subteams
      ? team.subteams.map(subteam => {
          const itemSubteamSecurity = itemSecurities.data.find(
            sec =>
              sec.SecurityGroupId === subteam.id &&
              sec.DocumentIndexItemCompoundId === item.documentIndexItemId,
          );
          return getItemSecurity(
            itemSubteamSecurity,
            {
              id: subteam.id,
              useSod: subteam.useSod,
            },
            item.documentIndexItemId,
          );
        })
      : [];

    const itemTeamSecurity = itemSecurities.data.find(
      sec =>
        sec.SecurityGroupId === team.subteamId &&
        sec.DocumentIndexItemCompoundId === item.documentIndexItemId,
    );

    const teamSecurity = getItemSecurity(
      itemTeamSecurity,
      {
        id: team.subteamId,
        useSod: team.useSod,
      },
      item.documentIndexItemId,
    );
    return [...acc, teamSecurity, ...teamSubteamSecurities];
  }, []);

const fakeHiddenInput = /* istanbul ignore next */ (name: string, value: string) => {
  const i = document.createElement('input');
  i.type = 'hidden';
  i.name = name;
  i.value = value;
  return i;
};

export const openExportSecurityForm = /* istanbul ignore next */ (uri: string, subteamId: *) => {
  const f = document.createElement('form');
  f.method = 'post';
  f.target = 'formpopup';
  f.action = uri;
  f.addEventListener('submit', () => {
    window.open(uri, 'formpopup');
  });

  f.appendChild(fakeHiddenInput('IncludeAccess', 'true'));
  f.appendChild(fakeHiddenInput('IncludeSecurityPolicies', 'true'));
  f.appendChild(fakeHiddenInput('IncludeWatermarks', 'true'));
  f.appendChild(fakeHiddenInput('SecurityGroupID', subteamId));
  document.body.appendChild(f);
  f.submit();
  f.parentNode.removeChild(f);
};

export const getCopyToTeamData = (teams: Array<Team>) =>
  // $FlowFixMe
  teams.map(team => ({
    id: team.subteamId,
    colour: team.colour,
    name: team.name,
    status: null,
    selected: false,
    subteams:
      team.subteams &&
      team.subteams.map(subteam => ({
        id: subteam.subteamId,
        name: subteam.name,
        status: null,
        selected: false,
      })),
  }));

export const getTeamWithSubteam = (teams: *, teamId: number, subteamId: number): ?Team => {
  const team = teams.find(t => t.teamId === teamId);
  return !team
    ? null
    : {
        ...team,
        subteams: team.subteams.filter(st => st.subteamId === subteamId),
      };
};
