// @flow

import AccessToggle from 'app/pages/security/accessToggle/AccessToggle';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { SessionType } from 'app/types/globalTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { TableBodyLegacy, TableRowLegacy, TableDataLegacy, Icon, icon } from '@ansarada/ace-react';
import Watermarks from 'app/pages/security/watermarks/Watermarks';
import SecurityPolicyToggles from 'app/pages/security/securityPolicyToggles/SecurityPolicyToggles';
import { isItemSecurable } from 'app/pages/security/helpers/securityHelpers';
import StatusSwatch from 'app/pages/security/statusSwatch/StatusSwatch';
import styles from 'app/pages/security/itemSecurity/subteamSecurity/subteamSecurity.scss';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { ItemSecurity, Team, Subteam, WatermarkType } from 'app/pages/security/securityTypes';
import classNames from 'classnames';
import { setItemSecuritySettings } from '../../securityActions';

export const ItemSubteamSecurity = (props: {
  group: Subteam | Team,
  security?: ItemSecurity,
  item: Object,
  isSecurable: boolean,
  watermarks: Array<WatermarkType>,
  permissions: PermissionsType,
  session: SessionType,
  isTeam?: boolean,
  setItemSecuritySettingsAction: Function,
}) => {
  const {
    group,
    security,
    item,
    permissions,
    session,
    watermarks,
    isSecurable,
    isTeam,
    setItemSecuritySettingsAction,
  } = props;

  return (
    <TableRowLegacy key={group.subteamId}>
      <TableDataLegacy className={styles.status}>
        <StatusSwatch isTeam={isTeam} name={group.name} colour={group.colour || ''} />
      </TableDataLegacy>

      <TableDataLegacy data-ansarada-ccd>
        <div
          className={classNames({
            [styles.teamName]: true,
            [styles.teamNameWithSecurity]: session.documentSecurityEnabled,
            [styles.teamNameWithoutSecurity]: !session.documentSecurityEnabled,
          })}
        >
          {!isTeam && <Icon glyph={icon.Glyphs.ObjectsArrowindent} text=" " />}
          {group.name}
        </div>
      </TableDataLegacy>

      <TableDataLegacy>
        {!!security && (
          <AccessToggle
            id={group.subteamId.toString()}
            isAllowed={security.allowAccess}
            disabled={!(permissions.securityGroup.modify && permissions.document.modify)}
            data-test-id="securityAccessButton"
            onToggle={() => {
              setItemSecuritySettingsAction({
                subteamId: security.subteamId,
                attr: 'allowAccess',
                value: !security.allowAccess,
              });
            }}
          />
        )}
      </TableDataLegacy>

      {session.documentSecurityEnabled && (
        <TableDataLegacy>
          {!!security && isSecurable && (
            <SecurityPolicyToggles
              documentIndexItemId={security.documentIndexItemId}
              subteamId={security.subteamId}
              securityPolicy={{
                allowPrinting: security.allowPrinting,
                allowEditing: security.allowEditing,
                allowSaving: security.allowSaving,
                allowCopying: security.allowCopying,
                tracking: security.tracking,
              }}
              permissions={permissions}
              itemIsSecurable={isSecurable}
              fileExt={item.fileExtension}
              useSod={group.useSod}
              setSecuritySettings={setItemSecuritySettingsAction}
            />
          )}
        </TableDataLegacy>
      )}

      <TableDataLegacy>
        {!!security && isSecurable && item.isWatermarkable && (
          <Watermarks
            documentIndexItemId={security.documentIndexItemId}
            subteamId={security.subteamId}
            value={security.watermarkId}
            watermarks={watermarks}
            session={session}
            permissions={permissions}
            setSecuritySettings={setItemSecuritySettingsAction}
          />
        )}
      </TableDataLegacy>
    </TableRowLegacy>
  );
};

export const SubteamSecurity = (props: {
  teams: Array<Team>,
  securities: Array<ItemSecurity>,
  dispatch: AppDispatch,
  watermarks: Array<WatermarkType>,
  permissions: PermissionsType,
  session: SessionType,
  item: Object,
  setItemSecuritySettingsAction: Function,
}) => {
  const {
    teams,
    securities,
    dispatch,
    watermarks,
    permissions,
    session,
    item,
    setItemSecuritySettingsAction,
  } = props;

  if (!item) return null;

  return (
    <TableBodyLegacy>
      {teams &&
        teams.map(team => {
          const isSecurable =
            item.isFolder ||
            isItemSecurable(
              item.fileExtension ? item.fileExtension : '',
              item.isSecurable,
              team.useSod,
            );
          const subteamSecurities = team.subteams
            ? team.subteams.map(subteam => {
                const security = securities.find(sec => sec.subteamId === subteam.id);
                return (
                  <ItemSubteamSecurity
                    group={subteam}
                    security={security}
                    item={item}
                    isSecurable={isSecurable}
                    watermarks={watermarks}
                    dispatch={dispatch}
                    permissions={permissions}
                    session={session}
                    key={`subteam-${subteam.id}`}
                    setItemSecuritySettingsAction={setItemSecuritySettingsAction}
                  />
                );
              })
            : null;

          const security = securities.find(sec => sec.subteamId === team.subteamId);

          const teamSecurity = (
            <ItemSubteamSecurity
              group={team}
              isTeam
              security={security}
              item={item}
              isSecurable={isSecurable}
              watermarks={watermarks}
              dispatch={dispatch}
              permissions={permissions}
              session={session}
              key={`team-${team.subteamId}`}
              setItemSecuritySettingsAction={setItemSecuritySettingsAction}
            />
          );
          return [teamSecurity, subteamSecurities];
        })}
    </TableBodyLegacy>
  );
};

const mapStateToProps = (state: Object): Object => ({
  teams: state.security.documents.pageData.teams,
  securities: state.security.documents.itemData.securities,
  watermarks: state.security.documents.pageData.watermarks,
  permissions: state.page.userPermissions,
  item: state.security.documents.itemData.item,
  session: state.session,
});

export default connect<_, _, *, *, *>(mapStateToProps, {
  setItemSecuritySettingsAction: setItemSecuritySettings,
})(SubteamSecurity);
