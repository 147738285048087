"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.publishManageSubscriptionDialogShowed = exports.publishSubscriptionActivateDialogShowed = exports.publishSubscriptionTransferUpgradeDialogShowed = exports.SubscriptionTransferUpgradeSteps = void 0;
var pubsub_1 = require("@ansarada/pubsub");
var constants_1 = require("app/constants");
var SubscriptionTransferUpgradeSteps;
(function (SubscriptionTransferUpgradeSteps) {
    SubscriptionTransferUpgradeSteps["SUBSCRIPTION_OWNER"] = "Choose subscription owner";
    SubscriptionTransferUpgradeSteps["SUBSCRIPTION_PLAN"] = "Choose subscription plan";
    SubscriptionTransferUpgradeSteps["CONFIRM_DETAILS"] = "Confirm details";
})(SubscriptionTransferUpgradeSteps = exports.SubscriptionTransferUpgradeSteps || (exports.SubscriptionTransferUpgradeSteps = {}));
var publishSubscriptionTransferUpgradeDialogShowed = function (payload) {
    return (0, pubsub_1.emit)({
        appName: constants_1.DATAROOM_APP_NAME,
        eventName: constants_1.EVENT_NAME.SMS.SUBSCRIPTION_TRANSFER_UPGRADE_DIALOG_SHOWED,
        payload: payload,
    });
};
exports.publishSubscriptionTransferUpgradeDialogShowed = publishSubscriptionTransferUpgradeDialogShowed;
var publishSubscriptionActivateDialogShowed = function (payload) {
    return (0, pubsub_1.emit)({
        appName: constants_1.DATAROOM_APP_NAME,
        eventName: constants_1.EVENT_NAME.SMS.SUBSCRIPTION_ACTIVATE_DIALOG_SHOWED,
        payload: payload,
    });
};
exports.publishSubscriptionActivateDialogShowed = publishSubscriptionActivateDialogShowed;
var publishManageSubscriptionDialogShowed = function (payload) {
    return (0, pubsub_1.emit)({
        appName: constants_1.DATAROOM_APP_NAME,
        eventName: constants_1.EVENT_NAME.SMS.MANAGE_SUBSCRIPTION_DIALOG_SHOWED,
        payload: payload,
    });
};
exports.publishManageSubscriptionDialogShowed = publishManageSubscriptionDialogShowed;
