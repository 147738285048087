// prettier-ignore

export default {
  AcceptTermsOfAccess_SentenceCase: 'Aceptar términos de acceso',
  Access: 'Acceso',
  Actions: 'Acciones',
  Add: 'Añadir',
  AdditionalRecipients_SentenceCase: 'Destinatarios adicionales:',
  Administration: 'Administración',
  AdminSideBankruptcy: 'Parte vendedora',
  AdminSideMerger: 'Parte vendedora',
  All: 'Todos',
  Attachments: 'Adjuntos',
  BatchOperationCantBePerformed: 'La operación por lote no puede realizarse porque no hay usuarios en esta sala de datos. Ingrese primero los usuarios.',
  BulkDownload_SentenceCase: 'Descarga masiva',
  BuySide: 'Parte compradora',
  BuySide_SentenceCase: 'Parte compradora',
  Cancel: 'Cancelar',
  Change: 'Cambiar',
  ChooseLanguage: 'Elegir idioma',
  Close: 'Cerrar',
  CollapseAll_SentenceCase: 'Contraer todos',
  Colour: 'Color:',
  ContactUs: 'Contáctenos',
  CopyEmailsOfSelectedUsers: 'Copiar correos de usuarios seleccionados',
  Daily: 'Diario',
  DataRooms: 'Salas de datos',
  DateSent: 'Fecha de envío',
  Delete: 'Eliminar',
  Disable: 'Desactivar',
  Disabled: 'Desactivada',
  Document: 'Documento',
  DocumentIndex: 'Índice de documentos',
  Documents: 'Documentos',
  Edit: 'Editar',
  EditMultipleUsers: 'Editar múltiples usuarios',
  EditProfile: 'Editar perfil',
  Email: 'Correo electrónico',
  EmailNotifications: 'Notificaciones por correo',
  Enabled: 'Activado',
  EnterIpAddressesRanges: 'Ingrese direcciones IP o rangos de direcciones IP separados por la tecla Enter.',
  ExpandAll_SentenceCase: 'Expandir todos',
  ExportToExcel_SentenceCase: 'Exportar a Excel',
  FileExtensionIsInList: 'El archivo "[FileName]" no puede subirse, ya que su extensión está en la lista de extensiones no permitidas para esta sala de datos:\n\n[FileTypeList]\n\nConvierta este archivo a uno de los formatos permitidos.',
  FileExtensionIsNotInList: 'El archivo "[FileName]" no puede subirse, ya que su extensión no está en la lista de extensiones permitidas para esta sala de datos:\n\n[FileTypeList]\n\nConvierta este archivo a uno de los formatos permitidos.',
  Filter: 'Filtro',
  Folder: 'Carpeta',
  ForRangesSeparateStartIp: 'Para indicar rangos, separe la IP inicial y la IP final con un guión, por ejemplo <b>1.1.1.1-1.1.1.6</b>',
  From: 'De:',
  FullName: 'Nombre completo',
  Help: 'Ayuda',
  HelpAndSupport: 'Ayuda y soporte',
  HideMenu: 'Ocultar menú',
  History: 'Historial',
  Hourly: 'Cada hora',
  Importance: 'Importancia:',
  Interval: 'Intervalo',
  InvalidEmailAddress: 'Dirección de correo electrónico no válida',
  InvalidPdf: 'ERROR: Este archivo no es un documento pdf válido. Intente con otro archivo.',
  InvitationEmail: 'Correo de invitación',
  Invited_Users_: 'Invitado',
  IpAccessList: 'Lista de acceso de IP',
  IpAccessList_SentenceCase: 'Lista de acceso de IP',
  IpAddressInvalidIpAccessList: "La IP '[IpAddress]' de la lista de acceso IP no es una dirección IP válida.",
  IpAddressInvalidRangeIpAccessList: "El rango de IP '[IpRange]' de la lista de acceso IP tiene una dirección IP inicial mayor que la dirección IP final. La dirección IP inicial debe ser menor o igual que la dirección IP final.",
  IpLimitRangeIpAccessList: "Limite la descripción del rango de IP '[IpRange]' de la lista de acceso IP a [NumOfChars] caracteres. Utilizó [UsedNumOfChars] caracteres.",
  IpUserAccessListLimit: 'Limite la lista de IP de acceso a [MaxNumberOfCharacters] caracteres. Ud. ingresó [EnteredNumberOfCharacters] caracteres.',
  LastMonth: 'Último mes',
  LastWeek: 'Última semana',
  LoadingThreeDots: 'Cargando...',
  LoggedInToDate_SentenceCase: 'Con sesiones iniciadas hasta la fecha',
  Logout: 'Cerrar sesión',
  Manage: 'Administrar',
  Message: 'Mensaje',
  Name: 'Nombre',
  Never: 'Nunca',
  NeverLoggedIn_SentenceCase: 'Nunca inició sesión',
  No: 'No',
  NonAdminSideBankruptcy: 'Parte compradora',
  NonAdminSideMerger: 'Parte compradora',
  None: 'Ninguno',
  NoRecordsFound: 'No se encontraron registros.',
  Notifications: 'Notificationes',
  NotInvited_Users__SentenceCase: 'No fue invitado',
  NotVerified_Users__SentenceCase: 'Sin verificar',
  Off: 'Desactivado',
  Ok: 'Aceptar',
  OkAllCaps: 'Aceptar',
  On: 'Activado',
  OnceOnlyAndWhenTermsAreUpdated: 'Sólamente una vez y cuando los términos son actualizados',
  OnEveryLogin: 'En cada inicio de sesión',
  Or: 'o',
  Period: 'Periodo',
  PrintingAllowed: 'Permitido imprimir',
  PrintingDenied: 'Impresión denegada',
  Profile: 'Perfil',
  QnaNotificationSwitchOff: 'No recibirá ningún correo',
  QnaNotificationSwitchOn: 'Recibirá actualizaciones inmediatamente',
  QnaRole: 'Rol de PyR',
  QnaRole_SentenceCase: 'Rol PyR',
  Recipients: 'Destinatarios',
  RecipientsOfEmailNote_Gen2: 'Ningún destinatario de este <nowrap>correo electrónico</nowrap> podrá ver la dirección de ninguno de los otros destinatarios, por lo que es seguro enviarlo a los usuarios de los diferentes grupos de usuarios.',
  Reset: 'Reiniciar',
  Save: 'Guardar',
  Saving: 'Guardando',
  SavingAllowed: 'Permitido guardar',
  SavingDenied: 'No se permite guardar',
  Search: 'Buscar',
  Security: 'Seguridad',
  SecurityGroup_SentenceCase: 'Grupo de seguridad',
  Select: 'Seleccionar',
  SelectUsers_SentenceCase: 'Elija usuarios',
  SelectUserType: 'Seleccione el tipo de usuario.',
  SellSide: 'Parte vendedora',
  SellSide_SentenceCase: 'Parte vendedora',
  Send: 'Enviar',
  SendInvites: 'Enviar invitaciones',
  SendUpdates: 'Mandar actualizaciones',
  SentDocumentChanged: 'Enviar cuando un documento es añadido o cambiado',
  ShouldBeEmailEmail: 'El correo electrónico que ingresó, no es una dirección de correo electrónico válida.',
  ShowActivityInReports_SentenceCase: 'Mostrar actividad en informes',
  Status: 'Estado',
  Subject: 'Asunto',
  Subject_Email_: 'Asunto',
  SubTeam: 'subequipo',
  SydneyTime: 'Hora de Sídney',
  Team: 'equipo',
  TimePeriodAm: 'AM',
  TimePeriodPm: 'PM',
  To: 'A:',
  Today: 'Hoy',
  Update: 'Actualización',
  UserCanLogInFromAnyIp: 'Este usuario puede iniciar sesión desde cualquier dirección IP',
  UserCanLogInOnlyFromIpsBelow: 'Este usuario SÓLO puede iniciar sesión desde las direcciones IP especificadas a continuación',
  UserGroup: 'Grupo de usuarios',
  UserProperties: 'Propiedades del usuario',
  Verified: 'Verificado',
  Verified_Users_: 'Verificado',
  View: 'Vista',
  ViewIndexAsGhost: '¿Qué pueden ver?',
  Watermark: 'Marca de agua',
  Weekly: 'Cada semana',
  Yes: 'Sí',
  YouCanAddDescriptionsAfterComma: 'También puede añadir descripciones después de la coma, por ejemplo<b>1.1.1.1, London office IP</b>',
  YourCurrentIpAddress: '<b>Nota:</b> Su dirección de IP actual es [IP Address]. <a [href]>Haga clic aquí</a> para añadirla a la lista.',
};
