// @flow

import * as autocompleteLegacy from 'app/components/autocompleteLegacy';
import type { AppDispatch } from 'app/types/appStateTypes';
import type { FilterItem } from './multiSelectTypes';

export const makeItems = (idKey: string, textKey: string, data: Array<*>): Array<*> =>
  data.map(item => ({
    id: item[idKey],
    text: item[textKey],
    data: {
      key: idKey,
    },
  }));

export const multiSelectUpdate = (action: { type: string, isGuest: boolean }) => (
  dispatch: AppDispatch,
  filterName: string,
  items: Array<FilterItem>,
  value: string,
  msg: string,
) => {
  // $FlowFixMe string is not matched with MessageType
  if (!autocompleteLegacy.shouldUpdateSuggestions(msg)) {
    dispatch({
      type: action.type,
      data: { [filterName]: value },
      isGuest: action.isGuest,
    });
    return;
  }

  const nextState = autocompleteLegacy.transition(
    // $FlowFixMe string is not matched with DisplayType
    value,
    autocompleteLegacy.msgUpdateSuggestion({
      suggestions: items,
      autoFilter: true,
      data: {},
    }),
  );

  dispatch({
    type: action.type,
    data: { [filterName]: nextState },
    isGuest: action.isGuest,
  });
};
