// @flow

import * as R from 'ramda';
import type {
  ItemSecurity,
  Item,
  EditableAttributes,
  Node,
  Tree,
  DocumentIndexItem,
  SubteamSetting,
} from 'app/pages/security/securityTypes';
import { getPolicy } from 'app/pages/security/helpers/securityHelpers';

export type EditableAttributeList =
  | 'allowAccess'
  | 'watermarkId'
  | 'allowSaving'
  | 'allowPrinting'
  | 'allowEditing'
  | 'allowCopying'
  | 'tracking';

type ItemIndex = { [key: number]: Array<Item> };
type ItemSecurities = { [compoundKey: string]: ItemSecurity };

export const getSecurityAttributes = (
  isSecurable: boolean,
  isWatermarkable: boolean,
  isFolder: boolean,
) => {
  const ACCESS_ATTRIBUTE = 'allowAccess';
  const TRACKING_ATTRIBUTE = 'tracking';
  const SECURITY_ATTRIBUTES = ['allowSaving', 'allowPrinting', 'allowEditing'];
  const PDF_EDITABLE_ATTRIBUTES = [
    ...SECURITY_ATTRIBUTES,
    ACCESS_ATTRIBUTE,
    TRACKING_ATTRIBUTE,
    'watermarkId',
  ];
  const SOD_EDITABLE_ATTRIBUTES = [
    ...SECURITY_ATTRIBUTES,
    ACCESS_ATTRIBUTE,
    TRACKING_ATTRIBUTE,
    'allowCopying',
  ];
  const FOLDER_EDITABLE_ATTRIBUTES = [
    ...SECURITY_ATTRIBUTES,
    ACCESS_ATTRIBUTE,
    TRACKING_ATTRIBUTE,
    'allowCopying',
    'watermarkId',
  ];

  if (!isSecurable) {
    return [ACCESS_ATTRIBUTE];
  }
  if (!isWatermarkable) {
    return SOD_EDITABLE_ATTRIBUTES;
  }
  if (isFolder) {
    return FOLDER_EDITABLE_ATTRIBUTES;
  }
  return PDF_EDITABLE_ATTRIBUTES;
};

export const makeDocumentItemId = (id: number, isFolder: boolean): string =>
  `${isFolder ? 'f' : 'd'}-${id}`;

const makeCompoundId = (id: string, subteamId: number) => `${id}-${subteamId}`;

const getNodeChildren = (index: ItemIndex, item: Item) =>
  item.isFolder && index[item.id] ? index[item.id].map(child => child.documentIndexItemId) : [];

export const getNodeById = (tree: Tree, id: number, isFolder: boolean) =>
  tree[makeDocumentItemId(id, isFolder)];

export const getNodeSecurities = (
  id: string,
  subteams?: Array<SubteamSetting>,
  securities: ItemSecurities,
) =>
  subteams &&
  subteams.reduce((acc, subteam) => {
    const subteamSecurity = securities[makeCompoundId(id, subteam.id)];
    if (!subteamSecurity) {
      return Object.assign(acc, {
        [subteam.id]: Object.assign({}, getPolicy(), {
          watermarkId: -1, // TODO: 'delete' watermark differently in the future
          allowAccess: false,
          subteamId: subteam.id,
          documentIndexItemId: id,
          useSod: subteam.useSod,
        }),
      });
    }
    return Object.assign(acc, {
      [subteam.id]: {
        ...subteamSecurity,
        useSod: subteam.useSod,
      },
    });
  }, {});

export const getDefaultEdited = (subteams: Array<SubteamSetting>) =>
  subteams.reduce(
    (acc, subteam) => ({
      ...acc,
      [subteam.id]: {},
    }),
    {},
  );

export const createNode = (
  index: ItemIndex,
  securities?: ItemSecurities,
  item: Item,
  depth: number,
  subteams?: Array<SubteamSetting>,
): Node => {
  const edited = subteams && getDefaultEdited(subteams);

  const children = getNodeChildren(index, item);
  if (item.isFolder) {
    return {
      ...item,
      depth,
      securities: securities && getNodeSecurities(item.documentIndexItemId, subteams, securities),
      edited,
      children,
    };
  }
  return {
    ...item,
    depth,
    securities: securities && getNodeSecurities(item.documentIndexItemId, subteams, securities),
    edited,
    children,
  };
};

const getAllChildren = (
  oldTree: Tree,
  edited: { [string]: boolean },
  documentIndexItemId: string,
) => {
  if (documentIndexItemId.includes('d-')) {
    return [];
  }

  const allChildren = [];
  const trackedFolderIds = [documentIndexItemId];

  while (trackedFolderIds.length > 0) {
    const trackedFolderId = trackedFolderIds.pop();
    const { children } = oldTree[trackedFolderId];
    allChildren.push(...children);
    trackedFolderIds.push(...children.filter(c => c.includes('f')));
  }
  return allChildren;
};

export const updateTreeSecurity = (
  edited: { [string]: boolean },
  oldTree: Tree,
  subteamId: number,
) => {
  return Object.keys(edited).reduce((tree, compoundKey) => {
    const keys = compoundKey.split('-');
    if (keys[2] === String(subteamId)) {
      const documentIndexItemId = `${keys[0]}-${keys[1]}`;
      const newSecurity = tree[documentIndexItemId].edited[subteamId];
      const allChildren = getAllChildren(tree, edited, documentIndexItemId);

      const newSubTree = Object.keys(tree).reduce((subTree, indexItemId) => {
        if (indexItemId === documentIndexItemId || allChildren.includes(indexItemId)) {
          const oldDocumentIndexItem = subTree[indexItemId] || tree[indexItemId];
          const newSecurities = {
            ...oldDocumentIndexItem.securities,
            [subteamId]: {
              ...oldDocumentIndexItem.securities[subteamId],
              ...newSecurity,
            },
          };
          const newDocumentIndexItem = {
            ...oldDocumentIndexItem,
            securities: newSecurities,
          };

          return {
            ...subTree,
            [indexItemId]: newDocumentIndexItem,
          };
        }
        return subTree;
      }, {});

      return {
        ...tree,
        ...newSubTree,
      };
    }
    return tree;
  }, oldTree);
};

export const getTree = (
  startNode: Node,
  index: ItemIndex,
  securities?: ItemSecurities,
  subteams?: Array<SubteamSetting>,
  oldTree?: Tree,
): Tree => {
  if (!index[startNode.id]) return {};

  const getNodeAndChildren = (item, depth) => {
    const node = createNode(index, securities, item, depth, subteams);

    // Keep edited items from known nodes
    const oldNode = oldTree && oldTree[item.documentIndexItemId];
    if (oldNode) node.edited = oldNode.edited;

    if (item.isFolder && node.children.length > 0) {
      const childrenNodes = index[item.id].reduce((acc, childItem) => {
        const childNode = getNodeAndChildren(childItem, depth + 1);
        return Object.assign(acc, childNode);
      }, {});

      return Object.assign({ [item.documentIndexItemId]: node }, childrenNodes);
    }

    return {
      [item.documentIndexItemId]: node,
    };
  };

  const tree = index[startNode.id].reduce((acc, item) => {
    const newNodes = getNodeAndChildren(item, startNode.depth + 1);
    return Object.assign(acc, newNodes);
  }, {});

  return tree;
};

const getDocumentIndexRootNode = (id: number) => ({
  id,
  documentIndexItemId: makeDocumentItemId(id, true),
  parentId: null,
  sortNumber: 10,
  number: '',
  name: 'Document Index',
  isDisabled: 0,
  securities: {},
  isSecurable: true,
  isWatermarkable: true,
  isFolder: true,
  folderCanBeExpanded: true,
});

export const getRootNode = (
  id: number,
  index: ItemIndex,
  securities?: ItemSecurities,
  subteams?: Array<SubteamSetting>,
): Node => {
  const rootNode = getDocumentIndexRootNode(id);
  return createNode(index, securities, rootNode, 0, subteams);
};

export const shouldLoad = (node: Node): boolean =>
  !!(node.isFolder && node.folderCanBeExpanded && !node.children.length);

// This function takes in the 'original' security settings for a node and
// recursively looks up the tree to see what else has been edited before rendering.
export const findEditedSecurities = (
  tree: Tree,
  id: string,
  subteamId: number,
  attrNames: Array<string>,
  acc: EditableAttributes,
) => {
  let names = [...attrNames];
  const node = tree[id];
  const itemAttrs = node.edited[subteamId];
  attrNames.forEach(attrName => {
    const value = itemAttrs[attrName];
    if (value !== undefined) {
      acc[attrName] = value;

      // This takes the current attribute name (e.g. 'allowAccess') out of the list
      // of attributes to look for, so that it isn't matched
      // on items further up the tree.
      names = names.filter(name => name !== attrName);
    }
  });
  if (names.length && node.parentId) {
    findEditedSecurities(tree, makeDocumentItemId(node.parentId, true), subteamId, names, acc);
  }
  return acc;
};

export const getSecurities = (tree: Tree, documentIndexItemId: string): Array<ItemSecurity> => {
  const node = tree[documentIndexItemId];
  const { securities, isSecurable, isWatermarkable, isFolder } = node;

  return Object.keys(securities).map(key => {
    const subteamId = parseInt(key, 10);
    const attrs = getSecurityAttributes(isSecurable, isWatermarkable, isFolder);

    // this gets the securities that should be displayed, which takes into account
    // any ancestors that have been updated but not saved
    // it doesn't return a full securities object, just things that have changed,
    // so below must again be checked.
    const editedSecurities = findEditedSecurities(tree, documentIndexItemId, subteamId, attrs, {});
    const getValue = (attrName: string) =>
      editedSecurities[attrName] !== undefined
        ? editedSecurities[attrName]
        : securities[subteamId][attrName];
    return {
      documentIndexItemId,
      subteamId,
      useSod: securities[subteamId].useSod,
      allowAccess: getValue('allowAccess'),
      watermarkId: getValue('watermarkId'),
      allowSaving: getValue('allowSaving'),
      allowPrinting: getValue('allowPrinting'),
      allowEditing: getValue('allowEditing'),
      allowCopying: getValue('allowCopying'),
      tracking: getValue('tracking'),
    };
  });
};

export const mapToDocumentIndex = (
  tree: Tree,
  index: Array<string>,
  expanded: Array<number>,
): Array<DocumentIndexItem> => {
  if (index[0] === undefined) {
    return [];
  }

  const isVisible = node => {
    if (!node) {
      return false;
    }
    if (node.parentId) {
      if (!expanded.includes(node.parentId)) {
        return false;
      }
    }
    return true;
  };

  const documentIndex = index.reduce((acc, id) => {
    const node = tree[id];
    const visible = isVisible(node);

    if (visible) {
      const securities = node.securities && getSecurities(tree, id);
      if (node.isFolder) {
        const nodeExpanded = expanded.includes(node.id);
        acc.push({
          id: node.id,
          documentIndexItemId: node.documentIndexItemId,
          depth: node.depth,
          name: `${node.number} ${node.name}`,
          isWatermarkable: node.isWatermarkable,
          isSecurable: node.isSecurable,
          isDisabled: node.isDisabled,
          securities,
          isFolder: true,
          expanded: nodeExpanded,
          expandable: node.folderCanBeExpanded,
        });
        return acc;
      }
      acc.push({
        id: node.id,
        documentIndexItemId: node.documentIndexItemId,
        depth: node.depth,
        name: `${node.number} ${node.name}`,
        isWatermarkable: node.isWatermarkable,
        isSecurable: node.isSecurable,
        isDisabled: node.isDisabled,
        securities,
        isFolder: false,
        fileExtension: node.fileExtension,
      });
    }
    return acc;
  }, []);
  return documentIndex;
};

export const setEditedState = (
  tree: Tree,
  id: string,
  subteamId: number,
  attr: EditableAttributeList,
  value: boolean | number,
) => {
  const removeIds = [];
  const newNodes = {};

  const setAttributeOnNode = (node, topNode?: boolean) => {
    const newNode = R.clone(node);

    // tracking is a special case. We need to know if it changed for a node,
    // so when sending the changes to the server, we can nullify any changed security controls
    if (topNode || attr === 'tracking') {
      newNode.edited[subteamId][attr] = value;
    } else {
      // otherwise, just delete the attribute from the edited object
      delete newNode.edited[subteamId][attr];
    }

    return newNode;
  };

  // This iterates down through the descendants of a changed item,
  // resetting any previously changed properties
  const resetChildren = (node: Node) => {
    node.children.forEach(childId => {
      const child = tree[childId];
      if (child) {
        newNodes[child.documentIndexItemId] = setAttributeOnNode(child);
        removeIds.push(`${childId}-${subteamId}-${attr}`);
        resetChildren(child);
      }
    });
  };

  const currentNode = tree[id];
  newNodes[currentNode.documentIndexItemId] = setAttributeOnNode(currentNode, true);
  resetChildren(currentNode);
  return {
    removeIds,
    nodes: newNodes,
  };
};

export const clearEditedState = (tree: Tree, id: string) => {
  const splitId = id.split('-');
  const documentIndexItemCompoundId = `${splitId[0]}-${splitId[1]}`;

  const subteamId = parseInt(splitId[2], 10);
  const newNodes = {};
  const getNode = node => ({
    ...node,
    edited: {
      [subteamId]: {},
    },
  });
  const resetChildren = (node: Node) => {
    node.children.forEach(childId => {
      const child = tree[childId];
      if (child) {
        newNodes[child.documentIndexItemId] = getNode(child);
        resetChildren(child);
      }
    });
  };
  const currentNode = tree[documentIndexItemCompoundId];
  newNodes[currentNode.documentIndexItemId] = getNode(currentNode);
  resetChildren(currentNode);
  return newNodes;
};
