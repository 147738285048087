"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyFunction = exports.SETUP_PROCURE_SUBMISSION_WINDOW_HELP_LINK = exports.EVENT_NAME = exports.DATAROOM_APP_NAME = exports.SMS_APP_NAME = exports.PLATFORM_HEADER_APP_NAME = exports.DATAROOM_SHELL_APP_NAME = exports.EMPTY_NAME_CHAR = exports.ROUTES = exports.ACTION_TYPES = exports.ELEMENT_IDS = exports.DIMENSIONS = exports.COLORS = exports.USER_TYPE_CODES_ORDER = exports.USER_TYPE_CODES = exports.COPY_SECURITY_ACTIONS = exports.COPY_SECURITY_STATUSES = exports.SECURITY_DIALOG_TYPES = exports.PEOPLE_DIALOG_TYPES = exports.TEAM_DIALOG_TYPES = exports.SITE_DIALOG_TYPES = exports.HTTP_METHODS = exports.SECURITY_TABS = exports.DATA_ROOMS = exports.PAGE_SECTIONS = exports.PAGE_TITLES = exports.UNCHANGED_BOOL = exports.UNCHANGED_INT = void 0;
// The backend sometimes expects that we explicitly pass a property marked as unchanged
// This value is different depending on the type of the property
exports.UNCHANGED_INT = -1;
exports.UNCHANGED_BOOL = null;
exports.PAGE_TITLES = {
    SECURITY: 'Security',
    TEAMS: 'Teams',
    TEAM_HISTORY: 'Team history',
    PERSON_HISTORY: 'Person history',
};
exports.PAGE_SECTIONS = {
    DATA_ROOMS: 'data_rooms',
    SECURITY: 'security',
    TEAMS: 'teams',
};
exports.DATA_ROOMS = {
    DEFAULT_ROOM_LIMIT: 50,
};
exports.SECURITY_TABS = {
    DOCUMENTS: 0,
    SUBJECTS: 1,
    TENDER_SUBMISSIONS: 2,
};
exports.HTTP_METHODS = {
    DELETE: 'DELETE',
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT',
};
exports.SITE_DIALOG_TYPES = Object.freeze({
    COPY_SECURITY_FORM: 'copySecurityForm',
    DELETE_TEAM_DIALOG: 'deleteTeamDialog',
    PEOPLE_FORM: 'peopleForm',
    SINGLE_ITEM_EDIT: 'SINGLE ITEM EDIT',
    TEAM_FORM: 'teamForm',
    TOGGLE_REGISTRATIONS_DIALOG: 'toggleRegistrationsDialog',
    TOGGLE_VERIFY_EMAIL_DIALOG: 'verifyEmailDialog',
});
exports.TEAM_DIALOG_TYPES = Object.freeze({
    COPY: 'Copy',
    CREATE: 'Create',
    DELETE: 'Delete',
    EDIT: 'Edit',
});
exports.PEOPLE_DIALOG_TYPES = Object.freeze({
    CREATE: 'Create',
    DELETE: 'Delete',
    EDIT: 'Edit',
});
exports.SECURITY_DIALOG_TYPES = Object.freeze({
    COPY_SECURITY: 'copySecurity',
    SECURITY_HELPER: 'securityHelper',
});
exports.COPY_SECURITY_STATUSES = Object.freeze({
    DONE: 'Done',
    ERROR: 'Error',
    IN_PROGRESS: 'InProgress',
    QUEUED: 'Queued',
});
exports.COPY_SECURITY_ACTIONS = Object.freeze({
    CONFIRM: 'CONFIRM',
    DISMISS: 'DISMISS',
    REVIEW: 'REVIEW',
});
exports.USER_TYPE_CODES = Object.freeze({
    ADM: 'ADM',
    DOP: 'DOP',
    GUE: 'GUE',
    USQ: 'USQ',
    USR: 'USR',
});
exports.USER_TYPE_CODES_ORDER = Object.freeze([
    exports.USER_TYPE_CODES.ADM,
    exports.USER_TYPE_CODES.USR,
    exports.USER_TYPE_CODES.DOP,
    exports.USER_TYPE_CODES.USQ,
    exports.USER_TYPE_CODES.GUE,
]);
exports.COLORS = {
    USERS: '#f67a50',
    DOCUMENT: '#43a2d3',
    QNA: '#1f9c73',
    SETTINGS: '#774dca',
    SECURITY: '#f4244d',
};
exports.DIMENSIONS = {
    TABLE_ROW_HEIGHT: 35,
};
exports.ELEMENT_IDS = {
    NAV_MANAGE_FILTERED_QUESTIONS: 'navManageFilteredQuestions',
    NAV_MANAGE_QNA: 'navManageQna',
    NAV_MANAGE_ADMIN_SIDE_USERS: 'navManageAdminSideUsers',
    NAV_MANAGE_GUEST_SIDE_USERS: 'navManageGuestSideUsers',
    FOOTER: 'footer',
    PEOPLE_FORM_TERMS_OF_ACCESS: 'PEOPLE_FORM_TERMS_OF_ACCESS',
    PEOPLE_FORM_IP_ACCESS: 'PEOPLE_FORM_IP_ACCESS',
};
exports.ACTION_TYPES = {
    SET_LICENSE_DATA: 'SET_LICENSE_DATA',
    CLEAR_ERRORS: 'CLEAR_ERRORS',
    COLOURPICKER_TOGGLE: 'COLOURPICKER_TOGGLE',
    DIALOG_SAVE_PROGRESS_BEGIN: 'DIALOG_SAVE_PROGRESS_BEGIN',
    DIALOG_SAVE_PROGRESS_END: 'DIALOG_SAVE_PROGRESS_END',
    DIALOG_SET_TITLE: 'DIALOG_SET_TITLE',
    DIALOG_TOGGLE: 'DIALOG_TOGGLE',
    LOADINGBAR_LOADING_TOGGLE: 'LOADINGBAR_LOADING_TOGGLE',
    LOADINGBAR_VALUE_UPDATE: 'LOADINGBAR_VALUE_UPDATE',
    PAGE_OPEN: 'PAGE_OPEN',
    USER_PERMISSIONS_FETCH: 'USER_PERMISSIONS_FETCH',
    USER_PERMISSIONS_UPDATE: 'USER_PERMISSIONS_UPDATE',
    USER_TYPES_FETCH: 'USER_TYPES_FETCH',
    USER_TYPES_UPDATE: 'USER_TYPES_UPDATE',
    FEATURE_FLAGS: {
        UPDATE: 'FEATURE_FLAGS.UPDATE',
    },
    FETCH_DATAROOM_FEATURES: 'FETCH_DATAROOM_FEATURES',
    DATAROOM_FEATURES_SET_DATA: 'DATAROOM_FEATURES_SET_DATA',
    USER_PROFILE: {
        UPDATE: 'USER_PROFILE.UPDATE',
    },
    ACTION_USER: {
        DIALOG_TOGGLE: 'ACTION_USER.DIALOG_TOGGLE',
        DIALOG_SET_DATA: 'ACTION_USER.DIALOG_SET_DATA',
    },
    BANNER_MESSAGES: {
        CLEAR_ALL: 'BANNER_MESSAGES.CLEAR_ALL',
        ITEM_ADD: 'BANNER_MESSAGES.ITEM_ADD',
        ITEM_DISMISS: 'BANNER_MESSAGES.ITEM_DISMISS',
        ITEM_DISMISSED: 'BANNER_MESSAGES.ITEM_DISMISSED',
        SERVER_ERRORS_ADD: 'BANNER_MESSAGES.SERVER_ERRORS_ADD',
        SERVER_ERRORS_GENERATE: 'BANNER_MESSAGES.SERVER_ERRORS_GENERATE',
    },
    PAGE: {
        CONFIRM_DISCARD_UNSAVED_DATA_SET: 'PAGE.CONFIRM_DISCARD_UNSAVED_DATA_SET',
        CURRENT_USER_QNA_ROLE_SET: 'PAGE.CURRENT_USER_QNA_ROLE_SET',
        IS_FOOTER_VISIBLE: 'PAGE.IS_FOOTER_VISIBLE',
        SECTION_SET: 'PAGE.SECTION_SET',
        SET_SHOW_SUBMISSION_LIMITS_REMAINING_LINK: 'PAGE.SET_SHOW_SUBMISSION_LIMITS_REMAINING_LINK',
        SET_REMOTE_IP: 'PAGE.SET_REMOTE_IP',
        QNA_ROLES_UPDATE: 'PAGE.QNA_ROLES_UPDATE',
        SET_LICENSES: 'PAGE.SET_LICENSES',
    },
    QNA: {
        MANAGE_QUESTIONS_GET_QNA_ROLE: 'QNA.MANAGE_QUESTIONS_GET_QNA_ROLE',
    },
    SECURITY: {
        OPEN_SECURITY_DIALOG: 'SECURITY.OPEN_SECURITY_DIALOG',
        CHANGE_TAB: 'SECURITY.CHANGE_TAB',
        CLEAR_CHANGES: 'SECURITY.CLEAR_CHANGES',
        COPY_FORM_CONFIRM: 'SECURITY.COPY_FORM_CONFIRM',
        COPY_FORM_DISMISS: 'SECURITY.COPY_FORM_DISMISS',
        COPY_FORM_REVIEW: 'SECURITY.COPY_FORM_REVIEW',
        COPY_FORM_SELECT: 'SECURITY.COPY_FORM_SELECT',
        COPY_FORM_UPDATE_OPTIONS: 'SECURITY.COPY_FORM_UPDATE_OPTIONS',
        COPY_FORM_STATUS_UPDATE: 'SECURITY.COPY_FORM_STATUS_UPDATE',
        COPY_FORM_UPDATE: 'SECURITY.COPY_FORM_UPDATE',
        COPY_SECURITY: 'SECURITY.COPY_SECURITY',
        COPY_SECURITY_INIT: 'SECURITY.COPY_SECURITY_INIT',
        DATA_FETCH_INITIAL: 'SECURITY.DATA_FETCH_INITIAL',
        DIALOG_TYPE: 'SECURITY.DIALOG_TYPE',
        EXPORT_SECURITY: 'SECURITY.EXPORT_SECURITY',
        FETCH_USER_QNA_ROLE: 'SECURITY.FETCH_USER_QNA_ROLE',
        FOLDER_TOGGLE: 'SECURITY.FOLDER_TOGGLE',
        FOLDER_TOGGLED: 'SECURITY.FOLDER_TOGGLED',
        ITEM_EDITED_CLEAR: 'SECURITY.ITEM_EDITED_CLEAR',
        ITEM_FETCHED: 'SECURITY.ITEM_FETCHED',
        ITEM_SECURITY_FETCH: 'SECURITY.ITEM_SECURITY_FETCH',
        ITEM_SECURITY_FETCH_INITIAL: 'SECURITY.ITEM_SECURITY_FETCH_INITIAL',
        ITEM_SETTINGS_SAVE: 'SECURITY.ITEM_SETTINGS_SAVE',
        ITEM_SETTINGS_UPDATE: 'SECURITY.ITEM_SETTINGS_UPDATE',
        RESET_STATE: 'SECURITY.RESET_STATE',
        SETTINGS_SAVE: 'SECURITY.SETTINGS_SAVE',
        SETTINGS_UPDATE: 'SECURITY.SETTINGS_UPDATE',
        STATE_INIT: 'SECURITY.STATE_INIT',
        TEAMS_FETCHED: 'SECURITY.TEAMS_FETCHED',
        TREE_EXPAND: 'SECURITY.TREE_EXPAND',
        TREE_EXPAND_ALL: 'SECURITY.TREE_EXPAND_ALL',
        TREE_FETCH_COLLAPSE_ALL: 'SECURITY.TREE_FETCH_COLLAPSE_ALL',
        TREE_FETCH_EXPAND_ALL: 'SECURITY.TREE_FETCH_EXPAND_ALL',
        TREE_INIT: 'SECURITY.TREE_INIT',
        TREE_SAVE_BEGIN: 'SECURITY.TREE_SAVE_BEGIN',
        TREE_SAVE_END: 'SECURITY.TREE_SAVE_END',
        TREE_TOGGLED: 'SECURITY.TREE_TOGGLED',
        TREE_UPDATE: 'SECURITY.TREE_UPDATE',
        WATERMARK_NEW: 'SECURITY.WATERMARK_NEW',
        WATERMARKS_FETCH: 'SECURITY.WATERMARKS_FETCH',
        WATERMARKS_FETCHED: 'SECURITY.WATERMARKS_FETCHED',
        SUBJECTS: {
            COLLAPSE_ALL_GROUPS: 'SECURITY.SUBJECTS.COLLAPSE_ALL_GROUPS',
            EXPAND_ALL_GROUPS: 'SECURITY.SUBJECTS.EXPAND_ALL_GROUPS',
            LOAD: 'SECURITY.SUBJECTS.LOAD',
            LOADED: 'SECURITY.SUBJECTS.LOADED',
            SAVE_CHANGES_IN_STORE: 'SECURITY.SUBJECTS.SAVE_CHANGES_IN_STORE',
            SAVED: 'SECURITY.SUBJECTS.SAVED',
            SAVING: 'SECURITY.SUBJECTS.SAVING',
            SEND_CHANGES_TO_SERVER: 'SECURITY.SUBJECTS.SEND_CHANGES_TO_SERVER',
            SET_ACCESS: 'SECURITY.SUBJECTS.SET_ACCESS',
            SET_GROUP_EXPANDED: 'SECURITY.SUBJECTS.SET_GROUP_EXPANDED',
        },
        TENDER_SUBMISSIONS: {
            INIT_DATA: 'SECURITY.TENDER_SUBMISSIONS.INIT_DATA',
            SET_SUBMISSION_INDEX: 'SECURITY.TENDER_SUBMISSIONS.SET_SUBMISSION_INDEX',
            UPDATE_SUBMISSION_TREE_INDEX: 'SECURITY.TENDER_SUBMISSIONS.UPDATE_SUBMISSION_TREE_INDEX',
            TOGGLE_FOLDER: 'SECURITY.TENDER_SUBMISSIONS.TOGGLE_FOLDER',
            SET_EXPANDED_FOLDERS: 'SECURITY.TENDER_SUBMISSIONS.SET_EXPANDED_FOLDERS',
            SET_SECURITY_SETTINGS: 'SECURITY.TENDER_SUBMISSIONS.SET_SECURITY_SETTINGS',
            RESET: 'SECURITY.TENDER_SUBMISSIONS.RESET',
            SAVE: 'SECURITY.TENDER_SUBMISSIONS.SAVE',
            UPDATE_SUBMISSION_TREE: 'SECURITY.TENDER_SUBMISSIONS.UPDATE_SUBMISSION_TREE',
            SET_TEAM_CONTEXT: 'SECURITY.TENDER_SUBMISSIONS.SET_TEAM_CONTEXT',
            SELECT_BIDDER: 'SECURITY.TENDER_SUBMISSIONS.SELECT_BIDDER',
            SET_ROOT_FOLDERS: 'SECURITY.TENDER_SUBMISSIONS.SET_ROOT_FOLDERS',
            EXPAND_TREE: 'SECURITY.TENDER_SUBMISSIONS.EXPAND_TREE',
            TREE_EXPAND_ALL: 'SECURITY.TENDER_SUBMISSIONS.TREE_EXPAND_ALL',
            COLLAPSE_TREE: 'SECURITY.TENDER_SUBMISSIONS.COLLAPSE_TREE',
        },
    },
    SITE: {
        DIALOG_OPEN: 'SITE.DIALOG_OPEN',
        DIALOG_SAVE_PROGRESS_BEGIN: 'SITE.DIALOG_SAVE_PROGRESS_BEGIN',
        DIALOG_SAVE_PROGRESS_END: 'SITE.DIALOG_SAVE_PROGRESS_END',
        DIALOG_TOGGLE: 'SITE.DIALOG_TOGGLE',
        DIALOG_SET_TITLE: 'SITE.DIALOG_SET_TITLE',
        DIALOG_TOGGLE_SPINNER: 'SITE.DIALOG_TOGGLE_SPINNER',
    },
    TEAMS: {
        SET_PROPS: 'TEAMS.SET_PROPS',
        COLLAPSE_ALL: 'TEAMS.COLLAPSE_ALL',
        COLLAPSED_ALL: 'TEAMS.COLLAPSED_ALL',
        COPY_SECURITY: 'TEAMS.COPY_SECURITY',
        DATA_FETCH: 'TEAMS.DATA_FETCH',
        DATA_FETCH_INITIAL: 'TEAMS.DATA_FETCH_INITIAL',
        DELETE_TEAM: 'TEAMS.DELETE_TEAM',
        DELETE_TEAM_DIALOG: 'TEAMS.DELETE_TEAM_DIALOG',
        EXPAND_ALL: 'TEAMS.EXPAND_ALL',
        EXPANDER_TOGGLE: 'TEAMS.EXPANDER_TOGGLE',
        EXPORT_USERS: 'TEAMS.EXPORT_USERS',
        FILTER_DATA_MAP: 'TEAMS.FILTER_DATA_MAP',
        FILTER_REMOVE: 'TEAMS.FILTER_REMOVE',
        FILTER_TOGGLE: 'TEAMS.FILTER_TOGGLE',
        FILTER_UPDATE: 'TEAMS.FILTER_UPDATE',
        FORM_HELP_TOGGLE: 'TEAMS.FORM_HELP_TOGGLE',
        PEOPLE_FORM_ADD: 'TEAMS.PEOPLE_FORM_ADD',
        PEOPLE_FORM_CHANGE: 'TEAMS.PEOPLE_FORM_CHANGE',
        PEOPLE_FORM_EDIT: 'TEAMS.PEOPLE_FORM_EDIT',
        PEOPLE_FORM_ERRORS: 'TEAMS.PEOPLE_FORM_ERRORS',
        PEOPLE_SAVE: 'TEAMS.PEOPLE_SAVE',
        PEOPLE_SAVE_SUCCESSFULLY: 'TEAMS.PEOPLE_SAVE_SUCCESSFULLY',
        PEOPLE_FORM_SET_IP_INFO: 'TEAMS.PEOPLE_FORM_SET_IP_INFO',
        PEOPLE_FETCH_IPLIST: 'TEAMS.PEOPLE_FETCH_IPLIST',
        PERSON_CONFIRM_DELETE: 'TEAMS.PERSON_CONFIRM_DELETE',
        PERSON_DELETE: 'TEAMS.PERSON_DELETE',
        PERSON_TOGGLE_DISABLE: 'TEAMS.PERSON_TOGGLE_DISABLE',
        PERSON_VIEW_HISTORY: 'TEAMS.PERSON_VIEW_HISTORY',
        SET_FOCUS_TEAM: 'TEAMS.SET_FOCUS_TEAM',
        TEAM_FORM_ADD_SUBTEAM: 'TEAMS.TEAM_FORM_ADD_SUBTEAM',
        TEAM_FORM_ADD_TEAM: 'TEAMS.TEAM_FORM_ADD_TEAM',
        TEAM_FORM_CHANGE: 'TEAMS.TEAM_FORM_CHANGE',
        TEAM_FORM_COPY: 'TEAMS.TEAM_FORM_COPY',
        TEAM_FORM_EDIT: 'TEAMS.TEAM_FORM_EDIT',
        TEAM_FORM_ERRORS: 'TEAMS.TEAM_FORM_ERRORS',
        TEAM_SAVE: 'TEAMS.TEAM_SAVE',
        TOGGLE_REGISTRATIONS: 'TEAMS.TOGGLE_REGISTRATIONS',
        TOGGLE_REGISTRATIONS_DIALOG: 'TEAMS.TOGGLE_REGISTRATIONS_DIALOG',
        UPDATE_TEAM: 'TEAMS.UPDATE_TEAM',
        VIEW_DATA_UPDATE: 'TEAMS.VIEW_DATA_UPDATE',
        TOGGLE_TENDER_FREEMIUM_INVITE_GUEST_UPGRADE_DIALOG: 'TEAMS.TOGGLE_TENDER_FREEMIUM_INVITE_GUEST_UPGRADE_DIALOG',
        TOGGLE_ALWAYS_INVITE_GUEST_UPGRADE_DIALOG: 'TEAMS.TOGGLE_ALWAYS_INVITE_GUEST_UPGRADE_DIALOG',
    },
    NOTIFICATION: {
        ENABLE_DOCUMENT: 'NOTIFICATION.ENABLE_DOCUMENT',
        DISABLE_DOCUMENT: 'NOTIFICATION.DISABLE_DOCUMENT',
        SET_DOCUMENT_SETTING: 'NOTIFICATION.SET_DOCUMENT_SETTING',
        SHOW_DOCUMENT_SELECTOR: 'NOTIFICATION.SHOW_DOCUMENT_SELECTOR',
        ENABLE_QNA: 'NOTIFICATION.ENABLE_QNA',
        DISABLE_QNA: 'NOTIFICATION.DISABLE_QNA',
        FETCH_SETTING: 'NOTIFICATION.FETCH_SETTING',
        SET_SETTING: 'NOTIFICATION.SET_SETTING',
        SAVE_SETTING: 'NOTIFICATION.SAVE_SETTING',
    },
    TEAM_HISTORY: {
        DATA_FETCH_INITIAL: 'TEAM_HISTORY.DATA_FETCH_INITIAL',
        SET_TEAM: 'TEAM_HISTORY.SET_TEAM',
        SET_TEAM_HISTORY: 'TEAM_HISTORY.SET_TEAM_HISTORY',
    },
    PERSON_HISTORY: {
        DATA_FETCH_INITIAL: 'PERSON_HISTORY.DATA_FETCH_INITIAL',
        RESET_STATE: 'PERSON_HISTORY.RESET_STATE',
        SET_PERSON: 'PERSON_HISTORY.SET_PERSON',
        SET_PERSON_HISTORY: 'PERSON_HISTORY.SET_PERSON_HISTORY',
        SET_IS_LOADED: 'PERSON_HISTORY.SET_IS_LOADED',
    },
    SEND_INVITATION: {
        TEAMS_UPDATE: 'SEND_INVITATION.TEAMS_UPDATE',
        PEOPLE_UPDATE: 'SEND_INVITATION.PEOPLE_UPDATE',
        DATA_FETCH_INITIAL: 'SEND_INVITATION.DATA_FETCH_INITIAL',
        SELECT_PEOPLE: 'SEND_INVITATION.SELECT_PEOPLE',
        CHANGE_FILTER: 'SEND_INVITATION.CHANGE_FILTER',
        RESET: 'SEND_INVITATION.RESET',
        SET_USER_TYPES_AND_QNA_TYPES: 'SEND_INVITATION.SET_USER_TYPES_AND_QNA_TYPES',
        SET_DEFAULT_INVITE: 'SEND_INVITATION.SET_DEFAULT_INVITE',
        UPLOAD_ATTACHMENT: 'SEND_INVITATION.UPLOAD_ATTACHMENT',
        ADD_ATTACHMENT: 'SEND_INVITATION.ADD_ATTACHMENT',
        REMOVE_ATTACHMENT: 'SEND_INVITATION.REMOVE_ATTACHMENT',
        SET_IS_LOADED: 'SEND_INVITATION.SET_IS_LOADED',
        SUBMIT_INVITATION: 'SEND_INVITATION.SUBMIT_INVITATION',
        SET_IS_SUBMITTING: 'SEND_INVITATION.SET_IS_SUBMITTING',
        SET_IS_UPLOADING_ATTACHMENT: 'SEND_INVITATION.SET_IS_UPLOADING_ATTACHMENT',
        SET_SENT: 'SEND_INVITATION.SET_SENT',
    },
    EDIT_PEOPLE: {
        DATA_FETCH_INITIAL: 'EDIT_PEOPLE.DATA_FETCH_INITIAL',
        SET_IS_LOADED: 'EDIT_PEOPLE.SET_IS_LOADED',
        RESET: 'EDIT_PEOPLE.RESET',
        SET_ROOM_ROLE: 'EDIT_PEOPLE.SET_ROOM_ROLE',
        SET_QNA_ROLE: 'EDIT_PEOPLE.SET_QNA_ROLE',
        SET_TEAM: 'EDIT_PEOPLE.SET_TEAM',
        SET_SUB_TEAM: 'EDIT_PEOPLE.SET_SUB_TEAM',
        SET_ACTIVITY_LOG: 'EDIT_PEOPLE.SET_ACTIVITY_LOG',
        SET_STATUS: 'EDIT_PEOPLE.SET_STATUS',
        SET_IP_ACCESS_LIST: 'EDIT_PEOPLE.SET_IP_ACCESS_LIST',
        SELECT_PEOPLE: 'EDIT_PEOPLE.SELECT_PEOPLE',
        SET_ERRORS: 'EDIT_PEOPLE.SET_ERRORS',
        UPDATE_PEOPLE: 'EDIT_PEOPLE.UPDATE_PEOPLE',
        SET_IS_SUBMITTING: 'EDIT_PEOPLE.SET_IS_SUBMITTING',
        SET_UPDATED: 'EDIT_PEOPLE.SET_UPDATED',
        SET_TERMS_OF_ACCESS: 'EDIT_PEOPLE.SET_TERMS_OF_ACCESS',
    },
    EMAIL_PEOPLE: {
        ADD_ATTACHMENT: 'EMAIL_PEOPLE.ADD_ATTACHMENT',
        DATA_FETCH_INITIAL: 'EMAIL_PEOPLE.DATA_FETCH_INITIAL',
        PEOPLE_UPDATE: 'EMAIL_PEOPLE.PEOPLE_UPDATE',
        REMOVE_ATTACHMENT: 'EMAIL_PEOPLE.REMOVE_ATTACHMENT',
        RESET: 'EMAIL_PEOPLE.RESET',
        SET_IS_LOADED: 'EMAIL_PEOPLE.SET_IS_LOADED',
        SET_IS_SUBMITTING: 'EMAIL_PEOPLE.SET_IS_SUBMITTING',
        SET_IS_UPLOADING_ATTACHMENT: 'EMAIL_PEOPLE.SET_IS_UPLOADING_ATTACHMENT',
        SET_SENT: 'EMAIL_PEOPLE.SET_SENT',
        SUBMIT_EMAIL: 'EMAIL_PEOPLE.SUBMIT_EMAIL',
        TEAMS_UPDATE: 'EMAIL_PEOPLE.TEAMS_UPDATE',
        UPLOAD_ATTACHMENT: 'EMAIL_PEOPLE.UPLOAD_ATTACHMENT',
    },
    SELF_REGISTRATION: {
        JOB_TITLE_GROUPS: {
            FETCH: 'SELF_REGISTRATION.JOB_TITLE_GROUPS.FETCH',
            SET: 'SELF_REGISTRATION.JOB_TITLE_GROUPS.SET',
            RESET: 'SELF_REGISTRATION.JOB_TITLE_GROUPS.RESET',
        },
        LOCATIONS: {
            FETCH: 'SELF_REGISTRATION.LOCATIONS.FETCH',
            LOADING: 'SELF_REGISTRATION.LOCATIONS.LOADING',
            SET: 'SELF_REGISTRATION.LOCATIONS.SET',
            RESET: 'SELF_REGISTRATION.LOCATIONS.RESET',
        },
        SUBMIT_REGISTRATION: {
            FETCH: 'SELF_REGISTRATION.SUBMIT_REGISTRATION.FETCH',
            LOADING: 'SELF_REGISTRATION.SUBMIT_REGISTRATION.LOADING',
            STATUS: 'SELF_REGISTRATION.SUBMIT_REGISTRATION.STATUS',
            SET_ERROR: 'SELF_REGISTRATION.SUBMIT_REGISTRATION.SET_ERROR',
            RESET_ERROR: 'SELF_REGISTRATION.SUBMIT_REGISTRATION.RESET_ERROR',
        },
    },
    MANAGE_REGISTRATION: {
        REGISTRANTS: {
            FETCH: 'MANAGE_REGISTRATION.REGISTRANTS.FETCH',
            LOADING: 'MANAGE_REGISTRATION.REGISTRANTS.LOADING',
            SET: 'MANAGE_REGISTRATION.REGISTRANTS.SET',
            RESET: 'MANAGE_REGISTRATION.REGISTRANTS.RESET',
            TOGGLE: 'MANAGE_REGISTRATION.REGISTRANTS.TOGGLE',
        },
        DECLINE_REGISTRATION: {
            FETCH: 'MANAGE_REGISTRATION.DECLINE_REGISTRATION.FETCH',
            LOADING: 'MANAGE_REGISTRATION.DECLINE_REGISTRATION.LOADING',
            SET: 'MANAGE_REGISTRATION.DECLINE_REGISTRATION.SET',
            TOGGLE_DIALOG: 'MANAGE_REGISTRATION.DECLINE_REGISTRATION.TOGGLE_DIALOG',
        },
        DELETE_REGISTRATION: {
            FETCH: 'MANAGE_REGISTRATION.DELETE_REGISTRATION.FETCH',
            LOADING: 'MANAGE_REGISTRATION.DELETE_REGISTRATION.LOADING',
            SET: 'MANAGE_REGISTRATION.DELETE_REGISTRATION.SET',
            TOGGLE_DIALOG: 'MANAGE_REGISTRATION.DELETE_REGISTRATION.TOGGLE_DIALOG',
        },
        INVITE_REGISTRATION: {
            FETCH: 'MANAGE_REGISTRATION.INVITE_REGISTRATION.FETCH',
            LOADING: 'MANAGE_REGISTRATION.INVITE_REGISTRATION.LOADING',
            SET: 'MANAGE_REGISTRATION.INVITE_REGISTRATION.SET',
            TOGGLE_DIALOG: 'MANAGE_REGISTRATION.INVITE_REGISTRATION.TOGGLE_DIALOG',
            ACCEPT: 'MANAGE_REGISTRATION.INVITE_REGISTRATION.ACCEPT',
        },
        SETTINGS: {
            FETCH: 'MANAGE_REGISTRATION.SETTINGS.FETCH',
            GET_LOADING: 'MANAGE_REGISTRATION.SETTINGS.GET_LOADING',
            SET: 'MANAGE_REGISTRATION.SETTINGS.SET',
            RESET: 'MANAGE_REGISTRATION.SETTINGS.RESET',
            UPDATE: 'MANAGE_REGISTRATION.SETTINGS.UPDATE',
            UPDATE_LOADING: 'MANAGE_REGISTRATION.SETTINGS.UPDATE_LOADING',
            TOGGLE_DIALOG: 'MANAGE_REGISTRATION.SETTINGS.TOGGLE_DIALOG',
        },
    },
    FREEMIUM: {
        TOGGLE_VERIFY_EMAIL_DIALOG: 'FREEMIUM.TOGGLE_VERIFY_EMAIL_DIALOG',
        CLOSE_VERIFY_EMAIL_MESSAGE: 'FREEMIUM.CLOSE_VERIFY_EMAIL_MESSAGE',
        VERIFY_EMAIL_DIALOG: {
            RESEND_VERIFICATION_LINK: 'FREEMIUM.VERIFY_EMAIL_DIALOG.RESEND_VERIFICATION_LINK',
            SET_IS_SUBMITTING: 'FREEMIUM.VERIFY_EMAIL_DIALOG.SET_IS_SUBMITTING',
            SET_EMAIL_SENT: 'FREEMIUM.VERIFY_EMAIL_DIALOG.SET_EMAIL_SENT',
        },
    },
    THIRD_PARTY_INTEGRATIONS_DATA_ROOM: {
        SET_DATA: 'THIRD_PARTY_INTEGRATIONS_DATA_ROOM.SET_DATA',
    },
    THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS: {
        FETCH_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP: 'THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS.FETCH_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP',
        UPDATE_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP: 'THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS.UPDATE_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP',
    },
    SEGMENT_EVENTS: {
        SECURITY_EDITED: 'SEGMENT_EVENTS.SECURITY_EDITED',
        TEAM_SECURITY_EDITED: 'SEGMENT_EVENTS.TEAM_SECURITY_EDITED',
        TEAM_ACCESS_EDITED: 'SEGMENT_EVENTS.TEAM_ACCESS_EDITED',
        TEAM_WATERMARK_EDITED: 'SEGMENT_EVENTS.TEAM_WATERMARK_EDITED',
        DOCUMENT_SECURITY_EDITED: 'SEGMENT_EVENTS.DOCUMENT_SECURITY_EDITED',
        USER_HISTORY_VIEWED: 'SEGMENT_EVENTS.USER_HISTORY_VIEWED',
    },
    LICENSE: {
        SET_DATA: 'LICENSE.SET_DATA',
        DATA_FETCH_INITIAL: 'LICENSE.DATA_FETCH_INITIAL',
    },
    SUBSCRIPTION: {
        TRANSFER_UPGRADE_DIALOG: 'SUBSCRIPTION.TRANSFER_UPGRADE_DIALOG',
        MANAGE_DIALOG: 'SUBSCRIPTION.MANAGE_DIALOG',
        ACTIVATE_DIALOG: 'SUBSCRIPTION.ACTIVATE_DIALOG',
    },
};
exports.ROUTES = {
    TEAMS: '/room/teams',
    SECURITY: '/room/security',
    SECURITY_QNA_SECURITY: '/room/security/qna',
    SECURITY_TENDER_SUBMISSION: '/room/security/tender',
    NOTIFICATIONS: '/room/notifications',
    TEAM_HISTORY: '/room/team/:id/history',
    PERSON_HISTORY: '/room/person/:id/history',
    SEND_INVITATION: '/room/sendInvitation',
    EDIT_PEOPLE: '/room/editPeople',
    EMAIL_PEOPLE: '/room/emailPeople',
    VIEW_DOCUMENT_INDEX: '/spa/documents',
    REQUEST_ACCESS: '/room/request-access',
};
exports.EMPTY_NAME_CHAR = '-';
exports.DATAROOM_SHELL_APP_NAME = 'dataroomShell';
exports.PLATFORM_HEADER_APP_NAME = 'platformHeader';
exports.SMS_APP_NAME = 'sms';
exports.DATAROOM_APP_NAME = 'dataroom';
exports.EVENT_NAME = {
    DATA_ROOM: {
        SET_LICENSING_DATA: 'data-room-set-licensing-data',
        PREMIUM_STORE_SUBSCRIBED: 'premium-store-subscribed',
        TEAM_PEOPLE_ADDED: 'team-people-added',
        PREMIUM_UPGRADE_DIALOG_OPENED: 'premium-upgrade-dialog-opened',
        INVITATION_SENT: 'invitation-sent',
    },
    TENDER: {
        UPGRADE_PREMIUM_BUTTON_CLICK: 'tender-upgrade-premium-button-click',
        TOGGLE_INVITE_GUEST_BANNER: 'tender-toggle-invite-guest-banner',
    },
    SMS: {
        TRANSFER_OWNERSHIP_RECOMMENDED: 'transfer_ownership_recommended',
        SUBSCRIPTION_TRANSFER_UPGRADE_DIALOG_SHOWED: 'subscription_transfer_upgrade_dialog_showed',
        SUBSCRIPTION_ACTIVATE_DIALOG_SHOWED: 'subscription_activate_dialog_showed',
        SUBSCRIPTION_ACTIVATED: 'subscription_activated',
        SUBSCRIPTION_TRANSFER_CANCELLED: 'subscription_transfer_cancelled',
        SUBSCRIPTION_TRANSFER_REQUESTED: 'subscription_transfer_requested',
        MANAGE_SUBSCRIPTION_DIALOG_SHOWED: 'manage_subscription_dialog_showed',
    },
};
exports.SETUP_PROCURE_SUBMISSION_WINDOW_HELP_LINK = 'https://help.ansarada.com/en/articles/3157800-procure-set-up-submission-windows';
var EmptyFunction = function () { };
exports.EmptyFunction = EmptyFunction;
