// prettier-ignore

export default {
  Access: 'Acesso',
  Actions: 'Ações',
  AdminSideTender_InviteHeading: 'Send invites to client',
  Cancel: 'Cancelar',
  Close: 'Fechar',
  Delete: 'Excluir',
  Document: 'Documento',
  DocumentIndex: 'Índice de documentos',
  Edit: 'Editar',
  Email: 'E-mail',
  EnterIpAddressesRanges: 'Insira endereços de IP ou intervalos de endereços IP separados por Enter',
  FileExtensionIsInList: 'O arquivo "[FileName]" não pode ser carregado porque sua extensão está na lista de extensões de arquivos não permitidas para essa sala de dados:\r\n\r\n[FileTypeList]\r\n\r\nConverta esse arquivo para um dos formatos permitidos.',
  FileExtensionIsNotInList: 'O arquivo "[FileName]" não pode ser carregado porque sua extensão não está na lista de extensões de arquivos permitidas para essa sala de dados:\r\n\r\n[FileTypeList]\r\n\r\nConverta esse arquivo para um dos formatos permitidos.',
  Filter: 'Filtro',
  Folder: 'Pasta',
  ForRangesSeparateStartIp: 'Para intervalos, separe o IP inicial e o IP final com um traço, por exemplo, <b>1.1.1.1-1.1.1.6</b>',
  From: 'De:',
  FullName: 'Nome completo',
  InvalidEmailAddress: 'Endereço de e-mail inválido',
  InvalidPdf: 'ERRO: Este arquivo não é um documento PDF válido. Tente outro arquivo.',
  IpAddressInvalidIpAccessList: "O IP '[IpAddress]' na lista de acesso por IP não é um endereço IP válido.",
  IpAddressInvalidRangeIpAccessList: "O intervalo de IP '[IpRange]' na lista de acesso por IP tem um endereço de IP inicial maior do que o endereço de IP final. O IP inicial deve ser menor ou igual ao IP final.",
  IpLimitRangeIpAccessList: "Limite a descrição do intervalo de IP '[IpRange]' na lista de acesso por IP a [NumOfChars] caracteres. Você usou [UsedNumOfChars] caracteres.",
  IpUserAccessListLimit: 'Limite a lista de acesso de IPs a [MaxNumberOfCharacters] caracteres. Você inseriu [EnteredNumberOfCharacters] caracteres.',
  LastMonth: 'Último mês',
  Manage: 'Gerenciar',
  Message: 'Mensagem',
  Never: 'Nunca',
  None: 'Nenhum',
  Off: 'Desativado',
  Or: 'ou',
  PrintingDenied: 'Impressão negada',
  QnaRole: 'Função de P&R',
  Reset: 'Redefinir',
  Save: 'Salvar',
  SavingDenied: 'Salvamento negado',
  Search: 'Pesquisar',
  Security: 'Segurança',
  Select: 'Selecionar',
  To: 'Até:',
  Today: 'Hoje',
  UserCanLogInFromAnyIp: 'Este usuário pode efetuar o logon a partir de qualquer endereço IP',
  UserCanLogInOnlyFromIpsBelow: 'Este usuário pode efetuar o logon SOMENTE a partir dos endereços de IP especificados abaixo',
  View: 'Visualizar',
  Watermark: "Marca d'Água",
  YouCanAddDescriptionsAfterComma: 'Também é possível adicionar descrições depois da vírgula, por exemplo, <b>1.1.1.1, IP do escritório de Londres</b>',
};
