// @flow strict
/* eslint array-callback-return: off */
/* eslint prefer-destructuring: off */
/* eslint jsx-a11y/click-events-have-key-events: off */
/* eslint no-shadow: off */
/* eslint consistent-return: off */
/* eslint react/no-array-index-key: off */
/* eslint no-nested-ternary: off */
/* eslint jsx-a11y/no-redundant-roles: off */
/* eslint no-return-assign: off */
/* eslint jsx-a11y/no-noninteractive-element-interactions: off */
/* eslint jsx-a11y/no-noninteractive-tabindex: off */
/* eslint jsx-a11y/aria-activedescendant-has-tabindex: off */
/* eslint jsx-a11y/role-has-required-aria-props: off */
/* eslint no-mixed-operators: off */
/* eslint no-use-before-define: off */
/* eslint class-methods-use-this: off */

import * as React from 'react';
import classnames from 'classnames';
import { Icon } from '@ansarada/ace-react';
import { KeyEvent } from '@ansarada/ace-react/dist/ace/ace-internal/types/keys';
import { addEventListener } from '@ansarada/ace-react/dist/ace/ace-internal/util/dom';
import { deprecateComponent } from '@ansarada/ace-react/dist/ace/ace-internal/util/deprecate';
import * as close from './Close';

// Single char text width to extend input width in the control
const TEXT_WIDTH = 0.75;

// Inserted item id prefix
const INSERTED = 'INSERTED';

const Up = -1;
const Down = 1;
const Directions = {
  Up,
  Down,
};

type Identifier = number | string | Symbol;
type HighlightedItemIdentifier = Identifier | null;

type Item = {
  id: string,
  text: string,
  inserted?: true,
  data?: Object,
};

type Group = {
  text: string,
  items: Array<Item>,
};

export type SuggestionType = Array<Item | Group>;

export type DisplayType = {
  editingText: string | null,
  highlightedId: HighlightedItemIdentifier,
  suggestions: SuggestionType,
  selected: Array<Item>,
};

export type AutocompletePropType = {
  id: string,
  className?: string,
  multiSelect?: boolean,
  noResultText?: string,
  insertMode?: boolean,
  display: DisplayType,
  onUpdate: Function,
  placeHolder?: string,
};

const UpdateInput = 'UPDATE_INPUT';
const Close = 'CLOSE';
const SelectItem = 'SELECT_ITEM';
const HighlightItem = 'HIGHLIGHT_ITEM';
const UpdateSuggestion = 'UPDATE_SUGGESTIONS';
const RemoveItem = 'REMOVE_ITEM';

export const Messages = {
  SelectItem,
  RemoveItem,
  HighlightItem,
  UpdateSuggestion,
  Close,
  UpdateInput,
};

type SelectItemMessageData = {
  item: Item,
  multiSelect: boolean,
};

// Message Types
type UpdateInputMessageType = {
  message: 'UPDATE_INPUT',
  data: string,
};

type CloseMessageType = { message: 'CLOSE' };

type SelectItemMessageType = {
  message: 'SELECT_ITEM',
  data: SelectItemMessageData,
};

type HighlightItemMessageType = {
  message: 'HIGHLIGHT_ITEM',
  data: HighlightedItemIdentifier,
};

type RemoveItemMessageType = {
  message: 'REMOVE_ITEM',
  data: Identifier,
};

type UpdateSuggestionMessageType = {
  message: 'UPDATE_SUGGESTIONS',
  data: {
    suggestions: SuggestionType,
    autoFilter: boolean,
  },
};

type MessageType =
  | RemoveItemMessageType
  | UpdateInputMessageType
  | CloseMessageType
  | SelectItemMessageType
  | HighlightItemMessageType
  | UpdateSuggestionMessageType;

const msgUpdateInput = (data: *) => ({ message: UpdateInput, data });

const msgClose = { message: Close };

const msgSelectItem = (data: SelectItemMessageData): SelectItemMessageType => ({
  message: SelectItem,
  data,
});

const msgHighlightItem = (data: HighlightedItemIdentifier): HighlightItemMessageType => ({
  message: HighlightItem,
  data,
});

const msgRemoveItem = (data: Identifier) => ({ message: RemoveItem, data });

export const msgUpdateSuggestion = (data: *): UpdateSuggestionMessageType => ({
  message: UpdateSuggestion,
  data,
});

const onKeyDown = (
  e: SyntheticKeyboardEvent<HTMLInputElement>,
  multiSelect: boolean,
  display: DisplayType,
  insertMode: boolean,
  id: string,
) => {
  const suggestions = getSuggestions(display, insertMode, id);
  const highlighted = getCurrentHighlighted(suggestions, display.highlightedId);

  if (KeyEvent.isArrowDown(e)) {
    return msgHighlightItem(getNextHighlighted(suggestions, highlighted, Directions.Down));
  }

  if (KeyEvent.isArrowUp(e)) {
    return msgHighlightItem(getNextHighlighted(suggestions, highlighted, Directions.Up));
  }

  if (KeyEvent.isEscape(e)) {
    return msgClose;
  }

  if (KeyEvent.isEnter(e)) {
    if (highlighted !== null) {
      if (display.selected.some(s => s.id === highlighted)) {
        return msgRemoveItem(highlighted);
      }
      const item = flatten(suggestions).find(s => s.id === highlighted);
      if (item) {
        return msgSelectItem({ item, multiSelect });
      }
    }
    return msgUpdateInput(display.editingText || '');
  }
  return null;
};

const filter = (list: SuggestionType, editingText: string): SuggestionType => {
  const searchStr = editingText.toLowerCase();

  const filterItems = (items: SuggestionType) =>
    items.reduce((accumulator: SuggestionType, currentValue: Item | Group) => {
      if (currentValue.items instanceof Array) {
        const filteredItems = currentValue.items.filter(item =>
          item.text.toLowerCase().includes(searchStr),
        );

        if (filteredItems.length) {
          accumulator.push({
            text: currentValue.text,
            items: filteredItems,
          });
        }
      } else if (currentValue.text.toLowerCase().includes(searchStr)) {
        accumulator.push(currentValue);
      }

      return accumulator;
    }, []);

  return filterItems(list);
};

const transition = (old: DisplayType, msg: MessageType): DisplayType => {
  switch (msg.message) {
    case UpdateInput: {
      const { data } = msg;
      return {
        ...old,
        editingText: data,
      };
    }

    case Close: {
      return {
        ...old,
        highlightedId: null,
        editingText: null,
        suggestions: [],
      };
    }
    case SelectItem: {
      const { data } = msg;
      const { multiSelect, item } = data;
      return {
        ...old,
        editingText: multiSelect ? '' : null,
        selected: multiSelect ? [...old.selected, item] : [item],
        highlightedId: null,
      };
    }
    case HighlightItem: {
      const { data } = msg;
      return {
        ...old,
        highlightedId: data,
      };
    }

    case RemoveItem: {
      const { data } = msg;
      return {
        ...old,
        selected: old.selected.filter(s => s.id !== data),
      };
    }

    case UpdateSuggestion: {
      const { data } = msg;
      if (data.autoFilter) {
        return {
          ...old,
          suggestions: filter(data.suggestions, old.editingText !== null ? old.editingText : ''),
        };
      }
      return {
        ...old,
        suggestions: [...data.suggestions],
      };
    }
    default:
      throw new Error(`Unknown message type: ${msg.message}`);
  }
};

// Functoins related to insertMode on
const getInsertedItem = (id: string, text: string): Item => ({
  id: `${id}-${INSERTED}-${text}`,
  text,
  inserted: true,
});

const getSuggestions = (display: DisplayType, insertMode: boolean, id: string) => {
  const { suggestions, editingText, selected } = display;

  if (insertMode && editingText) {
    const all = [...suggestions, ...selected];
    const items = flatten(all);
    const i = items.find(item => item.text.toLowerCase() === editingText.toLowerCase());
    if (!i) {
      const insertedGroup = {
        text: '',
        items: [getInsertedItem(id, editingText)],
      };
      return [insertedGroup, ...display.suggestions];
    }
  }
  return suggestions;
};

const getCurrentHighlighted = (
  suggestions: SuggestionType,
  highlightedId,
): HighlightedItemIdentifier => {
  if (suggestions.length === 0) {
    return null;
  }
  if (highlightedId !== null) {
    return highlightedId;
  }

  const flatItems = flatten(suggestions);
  return flatItems.length > 0 ? flatItems[0].id : null;
};

const getNextHighlighted = (
  suggestions: SuggestionType,
  highlightedId: HighlightedItemIdentifier,
  direction: number,
) => {
  if (suggestions.length === 0) {
    return null;
  }

  const items = flatten(suggestions);
  const thisIdx = items.findIndex(s => s.id === highlightedId);

  // if no current highlighted item, take the first (if move down) or the last (if move up)
  if (thisIdx === -1) {
    if (direction === Directions.Down) {
      return items[0].id;
    }
    return items[items.length - 1].id;
  }

  // if next highlight exceeds suggestion length, highlight the first item
  if (thisIdx + direction >= items.length) {
    return items[0].id;
  }

  // if next highlight index is smaller than 0, highlight the last item
  if (thisIdx + direction < 0) {
    return items[items.length - 1].id;
  }

  return items[thisIdx + direction].id;
};

const flatten = (array: SuggestionType) => {
  let newArray = [];
  array.map(item => {
    if (item.items instanceof Array) {
      newArray = newArray.concat([...item.items]);
    } else {
      newArray.push({ ...item });
    }
  });
  return newArray;
};

type UpdatedDisplayType = {
  editingText?: ?string,
  highlightedId?: HighlightedItemIdentifier,
  suggestions?: SuggestionType,
  selected?: Array<Item>,
};

const init = (defaults?: UpdatedDisplayType = {}) => {
  const { editingText = null, highlightedId = null, suggestions = [], selected = [] } = defaults;

  return {
    editingText,
    highlightedId,
    suggestions,
    selected,
  };
};

const shouldUpdateSuggestions = (msg: MessageType): boolean =>
  [SelectItem, UpdateInput].includes(msg.message);

class AutocompleteLegacy extends React.Component<AutocompletePropType> {
  _searchInput: ?HTMLElement;

  _componentNode: ?HTMLElement;

  _resultsNode: ?HTMLElement;

  _resultsListNode: ?HTMLElement;

  _unbindHandlers: Array<() => void>;

  _suggestionsPositioned: boolean;

  constructor(props: AutocompletePropType) {
    super(props);
    deprecateComponent('AutocompleteLegacy', '15.0.0');
  }

  componentDidMount() {
    this._handleAutocompleteOpenEvents();
    this._handleAutocompletePositioning();
  }

  componentDidUpdate(prevProps: AutocompletePropType) {
    this._handleAutocompleteOpenEvents();

    if (this.props.display.suggestions.length !== prevProps.display.suggestions.length) {
      this._suggestionsPositioned = false;

      const _resultsNode = this._resultsNode;
      const _resultsListNode = this._resultsListNode;

      if (_resultsNode && _resultsListNode) {
        _resultsNode.style.removeProperty('top');
        _resultsNode.style.removeProperty('left');
        _resultsListNode.style.removeProperty('height');
      }
    }
    this._handleAutocompletePositioning();
  }

  componentWillUnmount() {
    if (this._unbindHandlers) {
      this._unbindHandlers.forEach(func => func());
    }
  }

  render() {
    const {
      id,
      className = '',
      multiSelect = false,
      noResultText = '* Results empty *',
      display,
      onUpdate,
      insertMode = false,
      placeHolder,
      ...rest
    } = this.props;

    const suggestions = getSuggestions(display, insertMode, id);

    const editingModeOn = display.editingText !== null;

    const inputWidth = display.editingText ? display.editingText.length * TEXT_WIDTH : TEXT_WIDTH;

    const classes = classnames('ace-autocomplete', className);

    const containerClass = classnames('select2 select2-container', 'select2-container--ace', {
      'select2-container--focus': display.editingText !== null,
    });
    const suggestionContainerClasses = classnames('select2-container', 'select2-container--ace', {
      'select2-container--open': editingModeOn,
    });

    const renderSuggestionItem = (
      item: Item,
      highlightedId: HighlightedItemIdentifier,
      selected: Array<Item>,
      index: number,
    ) => {
      const itemClasses = classnames('select2-results__option', {
        'select2-results__option--highlighted': highlightedId && item.id === highlightedId,
      });
      const isSelected = selected.some(s => s.id === item.id);
      const iconComponent = item.inserted && (
        <Icon glyph="AddGeneric" text={`Add ${item.text}`} style={{ top: '2px' }} />
      );
      return (
        <li
          className={itemClasses}
          role="treeitem"
          key={`${index}-item-li`}
          aria-selected={isSelected}
          aria-label={item.inserted ? `Add ${item.text}` : item.text}
          tabIndex="-1"
          onMouseEnter={e => {
            e.preventDefault();
            const msg = msgHighlightItem(item.id);
            onUpdate(transition(display, msg), msg, e);
          }}
          onClick={e => {
            e.preventDefault();
            if (isSelected) {
              const msg = msgRemoveItem(item.id);
              onUpdate(transition(display, msg), msg, e);
              return;
            }

            const msg = msgSelectItem({
              item,
              multiSelect,
            });
            onUpdate(transition(display, msg), msg, e);
          }}
        >
          {iconComponent}
          <span style={item.inserted ? { paddingLeft: '5px' } : {}}>{item.text}</span>
        </li>
      );
    };

    const noResultItem = (
      <li className="select2-results__option" role="treeitem" key="no-result-item-li">
        {noResultText}
      </li>
    );

    const renderPlaceHolder = (text: string) => (
      <span className="select2-selection__placeholder">{text}</span>
    );

    const renderSuggestions = (suggestions, highlightedId, selected) => {
      if (suggestions.length === 0) {
        return noResultItem;
      }
      const highlight = getCurrentHighlighted(suggestions, highlightedId);
      return suggestions.map((suggestion, index) => {
        if (suggestion.items instanceof Array) {
          const heading = suggestion.text ? (
            <strong className="select2-results__group">{suggestion.text}</strong>
          ) : null;
          return (
            <li
              className="select2-results__option"
              role="group"
              aria-label={suggestion.text}
              key={`${index}-group-li`}
            >
              {heading}
              <ul
                className="select2-results__options select2-results__options--nested"
                key={`${index}-group-ul`}
              >
                {suggestion.items.map((child, childIndex) =>
                  renderSuggestionItem(child, highlight, selected, childIndex),
                )}
              </ul>
            </li>
          );
        }
        // 'if' condition was added to stop Flow errors in renderSuggestionItem
        if (!suggestion.items) {
          return renderSuggestionItem(suggestion, highlightedId, selected, index);
        }
      });
    };

    const renderSingleSelections = (items: Array<Item>) => {
      const displayValue = items.length
        ? items[0].text
        : placeHolder
        ? renderPlaceHolder(placeHolder)
        : '\u00A0';
      const titleValue = items.length ? items[0].text : '';

      return (
        <span
          className="select2-selection__rendered"
          title={display.editingText === null ? titleValue : null}
        >
          {display.editingText === null ? displayValue : null}

          {display.editingText !== null && (
            <input
              className="select2-search__field"
              type="search"
              tabIndex="0"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              role="textbox"
              aria-autocomplete="list"
              value={display.editingText}
              id={`${id}-input`}
              style={{ width: '100%' }}
              ref={el => (this._searchInput = el)}
              onChange={e => {
                const msg = msgUpdateInput(e.target.value);
                onUpdate(transition(display, msg), msg, e);
              }}
              onKeyDown={e => {
                const msg = onKeyDown(e, multiSelect, display, insertMode, id);
                if (msg) {
                  e.preventDefault();
                  onUpdate(transition(display, msg), msg, e);
                }
              }}
            />
          )}
        </span>
      );
    };

    const renderMultiSelections = (items: Array<Item>, id: string, inputWidth: number) => (
      <ul className="select2-selection__rendered">
        {items.map((item, index) => (
          <li
            className="ace-lozenge"
            data-ace-closable="true"
            id={`${id}-${index}-selected-li`}
            key={`${id}-${index}-selected-li`}
            tabIndex="-1"
            title={item.text}
            onClick={e => {
              const msg = msgRemoveItem(item.id);
              onUpdate(transition(display, msg), msg, e);
            }}
            onFocus={e => {
              e.stopPropagation();
            }}
            onKeyPress={e => {
              if (KeyEvent.isEnter(e)) {
                const msg = msgRemoveItem(item.id);
                onUpdate(transition(display, msg), msg, e);
              }
            }}
          >
            <span>{item.text}</span>
            <close.Close
              text="Close"
              onUpdate={(v, m, e) => {
                const msg = msgRemoveItem(item.id);
                onUpdate(transition(display, msg), msg, e);
              }}
            />
          </li>
        ))}
        <li className="select2-search select2-search--inline">
          <input
            className="select2-search__field"
            type="search"
            tabIndex="0"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            role="textbox"
            aria-autocomplete="list"
            value={display.editingText || ''}
            style={{ width: `${inputWidth}em` }}
            id={`${id}-input`}
            ref={el => (this._searchInput = el)}
            onChange={e => {
              const msg = msgUpdateInput(e.target.value);
              onUpdate(transition(display, msg), msg, e);
            }}
            onKeyDown={e => {
              const msg = onKeyDown(e, multiSelect, display, insertMode, id);
              if (msg) {
                e.preventDefault();
                onUpdate(transition(display, msg), msg, e);
              }
            }}
          />
        </li>
      </ul>
    );

    const getSelectCSSClasses = (multiSelect: boolean) => {
      if (multiSelect) {
        return 'select2-selection select2-selection--multiple';
      }

      return 'select2-selection select2-selection--single';
    };

    return (
      <div
        className={classes}
        id={id}
        ref={el => (this._componentNode = el)}
        tabIndex={!multiSelect ? '0' : null}
        onFocus={e => {
          if (!multiSelect) {
            const msg = msgUpdateInput(display.editingText || '');
            onUpdate(transition(display, msg), msg, e);
          }
        }}
        {...rest}
      >
        <span className={containerClass} dir="ltr" style={{ width: '240px' }}>
          <span className="selection">
            <span
              className={getSelectCSSClasses(multiSelect)}
              role="combobox"
              aria-haspopup="true"
              aria-expanded="true"
              tabIndex="-1"
              aria-owns={`${id}-autocomplete-result`}
              aria-activedescendant={`${id}-autocomplete-result`}
              onFocus={e => {
                const msg = msgUpdateInput(display.editingText || '');
                onUpdate(transition(display, msg), msg, e);
              }}
            >
              {multiSelect && renderMultiSelections(display.selected, id, inputWidth)}
              {!multiSelect && renderSingleSelections(display.selected)}

              <span className="select2-selection__arrow" role="presentation">
                <b role="presentation" />
              </span>
            </span>
          </span>
          <span
            className={suggestionContainerClasses}
            style={{
              position: 'absolute',
              width: '240px',
            }}
            data-ace-vpos="bottom"
            data-ace-halign="left"
          >
            <span className="select2-dropdown" dir="ltr" ref={el => (this._resultsNode = el)}>
              <span className="select2-results">
                <ul
                  className="select2-results__options"
                  role="tree"
                  aria-multiselectable={multiSelect}
                  id={`${id}-autocomplete-result`}
                  aria-expanded={editingModeOn}
                  aria-hidden={!editingModeOn}
                  ref={el => (this._resultsListNode = el)}
                >
                  {renderSuggestions(suggestions, display.highlightedId, display.selected)}
                </ul>
              </span>
            </span>
          </span>
        </span>
      </div>
    );
  }

  _handleAutocompleteOpenEvents = () => {
    if (this.props.display.editingText === null) {
      if (this._unbindHandlers !== undefined && this._unbindHandlers.length !== 0) {
        this._unbindHandlers.forEach(func => func());
        this._unbindHandlers = [];
      }
      if (this._searchInput && this._searchInput === document.activeElement) {
        this._searchInput.blur();
      }
      return;
    }

    if (this._unbindHandlers === undefined || this._unbindHandlers.length === 0) {
      this._unbindHandlers = [
        addEventListener(window, 'click', this._onFocusChange, true),
        addEventListener(window, 'focus', this._onFocusChange, true),
      ];
    }

    if (this._searchInput && this._searchInput !== document.activeElement) {
      this._searchInput.focus();
    }
  };

  _handleAutocompletePositioning = () => {
    if (this.props.display.editingText === null) {
      if (this._suggestionsPositioned) {
        this._suggestionsPositioned = false;

        const _resultsNode = this._resultsNode;

        if (_resultsNode) {
          _resultsNode.style.removeProperty('top');
          _resultsNode.style.removeProperty('left');
          _resultsNode.style.removeProperty('height');
        }
      }
      return;
    }

    if (this._suggestionsPositioned) {
      return;
    }

    if (!this._searchInput || !this._resultsNode || !this._resultsListNode) {
      return;
    }

    const searchInputBounds = this._searchInput
      ? this._searchInput.getBoundingClientRect()
      : undefined;

    const resultsNodeBounds = this._resultsNode
      ? this._resultsNode.getBoundingClientRect()
      : undefined;

    if (searchInputBounds && resultsNodeBounds) {
      const verticalProperties = this._calculateVerticalProperties(
        searchInputBounds,
        resultsNodeBounds,
      );

      if (this._resultsNode) {
        this._resultsNode.style.top = verticalProperties.top;
      }

      if (this._resultsListNode && verticalProperties.height) {
        this._resultsListNode.style.height = verticalProperties.height;
      }

      this._suggestionsPositioned = true;
    }
  };

  _calculateVerticalProperties = (searchInputBounds: ClientRect, resultsNodeBounds: ClientRect) => {
    const windowHeight = window.innerHeight;
    const verticalMargin = 10;

    const spaceBelowSearch = windowHeight - searchInputBounds.bottom;
    const spaceAboveSearch = searchInputBounds.top;

    const resultsHeight = resultsNodeBounds.height;

    if (spaceBelowSearch > resultsHeight) {
      // position below
      return { top: `${searchInputBounds.height - verticalMargin * 2}px` };
    }

    if (spaceAboveSearch > resultsHeight) {
      // position above
      return {
        top: `${-searchInputBounds.height - resultsHeight - verticalMargin}px`,
      };
    }

    // chose most space and apply scroll

    if (spaceBelowSearch > spaceAboveSearch) {
      // position below with scroll
      return {
        top: `${searchInputBounds.height - verticalMargin * 2}px`,
        height: `${spaceBelowSearch - verticalMargin * 4}px`,
      };
    }

    // position above with scroll
    return {
      top: `${-spaceAboveSearch - searchInputBounds.height - verticalMargin}px`,
      height: `${spaceAboveSearch - verticalMargin * 4}px`,
    };
  };

  _onFocusChange = (e: Event) => {
    const { display, onUpdate } = this.props;

    if (
      !(e.target instanceof Element) ||
      !this._componentNode ||
      this._componentNode.contains(e.target)
    ) {
      return;
    }

    if (display && display.editingText !== null) {
      onUpdate(transition(display, msgClose), msgClose, e);
    }
  };
}

export { AutocompleteLegacy, init, transition, shouldUpdateSuggestions };
