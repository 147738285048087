// @flow

import { ACTION_TYPES } from 'app/constants';
import type { TeamHistoryState } from 'app/pages/teamHistory/teamHistoryTypes';

const defaultTeamHistoryState: TeamHistoryState = {
  teamHistory: [],
  subTeam: undefined,
};

const teamHistoryReducer = (state: TeamHistoryState = defaultTeamHistoryState, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.TEAM_HISTORY.DATA_FETCH_INITIAL:
      return {
        ...state,
        teamHistory: [],
      };
    case ACTION_TYPES.TEAM_HISTORY.SET_TEAM:
      return {
        ...state,
        subTeam: action.data,
      };
    case ACTION_TYPES.TEAM_HISTORY.SET_TEAM_HISTORY:
      return {
        ...state,
        teamHistory: action.data,
      };
    default:
      return state;
  }
};

export default teamHistoryReducer;
