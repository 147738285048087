// @flow

import { ACTION_TYPES } from 'app/constants';
import type { DialogType, DialogActions } from './dialogTypes';

const initialState = {
  open: false,
  isSaving: false,
  title: '',
  isLoading: false,
};

const dialogReducer = (state?: DialogType = initialState, action: DialogActions): DialogType => {
  switch (action.type) {
    case ACTION_TYPES.DIALOG_TOGGLE: {
      return {
        ...state,
        open: !state.open,
        title: action.data || state.title,
      };
    }

    case ACTION_TYPES.DIALOG_SET_TITLE: {
      return {
        ...state,
        title: action.data,
      };
    }

    case ACTION_TYPES.DIALOG_SAVE_PROGRESS_BEGIN:
      return {
        ...state,
        isSaving: true,
      };

    case ACTION_TYPES.DIALOG_SAVE_PROGRESS_END:
      return {
        ...state,
        isSaving: false,
      };

    default:
      return state;
  }
};

export default dialogReducer;
