// @flow

import { getEditedItemSecurity } from 'app/pages/security/helpers/securityHelpers';
import { ACTION_TYPES } from 'app/constants';
import type { ItemReducerState } from 'app/pages/security/securityTypes';

const initialState: ItemReducerState = {
  edited: {},
  item: null,
  securities: [],
};
const itemSecurityReducer = (
  state: ItemReducerState = initialState,
  action: Object,
): ItemReducerState => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.ITEM_FETCHED: {
      return {
        ...state,
        ...action.data,
      };
    }

    case ACTION_TYPES.SECURITY.ITEM_SETTINGS_UPDATE: {
      const { subteamId, attr, value } = action.data;
      const edited = {
        ...state.edited,
        [subteamId]: {
          ...state.edited[subteamId],
          [attr]: value,
        },
      };
      const editedItemSecurity = getEditedItemSecurity(state.securities, attr, value, subteamId);

      return {
        ...state,
        edited,
        securities: editedItemSecurity,
      };
    }

    case ACTION_TYPES.SECURITY.ITEM_EDITED_CLEAR: {
      return {
        ...state,
        edited: {},
      };
    }
    default:
      return state;
  }
};

export default itemSecurityReducer;
