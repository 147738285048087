// @flow

import { ACTION_TYPES } from 'app/constants';
import type { AppDispatch } from 'app/types/appStateTypes';

export const getJSONFromScript = (scriptId: string) => {
  const scriptEl = document.getElementById(scriptId);

  if (!scriptEl || scriptEl.getAttribute('type') !== 'application/json') {
    return {};
  }

  try {
    return JSON.parse(scriptEl.innerHTML);
  } catch (e) {
    window.console.error(`Could not parse ${scriptId} script block as json`);
    throw e;
  }
};

export const isFooterVisible = (dispatch: AppDispatch) => {
  const element = document.querySelector('footer .ace-footer');

  const isVisible = element ? element.getBoundingClientRect().top <= window.innerHeight : false;

  dispatch({ type: ACTION_TYPES.PAGE.IS_FOOTER_VISIBLE, data: isVisible });
};
