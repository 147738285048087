// prettier-ignore

export default {
  AcceptTermsOfAccess_SentenceCase: 'Aceptar términos de acceso',
  Access: 'Acceso',
  Actions: 'Acciones',
  Add: 'Añadir',
  Administration: 'Administración',
  AdminSideBankruptcy: 'Parte vendedora',
  AdminSideMerger: 'Parte vendedora',
  All: 'Todas',
  Attachments: 'Archivos adjuntos',
  BatchOperationCantBePerformed: 'No es posible realizar la operación por lotes porque no existen usuarios en esta sala de datos.  Introduzca antes los usuarios.',
  BulkDownload_SentenceCase: 'Descarga masiva',
  BuySide: 'Parte compradora',
  BuySide_SentenceCase: 'Parte compradora',
  Cancel: 'Cancelar',
  Change: 'Cambiar',
  ChooseLanguage: 'Elegir idioma',
  Close: 'Cerrar',
  CollapseAll_SentenceCase: 'Contraer todos',
  Colour: 'Color:',
  ContactUs: 'Contáctenos',
  CopyEmailsOfSelectedUsers: 'Copiar correos de usuarios seleccionados',
  Daily: 'Diario',
  DataRooms: 'Salas de datos',
  DateSent: 'Fecha de envío',
  Delete: 'Eliminar',
  Disable: 'Desactivar',
  Disabled: 'Desactivada',
  Document: 'Documento',
  DocumentIndex: 'Índice de documentos',
  Documents: 'Documentos',
  Edit: 'Editar',
  EditMultipleUsers: 'Editar múltiples usuarios',
  EditProfile: 'Editar perfil',
  Email: 'Correo electrónico',
  EmailNotifications: 'Notificaciones por correo',
  Enabled: 'Activado',
  EnterIpAddressesRanges: 'Introduzca direcciones IP o rangos de direcciones IP separadas por la tecla Intro',
  ExpandAll_SentenceCase: 'Expandir todos',
  ExportToExcel_SentenceCase: 'Exportar a Excel',
  FileExtensionIsInList: 'No es posible cargar el archivo "[FileName]", dado que su extensión se encuentra en la lista de extensiones de archivo no permitidas en esta sala de datos:\n\n[FileTypeList]\n\nConvierta este archivo a uno de los formatos permitidos.',
  FileExtensionIsNotInList: 'No es posible cargar el archivo "[FileName]", dado que su extensión no se encuentra en la lista de extensiones permitidas en esta sala de datos:\n\n[FileTypeList]\n\nConvierta este archivo a uno de los formatos permitidos.',
  Filter: 'Filtro',
  Folder: 'Carpeta',
  ForRangesSeparateStartIp: 'Para los rangos, separe la IP inicial y la IP final con un guión; p.ej. <b>1.1.1.1-1.1.1.6</b>',
  From: 'De:',
  FullName: 'Nombre completo',
  Help: 'Ayuda',
  HelpAndSupport: 'Ayuda y soporte',
  HideMenu: 'Ocultar menú',
  History: 'Historial',
  Hourly: 'Cada hora',
  Interval: 'Intervalo',
  InvalidEmailAddress: 'Dirección de correo electrónico no válida',
  InvalidPdf: 'ERROR: este archivo no es un documento PDF válido. Inténtelo con otro archivo.',
  InvitationEmail: 'Correo de invitación',
  Invited_Users_: 'Invitado',
  IpAccessList: 'Lista de acceso por IP',
  IpAccessList_SentenceCase: 'Lista de acceso de IP',
  IpAddressInvalidIpAccessList: 'La IP \'[IpAddress]\' en la lista de acceso IP no es una dirección IP válida.',
  IpAddressInvalidRangeIpAccessList: 'El rango de IP \'[IpRange]\' en la lista de acceso IP tiene una dirección IP inicial superior a la dirección IP final. La IP inicial debe ser inferior o igual a la IP final.',
  IpLimitRangeIpAccessList: 'Limite la descripción del rango de IP \'[IpRange]\' en la lista de acceso IP a [NumOfChars] caracteres. Ha utilizado [UsedNumOfChars] caracteres.',
  IpUserAccessListLimit: 'Limite la lista de acceso por IP a [MaxNumberOfCharacters] caracteres. Ha introducido [EnteredNumberOfCharacters] caracteres.',
  LastMonth: 'El mes pasado',
  LastWeek: 'Última semana',
  LoadingThreeDots: 'Cargando...',
  LoggedInToDate_SentenceCase: 'Con sesiones iniciadas hasta la fecha',
  Logout: 'Cerrar sesión',
  Manage: 'Gestionar',
  Message: 'Mensaje:',
  Name: 'Nombre',
  Never: 'Nunca',
  NeverLoggedIn_SentenceCase: 'Nunca inició sesión',
  No: 'No',
  NonAdminSideBankruptcy: 'Parte compradora',
  NonAdminSideMerger: 'Parte compradora',
  None: 'Ninguno',
  NoRecordsFound: 'No se han encontrado registros.',
  Notifications: 'Notificationes',
  NotInvited_Users__SentenceCase: 'No fue invitado',
  NotVerified_Users__SentenceCase: 'Sin verificar',
  Off: 'Desactivar',
  Ok: 'Aceptar',
  OkAllCaps: 'Aceptar',
  On: 'Activado',
  OnceOnlyAndWhenTermsAreUpdated: 'Sólamente una vez y cuando los términos son actualizados',
  OnEveryLogin: 'En cada inicio de sesión',
  Or: 'o',
  Period: 'Periodo',
  PrintingAllowed: 'Permitido imprimir',
  PrintingDenied: 'Impresión denegada',
  Profile: 'Perfil',
  QnaNotificationSwitchOff: 'No recibirá ningún correo',
  QnaNotificationSwitchOn: 'Recibirá actualizaciones inmediatamente',
  QnaRole: 'Función de PyR',
  QnaRole_SentenceCase: 'Rol PyR',
  Recipients: 'Destinatarios',
  RecipientsOfEmailNote_Gen2: 'las direcciones de los demás receptores NO serán visibles para los destinatarios de este <nowrap>correo electrónico</nowrap>, por lo que resulta seguro enviarlo a usuarios de diferentes grupos de usuarios.',
  Reset: 'Reestablecer',
  Save: 'Guardar',
  Saving: 'Guardando',
  SavingAllowed: 'Permitido guardar',
  SavingDenied: 'Guardar denegado',
  Search: 'Buscar',
  Security: 'Seguridad',
  SecurityGroup_SentenceCase: 'Grupo de seguridad',
  Select: 'Seleccionar',
  SelectUsers_SentenceCase: 'Elija usuarios',
  SellSide: 'Parte vendedora',
  SellSide_SentenceCase: 'Parte vendedora',
  Send: 'Enviar',
  SendInvites: 'Enviar invitaciones',
  SendUpdates: 'Mandar actualizaciones',
  SentDocumentChanged: 'Enviar cuando un documento es añadido o cambiado',
  ShouldBeEmailEmail: 'El correo electrónico introducido no es una dirección válida.',
  ShowActivityInReports_SentenceCase: 'Mostrar actividad en informes',
  Status: 'Estado',
  Subject: 'Asunto',
  Subject_Email_: 'Asunto',
  SubTeam: 'subequipo',
  SydneyTime: 'Hora de Sídney',
  Team: 'equipo',
  TimePeriodAm: 'AM',
  TimePeriodPm: 'PM',
  To: 'A:',
  Today: 'Hoy',
  UserCanLogInFromAnyIp: 'Este usuario puede iniciar sesión desde cualquier dirección IP',
  UserCanLogInOnlyFromIpsBelow: 'Este usuario SÓLO puede iniciar sesión desde las direcciones IP especificadas a continuación',
  UserGroup: 'Grupo de usuarios',
  UserProperties: 'Propiedades del usuario',
  Verified: 'Verificado',
  Verified_Users_: 'Verificado',
  View: 'Ver',
  ViewIndexAsGhost: '¿Qué pueden ver?',
  Watermark: 'Marca de agua',
  Weekly: 'Cada semana',
  Yes: 'Sí',
  YouCanAddDescriptionsAfterComma: 'También puede añadir descripciones tras una coma; p. ej. <b>1.1.1.1, IP de la oficina de Londres</b>',
  YourCurrentIpAddress: '<b>Nota:</b> su dirección IP actual es [IP Address]. <a [href]>Haga clic aquí</a> para añadirla a la lista.',
};
