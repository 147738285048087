// @flow

import type { AppDispatch } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  TableLegacy,
  TableHeadLegacy,
  TableRowLegacy,
  TableHeaderLegacy,
} from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import SubteamSecurity from 'app/pages/security/itemSecurity/subteamSecurity/SubteamSecurity';
import { ACTION_TYPES } from 'app/constants';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { Item } from 'app/pages/security/securityTypes';
import { DataRoomType } from 'app/types/dataRoomTypes';
import TenderBidderEditSecurityBannerMessage from 'app/pages/security/TenderBidderEditSecurityBannerMessage';

type ItemSecurityProps = {
  item: Item,
  loading: boolean,
  dispatch: AppDispatch,
  permissions: PermissionsType,
  documentSecurityEnabled: boolean,
  dataRoomType: DataRoomType,
};

export class ItemSecurity extends React.Component<ItemSecurityProps> {
  componentDidMount() {
    if (!this.props.item) {
      // TODO  Remove this action https://jira.ansarada.com/browse/DR-17997
      this.props.dispatch({ type: ACTION_TYPES.SECURITY.ITEM_SECURITY_FETCH_INITIAL });
    }
  }

  shouldComponentUpdate(nextProps: ItemSecurityProps) {
    return !nextProps.loading && !!nextProps.permissions;
  }

  render() {
    const { item, permissions, documentSecurityEnabled, dataRoomType } = this.props;

    if (!item || !permissions) return null;

    return (
      <div>
        {dataRoomType === DataRoomType.Tender && (
          <TenderBidderEditSecurityBannerMessage sx={{ mb: 2 }} />
        )}

        <h3 data-ansarada-ccd="true" className="dr-spa-single-item-security-header">
          {item.number} {item.name}
        </h3>

        <TableLegacy>
          <TableHeadLegacy>
            <TableRowLegacy>
              <TableHeaderLegacy />
              <TableHeaderLegacy />
              <TableHeaderLegacy>{translate('Access')}</TableHeaderLegacy>
              {documentSecurityEnabled && (
                <TableHeaderLegacy>{translate('SecurityControls')}</TableHeaderLegacy>
              )}
              <TableHeaderLegacy>{translate('Watermark')}</TableHeaderLegacy>
            </TableRowLegacy>
          </TableHeadLegacy>
          <SubteamSecurity />
        </TableLegacy>
      </div>
    );
  }
}

const mapStateToProps = (state: Object): Object => ({
  item: state.security.documents.itemData.item,
  loading: state.page.loadingBar.loading,
  permissions: state.page.userPermissions,
  documentSecurityEnabled: state.session.documentSecurityEnabled,
  dataRoomType: state.session.dataRoomType,
});

export default connect<_, _, *, *>(mapStateToProps)(ItemSecurity);
