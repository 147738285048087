// @flow

import { AppError, BadRequestError, ERROR_TYPE, sessionErrors } from 'app/utils/error';
import { AxiosError } from 'axios';

type AnsaradaErrorResult = {
  ErrorCode?: string,
  ErrorId?: string,
  Message: string,
};

export const handleHttpError = (error: AxiosError<AnsaradaErrorResult>) => {
  const data = error.response.data || {};
  if (error.response.status === 400) {
    if (data.ErrorMessage && data.ErrorDetails) {
      throw new BadRequestError('BadRequestError', data.ErrorDetails);
    }
  }
  if (error.response.status === 401) {
    const code = data.ErrorCode || sessionErrors.accessDenied.code;
    throw new AppError(ERROR_TYPE.SESSION_ERROR, code);
  }
  throw new AppError(ERROR_TYPE.SERVER_ERROR, data.ErrorId);
};
