// prettier-ignore

export default {
  Access: '访问',
  Actions: '操作',
  Add: '添加',
  AdminSideBankruptcy: '卖家',
  AdminSideFundraising: '卖家',
  AdminSideMerger: '卖家',
  AdminSidePlacement: '卖家',
  AdminSidePublicOffer: '卖家',
  AdminSideTender: '卖家',
  AdminSideTender_InviteHeading: 'Send invites to client',
  All: '全部',
  At: '在',
  Attachments: '附件',
  BatchOperationCantBePerformed: '无法执行批处理操作，因为此资料室中没有用户。请首先输入用户。',
  BulkDownload_SentenceCase: '批量下载',
  BuySide: '买家',
  BuySide_SentenceCase: '买家',
  Cancel: '取消',
  Change: '更改',
  ChooseLanguage: '选择语言',
  Close: '关闭',
  CollapseAll_SentenceCase: '全部折叠',
  Colour: '颜色',
  ContactUs: '联系我们',
  Daily: '每日',
  DataRooms: '资料室',
  Delete: '删除',
  DemoAllCaps: '演示',
  Disable: '禁用',
  Disabled: '已禁用',
  Document: '文件',
  DocumentIndex: '文件索引',
  Documents: '文件',
  Edit: '编辑',
  EditMultipleUsers: '编辑多个用户',
  EditProfile: '编辑档案',
  Email: '电子邮件',
  EmailNotifications: '邮件通知',
  Enabled: '已启用',
  EnterIpAddressesRanges: '输入 IP 地址或由 Enter 键分隔的 IP 地址范围',
  Every: '每',
  ExpandAll_SentenceCase: '全部展开',
  ExportToExcel_SentenceCase: '导出到Excel',
  FileExtensionIsInList: '无法上传文件 "[FileName]"，因为其扩展名在此资料室不允许的文件扩展名列表中：\r\n\r\n[FileTypeList]\r\n\r\n请将此文件转换为一种允许的格式。',
  FileExtensionIsNotInList: '无法上传文件 "[FileName]"，因为其扩展名不在此资料室允许的文件扩展名列表中：\r\n\r\n[FileTypeList]\r\n\r\n请将此文件转换为一种允许的格式。',
  Filter: '筛选器',
  Folder: '文件夹',
  ForRangesSeparateStartIp: '对于范围，用一个破折号分隔开始 IP 和结束 IP，如 <b>1.1.1.1-1.1.1.6</b>',
  Friday: '星期五',
  From: '从：',
  FullName: '全名',
  Help: '帮助',
  History: '历史记录',
  Hourly: '每小时',
  Importance: '重要：',
  Interval: '间隔',
  InvalidEmailAddress: '无效的电子邮件地址',
  InvalidPdf: '错误：此文件不是有效的 pdf 文件。请尝试另一个文件。',
  InvitationEmail: '邀请邮件',
  Invited_Users_: '已邀请',
  IpAccessList: 'IP 访问列表',
  IpAddressInvalidIpAccessList: "IP 访问列表中的 IP '[IpAddress]' 不是有效的 IP 地址。",
  IpAddressInvalidRangeIpAccessList: "IP 访问列表中的 IP 范围 '[IpRange]' 的开始 IP 地址大于结束 IP 地址。开始 IP 应小于或等于结束 IP。",
  IpLimitRangeIpAccessList: "请将 IP 访问列表中的 IP 范围 '[IpRange]' 的说明限制为 [NumOfChars] 个字符。您使用了 [UsedNumOfChars] 个字符。",
  IpUserAccessListLimit: '请将 IP 访问列表限制为 [MaxNumberOfCharacters] 个字符。您输入了 [EnteredNumberOfCharacters] 个字符。',
  LastMonth: '上月',
  LastWeek: '上周',
  LoadingThreeDots: '正在加载...',
  Logout: '注销',
  Manage: '管理',
  Message: '消息',
  Monday: '星期一',
  Name: '名称',
  Never: '绝不',
  No: '否',
  NonAdminSideBankruptcy: '买家',
  NonAdminSideFundraising: '买家',
  NonAdminSideMerger: '买家',
  NonAdminSidePlacement: '买家',
  NonAdminSidePublicOffer: '买家',
  NonAdminSideTender: '买家',
  None: '无',
  NoRecordsFound: '未找到记录。',
  Notifications: '邮件通知',
  Off: '关',
  OkAllCaps: '确定',
  On: '开启',
  OnceOnlyAndWhenTermsAreUpdated: '在第一次登陆和每次更改访问条款时',
  OnEveryLogin: '在每次登录时',
  Or: '或',
  Period: '句号',
  PrintingAllowed: '打印允许',
  PrintingDenied: '打印被拒绝',
  Profile: '档案',
  QnaNotificationSwitchOff: '您将不会收到任何邮件',
  QnaNotificationSwitchOn: '您将即刻收到更新',
  QnaRole: 'Q&A 角色',
  QnaRole_SentenceCase: 'Q&A 角色',
  RecipientsOfEmailNote_Gen2: '此<nowrap>电子邮件</nowrap>的收件人将无法看到彼此的地址，因此将它发送给来自不同用户组的用户是安全的。',
  Reset: '重设',
  Saturday: '星期六',
  Save: '保存',
  Saving: '正在保存',
  SavingAllowed: '保存允许',
  SavingDenied: '保存被拒绝',
  Search: '搜索',
  Security: '安全',
  Select: '选择',
  SelectUsers_SentenceCase: '选择用户',
  SelectUserType: '请选择用户类型。',
  SellSide: '卖家',
  SellSide_SentenceCase: '卖家',
  Send: '发送',
  SendUpdates: '发送更新',
  SentDocumentChanged: '添加或更改一个文件后将发送',
  ShouldBeEmailEmail: '您输入的电子邮件地址无效。',
  ShowActivityInReports_SentenceCase: '在报告中记录活动信息',
  Status: '状态',
  Subject: '主题',
  SubTeam: '子团队',
  Sunday: '星期日',
  SydneyTime: '悉尼时间',
  Team: '团队',
  Thursday: '星期四',
  TimePeriodAm: '上午',
  TimePeriodPm: '下午',
  To: '到：',
  Today: '今天',
  Tuesday: '星期二',
  UserCanLogInFromAnyIp: '此用户可从任何 IP 地址登录',
  UserCanLogInOnlyFromIpsBelow: '此用户只能从下面指定的 IP 地址登录',
  UserGroup: '用户组',
  UserProperties: '用户属性',
  Verified_Users_: '已验证',
  View: '查看',
  ViewIndexAsGhost: '模拟此用户',
  Watermark: '水印',
  Wednesday: '星期三',
  Weekly: '每周',
  Yes: '是',
  YouCanAddDescriptionsAfterComma: '也可在逗号后添加描述，如 <b>1.1.1.1，伦敦办事处 IP</b>',
  YourCurrentIpAddress: '<b>注：</b>您当前的 IP 地址是 [IP Address]。<a [href]>单击此处</a>将它添加到列表。',
};
