// @flow

import { toResult } from 'redux-saga-helpers';
import { window } from 'app/config';
import * as url from 'app/uri/url';
import { SPA_ERROR, ERROR_TYPE, sessionErrors } from 'app/utils/error';
import { ACTION_TYPES } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';
import type { SessionErrorType } from 'app/types/errorTypes';
import { call, select, put } from 'redux-saga/effects';

// TODO: Move session logout logic to saga
// Currently we have to hard redirect to certain asp pages in order to
// invalidate sessions. Fix this when we have a proper session service to call,
// and display a dialog to the user.
// At the moment this is a direct copy of logics from ng-ansarada
export const expiredSessionLogOut = (code: string | number, session: SessionType) => {
  let sessionError: SessionErrorType = sessionErrors.defaultAction;

  // eslint-disable-next-line no-restricted-syntax
  for (const error in sessionErrors) {
    if (sessionErrors[error].code === String(code)) {
      sessionError = sessionErrors[error];
    }
  }

  const errorUrl = url.generateLegacyURL(sessionError.actionUrl, session);
  if (window.top === window) {
    window.location.href = errorUrl;
    return errorUrl;
  }
  window.top.location.href = errorUrl;
  return errorUrl;
};

export function* safeCall(fn: (*, *, Object) => *, ...args: Array<*>): Generator<*, *, *> {
  const calledResult = yield call(toResult(fn), ...args);
  if (calledResult) {
    const [result, error] = calledResult;

    if (!error) return result;

    if (error.name === SPA_ERROR) {
      if (error.type === ERROR_TYPE.SESSION_ERROR) {
        const state = yield select();
        if (state) expiredSessionLogOut(error.details, state.session);
      }

      yield put({
        type: ACTION_TYPES.BANNER_MESSAGES.SERVER_ERRORS_GENERATE,
        data: error,
      });
    }
  }
  return calledResult;
}

export function* safeRequest(fn: (...args: Array<*>) => *, ...args: Array<*>): Generator<*, *, *> {
  // !Note: toResult return a iterator instead of an array, so DO NOT use index to access the result
  const [result, error] = yield call(toResult(fn), ...args);
  if (error) {
    if (error.name === SPA_ERROR) {
      if (error.type === ERROR_TYPE.SESSION_ERROR) {
        const state = yield select();
        if (state) expiredSessionLogOut(error.details, state.session);
      }
      yield put({
        type: ACTION_TYPES.BANNER_MESSAGES.SERVER_ERRORS_GENERATE,
        data: error,
      });
    }
  }
  return [result, error];
}
