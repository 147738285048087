// @flow

import type {
  Team,
  CopyDialogTeam,
  SecurityActionsType,
  CopySecurityOptionsType,
} from 'app/pages/security/securityTypes';
import translate from 'app/i18n/translate';
import { ACTION_TYPES, COPY_SECURITY_STATUSES, COPY_SECURITY_ACTIONS } from 'app/constants';
import type { AppDispatch } from 'app/types/appStateTypes';

export const getFromId = (team: Team) => (team.subteams ? team.subteams[0].id : team.subteamId);

export const calculatePercentage = (
  teams: Array<CopyDialogTeam>,
  nextAction: SecurityActionsType,
) => {
  if (nextAction !== COPY_SECURITY_ACTIONS.DISMISS)
    return { done: 0, progress: 0, completed: false };
  const total = teams.length;

  const { progress, done, error } = teams.reduce(
    (acc, team) => {
      switch (team.status) {
        case COPY_SECURITY_STATUSES.IN_PROGRESS:
          return {
            ...acc,
            progress: acc.progress + 1,
          };
        case COPY_SECURITY_STATUSES.DONE:
          return {
            ...acc,
            done: acc.done + 1,
          };
        case COPY_SECURITY_STATUSES.ERROR:
          return {
            ...acc,
            error: acc.error + 1,
          };
        default:
          return acc;
      }
    },
    {
      progress: 0,
      done: 0,
      error: 0,
    },
  );
  return {
    done: total > 0 ? Math.floor((done / total) * 100) : 0,
    progress: total > 0 ? Math.floor(((progress + done) / total) * 100) : 0,
    completed: error + done + progress === total,
  };
};

export const updateSelectedStatus = (
  id: number | boolean,
  fromId: number,
  teams: Array<CopyDialogTeam>,
): Array<CopyDialogTeam> => {
  if (typeof id === 'boolean') {
    const selected: boolean = id;
    return teams.map(team =>
      team.id === fromId
        ? { ...team, subteams: (team.subteams || []).map(subteam => ({ ...subteam, selected })) }
        : {
            ...team,
            selected,
            subteams: (team.subteams || []).map(subteam =>
              subteam.id === fromId ? subteam : { ...subteam, selected },
            ),
          },
    );
  }

  return teams.map(team => {
    if (team.id === id) {
      return {
        ...team,
        selected: !team.selected,
      };
    }

    const { subteams } = team;
    if (!subteams) return team;

    const newSubteams = subteams.map(subteam => {
      if (subteam.id === id) {
        return {
          ...subteam,
          selected: !subteam.selected,
        };
      }
      return subteam;
    });
    return {
      ...team,
      subteams: newSubteams,
    };
  });
};

export const isDisabled = (
  teams: Array<CopyDialogTeam>,
  copyOptions: CopySecurityOptionsType,
  isGuest: boolean,
  submissionToolEnabled: boolean,
) => {
  const isValidTeam = teams.some(
    team => team.selected || (team.subteams && team.subteams.some(subteam => subteam.selected)),
  );

  const isValidCopyOptions =
    copyOptions.documentIndexSecuritySettings ||
    copyOptions.qnaSubjectSecuritySettings ||
    (submissionToolEnabled && copyOptions.submissionIndexSecuritySettings);

  return !isValidTeam || !isValidCopyOptions;
};

export const dialogProps = (
  nextAction: SecurityActionsType,
  completed: boolean,
  teams: Array<CopyDialogTeam>,
  copyOptions: CopySecurityOptionsType,
  isGuest: boolean,
  submissionToolEnabled: boolean,
  dispatch: AppDispatch,
) => {
  switch (nextAction) {
    case COPY_SECURITY_ACTIONS.CONFIRM:
      return {
        showCloseButton: true,
        triggerButton: {
          children: translate('Confirm'),
          variant: 'Primary',
          onClick: () => {
            dispatch({
              type: ACTION_TYPES.DIALOG_SET_TITLE,
              data: translate('CopyingSecuritySettings'),
            });
            dispatch({ type: ACTION_TYPES.SECURITY.COPY_FORM_CONFIRM });
          },
        },
      };
    case COPY_SECURITY_ACTIONS.DISMISS:
      return {
        showCloseButton: false,
        triggerButton: {
          children: translate('Ok'),
          variant: 'Primary',
          disabled: !completed,
          onClick: () => {
            dispatch({ type: ACTION_TYPES.DIALOG_TOGGLE });
            dispatch({ type: ACTION_TYPES.SECURITY.COPY_FORM_DISMISS });
          },
        },
      };
    default:
      return {
        showCloseButton: true,
        triggerButton: {
          children: translate('Review'),
          variant: 'Primary',
          disabled: isDisabled(teams, copyOptions, isGuest, submissionToolEnabled),
          onClick: () => dispatch({ type: ACTION_TYPES.SECURITY.COPY_FORM_REVIEW }),
        },
      };
  }
};
