// @flow

import { getJSONFromScript } from './dom';

let feature2Data;

export const getFeature2Data = (key: string) => {
  if (!feature2Data) {
    feature2Data = getJSONFromScript('feature2-data');
  }

  return feature2Data[key];
};
