// @flow

import api from 'app/uri/api';
import { request } from 'app/utils/request';
import { safeCall } from 'app/utils/saga-helpers';
import { ACTION_TYPES, HTTP_METHODS } from 'app/constants';
import type { SubjectSecurityChanges } from 'app/pages/security/subjectSecurityPage/subjectSecurityPageTypes';
import { select, put, takeEvery } from 'redux-saga/effects';
import type { SubjectOrGroup } from './subjectSecurityPageTypes';

type RawDataType = {
  Id: number,
  Name: string,
  Denied: boolean,
  IsDisabled: boolean,
  Subjects: Array<{
    Id: number,
    Name: string,
    Denied: boolean,
    IsDisabled: boolean,
  }>,
};

export const parseSubjectData = (data: Array<RawDataType>): Array<SubjectOrGroup> => {
  const subjectsAndGroups = [];

  data.forEach(subjectGroup => {
    subjectsAndGroups.push({
      id: subjectGroup.Id,
      name: subjectGroup.Name,
      isGroup: true,
      expanded: true,
      allowAccess: !subjectGroup.Denied,
      disabled: subjectGroup.IsDisabled,
    });

    subjectGroup.Subjects.forEach(subject => {
      subjectsAndGroups.push({
        id: subject.Id,
        parentId: subjectGroup.Id,
        name: subject.Name,
        visible: true,
        isGroup: false,
        allowAccess: !subject.Denied,
        disabled: subject.IsDisabled,
      });
    });
  });

  return subjectsAndGroups;
};

// The load subjects API will log a user out if they don't have a Q&A role,
// so we must be careful to not make a request to it for non-Q&A users
function* loadSubjects(): Generator<*, *, *> {
  const { session, security } = yield select();
  const { dataroomId, userId } = session;
  const { subteamId } = security.props;
  const questionSubjectGroupsApi = api.getSubjectSecurity(dataroomId, userId, subteamId);
  const subjectData = yield safeCall(request, questionSubjectGroupsApi, session);
  const subjectList = parseSubjectData(subjectData.data);

  yield put({
    type: ACTION_TYPES.SECURITY.SUBJECTS.LOADED,
    data: subjectList,
  });
}

type SaveSecurityApiPayload = {
  SecurityGroupId: number,
  SubjectGroupSecurityAccesses: Array<?{
    Id: number,
    Denied: boolean,
  }>,
  SubjectSecurityAccesses: Array<?{
    Id: number,
    Denied: boolean,
  }>,
};

export const mapToSecurityApiPayload = ({
  teamId,
  subjects,
  changes,
}: {
  teamId: number,
  subjects: Array<SubjectOrGroup>,
  changes: SubjectSecurityChanges,
}): SaveSecurityApiPayload => {
  const apiPayload: SaveSecurityApiPayload = {
    SecurityGroupId: teamId,
    SubjectGroupSecurityAccesses: [],
    SubjectSecurityAccesses: [],
  };

  Object.entries(changes).forEach(([subjectId, allowAccess]) => {
    const changedItem = {
      Id: Number(subjectId),
      Denied: !allowAccess,
    };

    // $FlowFixMe Flow thinks find() might not find. But it will.
    if (subjects.find(subject => subject.id === Number(subjectId)).isGroup) {
      apiPayload.SubjectGroupSecurityAccesses.push(changedItem);
    } else {
      apiPayload.SubjectSecurityAccesses.push(changedItem);
    }
  });

  return apiPayload;
};

export function* saveSubjects(): Generator<*, *, *> {
  const { session, security } = yield select();
  const { dataroomId, userId } = session;

  const { subteamId } = security.props;

  const questionSubjectGroupsApi = api.saveSubjectSecurity(dataroomId, userId);

  yield put({ type: ACTION_TYPES.SECURITY.SUBJECTS.SAVING });

  yield safeCall(request, questionSubjectGroupsApi, session, {
    method: 'POST',
    data: mapToSecurityApiPayload({
      teamId: subteamId,
      subjects: security.subjects.subjects,
      changes: security.subjects.changes,
    }),
  });

  yield put({ type: ACTION_TYPES.SECURITY.SUBJECTS.SAVED });

  yield put({ type: ACTION_TYPES.SECURITY.SUBJECTS.SAVE_CHANGES_IN_STORE });
}

export function* fetchQnaRole(): Generator<*, *, *> {
  const state = yield select();
  const qnaRoleUri = api.getUser;
  const { dataroomId } = state.session;
  const { userId } = state.session;

  const qnaRoleResult = yield safeCall(request, qnaRoleUri(dataroomId, userId), state.session, {
    method: HTTP_METHODS.GET,
  });

  if (qnaRoleResult && 'data' in qnaRoleResult) {
    yield put({
      type: ACTION_TYPES.QNA.MANAGE_QUESTIONS_GET_QNA_ROLE,
      qnaRole: qnaRoleResult.data,
    });

    yield put({
      type: ACTION_TYPES.PAGE.CURRENT_USER_QNA_ROLE_SET,
      data: qnaRoleResult.data,
    });
  }
}

export const subjectSecuritySagas = [
  takeEvery(ACTION_TYPES.SECURITY.FETCH_USER_QNA_ROLE, fetchQnaRole),
  takeEvery(ACTION_TYPES.SECURITY.SUBJECTS.LOAD, loadSubjects),
  takeEvery(ACTION_TYPES.SECURITY.SUBJECTS.SEND_CHANGES_TO_SERVER, saveSubjects),
];
