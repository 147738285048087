/* eslint-disable no-unused-vars */
// @flow

import type { SegmentInstance } from './type';

export type SegmentCallback = (segment: SegmentInstance) => void;

export const callSegment = (callback: SegmentCallback) => {
  if (window.segment) {
    callback(window.segment);
  }
};
