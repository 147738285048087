"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectLicenseState = exports.actions = void 0;
// @ts-ignore
var constants_1 = require("app/constants");
var intialValues = {
    platformAccountId: '',
    companyId: '',
    features: {},
    plans: [],
    consumablesWithDetails: [],
    userLicences: [],
};
var setLicenseData = function (payload) { return ({
    type: constants_1.ACTION_TYPES.LICENSE.SET_DATA,
    payload: payload,
}); };
var licenseReducer = function (state, action) {
    if (state === void 0) { state = intialValues; }
    if (action.type === constants_1.ACTION_TYPES.LICENSE.SET_DATA) {
        return __assign(__assign({}, state), action.payload);
    }
    return state;
};
exports.actions = { setLicenseData: setLicenseData };
var selectLicenseState = function (state) { return state.license || {}; };
exports.selectLicenseState = selectLicenseState;
exports.default = licenseReducer;
