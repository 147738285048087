// @flow

import { ACTION_TYPES } from 'app/constants';
import * as notificationService from 'app/services/notification';
import { safeRequest } from 'app/utils/saga-helpers';
import { put, select, takeEvery } from 'redux-saga/effects';

function* fetchSetting() {
  const { session } = yield select();
  const [notificationSetting, error] = yield safeRequest(notificationService.getSetting, session);
  if (!error) {
    yield put({
      type: ACTION_TYPES.NOTIFICATION.SET_SETTING,
      payload: notificationSetting,
    });
  }
}

function* saveSetting(action) {
  const { session } = yield select();
  yield notificationService.saveSetting(session, action.payload);
  yield fetchSetting();
}

export const notificationSagas = [
  takeEvery(ACTION_TYPES.NOTIFICATION.FETCH_SETTING, fetchSetting),
  takeEvery(ACTION_TYPES.NOTIFICATION.SAVE_SETTING, saveSetting),
];
