// @flow

import * as R from 'ramda';
import * as autocompleteLegacy from 'app/components/autocompleteLegacy';
import { makeItems } from 'app/components/multiSelect/multiSelectHelpers';
import { makeTeamsItems } from 'app/pages/teams/teamsHelpers';
import { ACTION_TYPES, EMPTY_NAME_CHAR } from 'app/constants';
import type { FilterData } from './filterTypes';

// TODO move mapDataToItem() to selector https://jira.ansarada.com/browse/DR-18027
const mapDataToItem = (data: Object) => {
  if (data.teams) {
    return {
      teamsItems: makeTeamsItems(data.teams),
    };
  }
  if (data.qnaRoles || data.userTypes) {
    return {
      qnaItems: [
        ...makeItems('qnaRoleCode', 'qnaRoleName', data.qnaRoles),
        {
          id: 'None',
          text: 'None',
          data: { key: 'qnaRoleCode' },
        },
      ],
      guestUserTypeItems: makeItems(
        'userTypeCode',
        'userType',
        data.userTypes.filter(({ isGuest }) => isGuest),
      ),
      nonGuestUserTypeItems: makeItems(
        'userTypeCode',
        'userType',
        data.userTypes.filter(({ isGuest }) => !isGuest),
      ),
    };
  }
  if (data.people) {
    const industryItems = R.uniqBy(
      x => x.id,
      makeItems(
        'industry',
        'industry',
        data.people.filter(x => x.industry),
      ),
    );
    const jobTitleItems = R.uniqBy(
      x => x.id,
      makeItems(
        'title',
        'title',
        data.people.filter(x => x.title),
      ),
    );
    const names = R.uniqBy(
      x => x.id,
      makeItems(
        'name',
        'name',
        data.people.filter(x => x.name !== EMPTY_NAME_CHAR),
      ),
    );
    const emailItems = R.uniqBy(x => x.id, makeItems('email', 'email', data.people));
    const jobTitleProfessionItems = R.uniqBy(x => x.id, industryItems.concat(jobTitleItems));

    return {
      fullNameItems: [...names],
      emailItems,
      jobTitleProfessionItems,
    };
  }
  return {};
};

export const initialState: FilterData = {
  filterOpen: false,
  filters: {
    teamsFilter: autocompleteLegacy.init(),
    qnaFilter: autocompleteLegacy.init(),
    userTypeFilter: autocompleteLegacy.init(),
    fullNameFilter: autocompleteLegacy.init(),
    emailFilter: autocompleteLegacy.init(),
    jobTitleProfessionFilter: autocompleteLegacy.init(),
    statusFilter: autocompleteLegacy.init(),
  },
  filterItems: {
    teamsItems: [],
    guestUserTypeItems: [],
    nonGuestUserTypeItems: [],
    qnaItems: [],
    fullNameItems: [],
    emailItems: [],
    jobTitleProfessionItems: [],
    status: ['Enabled', 'Disabled', 'Invited', 'NotInvited'],
  },
};

const teamsFilterReducer = (state: FilterData = initialState, action: Object): FilterData => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.FILTER_DATA_MAP:
      return {
        ...state,
        filterItems: {
          ...state.filterItems,
          ...mapDataToItem(action.data),
        },
      };

    case ACTION_TYPES.TEAMS.FILTER_TOGGLE:
      return {
        ...state,
        filterOpen: !state.filterOpen,
      };

    case ACTION_TYPES.TEAMS.FILTER_UPDATE: {
      const newFilters = {
        ...state.filters,
        ...action.data,
      };

      return {
        ...state,
        filters: newFilters,
      };
    }

    case ACTION_TYPES.TEAMS.FILTER_REMOVE: {
      const newFilters = {
        ...state.filters,
        [action.data.filterName]: {
          ...state.filters[action.data.filterName],
          selected: action.data.newFilterSelected,
        },
      };

      return {
        ...state,
        filters: newFilters,
      };
    }

    default:
      return state;
  }
};

export default teamsFilterReducer;
