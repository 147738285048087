// @flow

import { ACTION_TYPES } from 'app/constants';
import * as dataRoomService from 'app/services/dataRoom';
import * as personService from 'app/services/person';
import * as teamService from 'app/services/team';
import { safeRequest } from 'app/utils/saga-helpers';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import type { QueryType } from 'app/components/emailForm/EmailFormTypes';
import { filterPeopleWithQuery, getUserTypeMap, getQnaTypeMap } from './sendInvitationHelper';

export function* fetchTeams(query: QueryType): Generator<*, *, *> {
  const { session } = yield select();
  const [teams, error] = yield safeRequest(teamService.getTeams, session, query);
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.TEAMS_UPDATE,
      data: teams,
    });
  }
}

export function* fetchTeam(teamId: number): Generator<*, *, *> {
  const { session } = yield select();
  const [team, error] = yield safeRequest(teamService.getTeam, session, teamId);
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.TEAMS_UPDATE,
      data: [team],
    });
  }
}

export function* fetchPeople(query: QueryType): Generator<*, *, *> {
  const { session } = yield select();
  const [people, error] = yield safeRequest(personService.fetchPeople, session);
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.PEOPLE_UPDATE,
      data: filterPeopleWithQuery(people, query),
    });
  }
}

export function* findPeople(query: QueryType): Generator<*, *, *> {
  const { session } = yield select();
  const { userId, teamId, isGuest, subTeamId } = query;

  let filteredIsGuest = null;
  if (isGuest) {
    filteredIsGuest = Boolean(isGuest === '1');
  }

  const [people, error] = yield safeRequest(
    personService.findPeople,
    session,
    userId,
    teamId,
    subTeamId,
    filteredIsGuest,
  );
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.PEOPLE_UPDATE,
      data: filterPeopleWithQuery(people, query),
    });
  }
}

export function* fetchUserTypes(): Generator<*, *, *> {
  const { session } = yield select();
  const [userTypes, error] = yield safeRequest(
    dataRoomService.fetchAvailableUserTypesAndQnaRoles,
    session,
  );
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.SET_USER_TYPES_AND_QNA_TYPES,
      data: {
        userTypeMap: getUserTypeMap(userTypes),
        qnaRoleMap: getQnaTypeMap(userTypes),
      },
    });
  }
}

export function* fetchDefaultInvite(): Generator<*, *, *> {
  const { session } = yield select();
  const [defaultInvite, error] = yield safeRequest(dataRoomService.fetchDefaultInvite, session);
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.SET_DEFAULT_INVITE,
      data: defaultInvite,
    });
  }
}

export function* fetchInitialData(action: Object): Generator<*, *, *> {
  const { teamId } = action.data;
  if (teamId) {
    yield all([
      call(findPeople, action.data),
      call(fetchTeam, teamId),
      call(fetchUserTypes),
      call(fetchDefaultInvite),
    ]);
  } else {
    yield all([
      call(fetchPeople, action.data),
      call(fetchTeams, { isGuest: action.data.isGuest === '1' }),
      call(fetchUserTypes),
      call(fetchDefaultInvite),
    ]);
  }

  yield put({ type: ACTION_TYPES.SEND_INVITATION.SET_IS_LOADED });
}

export function* uploadAttachment(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  const files = action.data;
  const formData = new FormData();
  formData.append('DocumentFile', files[0]);
  yield put({ type: ACTION_TYPES.SEND_INVITATION.SET_IS_UPLOADING_ATTACHMENT, data: true });
  const [attachment, error] = yield safeRequest(dataRoomService.addAttachment, session, formData);
  yield put({ type: ACTION_TYPES.SEND_INVITATION.SET_IS_UPLOADING_ATTACHMENT, data: false });
  if (!error) {
    yield put({
      type: ACTION_TYPES.SEND_INVITATION.ADD_ATTACHMENT,
      data: attachment,
    });
  }
}

export function* submitInvitation(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.SEND_INVITATION.SET_IS_SUBMITTING, data: true });
  const [, error] = yield safeRequest(dataRoomService.sendInvitation, session, action.data);
  if (!error) {
    yield put({ type: ACTION_TYPES.SEND_INVITATION.SET_SENT, data: action.data });
  }
  yield put({ type: ACTION_TYPES.SEND_INVITATION.SET_IS_SUBMITTING, data: false });
}

export const sendInvitationSagas = [
  takeEvery(ACTION_TYPES.SEND_INVITATION.DATA_FETCH_INITIAL, fetchInitialData),
  takeEvery(ACTION_TYPES.SEND_INVITATION.UPLOAD_ATTACHMENT, uploadAttachment),
  takeEvery(ACTION_TYPES.SEND_INVITATION.SUBMIT_INVITATION, submitInvitation),
];
