// @flow

import { ACTION_TYPES } from 'app/constants';
import type { Person } from 'app/types/personTypes';
import * as personService from 'app/services/person';
import { safeRequest } from 'app/utils/saga-helpers';
import { select, put, takeEvery } from 'redux-saga/effects';

export function* deletePerson(action: Object): Generator<*, *, *> {
  const person: Person = action.data;
  const { session } = yield select();
  const [, error] = yield safeRequest(personService.deletePerson, session, person);
  if (!error) {
    yield put({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
  }
}

export const personSagas = [takeEvery(ACTION_TYPES.TEAMS.PERSON_CONFIRM_DELETE, deletePerson)];
