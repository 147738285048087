// @flow

import { ACTION_TYPES } from 'app/constants';
import type { FormInputHelpState } from 'app/pages/teams/teamsTypes';

export const initialState: FormInputHelpState = {
  fullTextSearch: false,
  bulkDownload: false,
  sod: false,
  roomRole: false,
};
const formHelpersReducer = (
  state?: FormInputHelpState = initialState,
  action: Object,
): FormInputHelpState => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.FORM_HELP_TOGGLE: {
      const visible = state[action.data];
      return {
        ...state,
        [action.data]: !visible,
      };
    }
    case ACTION_TYPES.TEAMS.PEOPLE_FORM_EDIT:
    case ACTION_TYPES.TEAMS.PEOPLE_FORM_ADD:
    case ACTION_TYPES.TEAMS.TEAM_FORM_EDIT:
    case ACTION_TYPES.TEAMS.TEAM_FORM_ADD_TEAM:
    case ACTION_TYPES.TEAMS.TEAM_FORM_ADD_SUBTEAM:
    case ACTION_TYPES.TEAMS.TEAM_FORM_COPY:
      return {
        fullTextSearch: false,
        bulkDownload: false,
        sod: false,
        roomRole: false,
      };
    default:
      return state;
  }
};

export default formHelpersReducer;
