// @flow

// prettier-ignore

export default {
  Accept: 'Accept',
  AcceptRegistration: 'Accept registration',
  AcceptTermsOfAccess_SentenceCase: 'Accept terms of access',
  Access: 'Access',
  AccessAllowed: 'Access allowed',
  AccessDenied: 'Access denied',
  AccessedAgo: 'Accessed [time] ago',
  Actions: 'Actions',
  Add: 'Add',
  AddAndInvite: 'Add and invite',
  AddAndInvitePeople: 'Add and invite people',
  AdditionalRecipients: 'Additional recipients',
  AdditionalRecipients_SentenceCase: 'Additional recipients',
  AdditionalRecipientsHint: 'Enter each email address on a separate line',
  AddPeople: 'Add people',
  AddPeopleTo: 'Add people to',
  AddPeopleToSubteam: 'Add people to [Team]/[Subteam]',
  AddPeopleToTeam: 'Add people to [Team]',
  AddPeopleWillHaveTheSameAccess: 'Added people will have the same access',
  AddToExistingTeam: 'Add to existing team',
  Administration: 'Administration',
  AdminSideAudit: 'Teams',
  AdminSideBankruptcy: 'Sell Side',
  AdminSideBankruptcy_EmailHeading: 'Email sell side people',
  AdminSideBankruptcy_InviteHeading: 'Send invites to sell side people',
  AdminSideDebt: 'Issuer',
  AdminSideDebt_EmailHeading: 'Email issuers',
  AdminSideDebt_InviteHeading: 'Send invites to issuers',
  AdminSideDocumentRepository: 'Internal',
  AdminSideDocumentRepository_EmailHeading: 'Email internals',
  AdminSideDocumentRepository_InviteHeading: 'Send invites to internals',
  AdminSideFundraising: 'Client',
  AdminSideFundraising_EmailHeading: 'Email client',
  AdminSideFundraising_InviteHeading: 'Send invites to client',
  AdminSideInternalAudit: 'Teams',
  AdminSideJointVenture: 'Internal',
  AdminSideJointVenture_EmailHeading: 'Email internals',
  AdminSideJointVenture_InviteHeading: 'Send invites to internals',
  AdminSideMerger: 'Sell side',
  AdminSideMerger_EmailHeading: 'Email sell side people',
  AdminSideMerger_InviteHeading: 'Send invites to sell side people',
  AdminSideOther: 'Internal',
  AdminSideOther_EmailHeading: 'Email internals',
  AdminSideOther_InviteHeading: 'Send invites to internals',
  AdminSidePlacement: 'Issuer',
  AdminSidePlacement_EmailHeading: 'Email issuers',
  AdminSidePlacement_InviteHeading: 'Send invites to issuers',
  AdminSidePublicOffer: 'Internal',
  AdminSidePublicOffer_EmailHeading: 'Email internals',
  AdminSidePublicOffer_InviteHeading: 'Send invites to internals',
  AdminSideTargeted: 'Internal team',
  AdminSideTargeted_EmailHeading: 'Email internal team',
  AdminSideTargeted_InviteHeading: 'Send invites to internal team',
  AdminSideTax: 'Teams',
  AdminSideTender: 'Client',
  AdminSideTender_EmailHeading: 'Email client',
  AdminSideTender_InviteHeading: 'Send invites to clients',
  All: 'All',
  Allow: 'Allow',
  Allowed: 'Allowed',
  AlwaysInviteGuestDialogContent:
    'You need to contact Ansarada to change plans. Don’t worry you’ll be sending invites to external folks in no time.',
  And_SentenceCase: 'and',
  AndGoLive: 'and go live',
  AreYourReadyToGoLive: 'Are you ready to go live?',
  AreYouSureToDeclineRegistration: 'Are you sure you want to decline registrant [registrant]?',
  AreYouSureToDeleteRegistration: 'Are you sure you want to delete registrant [registrant]?',
  AreYouSureToDeleteRequest: 'Are you sure you want to delete request [registrant]?',
  At: 'At',
  Attachments: 'Attachments',
  BackToTeamsPage: 'Back to teams page',
  BannerMessage: "Team created with [TeamName]'s settings",
  BatchOperationCantBePerformed:
    'The batch operation cannot be performed because there are no Users in this Data Room. Please enter Users first.',
  BeforeYouCanEmail:'Before you can email [teamType], you need to verify your email.',
  BeforeYouCanGoLive: 'Before you can go live',
  BeforeYouCanSendInvites:'Before you can send invites to [teamType], you need to verify your email.',
  BidderEditSecurityBannerMessage: 'Bidders can’t return documents with security controls enabled',
  BillingCommencesWhenTheFirstGuestIsInvited:'Billing commences when the first guest is invited.',
  BulkDownload_SentenceCase: 'Bulk download',
  BulkDownloadText:
    'Allows people to download multiple files in a single .zip file. Zip downloads are available only for a limited time.',
  BuySide: 'Buy Side',
  BuySide_InviteHeading: 'Send invites to buy side people',
  BuySide_SentenceCase: 'Buy Side',
  BySubmittingThisForm: 'By submitting this form, you agree to our',
  Cancel: 'Cancel',
  Change: 'Change',
  ChangeTeamAccess: 'Change Team Access',
  ChooseLanguage: 'Choose language',
  ClickAndPreview: '2. Click and preview',
  Close: 'Close',
  CollapseAll_SentenceCase: 'Collapse all',
  Colour: 'Colour',
  Company: 'Company',
  Confirm: 'Confirm',
  ContactUs: 'Contact us',
  Copied: 'Copied',
  CopiedSharedLinkInfo: 'Share the link with external bidder teams or guests so they can register.',
  CopyEmailsOfSelectedUsers: 'Copy emails of selected users',
  CopyEmailsOfSelectedUsersSec2: 'Copy selected emails',
  CopyingAllowed: 'Copying allowed',
  CopyingDenied: 'Copying denied',
  CopyingSecuritySettings: 'Copying security settings',
  CopyLink: 'Copy Link',
  CopyOf: 'Copy of',
  CopyRegistrationLink: 'Copy registration link',
  CopySecurity: 'Copy security',
  CopySharedLinkToClipboard: 'Copy Link to clipboard',
  CopyTeam: 'Copy team',
  CreateArchive: 'Create archive',
  CreateNewTeam: 'Create new team',
  CreatePeopleWithSameEmail: 'Create people that have the same email as an existing person',
  CreateSideTeam: 'Create [Side] team',
  CreateSubteam: 'Create sub-team',
  CreateTeam: 'Create team',
  CreateTeamEmptyState: 'Create a team to organise people and set up access',
  CreateWatermark: 'Create watermark',
  Daily: 'Daily',
  DataRoom: 'Data room',
  DataroomRole: 'Room role',
  DataRooms: 'Data rooms',
  DataRoomStatus: 'Data room status',
  DataRoomUserNotVerified1: 'To access the data room:',
  DataRoomUserNotVerified2: 'Open your invitation email',
  DataRoomUserNotVerified3: 'Click the "Verify your details" link',
  DataRoomUserNotVerified4: 'Log in',
  DataRoomUserNotVerified5:
    "If you haven't received an invitation email, please contact your data room administrator.",
  DataRoomUserNotVerifiedTitle: "Your access isn't verified",
  DateModified: 'Date modified',
  DateSent: 'Date sent',
  Decline: 'Decline',
  Declined: 'Declined',
  DeclineRegistration: 'Decline registration',
  Delete: 'Delete',
  DeleteBidderInformation:
    "A team can only be deleted when it's not a qualified bidder in Evaluation process. Remove this team from the qualified bidder list.",
  DeleteBidderTeamInformationLine1:
    "A team can only be deleted when it's empty and not a qualified bidder in Evaluation process.",
  DeleteBidderTeamInformationLine2:
    'Move people out of this team and remove this team from qualified bidder list.',
  DeletePerson: 'Delete person',
  DeletePersonConfirm: 'Delete person',
  DeletePersonHeader: 'Delete [email]?',
  DeletePersonMessage:
    'Deleting [email] will permanently remove them from the Room, all activity reports and history. This action cannot be undone.',
  DeleteRegistration: 'Delete registration',
  DeleteRequest: 'Delete request',
  DeleteSubteam: 'Delete sub-team',
  DeleteSubteamInformation:
    'A sub-team can only be deleted when it is empty. Move people out of this sub-team to delete it.',
  DeleteTeam: 'Delete team',
  DeleteTeamInformation:
    'A team can only be deleted when it is empty. Move people out of this team to delete it.',
  DeletingTeamContainsPeople:
    'The team can only be deleted when it is empty. Move people out of this team to delete it.',
  DemoAllCaps: 'DEMO',
  Deny: 'Deny',
  DenyCopyingDisabled: 'Deny copying disabled',
  DenyEditingDisabled: 'Deny editing disabled',
  DenyPrintingDisabled: 'Deny printing disabled',
  DenySavingDisabled: 'Deny saving disabled',
  DidntReceiveTheEmail: "Didn't receive the email?",
  Disable: 'Disable',
  DisableAccess: 'Disable access',
  Disabled: 'Disabled',
  DisabledUser: 'Disabled user',
  DisableRegistrations: 'Disable Registrations',
  DisableRegistrations_SentenceCase: 'Disable registrations',
  DisableRegistrationsAction: 'Disable',
  DisableRegistrationsInformation: 'When disabled the registration link is no longer active.',
  Disallowed: 'Disallowed',
  Document: 'Document',
  DocumentIndex: 'Document index',
  DocumentIndexSecurity: 'Document Index Security',
  Documents: 'Documents',
  Down: 'Down',
  Draft: 'Draft',
  Edit: 'Edit',
  EditAfterCloseDate: 'Edit submission index after close date',
  EditDocumentSecurityTeam:
    'No print or save security controls, access or watermarks have been set for this team.',
  EditDocumentSecurityTeamSubteam:
    'No print or save security controls, access or watermarks have been set for this sub-team.',
  EditingAllowed: 'Editing allowed',
  EditingDenied: 'Editing denied',
  EditMultipleUsers: 'Edit Multiple Users',
  EditPeople: 'Edit people',
  EditPerson: 'Edit person',
  EditPersonFormInvitationMessage:
    'Send a new invitation to the updated email address by clicking the checkbox below',
  EditProfile: 'Edit profile',
  EditSecurity_SentenceCase: 'Edit security',
  EditSecurityFor: 'Edit security for',
  EditSubteam: 'Edit sub-team',
  EditTeam: 'Edit team',
  Email: 'Email',
  EmailAddress: 'email address',
  EmailCanNotBeEmpty: 'Email cannot be empty',
  EmailHasBeenSent: 'Email has been sent.',
  EmailMessage: 'Email message is a required field',
  EmailNotifications: 'Email notifications',
  EmailNotValid: 'Email is not valid',
  EmailPeople: 'Email people',
  EmailPeopleSubjectRequiredSentenceCase: 'A subject is required for the email',
  Emails: 'Emails',
  EmailsNotValid: 'Email [Email] is not valid',
  EmailSuccessMessage: 'Your email has been sent successfully.',
  EnableAccess: 'Enable access',
  Enabled: 'Enabled',
  EnableRegistrations: 'Enable Registrations',
  EnableRegistrations_SentenceCase: 'Enable registrations',
  EnableRegistrationsAction: 'Enable',
  EnableRegistrationsInformation:
    'When enabled the registration link is active and can be shared publicly.',
  EnterIpAddressesRanges: 'Enter IP addresses or ranges of IP addresses separated by the Enter key',
  EnterOneOrMoreEmailAddresses: 'Email for each person',
  EnterYourLocationOrClosestCity: 'Enter your location or closest city',
  Every: 'Every',
  ExpandAll_SentenceCase: 'Expand all',
  ExportToExcel_SentenceCase: 'Export to Excel',
  FieldIsRequired: '[field] is required',
  FieldMustBeAtMostMaxCharacters: '[field] must be at most [max] characters.',
  FileExtensionIsInList:
    'The file "[FileName]" cannot be uploaded, because its extension is in the list of file extensions disallowed for this data room:\n\n[FileTypeList]\n\nPlease convert this file into one of the allowed formats.',
  FileExtensionIsNotInList:
    'The file "[FileName]" cannot be uploaded, because its extension is not in the list of file extensions allowed for this data room:\n\n[FileTypeList]\n\nPlease convert this file into one of the allowed formats.',
  Filter: 'Filter',
  FirstName: 'First name',
  Folder: 'Folder',
  ForRangesSeparateStartIp:
    'For ranges, separate the start IP and the end IP by a dash, e.g. <b>1.1.1.1-1.1.1.6</b>',
  Friday: 'Friday',
  From: 'From:',
  FullName: 'Full name',
  FullText: 'Full text search',
  FullTextText: 'Allow people to search within documents.',
  GDPRInformation: 'GDPR information',
  GlobalUnexpectedErrorMessage:
    "There's a problem with your dataroom.  Please contact our Client Services team.",
  GlobalUnexpectedErrorWithIdMessage:
    "There's a problem with your dataroom.  Quote this code <[ErrorId]> to our Client Services team.",
  GoLiveDialogTitle: 'Go live',
  GotIt: 'Got it',
  Guest: 'Guest',
  GuestUserRegistration: 'Guest user registration',
  GuideToUsingLink: 'Guide to using Link',
  Help: 'Help',
  HelpAndSupport: 'Help & support',
  HideMenu: 'Hide menu',
  High: 'High',
  History: 'History',
  Hourly: 'Hourly',
  IamNotReadyYet: 'Not yet',
  IamReadyToGoLive: 'I’m ready to go live',
  ID: 'ID',
  Importance: 'Importance',
  Important:'IMPORTANT!',
  Important_SentenceCase:'Important',
  Information: 'Information',
  Interval: 'Interval',
  InvalidEmailAddress: 'Invalid email address',
  InvalidField: 'Invalid [field]',
  InvalidPdf: 'ERROR: This file is not a valid pdf document. Please try another file.',
  Invitation: 'Invitation',
  InvitationEmail: 'Invitation email',
  InvitationSuccessMessage: 'Your invitation has been sent successfully.',
  Invite: 'Invite',
  InviteAGuest: 'Invite a guest',
  Invited: 'Invited',
  Invited_Users_: 'Invited',
  InvitePeople: 'Invite People',
  IpAccessList: 'IP Access List',
  IpAccessList_SentenceCase: 'IP access list',
  IpAccessListCanNotBeEmpty: 'IP access list cannot be empty',
  IpAddressInvalidIpAccessList: "IP '[IpAddress]' in the IP Access List is not a valid IP address.",
  IpAddressInvalidRangeIpAccessList:
    "IP range '[IpRange]' in the IP Access List has its start IP address greater than the end IP address. Start IP should be less than or equal to the end IP.",
  IpLimitRangeIpAccessList:
    "Please limit the description of IP range '[IpRange]' in the IP Access List to [NumOfChars] characters. You used [UsedNumOfChars] characters.",
  IpUserAccessListLimit:
    'Please limit the IP Access List to [MaxNumberOfCharacters] characters. You entered [EnteredNumberOfCharacters] characters.',
  IWillInviteLater:"I'll invite later",
  JobTitle: 'Job title',
  JobTitleProfession: 'Job title/profession',
  Last30days: 'Last 30 days',
  Last7days: 'Last 7 days',
  LastMonth: 'Last Month',
  LastName: 'Last name',
  LastWeek: 'Last Week',
  LearnMore: 'Learn more',
  LicenceChatHelp: 'Chat to a specialist.',
  LicenceErrorHelp: 'Need Help? ',
  LicenceErrorMessage:
    'You are out of licences. Your subscription will be upgraded if you go over.',
  LicencePack: 'User team pre-pay pack',
  LicencePrompt:
    'You may have pending invitations, licences will only be used after an invitation is accepted. Your subscription will be upgraded if you go over.',
  LicenceSectionPlan: 'Your Plan',
  LicenceTooltip: 'A license is consumed when a person exists in the platform in the following way',
  LicenceTooltipStep1: 'Only in Platform Pathways',
  LicenceTooltipStep2: 'Only in Platform Data Room',
  LicenceTooltipStep3: 'Both',
  LicenseMessage:
    'Accepted invites will use a license from your subscription. Your subscription will be upgraded if you go over.',
  LimitedCharacterLength: '[1] cannot exceed [0] characters.',
  LinkCopied: 'Link copied',
  LinkCopiedToClipboard: 'Link copied to clipboard',
  LinkedDocuments: 'Linked documents',
  Loading: 'Loading',
  LoadingThreeDots: 'Loading...',
  Location: 'Location',
  LoggedInToDate_SentenceCase: 'Logged in to date',
  LoginIpAccess: 'Login IP access',
  LoginLowerCase: 'login',
  LoginsLowerCase: 'logins',
  Logout: 'Log out',
  Low: 'Low',
  Manage: 'Manage',
  ManageQnaSubjects: 'Manage Q&A subjects',
  Medium: 'Medium',
  Message: 'Message',
  MessageEmpty: 'Message cannot be empty',
  Minute: 'minute',
  Minutes: 'minutes',
  ModifiedAgo: 'Modified [time] ago',
  ModifiedBy: 'Modified by',
  Monday: 'Monday',
  MyAccount: 'My account',
  Name: 'Name',
  NavigateAwayConfirm: 'Leave',
  NavigateAwayWarning: 'Leave page?',
  NavigateAwayWarningMessage: 'Changes that you made will not be saved.',
  Never: 'Never',
  NeverLoggedIn_SentenceCase: 'Never logged in',
  No: 'No',
  NoCompanyContact: 'No company contact',
  NoDemonstration: 'No demonstration',
  NonAdminSideBankruptcy: 'Buy Side',
  NonAdminSideBankruptcy_EmailHeading: 'Email buy side people',
  NonAdminSideBankruptcy_InviteHeading: 'Send invites to buy side people',
  NonAdminSideDebt: 'Investor',
  NonAdminSideDebt_EmailHeading: 'Email investors',
  NonAdminSideDebt_InviteHeading: 'Send invites to investors',
  NonAdminSideDocumentRepository: 'External',
  NonAdminSideDocumentRepository_EmailHeading: 'Email externals',
  NonAdminSideDocumentRepository_InviteHeading: 'Send invites to externals',
  NonAdminSideFundraising: 'Financiers',
  NonAdminSideFundraising_EmailHeading: 'Email financiers',
  NonAdminSideFundraising_InviteHeading: 'Send invites to financiers',
  NonAdminSideJointVenture: 'External',
  NonAdminSideJointVenture_EmailHeading: 'Email externals',
  NonAdminSideJointVenture_InviteHeading: 'Send invites to externals',
  NonAdminSideMerger: 'Buy side',
  NonAdminSideMerger_EmailHeading: 'Email buy side people',
  NonAdminSideMerger_InviteHeading: 'Send invites to buy side people',
  NonAdminSideOther: 'External',
  NonAdminSideOther_EmailHeading: 'Email externals',
  NonAdminSideOther_InviteHeading: 'Send invites to externals',
  NonAdminSidePlacement: 'Investor',
  NonAdminSidePlacement_EmailHeading: 'Email investors',
  NonAdminSidePlacement_InviteHeading: 'Send invites to investors',
  NonAdminSidePublicOffer: 'External',
  NonAdminSidePublicOffer_EmailHeading: 'Email externals',
  NonAdminSidePublicOffer_InviteHeading: 'Send invites to externals',
  NonAdminSideTargeted: 'Target team',
  NonAdminSideTargeted_EmailHeading: 'Email target team',
  NonAdminSideTargeted_InviteHeading: 'Send invites to target team',
  NonAdminSideTender: 'Bidders',
  NonAdminSideTender_EmailHeading: 'Email bidders',
  NonAdminSideTender_InviteHeading: 'Send invites to bidders',
  None: 'None',
  NoQuestionPermission: "You don't have permission to set question subjects.",
  NoQuestionSubjects: "You don't have any question subjects yet.",
  NoRecordsFound: 'No records found.',
  Normal: 'Normal',
  NoRooms: "You currently don't have any Rooms",
  NoRoomsMatch: 'No Rooms found',
  NoScorecards: "You currently don't have any Scorecards",
  NoScorecardsMatch: 'No Scorecards found',
  Notes: 'Notes',
  Notifications: 'Notifications',
  NotInvited: 'Not invited',
  NotInvited_Users__SentenceCase: 'Not invited',
  NotVerified_Users__SentenceCase: 'Not verified',
  NotYet: 'Not yet',
  Off: 'Off',
  Ok: 'Ok',
  OK: 'OK',
  OkAllCaps: 'OK',
  On: 'On',
  OnceOnlyAndWhenTermsAreUpdated: 'Once only and when terms are updated',
  OnEveryLogin: 'Every login',
  OnfirstLogin: 'On first login',
  OnlyEnabledUsersCanBeInvited: 'Only enabled users can be invited.',
  OnlyEnabledUsersCanBeInvitedSec2: 'Only people with access enabled can be invited.',
  Optional: 'Optional',
  Or: 'or',
  OtherTeamMembers: '[size] other team members',
  Pending: 'Pending',
  PendingScorecards: 'Pending scorecards',
  PeopleFormWarningText:
    "Applying the room role 'Administrator' gives people unrestricted access. They are not limited by their team's security settings",
  PeopleLowerCase: 'people',
  PerDay: 'Per Day',
  Period: 'Period',
  PerMonth: 'Per Month',
  PersonHistory: 'Person history',
  PersonHistoryFor: 'History for',
  PersonLowerCase: 'person',
  PerWeek: 'Per Week',
  Phone: 'Phone',
  PhoneNumber: 'phone number',
  PhoneNumber_SentenceCase: 'Phone number',
  PrintingAllowed: 'Printing allowed',
  PrintingDenied: 'Printing denied',
  Priority: 'Priority',
  PrivacyPolicy: 'Privacy Policy',
  Profession: 'Profession',
  Profile: 'Profile',
  Properties: 'Properties',
  ProvideBilling:'Provide your billing details',
  ProvidePayment:'Provide your payment details',
  Qna: 'Q&A',
  QnaNotificationSwitchOff: 'You will not receive any emails',
  QnaNotificationSwitchOn: 'You will receive updates immediately',
  QnaRole: 'Q&A role',
  QnaRole_SentenceCase: 'Q&A role',
  QnaSubjects: 'Q&A subjects',
  QnaSubjectSecurity: 'Q&A Subject Security',
  QnaSubmissionsLimit: 'Question submissions limits',
  QnaSubmissionsOnWeekends: 'Question submissions on weekends',
  Ready: 'Ready',
  ReasonForRequest: 'Reason for request',
  Recipients: 'Recipients',
  RecipientsOfEmailNote_Gen2:
    "The recipients of this email will NOT be able to see each other's addresses, so it is safe to send it to the users from different user groups",
  Register: 'Register',
  RegisterYourInterest: 'Register your interest',
  RegisterYourInterest_SentenceCase:
    'Submit a request to the <strong>[dataRoomName]</strong> Administrator for review. If your registration is accepted you will be invited into the project.',
  Registration: 'Registration',
  Registration_LowerCase: 'registration',
  RegistrationDetails: 'Registration details',
  RegistrationLinkDisabled: 'Registration Link Disabled',
  RegistrationRequestSubmitted: 'Registration request submitted',
  Registrations: 'Registrations',
  Registrations_LowerCase: 'registrations',
  RegistrationTextCopy:
  'You have enabled bidders teams or guests to register interest. Copy the provided link to allow people to register. New registrants will appear in this list for you to review and invite. People in this list do not have access until you invite and add them to a team.',
  Request: 'Request',
  RequestAccess: 'Request Access',
  RequestAccessDialogDescription: 'Provide details for the person who requires additional access. Your request will be reviewed by the Administrator.',
  RequestAccessDialogTitle: 'Request for additional access',
  RequestAccessEmptyStateText: 'New registrants will appear in this list for you to review. People in this list do not have access until Administrator add them to a team.',
  RequestDetails: 'Request details',
  RequestorEmail: 'Requestor email',
  Required: 'Required',
  ResendEmail: 'Resend email',
  ResendVerificationLink: 'Resend verification link',
  Reset: 'Reset',
  Review: 'Review',
  RoomRole_SentenceCase: 'Room role',
  RoomRoleAdminSubteamHelp: 'Administrators cannot be added to subteams.',
  RoomRoleHelp: "A Room role determines a person's level of access in the room.",
  Rooms: 'Rooms',
  RoomsAdvert1:
    "With Dropbox Showcase, you can share your work with partners and clients beautifully, track progress and keep tabs on who's seen what - all in one place.",
  RoomsAdvert2: 'Showcase is available for Dropbox Business Advanced and Enterprise users.',
  RoomsAdvertLink: 'Find out more',
  RoomsAdvertTitle: 'Run a high-performance deal',
  Saturday: 'Saturday',
  Save: 'Save',
  SaveAndInvitePeople: 'Save and invite people',
  Saving: 'Saving',
  SavingAllowed: 'Saving allowed',
  SavingDenied: 'Saving denied',
  SavingFailed: 'Save failed',
  Scorecards: 'Scorecards',
  ScorecardsAdvert1:
    "With Dropbox Showcase, you can share your work with partners and clients beautifully, track progress and keep tabs on who's seen what - all in one place.",
  ScorecardsAdvert2: 'Showcase is available for Dropbox Business Advanced and Enterprise users.',
  ScorecardsAdvertLink: 'Find out more',
  ScorecardsAdvertTitle: 'Run a high-performance deal',
  Search: 'Search',
  Secure: 'Secure',
  SecureByDefault: 'Secure by default',
  SecureOffice: 'Secure office',
  SecureOfficeText:
    'Secure Office tracks views and can disable saving, copying and printing in Microsoft Word, Excel and PowerPoint files.',
  SecureOfficeTitle: 'Security for Microsoft Office documents (Secure Office)',
  SecureOfficeWarning:
    'Secure Office is not compatible with many common devices, including Apple iOS and Android devices. This setting prevents people without a compatible system from being able to view these files.',
  Security: 'Security',
  SecurityControls: 'Security controls',
  SecurityControlsOff: 'Security controls off',
  SecurityControlsOffDescription:
    'When security controls are off, the usage of these documents is not restricted after download.',
  SecurityControlsOn: 'Security controls on',
  SecurityControlsOnDescription:
    'When the security controls are on, the usage of Microsoft Office and PDF documents is tracked. Saved copies will be remotely disabled when access is revoked for a team or when the Room is disabled.',
  SecurityControlsOnExtraDescription:
    'You can also restrict saving, printing, editing, and copying (copy restrictions are only available for Microsoft Office documents).',
  SecurityGroup_SentenceCase: 'Security group',
  SecurityPermissions: 'Security permissions',
  Select: 'Select',
  SelectPeople_SentenceCase: 'Select people',
  SelectSubTeam: 'Please select Sub-team.',
  SelectTeam: 'Please select Team.',
  SelectUsers_SentenceCase: 'Select people',
  SelectUserType: 'Please select room role.',
  SelfRegistrationHeader: 'Self Registration',
  SelfRegistrationToolIntroDialogHeading:
    'Allow guest users to register',
  SelfRegistrationToolIntroDialogHelpLink:
    'View Help and Support on Bidder Registration',
  SelfRegistrationToolIntroDialogTitle1:
    'Activate guest user registrations in the actions menu on the Buy side/Bidders page.',
  SelfRegistrationToolIntroDialogTitle2:
    'Share the provided link on a web page or in an email that you create.',
  SelfRegistrationToolIntroDialogTitle3:
    'Registered users can be reviewed and invited.',
  SellSide: 'Sell Side',
  SellSide_InviteHeading: 'Send invites to sell side people',
  SellSide_SentenceCase: 'Sell Side',
  Send: 'Send',
  SendEmailInvitation: 'Send email invitation',
  SendEmailInvitations: 'Send email invitations',
  SendGuestInvitation:'Send guest user invitations',
  SendInvitationSubjectRequiredSentenceCase: 'A subject is required for the invitation email',
  SendInvite_SentenceCase: 'Send invitation',
  SendInvites: 'Send invitations',
  SendInviteToSubscription: 'Invite to your subscription',
  SendUpdates: 'Send updates',
  SentAgo: 'Sent [time] ago',
  SentDocumentChanged: 'Sent when a document is added or changed',
  SessionTimeout: 'Session timeout',
  SetSecurityControls: 'Set security controls',
  SetSecurityControlsBeforeAddingPeople: 'Set security controls before adding people.',
  SetSubmissionLimits: 'Set submission limits',
  SetSubmissionLimitsDescription:
    'Restrict the number of questions a team can ask per day, week or month',
  ShouldBeEmailEmail: 'The Email you entered is not a valid email address.',
  ShowActivityInReports_SentenceCase: 'Show activity in reports',
  ShowDetails: 'Show details',
  ShowMore: 'Show more',
  Status: 'Status',
  StayOnPage: 'Please stay on this screen while settings are being copied',
  Subject: 'Subject',
  Subject_Email_: 'Subject',
  SubjectEmailHint: 'Email subject is a required field',
  SubjectGroupNotSet: 'Subject group not set',
  SubmissionIndex: 'Submission index',
  SubmissionIndexesSecurity: 'Submission Indexes Security',
  SubmissionIndexTopLevelFoldersSecurity: 'Submission Index top level folders Security',
  Submissions: 'Submissions',
  SubTeam: 'Sub-team',
  SubteamName: 'Sub-team name',
  SubTeamNameAlreadyExists: 'A sub-team with the same name already exists.',
  SubteamNameValidation: 'Sub-team name cannot be empty',
  Success: 'Success',
  Sunday: 'Sunday',
  SwitchTeam_Confirm: 'Yes, switch the team',
  SwitchTeam_Title: 'Switch team',
  SwitchTeam_WarningMessage:
    'It looks like you made some changes. If you leave without saving, your changes will be lost.',
  SydneyTime: 'Sydney time',
  TalkToAnsaradaNow: 'Talk to Ansarada now',
  Team: 'Team',
  TeamColour: 'Team colour – [colour]',
  TeamHistory: 'Team history',
  TeamHistoryFor: 'Team history for',
  TeamMemberEmails: 'Team member emails',
  TeamMemberEmailsInfo: 'Emails of your team members, separated by semicolon or comma. They will all be invited if the registration is accepted.',
  TeamName: 'Team name',
  TeamNameAlreadyExists: 'A team with the same name already exists.',
  TeamNameValidation: 'Team name cannot be empty',
  TeamPermissions: 'Team permissions',
  TenderFreemiumDialogBannerTitle:
    'Billing commences when the first guest is invited.',
  TenderFreemiumDialogContent:
    'Send invites to bid teams and you can go live on the first stage of procurement. You can invite as many bidders as you need.',
  TenderFreemiumDialogSubContent:
    'Ansarada Sales will reach out to your team and help you through this process.',
  TermsAndConditions: 'Terms and Conditions',
  TheSafestWayToEnterEmails:'The safest way to enter email is with copy and paste. Make sure each email address is separated by a comma, semi-colon, space or line-break.',
  ThirdPartyIntegrations_SentenceCase: 'Third Party Integrations',
  ThirdPartyIntegrationsText:'Allow all, some, or none of the third party integrations.',
  ThisSubTeamCannotAccess: 'This sub team cannot access any folders or documents',
  ThisTeamCannotAccess: 'This team cannot access any folders or documents',
  Thursday: 'Thursday',
  Time: 'time',
  TimePeriodAm: 'AM',
  TimePeriodPm: 'PM',
  To: 'To:',
  ToBeAbleToInvitePeopleToYourDataRoom:'to be able to invite people to your data room.',
  Today: 'Today',
  ToInviteFirstGuestAndGoLive:'to invite your first guest user and go live',
  Topic: '[topics] topic',
  Topics: '[topics] topics',
  Total: 'Total',
  Tuesday: 'Tuesday',
  Unknown: 'Unknown',
  Up: 'Up',
  Update: 'Update',
  UserCanLogInFromAnyIp: 'This User can log in from any IP address',
  UserCanLogInOnlyFromIpsBelow: 'This User can log in ONLY from the IP addresses specified below',
  UserExistsWithEmail: 'User exists with email [0]',
  UserGroup: 'User group',
  UserInvitationLinkNote_Gen2:
    'The "[invitation_link]" tag is replaced with a generated hyperlink when the email is sent. It must be in the body of the email.',
  UserInvitationLinkTagIsMissing:
    'An invitation email cannot be sent without an access link. Copy [invitation_link] with brackets and add it into the message for the recipient to create their access.',
  UserProperties: 'User Properties',
  UserStatus: 'User status',
  UserType: 'User type',
  UserTypeError: 'Room role is required',
  UserUpdated: 'People were updated successfully.',
  Verified: 'Verified',
  Verified_Users_: 'Verified',
  VerifyYourEmail:'Verify your email',
  VerifyYourEmailAddressToInviteUsers: 'Verify your email address to invite users.',
  View: 'View',
  ViewDetails: 'View details',
  ViewIndexAsGhost: 'What can they view?',
  ViewSecurity: 'View security',
  Warning: 'Warning',
  Watermark: 'Watermark',
  Wednesday: 'Wednesday',
  Weekly: 'Weekly',
  WeHaveSentTheVerificationLinkTo:"We've sent the verification link to [email]",
  Yes: 'Yes',
  YouCanAddDescriptionsAfterComma:
    'You can also add descriptions after comma, e.g. <b>1.1.1.1, London office IP</b>',
  YourCurrentIpAddress:
    '<b>Note:</b> Your current IP address is [IP Address]. <a class="ace-link" [href]>Click here</a> to add it to the list.',
  YourCurrentIpAddressSec2: 'Add your current IP address to the whitelist.',
  YouWillReceiveAConfirmationInYourEmailAboutNextSteps:
    "You'll receive a confirmation in your email about next steps.",
};
