// @flow

export const INTERVAL_TYPE = Object.freeze({
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
});

export const WEEKDAY = Object.freeze({
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
});

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const STATUS = Object.freeze({
  ON: 'ON',
  OFF: 'OFF',
});

export const PERIOD = Object.freeze({
  AM: 'AM',
  PM: 'PM',
});
