// @flow

import { ACTION_TYPES } from 'app/constants';
import type { LoadingBarType } from './loadingBarTypes';

const initialState: LoadingBarType = {
  value: 0,
  loading: false,
};
const loadingBarReducer = (
  state?: LoadingBarType = initialState,
  action: Object,
): LoadingBarType => {
  switch (action.type) {
    case ACTION_TYPES.LOADINGBAR_LOADING_TOGGLE: {
      const isPageLoading = !state.loading;
      if (isPageLoading) {
        return {
          value: 1,
          loading: true,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }

    case ACTION_TYPES.LOADINGBAR_VALUE_UPDATE: {
      return {
        ...state,
        value: action.data,
      };
    }

    default:
      return state;
  }
};

export default loadingBarReducer;
