// @flow

import { openWatermarkPage } from 'app/pages/security/securityActions';
import type { SessionType } from 'app/types/globalTypes';
import * as React from 'react';
import { Button, Icon, icon, DropdownAction, DropdownGroup, Tooltip } from '@ansarada/ace-react';
import Dropdown from 'app/components/dropdown/Dropdown';
import translate from 'app/i18n/translate';
import type { PermissionsType } from 'app/types/permissionsTypes';
import type { WatermarkType } from 'app/pages/security/securityTypes';
import styles from './watermarks.scss';

type WatermarkPropType = {
  documentIndexItemId: string,
  subteamId: number,
  value: number,
  watermarks: Array<WatermarkType>,
  permissions: PermissionsType,
  session: SessionType,
  setSecuritySettings: Function,
};

export const Trigger = (props: { watermark: ?WatermarkType }) => {
  const { watermark, ...rest } = props;
  const text = watermark ? watermark.name : translate('None');
  const iconGlyph = watermark ? icon.Glyphs.StatusYes : icon.Glyphs.StatusNo;

  return (
    <Tooltip id={text} defaultOpen={false} text={text}>
      <Button
        size="Small"
        variant="Ghost"
        data-test-id="securityWatermark"
        icon={<Icon glyph={iconGlyph} size="Medium" />}
        {...rest}
        data-ansarada-ccd
      >
        {text}
      </Button>
    </Tooltip>
  );
};

class Watermarks extends React.Component<WatermarkPropType> {
  shouldComponentUpdate(nextProps: WatermarkPropType) {
    return (
      this.props.value !== nextProps.value ||
      this.props.documentIndexItemId !== nextProps.documentIndexItemId ||
      this.props.watermarks.length !== nextProps.watermarks.length ||
      this.props.subteamId !== nextProps.subteamId ||
      this.props.session.customWatermarksEnabled !== nextProps.session.customWatermarksEnabled ||
      this.props.session.secureWatermarksEnabled !== nextProps.session.secureWatermarksEnabled
    );
  }

  render() {
    const {
      documentIndexItemId,
      subteamId,
      watermarks,
      value,
      permissions,
      session,
      setSecuritySettings,
    } = this.props;

    const uniqueId = `${documentIndexItemId}-${subteamId}`;
    const selectedWatermark = watermarks.find(s => s.id === value);
    const onUpdate = (newWatermark: ?number) => {
      setSecuritySettings({
        documentIndexItemId,
        subteamId,
        value: newWatermark,
        attr: 'watermarkId',
      });
    };

    return (
      <Dropdown
        trigger={
          <Trigger
            className={`${styles.button} ace-button-minimal button-legacy-support transition-blur`}
            disabled={!session.secureWatermarksEnabled || !permissions.watermark.modify}
            key={`watermark-trigger-${uniqueId}`}
            watermark={selectedWatermark}
          />
        }
      >
        <DropdownGroup>
          {watermarks.map(watermark => (
            <DropdownAction
              key={watermark.id}
              onClick={() => {
                if (value !== watermark.id) onUpdate(watermark.id);
              }}
              data-ansarada-ccd
            >
              {watermark.name}
            </DropdownAction>
          ))}

          <DropdownAction
            onClick={() => {
              if (value !== null) onUpdate(null);
            }}
          >
            {translate('None')}
          </DropdownAction>
        </DropdownGroup>

        {session.customWatermarksEnabled && (
          <DropdownGroup>
            <DropdownAction onClick={() => openWatermarkPage(session)}>
              {translate('CreateWatermark')}
            </DropdownAction>
          </DropdownGroup>
        )}
      </Dropdown>
    );
  }
}

export default Watermarks;
