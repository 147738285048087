// prettier-ignore

export default {
  Access: 'Accès',
  Actions: 'Actions',
  AdminSideTender_InviteHeading: 'Send invites to client',
  All: 'Tous',
  Attachments: 'Fichiers joints',
  BatchOperationCantBePerformed: 'L’opération de lots ne peut être accomplie car il n’y a aucun utilisateur dans cette Data-room. Merci de saisir les utilisateurs en premier.',
  Cancel: 'Annuler',
  Change: 'Modifier',
  Close: 'Fermer',
  Colour: 'Couleur :',
  DataRooms: 'Data-room',
  Delete: 'Supprimer',
  Document: 'Document',
  DocumentIndex: 'Index du document',
  Documents: 'Documents',
  Edit: 'Modifier',
  EditMultipleUsers: 'Editer plusieurs utilisateurs',
  EditProfile: 'Modification du profil',
  Email: 'Adresse électronique',
  EnterIpAddressesRanges: 'Saisir les adresses IP ou les plages d’adresses IP séparées par la touche Entrée',
  FileExtensionIsInList: 'Le fichier "[FileName]" ne peut être téléchargé, son extension est incluse dans la liste des extensions de fichiers non autorisées pour cette Data-Room :                        \r\n\r\n[FileTypeList]\r\n\r\nVeuillez convertir le fichier dans l’un des formats autorisés.',
  FileExtensionIsNotInList: 'Le fichier "[FileName]" ne peut être téléchargé, son extension ne figure pas dans la liste des extensions de fichiers autorisées pour cette Data-Room :                        \r\n\r\n[FileTypeList]\r\n\r\nVeuillez convertir le fichier dans l’un des formats autorisés.',
  Filter: 'Filtre',
  Folder: 'Dossier',
  ForRangesSeparateStartIp: 'Pour les plages, séparez l’IP deq début et l’IP de fin par un tiret . ex : <B>1.1.1.1-1.1.6</b>',
  From: 'Depuis :',
  FullName: 'Nom complet',
  Help: 'Aide',
  Importance: 'Importance :',
  InvalidEmailAddress: 'Adresse électronique non valide',
  InvalidPdf: 'ERREUR : ce fichier n’est pas un document PDF valide. Merci d’essayer avec un autre fichier .',
  Invited_Users_: 'Invité',
  IpAddressInvalidIpAccessList: "L’adresse IP '[IpAddress]' dans la liste d’accès IP n’est pas une adresse IP correcte.",
  IpAddressInvalidRangeIpAccessList: "La plage d’adresses IP '[IpRange]' dans la liste d’accès IP dispose d’une adresse IP de début supérieure à l’adresse IP de fin. L’adresse IP de début doit être inférieure ou égale à l’adresse IP de fin.",
  IpLimitRangeIpAccessList: "Veuillez limiter la description de la plage d’adresses IP '[IpRange]' dans la liste d’accès IP à [NumOfChars] caractères. Vous avez utilisé [UsedNumOfChars] caractères.",
  IpUserAccessListLimit: 'Merci de limiter la liste d’accès IP à [MaxNumberOfCharacters] caractères. Vous avez entré [EnteredNumberOfCharacters] caractères.',
  LastMonth: 'Le mois dernier',
  LoadingThreeDots: 'Chargement…',
  Logout: 'Se déconnecter',
  Manage: 'Gérer',
  Message: 'Message :',
  Never: 'Jamais',
  No: 'Non',
  None: 'Aucun',
  NoRecordsFound: 'Pas de résultats.',
  Off: 'Off',
  OkAllCaps: 'OK',
  OnEveryLogin: 'A chaque connexion',
  Or: 'ou',
  PrintingDenied: 'Impression non autorisée',
  QnaRole: 'Rôle Q/R',
  RecipientsOfEmailNote_Gen2: 'les destinataires de ce <nowrap>e-mail</nowrap> ne pourront PAS voir leurs adresses respectables, donc il est possible de l’envoyer aux utilisateurs des différents groupes d’utilisateurs sans risque.',
  Reset: 'Réinitialiser',
  Save: 'Enregistrer',
  SavingDenied: 'Enregistrement non autorisé',
  Search: 'Rechercher',
  Security: 'Sécurité',
  Select: 'Sélectionner',
  SelectUserType: 'Veuillez sélectionner le type d’utilisateurs.',
  Send: 'Envoyer',
  ShouldBeEmailEmail: 'L’adresse électronique saisie n’est pas une adresse électronique valable.',
  Status: 'Etat',
  To: 'A :',
  Today: 'Aujourd’hui',
  UserCanLogInFromAnyIp: 'Cet utilisateur peut se connecter à partir de n’importe quelle adresse IP',
  UserCanLogInOnlyFromIpsBelow: 'Cet utilisateur ne peut se connecter QU’A PARTIR des adresses IP spécifiées ci-dessous',
  UserGroup: 'Groupe d’utilisateurs',
  UserProperties: 'Propriétés utilisateur',
  Verified_Users_: 'Vérifié',
  View: 'Lecture',
  Watermark: 'Filigrane',
  Yes: 'Oui',
  YouCanAddDescriptionsAfterComma: 'Vous pouvez également ajouter des descriptions après une virgule, ex : <b>1.1.1.1, London office IP</b>',
  YourCurrentIpAddress: '<B>IMPORTANT :</b> votre adresse IP est [IP Address]. <a [href]> Cliquez ici</a> pour l’ajouter à la liste.',
};
