// @flow

import axios from 'axios';
import { parseResult } from 'app/utils/parsing';
import { getAppError, SPA_ERROR } from 'app/utils/error';
import { HTTP_METHODS } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';

const CONTENT_TYPES = Object.freeze({
  JSON: 'application/json',
  TEXT: 'text/plain',
});

type RequestConfig = {
  credentials: string,
  headers: {
    'X-CSRequested-By'?: number,
    'X-Session-DataRoomId'?: number,
    'X-Session-UserId'?: number,
    'X-HTTP-Method-Override'?: 'GET' | 'PUT' | 'DELETE' | 'PATCH',
    'Content-Type'?: $Values<typeof CONTENT_TYPES>,
  },
};

export const makeConfig = (
  url: string,
  session: ?SessionType,
  config: ?RequestConfig,
  data: ?FormData,
): RequestConfig => {
  const s = !session
    ? {}
    : {
        'X-CSRequested-By': session.userAccessLogId,
        'X-Session-DataRoomId': session.dataroomId,
        'X-Session-UserId': session.userId,
      };
  const headers = {
    'Content-Type': CONTENT_TYPES.JSON,
    ...s,
    ...(config && config.headers ? config.headers : {}),
  };

  return {
    url,
    credentials: 'same-origin',
    data,
    ...(config || {}),
    headers,
  };
};

export const getUrlAndConfigs = (url: string, config: ?RequestConfig) => {
  // 2000 is the magic number of url length limit (2136?).
  // This number is also used in both ng-ansarada and services api.
  if (url.length <= 2000) {
    return {
      url,
      config,
    };
  }
  const strs = url.split('?');
  if (strs.length < 2) {
    return {
      url,
      config,
    };
  }
  return {
    url: strs[0],
    config: {
      method: HTTP_METHODS.POST,
      data: strs[1],
      ...(config || {}),
      headers: {
        ...(config && config.headers ? config.headers : {}),
        'X-HTTP-Method-Override': HTTP_METHODS.GET,
        'Content-Type': CONTENT_TYPES.TEXT,
      },
    },
  };
};

export const request = (
  url: string,
  session: ?SessionType,
  config: ?RequestConfig,
  fn?: (*) => *,
  data: ?FormData,
  handleErrors?: Object => Object = response => response,
): Promise<*> => {
  const rConfigs = getUrlAndConfigs(url, config);
  const c = makeConfig(rConfigs.url, session, rConfigs.config, data);
  const requestFn = fn || axios;

  return requestFn(c)
    .then((response: Object) => {
      const responseErrorsHandled = handleErrors(response);
      const responseParsed = parseResult(responseErrorsHandled);
      return responseParsed;
    })
    .catch(error => {
      if (error.name === SPA_ERROR) {
        throw error;
      }
      getAppError(error.response);
    });
};
