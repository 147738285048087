// @flow
import {
  updateSelectedStatus,
  getFromId,
} from 'app/pages/security/copySecurityDialog/copySecurityDialogHelpers';
import { ACTION_TYPES, COPY_SECURITY_STATUSES, COPY_SECURITY_ACTIONS } from 'app/constants';
import type { CopySecurityDialogType } from 'app/pages/security/securityTypes';

const initialState: CopySecurityDialogType = {
  nextAction: COPY_SECURITY_ACTIONS.REVIEW,
  copyOptions: {
    submissionIndexSecuritySettings: true,
    qnaSubjectSecuritySettings: true,
    documentIndexSecuritySettings: true,
  },
  from: null,
  to: [],
};
export const copySecurityDialogReducer = (
  state: CopySecurityDialogType = initialState,
  action: Object,
): CopySecurityDialogType => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.COPY_FORM_DISMISS:
      return initialState;

    case ACTION_TYPES.SECURITY.COPY_FORM_UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case ACTION_TYPES.SECURITY.COPY_FORM_CONFIRM:
      return {
        ...state,
        nextAction: COPY_SECURITY_ACTIONS.DISMISS,
      };
    case ACTION_TYPES.SECURITY.COPY_FORM_STATUS_UPDATE: {
      const { id, status } = action.data;
      const to = state.to.map(team => {
        if (team.id === id) {
          return {
            ...team,
            status,
          };
        }
        return team;
      });
      return {
        ...state,
        to,
      };
    }
    case ACTION_TYPES.SECURITY.COPY_FORM_UPDATE_OPTIONS: {
      const { optionType } = action.data;

      switch (optionType) {
        case 'submissionIndexSecuritySettings':
          return {
            ...state,
            copyOptions: {
              ...state.copyOptions,
              submissionIndexSecuritySettings: !state.copyOptions.submissionIndexSecuritySettings,
            },
          };
        case 'qnaSubjectSecuritySettings':
          return {
            ...state,
            copyOptions: {
              ...state.copyOptions,
              qnaSubjectSecuritySettings: !state.copyOptions.qnaSubjectSecuritySettings,
            },
          };

        case 'documentIndexSecuritySettings':
          return {
            ...state,
            copyOptions: {
              ...state.copyOptions,
              documentIndexSecuritySettings: !state.copyOptions.documentIndexSecuritySettings,
            },
          };
        default:
          return state;
      }
    }
    case ACTION_TYPES.SECURITY.COPY_FORM_SELECT: {
      if (!state.from) return state;
      const id = action.data;
      const fromId = getFromId(state.from);
      return {
        ...state,
        to: updateSelectedStatus(id, fromId, [...state.to]),
      };
    }
    case ACTION_TYPES.SECURITY.COPY_FORM_REVIEW: {
      const to = state.to.reduce((acc, team) => {
        const subTeams = [];
        if (team.subteams) {
          team.subteams.forEach(subteam => {
            if (subteam.selected) {
              subTeams.push({
                id: subteam.id,
                name: `${team.name}: ${subteam.name}`,
                status: COPY_SECURITY_STATUSES.QUEUED,
                colour: team.colour,
                selected: true,
                isTeam: false,
                subteams: null,
              });
            }
          }, []);
        }
        if (team.selected) {
          acc.push({
            id: team.id,
            name: team.name,
            status: COPY_SECURITY_STATUSES.QUEUED,
            colour: team.colour,
            selected: true,
            isTeam: true,
            subteams: null,
          });
        }
        return acc.concat(subTeams);
      }, []);
      return {
        ...state,
        to,
        nextAction: COPY_SECURITY_ACTIONS.CONFIRM,
      };
    }
    default:
      return state;
  }
};

export default copySecurityDialogReducer;
