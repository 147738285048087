// @flow

import { HTTP_METHODS } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';
import axios from 'axios';
import { handleHttpError } from './handleHttpError';
import { parseResult } from './parsing';

const CONTENT_TYPES = Object.freeze({
  JSON: 'application/json',
  TEXT: 'text/plain',
  FORM: 'multipart/form-data',
});

export type RequestConfig = {
  headers?: {
    'X-CSRequested-By'?: number,
    'X-Session-DataRoomId'?: number,
    'X-Session-UserId'?: number,
    'X-HTTP-Method-Override'?: 'GET' | 'PUT' | 'DELETE' | 'PATCH',
    'Content-Type'?: $Values<typeof CONTENT_TYPES>,
  },
};

type AxiosConfig = RequestConfig & {
  url: string,
  method: $Values<typeof HTTP_METHODS>,
};

const methodOverride = (config: AxiosConfig): AxiosConfig => {
  // 2000 is the magic number of url length limit (2136?).
  // This number is also used in both ng-ansarada and services api.
  if (config.url.length > 2000) {
    const strs = config.url.split('?');
    if (strs.length >= 2) {
      return {
        ...config,
        url: strs[0],
        data: strs[1],
        method: HTTP_METHODS.POST,
        headers: {
          'X-HTTP-Method-Override': HTTP_METHODS.GET,
          'Content-Type': CONTENT_TYPES.TEXT,
          ...(config.headers || {}),
        },
      };
    }
  }
  return config;
};

const attachSession = (config: AxiosConfig, session: SessionType): AxiosConfig => {
  const headers = Object.assign(
    {},
    {
      'Content-Type': CONTENT_TYPES.JSON,
      'X-CSRequested-By': session.userAccessLogId,
      'X-Session-DataRoomId': session.dataroomId,
      'X-Session-UserId': session.userId,
    },
    config.headers,
  );
  return {
    ...config,
    credentials: 'same-origin',
    headers,
  };
};

const request = (
  session: SessionType,
  url: string,
  method: $Values<typeof HTTP_METHODS>,
  data?: Object | Array<Object>,
  config: RequestConfig = {},
): Promise<*> => {
  const axiosConfig = attachSession(
    methodOverride({
      url,
      method,
      data,
      ...config,
    }),
    session,
  );
  return axios(axiosConfig).then(parseResult, handleHttpError);
};

export default request;
