// @flow

import * as uuid from 'uuid';
import translate from 'app/i18n/translate';
import { BANNER_MESSAGE_TYPES } from 'app/components/bannerMessage/bannerMessageTypes';
import { ACTION_TYPES } from 'app/constants';
import type { Error } from 'app/types/errorTypes';
import { takeEvery, put } from 'redux-saga/effects';

type DismissAction = {|
  type: string,
  data: string,
|};

export function* dismiss(action: DismissAction): Generator<*, *, *> {
  const { data } = action;
  // const bannerMessages = yield select(s => s.notifications.bannerMessages);
  // select from state.notifications and log details to NewRelic first, then dismiss
  yield put({
    type: ACTION_TYPES.BANNER_MESSAGES.ITEM_DISMISSED,
    data,
  });
}

export const getErrorArray = (details: *) => {
  if (Array.isArray(details)) {
    return details.map(error => ({
      id: error.id,
      type: BANNER_MESSAGE_TYPES.ERROR,
      timeStamp: Date.now(),
      content: {
        code: error.code,
        description: error.description,
      },
    }));
  }

  const description = details
    ? translate('GlobalUnexpectedErrorWithIdMessage', { ErrorId: details })
    : translate('GlobalUnexpectedErrorMessage');

  return [
    {
      id: `server-${uuid.v1()}`,
      type: BANNER_MESSAGE_TYPES.ERROR,
      timeStamp: Date.now(),
      content: {
        code: '',
        description,
      },
    },
  ];
};

type AddServerErrorAction = {|
  data: Error<*>,
|};

export function* addServerError(action: AddServerErrorAction): Generator<*, *, *> {
  const { data } = action;
  const { details } = data;
  const errors = getErrorArray(details);
  yield put({
    type: ACTION_TYPES.BANNER_MESSAGES.SERVER_ERRORS_ADD,
    data: errors,
  });
}

export const bannerMessagesSagas = [
  takeEvery(ACTION_TYPES.BANNER_MESSAGES.ITEM_DISMISS, dismiss),
  takeEvery(ACTION_TYPES.BANNER_MESSAGES.SERVER_ERRORS_GENERATE, addServerError),
];
