// @flow

import { window } from 'app/config';
import type { SessionType } from 'app/types/globalTypes';
import * as url from 'app/uri/url';
import * as R from 'ramda';
import { ACTION_TYPES, SECURITY_DIALOG_TYPES } from 'app/constants';
import type { EditableAttributeList } from './helpers/treeHelpers';

type folderToggledDispatchType = {
  type: string,
  data: Array<number>,
};

export const folderToggled = (data: number, expanded: Array<number>): Array<number> =>
  expanded.includes(data) ? [...expanded.filter(x => x !== data)] : [...expanded, data];

export const collapseParentFolder = (
  data: number,
  expanded: Array<number>,
): folderToggledDispatchType => {
  const newExpanded = folderToggled(data, expanded);
  return { type: ACTION_TYPES.SECURITY.FOLDER_TOGGLED, data: newExpanded };
};

export const collapseChildrenFolder = (
  data: Array<number>,
  expanded: Array<number>,
): folderToggledDispatchType => {
  const newExpanded = R.difference(R.union(data, expanded), R.intersection(data, expanded));
  return { type: ACTION_TYPES.SECURITY.FOLDER_TOGGLED, data: newExpanded };
};

export const expandFolder = (folderId: number) => ({
  type: ACTION_TYPES.SECURITY.FOLDER_TOGGLE,
  data: folderId,
});

export const openWatermarkPage = (session: SessionType) => {
  window.open(url.generateLegacyURL('editwatermark.asp', session), '_blank');
};

export const setDialogType = (dialogType: $Values<typeof SECURITY_DIALOG_TYPES>) => ({
  type: ACTION_TYPES.SECURITY.DIALOG_TYPE,
  data: dialogType,
});

export const setSecuritySettings = (data: {
  documentIndexItemId: string,
  subteamId: string,
  value: boolean | ?number,
  attr: EditableAttributeList,
}) => ({
  type: ACTION_TYPES.SECURITY.SETTINGS_UPDATE,
  data,
});

export const setItemSecuritySettings = (data: {
  subteamId: string,
  value: boolean | ?number,
  attr: EditableAttributeList,
}) => ({
  type: ACTION_TYPES.SECURITY.ITEM_SETTINGS_UPDATE,
  data,
});
