// @flow

import { getJSONFromScript } from './utils/dom';

let configCache;

type Config = {
  bundleBasePath: string,
  basename: string,
};

export const config = (): Config => {
  if (configCache) return configCache;

  const data = getJSONFromScript('ansarada-react-config');

  const conf = {
    bundleBasePath: '',
    basename: '',
    ...data,
  };

  configCache = conf;
  return conf;
};

// Used to test this singleton
export const reset = () => {
  configCache = null;
};

// Re-export some globals so we can mock them in tests
export const { document } = global.window;
export const { window } = global;
export const { navigator } = global.window;
export const { Date } = global;
