"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = void 0;
var constants_1 = require("app/constants");
var intialValues = {
    isAllowInviteGuest: true,
    isAllowEnableGuestAccess: true,
};
var featuresReducer = function (state, action) {
    if (state === void 0) { state = intialValues; }
    if (action.type === constants_1.ACTION_TYPES.DATAROOM_FEATURES_SET_DATA) {
        return __assign(__assign({}, state), action.payload);
    }
    return state;
};
var setFeaturesData = function (payload) { return ({
    type: constants_1.ACTION_TYPES.DATAROOM_FEATURES_SET_DATA,
    payload: payload,
}); };
var fetchFeaturesData = function (dispatch) {
    return dispatch({
        type: constants_1.ACTION_TYPES.FETCH_DATAROOM_FEATURES,
    });
};
exports.actions = { setFeaturesData: setFeaturesData, fetchFeaturesData: fetchFeaturesData };
exports.default = featuresReducer;
