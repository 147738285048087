// @flow

import { ACTION_TYPES } from 'app/constants';
import { INTERVAL_TYPE, WEEKDAY } from './constants';
import type { NotificationState } from './notificationTypes';

const defaultNotificationState: NotificationState = {
  hasChanged: false,
  viewOnly: true,
  isLoaded: false,
  documentSetting: {
    status: 'OFF',
    intervalType: INTERVAL_TYPE.HOURLY,
    weekday: WEEKDAY.MONDAY,
    hour: 12,
    period: 'AM',
  },
  qnaSetting: {
    status: 'ON',
  },
};

const notificationReducer = (
  state: NotificationState = defaultNotificationState,
  action: Object,
): NotificationState => {
  switch (action.type) {
    case ACTION_TYPES.NOTIFICATION.ENABLE_DOCUMENT:
      return {
        ...state,
        hasChanged: true,
        viewOnly: false,
        documentSetting: {
          ...state.documentSetting,
          status: 'ON',
        },
      };
    case ACTION_TYPES.NOTIFICATION.DISABLE_DOCUMENT:
      return {
        ...state,
        hasChanged: true,
        viewOnly: false,
        documentSetting: {
          ...state.documentSetting,
          status: 'OFF',
        },
      };
    case ACTION_TYPES.NOTIFICATION.SHOW_DOCUMENT_SELECTOR:
      return {
        ...state,
        viewOnly: false,
      };
    case ACTION_TYPES.NOTIFICATION.SET_DOCUMENT_SETTING:
      return {
        ...state,
        hasChanged: true,
        documentSetting: {
          ...state.documentSetting,
          ...action.payload,
        },
      };
    case ACTION_TYPES.NOTIFICATION.ENABLE_QNA:
      return {
        ...state,
        hasChanged: true,
        qnaSetting: {
          status: 'ON',
        },
      };
    case ACTION_TYPES.NOTIFICATION.DISABLE_QNA:
      return {
        ...state,
        hasChanged: true,
        qnaSetting: {
          status: 'OFF',
        },
      };
    case ACTION_TYPES.NOTIFICATION.SET_SETTING:
      return {
        ...state,
        hasChanged: false,
        viewOnly: true,
        isLoaded: true,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default notificationReducer;
