// @flow

import produce from 'immer';
import type { ToastMessagesState, ToastMessagesActions } from './types.js';

const defaultState: ToastMessagesState = {
  toasts: [],
};

const ToastMessagesReducer = (
  state: ToastMessagesState = defaultState,
  action: ToastMessagesActions,
) => {
  return produce(state, draft => {
    switch (action.type) {
      case 'TOAST_MESSAGE:ADD':
        draft.toasts.push(action.data);
        break;
      case 'TOAST_MESSAGE:REMOVE': {
        const index = draft.toasts.findIndex(t => t.id === action.data.id);

        if (index > -1) {
          draft.toasts.splice(index, 1);
        }
        break;
      }
      case 'TOAST_MESSAGE:UPDATE': {
        const index = draft.toasts.findIndex(t => t.id === action.data.id);

        if (index > -1) {
          draft.toasts.splice(index, 1, action.data);
        }

        break;
      }

      default:
        break;
    }
  });
};

export default ToastMessagesReducer;
