// @flow

// prettier-ignore

export default {
  Accept: 'Akzeptieren',
  AcceptRegistration: 'Registrierung akzeptieren',
  AcceptTermsOfAccess_SentenceCase: 'Akzeptieren Sie die Zugangsbedingungen',
  Access: 'Zugriff',
  AccessAllowed: 'Zugriff erlaubt',
  AccessDenied: 'Zugriff verweigert',
  AccessedAgo: 'Auf [time] zugegriffen',
  Actions: 'Aktionen',
  Add: 'Hinzufügen',
  AddAndInvite: 'Hinzufügen und einladen',
  AddAndInvitePeople: 'Personen hinzufügen und einladen',
  AdditionalRecipients: 'Weitere Empfänger',
  AdditionalRecipients_SentenceCase: 'Weitere Empfänger',
  AdditionalRecipientsHint: 'Geben Sie jede E-Mail-Adresse in einer separaten Zeile ein',
  AddPeople: 'Leute hinzufügen',
  AddPeopleTo: 'Personen hinzufügen zu',
  AddPeopleToSubteam: 'Personen zu [Team]/[Subteam] hinzufügen',
  AddPeopleToTeam: 'Personen zu [Team] hinzufügen',
  AddPeopleWillHaveTheSameAccess: 'Eingeschlossene Personen haben den gleichen Zugriff',
  AddToExistingTeam: 'Zu vorhandenem Team hinzufügen',
  Administration: 'Verwaltung',
  AdminSideAudit: 'Teams',
  AdminSideBankruptcy: 'Sell-Side',
  AdminSideBankruptcy_EmailHeading: 'E-Mail an sell side',
  AdminSideBankruptcy_InviteHeading: 'Senden Sie Einladungen an Mitarbeiter der sell side',
  AdminSideDebt: 'Aussteller',
  AdminSideDebt_EmailHeading: 'E-Mail-Aussteller',
  AdminSideDebt_InviteHeading: 'Senden Sie Einladungen an Aussteller',
  AdminSideDocumentRepository: 'Sell-Side',
  AdminSideDocumentRepository_EmailHeading: 'E-Mail-Interna',
  AdminSideDocumentRepository_InviteHeading: 'Senden Sie Einladungen an interne Mitarbeiter',
  AdminSideFundraising: 'Sell-Side',
  AdminSideFundraising_EmailHeading: 'E-Mail-Client',
  AdminSideFundraising_InviteHeading: 'Senden Sie Einladungen an den Kunden',
  AdminSideInternalAudit: 'Teams',
  AdminSideJointVenture: 'Sell-Side',
  AdminSideJointVenture_EmailHeading: 'E-Mail-Interna',
  AdminSideJointVenture_InviteHeading: 'Senden Sie Einladungen an interne Mitarbeiter',
  AdminSideMerger: 'Sell-Side',
  AdminSideMerger_EmailHeading: 'E-Mail an sell side',
  AdminSideMerger_InviteHeading: 'Senden Sie Einladungen an Mitarbeiter der sell side',
  AdminSideOther: 'Sell-Side',
  AdminSideOther_EmailHeading: 'E-Mail-Interna',
  AdminSideOther_InviteHeading: 'Senden Sie Einladungen an interne Mitarbeiter',
  AdminSidePlacement: 'Sell-Side',
  AdminSidePlacement_EmailHeading: 'E-Mail-Aussteller',
  AdminSidePlacement_InviteHeading: 'Senden Sie Einladungen an Aussteller',
  AdminSidePublicOffer: 'Sell-Side',
  AdminSidePublicOffer_EmailHeading: 'E-Mail-Interna',
  AdminSidePublicOffer_InviteHeading: 'Senden Sie Einladungen an interne Mitarbeiter',
  AdminSideTargeted: 'Sell-Side',
  AdminSideTargeted_EmailHeading: 'E-Mail an das interne Team',
  AdminSideTargeted_InviteHeading: 'Senden Sie Einladungen an das interne Team',
  AdminSideTax: 'Teams',
  AdminSideTender: 'Sell-Side',
  AdminSideTender_EmailHeading: 'E-Mail-Client',
  AdminSideTender_InviteHeading: 'Send invites to client',
  All: 'Alle',
  Allow: 'Ermöglichen',
  Allowed: 'Ermöglicht',
  AlwaysInviteGuestDialogContent:
    'Sie müssen Ansarada kontaktieren, um Pläne zu ändern. Keine Sorge, Sie werden in kürzester Zeit Einladungen an Externe senden.',
  And_SentenceCase: 'und',
  AndGoLive: 'und live gehen',
  AreYourReadyToGoLive: 'Sind Sie bereit, online zu live gehen?',
  AreYouSureToDeclineRegistration: 'Sind Sie sicher, dass Sie den Registrator [registrant] ablehnen wollen?',
  AreYouSureToDeleteRegistration: 'Sind Sie sicher, dass Sie den Registrator [registrant] löschen wollen?',
  AreYouSureToDeleteRequest: 'Sind Sie sicher, dass Sie die Anforderung [registrant] löschen wollen?',
  At: 'Bei',
  Attachments: 'Anlagen',
  BackToTeamsPage: 'Zurück zur Teamsseite',
  BannerMessage: "Team erstellt mit den Einstellungen von [TeamName]",
  BatchOperationCantBePerformed:
'Die Massenaktion kann nicht durchgeführt werden, da in diesem Datenraum keine Benutzer vorhanden sind. Bitte legen Sie zunächst Benutzer an.',
  BeforeYouCanEmail:'Bevor Sie [teamType] per E-Mail senden können, müssen Sie Ihre E-Mail-Adresse bestätigen.',
  BeforeYouCanGoLive: 'Bevor Sie live gehen können',
  BeforeYouCanSendInvites:'Bevor Sie Einladungen an [teamType] senden können, müssen Sie Ihre E-Mail-Adresse bestätigen.',
  BillingCommencesWhenTheFirstGuestIsInvited:'Die Abrechnung beginnt mit der Einladung des ersten Gastes.',
  BulkDownload_SentenceCase: 'Sammeldownload',
  BulkDownloadText:
    'Ermöglicht Personen, mehrere Dateien in einer einzigen ZIP-Datei herunterzuladen. ZIP-Downloads sind nur für eine begrenzte Zeit verfügbar.',
  BuySide: 'Buy Side',
  BuySide_InviteHeading: 'Senden Sie Einladungen an Personen auf der buy side',
  BuySide_SentenceCase: 'Buy-Side',
  BySubmittingThisForm: 'Mit dem Absenden dieses Formulars akzeptieren Sie unsere',
  Cancel: 'Abbrechen',
  Change: 'Ändern',
  ChangeTeamAccess: 'Teamzugriff ändern',
  ChooseLanguage: 'Sprache auswählen',
  ClickAndPreview: '2. Klicken Sie auf und zeigen Sie eine Vorschau an',
  Close: 'Schließen',
  CollapseAll_SentenceCase: 'Alles schließen',
  Colour: 'Farbe:',
  Company: 'Unternehmen',
  Confirm: 'Bestätigen',
  ContactUs: 'Kontaktieren Sie uns',
  Copied: 'Kopiert',
  CopiedSharedLinkInfo: 'Teilen Sie den Link mit externen Bieternteams oder Gästen, damit diese sich registrieren können.',
  CopyEmailsOfSelectedUsers: 'Kopieren Sie E-Mails ausgewählter Benutzer',
  CopyEmailsOfSelectedUsersSec2: 'Ausgewählte E-Mails kopieren',
  CopyingAllowed: 'Kopieren erlaubt',
  CopyingDenied: 'Kopieren verweigert',
  CopyingSecuritySettings: 'Kopieren von Sicherheitseinstellungen',
  CopyLink: 'Link kopieren',
  CopyOf: 'Kopie von',
  CopyRegistrationLink: 'Registrierungslink kopieren',
  CopySecurity: 'Kopiersicherheit',
  CopySharedLinkToClipboard: 'Link in die Zwischenablage kopieren',
  CopyTeam: 'Team kopieren',
  CreateArchive: 'Archiv erstellen',
  CreateNewTeam: 'Neues Team erstellen',
  CreatePeopleWithSameEmail: 'Erstellen Sie Personen, die dieselbe E-Mail-Adresse wie eine vorhandene Person haben',
  CreateSideTeam: 'Erstellen Sie ein [Side]-Team',
  CreateSubteam: 'Unterteam erstellen',
  CreateTeam: 'Team erstellen',
  CreateTeamEmptyState: 'Erstellen Sie ein Team, um Personen zu organisieren und den Zugriff einzurichten',
  CreateWatermark: 'Wasserzeichen erstellen',
  Daily: 'täglich',
  DataRoom: 'Datenraum',
  DataroomRole: 'Raumrolle',
  DataRooms: 'Datenräume',
  DataRoomStatus: 'Datenraumstatus',
  DataRoomUserNotVerified1: 'So greifen Sie auf den Datenraum zu:',
  DataRoomUserNotVerified2: 'Öffnen Sie Ihre Einladungs-E-Mail',
  DataRoomUserNotVerified3: 'Klicken Sie auf den Link „Bestätigen Sie Ihre Details“.',
  DataRoomUserNotVerified4: 'Anmelden',
  DataRoomUserNotVerified5:
    "Wenn Sie keine Einladungsmail erhalten haben, wenden Sie sich bitte an Ihren Datenraum-Administrator.",
  DataRoomUserNotVerifiedTitle: "Ihr Zugang ist nicht bestätigt",
  DateModified: 'Änderungsdatum',
  DateSent: 'Senden am',
  Decline: 'Ablehnen',
  Declined: 'Abgelehnt',
  DeclineRegistration: 'Registrierung ablehnen',
  Delete: 'Löschen',
  DeleteBidderInformation:
    "Ein Team kann nur gelöscht werden, wenn es im Evaluierungsprozess kein qualifizierter Bieter ist. Entfernen Sie dieses Team aus der Liste der qualifizierten Bieter.",
  DeleteBidderTeamInformationLine1:
    "Ein Team kann nur gelöscht werden, wenn es leer ist und nicht im Evaluierungsprozess ein qualifizierter Bieter ist.",
  DeleteBidderTeamInformationLine2:
    'Verschieben Sie Personen aus diesem Team und entfernen Sie dieses Team von der Liste der qualifizierten Bieter.',
  DeletePerson: 'Person löschen',
  DeletePersonConfirm: 'Person löschen',
  DeletePersonHeader: '[email] löschen?',
  DeletePersonMessage:
    'Durch das Löschen von [email] werden sie dauerhaft aus dem Raum, allen Aktivitätsberichten und dem Verlauf entfernt. Diese Aktion kann nicht rückgängig gemacht werden.',
  DeleteRegistration: 'Registrierung löschen',
  DeleteRequest: 'Anfrage löschen',
  DeleteSubteam: 'Unterteam löschen',
  DeleteSubteamInformation:
    'Ein Unterteam kann nur gelöscht werden, wenn es leer ist. Um es zu löschen, verschieben Sie Personen aus diesem Unterteam.',
  DeleteTeam: 'Team löschen',
  DeleteTeamInformation:
    'Ein Team kann nur gelöscht werden, wenn es leer ist. Um es zu löschen, verschieben Sie Personen aus diesem Team.',
  DeletingTeamContainsPeople:
    'Das Team kann nur gelöscht werden, wenn es leer ist. Um es zu löschen, verschieben Sie Personen aus diesem Team.',
  DemoAllCaps: 'DEMO',
  Deny: 'Verweigern',
  DenyCopyingDisabled: 'Kopierverbot deaktiviert',
  DenyEditingDisabled: 'Bearbeitung verweigern deaktiviert',
  DenyPrintingDisabled: 'Drucken verweigern deaktiviert',
  DenySavingDisabled: 'Speichern verweigern deaktiviert',
  DidntReceiveTheEmail: "Sie haben die E-Mail nicht erhalten?",
  Disable: 'Sperren',
  DisableAccess: 'Zugriff deaktivieren',
  Disabled: 'Gesperrt',
  DisabledUser: 'Deaktivierter Benutzer',
  DisableRegistrations: 'Anmeldungen deaktivieren',
  DisableRegistrations_SentenceCase: 'Anmeldungen deaktivieren',
  DisableRegistrationsAction: 'Deaktivieren',
  DisableRegistrationsInformation: 'Wenn deaktiviert, ist der Registrierungslink nicht mehr aktiv.',
  Disallowed: 'Nicht erlaubt',
  Document: 'Dokument',
  DocumentIndex: 'Dokumentenindex',
  DocumentIndexSecurity: 'Dokumentindex-Sicherheit',
  Documents: 'Dokumente',
  Down: 'Unten',
  Draft: 'Entwurf',
  Edit: 'Bearbeiten',
  EditAfterCloseDate: 'Einreichungsindex nach Anmeldeschluss bearbeiten',
  EditDocumentSecurityTeam:
    'Für dieses Team wurden keine Druck- oder Speichersicherheitskontrollen, Zugriffsrechte oder Wasserzeichen festgelegt.',
  EditDocumentSecurityTeamSubteam:
    'Für dieses Unterteam wurden keine Sicherheitskontrollen, Zugriffsrechte oder Wasserzeichen zum Drucken oder Speichern festgelegt.',
  EditingAllowed: 'Bearbeiten erlaubt',
  EditingDenied: 'Bearbeitung verweigert',
  EditMultipleUsers: 'Mehrere Benutzer bearbeiten',
  EditPeople: 'Personen bearbeiten',
  EditPerson: 'Person bearbeiten',
  EditPersonFormInvitationMessage:
    'Senden Sie eine neue Einladung an die aktualisierte E-Mail-Adresse, indem Sie das unten stehende Kontrollkästchen aktivieren',
  EditProfile: 'Profil bearbeiten',
  EditSecurity_SentenceCase: 'Sicherheit bearbeiten',
  EditSecurityFor: 'Sicherheit bearbeiten für',
  EditSubteam: 'Unterteam bearbeiten',
  EditTeam: 'Team bearbeiten',
  Email: 'E-Mail',
  EmailAddress: 'E-Mail-Adresse',
  EmailCanNotBeEmpty: 'Die E-Mail-Adresse darf nicht leer sein',
  EmailHasBeenSent: 'E-Mail wurde gesendet.',
  EmailMessage: 'Die E-Mail-Adresse ist ein erforderliches Feld',
  EmailNotifications: 'E-Mail Benachrichtigungen',
  EmailNotValid: 'Die E-Mail-Adresse ist ungültig',
  EmailPeople: 'E-Mail an Mitarbeiter',
  EmailPeopleSubjectRequiredSentenceCase: 'Für die E-Mail ist ein Betreff erforderlich',
  Emails: 'E-Mails',
  EmailsNotValid: 'E-Mail [Email] ist ungültig',
  EmailSuccessMessage: 'Ihre E-Mail wurde erfolgreich gesendet.',
  EnableAccess: 'Zugriff aktivieren',
  Enabled: 'Aktiviert',
  EnableRegistrations: 'Registrierungen aktivieren',
  EnableRegistrations_SentenceCase: 'Anmeldungen aktivieren',
  EnableRegistrationsAction: 'Aktivieren',
  EnableRegistrationsInformation:
    'Wenn aktiviert, ist der Registrierungslink aktiv und kann öffentlich geteilt werden.',
  EnterIpAddressesRanges: 'Geben Sie die IP-Adressen bzw. -Adressbereiche jeweils durch einen Zeilenumbruch getrennt ein',
  EnterOneOrMoreEmailAddresses: 'E-Mail für jede Person',
  EnterYourLocationOrClosestCity: 'Geben Sie Ihren Standort oder grössere benachbarte Stadt an',
  Every: 'Alle',
  ExpandAll_SentenceCase: 'Alles erweitern',
  ExportToExcel_SentenceCase: 'In Excel exportieren',
  FieldIsRequired: '[field] ist erforderlich',
  FieldMustBeAtMostMaxCharacters: '[field] darf maximal [max] Zeichen sein.',
  FileExtensionIsInList:
'Die Datei "[FileName]" kann nicht hochgeladen werden, da ihre Erweiterung auf der Liste der gesperrten Dateierweiterungen für diesen Datenraum steht:\r\n\r\n[FileTypeList]\r\n\r\nBitte konvertieren Sie die Datei in eines der zulässigen Dateiformate.',
  FileExtensionIsNotInList:
'Die Datei "[FileName]" kann nicht hochgeladen werden, da ihre Erweiterung nicht auf der Liste der zulässigen Dateierweiterungen für diesen Datenraum steht:\r\n\r\n[FileTypeList]\r\n\r\nBitte konvertieren Sie die Datei in eines der zulässigen Dateiformate.',
  Filter: 'Filter',
  FirstName: 'Vorname',
  Folder: 'Ordner',
  ForRangesSeparateStartIp:
'Für einen Adressbereich geben Sie die erste und letzte IP-Adresse durch einen Bindestrich getrennt ein. Beispiel: <b>1.1.1.1-1.1.1.6</b>',
  Friday: 'Freitag',
  From: 'Von:',
  FullName: 'Name',
  FullText: 'Volltextsuche',
  FullTextText: 'Ermöglichen Sie Personen, in Dokumenten zu suchen.',
  GDPRInformation: 'DSGVO-Informationen',
  GlobalUnexpectedErrorMessage:
    "Es liegt ein Problem mit Ihrem Datenraum vor. Bitte wenden Sie sich an unseren Kundenservice.",
  GlobalUnexpectedErrorWithIdMessage:
    "Es liegt ein Problem mit Ihrem Datenraum vor. Zitieren Sie diesen Code<[ErrorId]> an unseren Kundenservice.",
  GoLiveDialogTitle: 'Live gehen',
  GotIt: 'Verstanden',
  Guest: 'Gast',
  GuestUserRegistration: 'Registrierung des Gastbenutzers',
  GuideToUsingLink: 'Anleitung zur Verwendung von Link',
  Help: 'Hilfe',
  HelpAndSupport: 'Hilfe & Support',
  HideMenu: 'Menü ausblenden',
  High: 'Hoch',
  History: 'Historie',
  Hourly: 'stündlich',
  IamNotReadyYet: 'Noch nicht',
  IamReadyToGoLive: 'Ich bin bereit, live gehen',
  ID: 'ID',
  Importance: 'Wichtigkeit:',
  Important:'Wichtig!',
  Information: 'Informationen',
  Interval: 'Intervall',
  InvalidEmailAddress: 'Ungültige E-Mail-Adresse',
  InvalidField: 'Ungültiges [field]',
  InvalidPdf: 'FEHLER: Diese Datei ist kein gültiges PDF-Dokument. Bitte versuchen Sie es mit einer anderen Datei.',
  Invitation: 'Einladung',
  InvitationEmail: 'E-Mail Einladung',
  InvitationSuccessMessage: 'Ihre Einladung wurde erfolgreich gesendet.',
  Invite: 'Einladen',
  InviteAGuest: 'Gast einladen',
  Invited: 'Eingeladen',
  Invited_Users_: 'Eingeladen',
  InvitePeople: 'Personen einladen',
  IpAccessList: 'IP-Zugriffsliste',
  IpAccessList_SentenceCase: 'IP-Zugriffsliste',
  IpAccessListCanNotBeEmpty: 'Die IP-Zugriffsliste darf nicht leer sein',
  IpAddressInvalidIpAccessList: "Die IP-Adresse '[IpAddress]' in der IP-Zugriffsliste ist ungültig.",
  IpAddressInvalidRangeIpAccessList:
"Im IP-Adressbereich '[IpRange]' der IP-Zugriffsliste liegt die Anfangsadresse hinter der Endadresse. Die Anfangsadresse muss vor der Endadresse liegen oder mit dieser identisch sein.",
  IpLimitRangeIpAccessList:
"Bitte beschränken Sie die Beschreibung des IP-Adressbereichs '[IpRange]' in der IP-Zugriffsliste auf [NumOfChars] Zeichen. Momentane Länge: [UsedNumOfChars] Zeichen.",
  IpUserAccessListLimit:
'Die IP-Zugriffsliste darf maximal [MaxNumberOfCharacters] Zeichen lang sein. Sie haben [EnteredNumberOfCharacters] Zeichen eingegeben.',
  IWillInviteLater:"Ich werde später einladen",
  JobTitle: 'Berufsbezeichnung',
  JobTitleProfession: 'Berufsbezeichnung/Beruf',
  Last30days: 'Letzte 30 Tage',
  Last7days: 'Letzte 7 Tage',
  LastMonth: 'Letzter Monat',
  LastName: 'Nachname',
  LastWeek: 'Letzte Woche',
  LearnMore: 'Erfahren Sie mehr',
  LicenceChatHelp: 'Chatten Sie mit einem Spezialisten.',
  LicenceErrorHelp: 'Benötigen Sie Hilfe? ',
  LicenceErrorMessage:
    'Sie haben keine Lizenzen. Ihr Abonnement wird aktualisiert, wenn Sie wechseln.',
  LicencePack: 'Vorauszahlungspaket für das Benutzerteam',
  LicencePrompt:
    'Möglicherweise haben Sie ausstehende Einladungen. Lizenzen werden erst verwendet, nachdem eine Einladung angenommen wurde. Ihr Abonnement wird aktualisiert, wenn Sie wechseln.',
  LicenceSectionPlan: 'Ihr Plan',
  LicenceTooltip: 'Eine Lizenz wird auf folgende Weise verbraucht, wenn eine Person auf der Plattform vorhanden ist',
  LicenceTooltipStep1: 'Nur in Plattformpfaden',
  LicenceTooltipStep2: 'Nur im Plattform-Datenraum',
  LicenceTooltipStep3: 'Beides',
  LicenseMessage:
    'Für angenommene Einladungen wird eine Lizenz aus Ihrem Abonnement verwendet. Ihr Abonnement wird aktualisiert, wenn Sie wechseln.',
  LimitedCharacterLength: '[1] darf die [0]-Zeichen nicht überschreiten.',
  LinkCopied: 'Link kopiert',
  LinkCopiedToClipboard: 'Link in die Zwischenablage kopiert',
  LinkedDocuments: 'Verlinkte Dokumente',
  Loading: 'Laden',
  LoadingThreeDots: 'Wird geladen...',
  Location: 'Ort',
  LoggedInToDate_SentenceCase: 'Bis heute eingeloggt',
  LoginIpAccess: 'Anmelde-IP-Zugriff',
  LoginLowerCase: 'anmelden',
  LoginsLowerCase: 'Anmeldungen',
  Logout: 'Ausloggen',
  Low: 'Niedrig',
  Manage: 'Verwalten',
  ManageQnaSubjects: 'Fragen und Antworten verwalten',
  Medium: 'Mittel',
  Message: 'Nachricht',
  MessageEmpty: 'Die Nachricht darf nicht leer sein',
  Minute: 'Minute',
  Minutes: 'Minuten',
  ModifiedAgo: 'Geändert vor [time]',
  ModifiedBy: 'Geändert von',
  Monday: 'Montag',
  MyAccount: 'Mein Konto',
  Name: 'Name',
  NavigateAwayConfirm: 'Gehen',
  NavigateAwayWarning: 'Seite verlassen?',
  NavigateAwayWarningMessage: 'Die von Ihnen vorgenommenen Änderungen werden nicht gespeichert.',
  Never: 'Nie',
  NeverLoggedIn_SentenceCase: 'Nie eingeloggt',
  No: 'Nein',
  NoCompanyContact: 'Kein Firmenkontakt',
  NoDemonstration: 'Keine Demonstration',
  NonAdminSideBankruptcy: 'Buy-Side',
  NonAdminSideBankruptcy_EmailHeading: 'E-Mail an Mitarbeiter auf der buy side',
  NonAdminSideBankruptcy_InviteHeading: 'Senden Sie Einladungen an Personen auf der buy side',
  NonAdminSideDebt: 'Investor',
  NonAdminSideDebt_EmailHeading: 'E-Mail an Investoren',
  NonAdminSideDebt_InviteHeading: 'Einladungen an Investoren versenden',
  NonAdminSideDocumentRepository: 'Externe',
  NonAdminSideDocumentRepository_EmailHeading: 'E-Mail an Externe',
  NonAdminSideDocumentRepository_InviteHeading: 'Senden Sie Einladungen an Externe',
  NonAdminSideFundraising: 'Buy-Side',
  NonAdminSideFundraising_EmailHeading: 'E-Mail an Finanziers',
  NonAdminSideFundraising_InviteHeading: 'Senden Sie Einladungen an Finanziers',
  NonAdminSideJointVenture: 'Externe',
  NonAdminSideJointVenture_EmailHeading: 'E-Mail an Externe',
  NonAdminSideJointVenture_InviteHeading: 'Senden Sie Einladungen an Externe',
  NonAdminSideMerger: 'Buy-Side',
  NonAdminSideMerger_EmailHeading: 'E-Mail an Mitarbeiter auf der buy side',
  NonAdminSideMerger_InviteHeading: 'Senden Sie Einladungen an Personen auf der buy side',
  NonAdminSideOther: 'Externe',
  NonAdminSideOther_EmailHeading: 'E-Mail an Externe',
  NonAdminSideOther_InviteHeading: 'Senden Sie Einladungen an Externe',
  NonAdminSidePlacement: 'Buy-Side',
  NonAdminSidePlacement_EmailHeading: 'E-Mail an Investoren',
  NonAdminSidePlacement_InviteHeading: 'Einladungen an Investoren versenden',
  NonAdminSidePublicOffer: 'Buy-Side',
  NonAdminSidePublicOffer_EmailHeading: 'E-Mail an Externe',
  NonAdminSidePublicOffer_InviteHeading: 'Senden Sie Einladungen an Externe',
  NonAdminSideTargeted: 'Zielteam',
  NonAdminSideTargeted_EmailHeading: 'E-Mail an das Zielteam',
  NonAdminSideTargeted_InviteHeading: 'Senden Sie Einladungen an das Zielteam',
  NonAdminSideTender: 'Buy-Side',
  NonAdminSideTender_EmailHeading: 'E-Mail-Bieter',
  NonAdminSideTender_InviteHeading: 'Senden Sie Einladungen an Bietern',
  None: 'Keine',
  NoQuestionPermission: "Sie haben keine Berechtigung, Fragethemen festzulegen.",
  NoQuestionSubjects: "Sie haben noch keine Fragethemen.",
  NoRecordsFound: 'Keine Datensätze gefunden.',
  Normal: 'Normal',
  NoRooms: "Sie haben derzeit keine Zimmer",
  NoRoomsMatch: 'Keine Zimmer gefunden',
  NoScorecards: "Sie haben derzeit keine Scorecards",
  NoScorecardsMatch: 'Keine Scorecards gefunden',
  Notes: 'Notizen',
  Notifications: 'Benachrichtigungen',
  NotInvited: 'Nicht eingeladen',
  NotInvited_Users__SentenceCase: 'Nicht eingeladen',
  NotVerified_Users__SentenceCase: 'Nicht verifiziert',
  NotYet: 'Noch nicht',
  Off: 'Aus',
  Ok: 'Ok',
  OK: 'OK',
  OkAllCaps: 'OK',
  On: 'Ein',
  OnceOnlyAndWhenTermsAreUpdated: 'Nur einmal und wenn die Bedingungen aktualisiert werden',
  OnEveryLogin: 'Bei jeder Anmeldung',
  OnfirstLogin: 'Beim ersten Login',
  OnlyEnabledUsersCanBeInvited: 'Nur aktivierte Benutzer können eingeladen werden.',
  OnlyEnabledUsersCanBeInvitedSec2: 'Nur Personen mit aktiviertem Zugriff können eingeladen werden.',
  Optional: 'Optional',
  Or: 'oder',
  OtherTeamMembers: '[size] andere Teammitglieder',
  Pending: 'Ausstehende',
  PendingScorecards: 'Ausstehende Scorecards',
  PeopleFormWarningText:
    "Durch die Anwendung der Raumrolle „Administrator“ erhalten Personen uneingeschränkten Zugriff. Sie sind nicht durch die Sicherheitseinstellungen ihres Teams eingeschränkt",
  PeopleLowerCase: 'Leute',
  PerDay: 'Pro Tag',
  Period: 'Periodisch',
  PerMonth: 'Pro Monat',
  PersonHistory: 'Personenhistorie',
  PersonHistoryFor: 'Geschichte für',
  PersonLowerCase: 'Person',
  PerWeek: 'Pro Woche',
  Phone: 'Telefon',
  PhoneNumber: 'Telefonnummer',
  PhoneNumber_SentenceCase: 'Telefonnummer',
  PrintingAllowed: 'Drucken erlaubt',
  PrintingDenied: 'Drucken nicht zugelassen',
  Priority: 'Priorität',
  PrivacyPolicy: 'Datenschutzrichtlinie',
  Profession: 'Berufsstand',
  Profile: 'Profil',
  Properties: 'Eigenschaften',
  ProvideBilling:'Geben Sie Ihre Rechnungsdetails an',
  ProvidePayment:'Geben Sie Ihre Zahlungsdetails an',
  Qna: 'Fragen und Antworten',
  QnaNotificationSwitchOff: 'Sie werden keine Emails erhalten',
  QnaNotificationSwitchOn: 'Sie erhalten Updates unverzüglich',
  QnaRole: 'Q&A-Rolle',
  QnaRole_SentenceCase: 'Q&A Rolle',
  QnaSubjects: 'Q&A Themen',
  QnaSubjectSecurity: 'Q&A Themensicherheit',
  QnaSubmissionsLimit: 'Fragenkontingente',
  QnaSubmissionsOnWeekends: 'Einreichung von Fragen am Wochenende',
  Ready: 'Fertig',
  ReasonForRequest: 'Grund der Anfrage',
  Recipients: 'Empfänger',
  RecipientsOfEmailNote_Gen2:
'Die Adressen der anderen Empfänger sind für die einzelnen Empfänger dieser <nowrap>E-Mail</nowrap> nicht sichtbar. Ein Versand an Benutzer aus unterschiedlichen Benutzergruppen ist daher unproblematisch.',
  Register: 'Registrieren',
  RegisterYourInterest: 'Melden Sie Ihr Interesse an',
  RegisterYourInterest_SentenceCase:
    'Senden Sie eine Anfrage an den <strong>[dataRoomName]-</strong> Administrator zur Überprüfung. Wenn Ihre Anmeldung angenommen wird, werden Sie in das Projekt eingeladen.',
  Registration: 'Anmeldung',
  Registration_LowerCase: 'Registrierung',
  RegistrationDetails: 'Anmeldedetails',
  RegistrationLinkDisabled: 'Registrierungslink deaktiviert',
  RegistrationRequestSubmitted: 'Registrierungsanfrage eingereicht',
  Registrations: 'Anmeldungen',
  Registrations_LowerCase: 'Anmeldungen',
  RegistrationTextCopy:
  'Sie haben Bieternteams oder Gäste aktiviert, um Interesse zu registrieren. Kopieren Sie den bereitgestellten Link, um Personen die Registrierung zu ermöglichen. In dieser Liste werden neue Registratoren angezeigt, die Sie überprüfen und einladen können. Personen in dieser Liste haben keinen Zugriff, bis Sie sie einladen und zu einem Team hinzufügen.',
  Request: 'Anfrage',
  RequestAccess: 'Zugriff anfordern',
  RequestAccessDialogDescription: 'Geben Sie Details für die Person an, die zusätzlichen Zugriff benötigt. Ihre Anfrage wird vom Administrator geprüft.',
  RequestAccessDialogTitle: 'Anfrage für zusätzlichen Zugriff',
  RequestAccessEmptyStateText: 'Neue Registratoren werden in dieser Liste angezeigt, damit Sie sie überprüfen können. Personen in dieser Liste haben keinen Zugriff, bis der Administrator sie zu einem Team hinzufügt.',
  RequestDetails: 'Details anfordern',
  RequestorEmail: 'E-Mail des Anforderers',
  Required: 'Erforderlich',
  ResendEmail: 'E-Mail erneut senden',
  ResendVerificationLink: 'Bestätigungs-Link erneut senden',
  Reset: 'Zurücksetzen',
  Review: 'Rezension',
  RoomRole_SentenceCase: 'Raumrolle',
  RoomRoleAdminSubteamHelp: 'Administratoren können nicht zu Unterteams hinzugefügt werden.',
  RoomRoleHelp: "Eine Raumrolle bestimmt die Zugriffsebene einer Person im Raum.",
  Rooms: 'Zimmer',
  RoomsAdvert1:
    "Mit Dropbox Showcase können Sie Ihre Arbeit auf schöne Weise mit Partnern und Kunden teilen, den Fortschritt verfolgen und den Überblick darüber behalten, wer was gesehen hat – alles an einem Ort.",
  RoomsAdvert2: 'Showcase ist für Dropbox Business Advanced- und Enterprise-Benutzer verfügbar.',
  RoomsAdvertLink: 'Erfahren Sie mehr',
  RoomsAdvertTitle: 'Führen Sie einen leistungsstarken Deal aus',
  Saturday: 'Samstag',
  Save: 'Speichern',
  SaveAndInvitePeople: 'Personen speichern und einladen',
  Saving: 'Speichern',
  SavingAllowed: 'Speichern erlaubt',
  SavingDenied: 'Speichern nicht zugelassen',
  SavingFailed: 'Speichern fehlgeschlagen',
  Scorecards: 'Scorecards',
  ScorecardsAdvert1:
    "Mit Dropbox Showcase können Sie Ihre Arbeit auf schöne Weise mit Partnern und Kunden teilen, den Fortschritt verfolgen und den Überblick darüber behalten, wer was gesehen hat – alles an einem Ort.",
  ScorecardsAdvert2: 'Showcase ist für Dropbox Business Advanced- und Enterprise-Benutzer verfügbar.',
  ScorecardsAdvertLink: 'Erfahren Sie mehr',
  ScorecardsAdvertTitle: 'Führen Sie einen leistungsstarken Deal aus',
  Search: 'Suchen',
  Secure: 'Sicher',
  SecureByDefault: 'Standardmäßig sicher',
  SecureOffice: 'Sicheres Büro',
  SecureOfficeText:
    'Secure Office verfolgt Aufrufe und kann das Speichern, Kopieren und Drucken in Microsoft Word-, Excel- und PowerPoint-Dateien deaktivieren.',
  SecureOfficeTitle: 'Sicherheit für Microsoft Office-Dokumente (sicheres Office)',
  SecureOfficeWarning:
    'Secure Office ist mit vielen gängigen Geräten nicht kompatibel, einschließlich Apple iOS- und Android-Geräten. Diese Einstellung verhindert, dass Personen ohne kompatibles System diese Dateien anzeigen können.',
  Security: 'Sicherheit',
  SecurityControls: 'Sicherheitskontrollen',
  SecurityControlsOff: 'Sicherheitskontrollen deaktiviert',
  SecurityControlsOffDescription:
    'Wenn die Sicherheitskontrollen deaktiviert sind, ist die Verwendung dieser Dokumente nach dem Download nicht eingeschränkt.',
  SecurityControlsOn: 'Sicherheitskontrollen aktiviert',
  SecurityControlsOnDescription:
    'Wenn die Sicherheitskontrollen aktiviert sind, wird die Nutzung von Microsoft Office- und PDF-Dokumenten verfolgt. Gespeicherte Kopien werden aus der Ferne deaktiviert, wenn der Zugriff für ein Team widerrufen oder der Raum deaktiviert wird.',
  SecurityControlsOnExtraDescription:
    'Sie können auch das Speichern, Drucken, Bearbeiten und Kopieren einschränken (Kopierungsbeschränkungen sind nur für Microsoft Office-Dokumente verfügbar).',
  SecurityGroup_SentenceCase: 'Sicherheitsgruppe',
  SecurityPermissions: 'Sicherheitsberechtigungen',
  Select: 'Auswählen',
  SelectPeople_SentenceCase: 'Personen auswählen',
  SelectSubTeam: 'Bitte wählen Sie ein Unterteam aus.',
  SelectTeam: 'Bitte wählen Sie Team.',
  SelectUsers_SentenceCase: 'Benutzer auswählen',
  SelectUserType: 'Bitte wählen Sie den Benutzertyp.',
  SelfRegistrationHeader: 'Selbstregistrierung',
  SelfRegistrationToolIntroDialogHeading:
    'Ermöglichen Sie Gastbenutzern, sich zu registrieren',
  SelfRegistrationToolIntroDialogHelpLink:
    'Hilfe und Support bei der Bieterregistrierung anzeigen',
  SelfRegistrationToolIntroDialogTitle1:
    'Aktivieren Sie die Registrierung von Gastbenutzern im Aktionsmenü auf der Buy side/Bieterseite.',
  SelfRegistrationToolIntroDialogTitle2:
    'Geben Sie den bereitgestellten Link auf einer Webseite oder in einer von Ihnen erstellten E-Mail mit.',
  SelfRegistrationToolIntroDialogTitle3:
    'Registrierte Benutzer können überprüft und eingeladen werden.',
  SellSide: 'Sell Side',
  SellSide_InviteHeading: 'Senden Sie Einladungen an Mitarbeiter der sell side',
  SellSide_SentenceCase: 'Sell-Side',
  Send: 'Senden',
  SendEmailInvitation: 'E-Mail-Einladung senden',
  SendEmailInvitations: 'Senden Sie E-Mail-Einladungen',
  SendGuestInvitation:'Senden Sie Einladungen für Gastbenutzer',
  SendInvitationSubjectRequiredSentenceCase: 'Für die Einladungs-E-Mail ist ein Betreff erforderlich',
  SendInvite_SentenceCase: 'Einladung senden',
  SendInvites: 'Einladungen verschicken',
  SendInviteToSubscription: 'Einladung zu Ihrem Abonnement',
  SendUpdates: 'Updates senden',
  SentAgo: 'Vor [time] gesendet',
  SentDocumentChanged: 'Gesendet sobald ein Dokument hinzugefügt oder geändert wurde',
  SessionTimeout: 'Sitzungs-Timeout',
  SetSecurityControls: 'Legen Sie Sicherheitskontrollen fest',
  SetSecurityControlsBeforeAddingPeople: 'Legen Sie Sicherheitskontrollen fest, bevor Sie Personen hinzufügen.',
  SetSubmissionLimits: 'Legen Sie Übermittlungslimits fest',
  SetSubmissionLimitsDescription:
    'Beschränken Sie die Anzahl der Fragen, die ein Team pro Tag, Woche oder Monat stellen kann',
  ShouldBeEmailEmail: 'Die eingegebene E-Mail-Adresse ist ungültig.',
  ShowActivityInReports_SentenceCase: 'Aktivität in Berichten anzeigen',
  ShowDetails: 'Details anzeigen',
  ShowMore: 'Mehr anzeigen',
  Status: 'Status',
  StayOnPage: 'Bitte bleiben Sie auf diesem Bildschirm, während die Einstellungen kopiert werden',
  Subject: 'Betreff',
  Subject_Email_: 'Betreff',
  SubjectEmailHint: 'Der E-Mail-Betreff ist ein erforderliches Feld',
  SubjectGroupNotSet: 'Themengruppe nicht festgelegt',
  SubmissionIndex: 'Einreichungsindex',
  SubmissionIndexesSecurity: 'Übermittlungsindex-Sicherheit',
  SubmissionIndexTopLevelFoldersSecurity: 'Übermittlungsindex Ordner der obersten Ebene Sicherheit',
  Submissions: 'Einreichungen',
  SubTeam: 'Untergruppe',
  SubteamName: 'Name des Unterteams',
  SubTeamNameAlreadyExists: 'Ein Unterteam mit demselben Namen ist bereits vorhanden.',
  SubteamNameValidation: 'Der Name des Unterteams darf nicht leer sein',
  Success: 'Erfolg',
  Sunday: 'Sonntag',
  SwitchTeam_Confirm: 'Ja, das Team wechseln',
  SwitchTeam_Title: 'Team wechseln',
  SwitchTeam_WarningMessage:
    'Es sieht so aus, als hätten Sie einige Änderungen vorgenommen. Wenn Sie ohne Speichern gehen, gehen Ihre Änderungen verloren.',
  SydneyTime: 'Sydney Zeit',
  TalkToAnsaradaNow: 'Sprechen Sie jetzt mit Ansarada',
  Team: 'Team',
  TeamColour: 'Teamfarbe – [colour]',
  TeamHistory: 'Teamhistorie',
  TeamHistoryFor: 'Teamhistorie für',
  TeamMemberEmails: 'E-Mails von Teammitgliedern',
  TeamMemberEmailsInfo: 'E-Mails Ihrer Teammitglieder, getrennt durch Semikolon oder Komma. Sie werden alle eingeladen, wenn die Anmeldung angenommen wird.',
  TeamName: 'Teamname',
  TeamNameAlreadyExists: 'Ein Team mit demselben Namen ist bereits vorhanden.',
  TeamNameValidation: 'Der Teamname darf nicht leer sein',
  TeamPermissions: 'Teamberechtigungen',
  TenderFreemiumDialogBannerTitle:
    'Die Abrechnung beginnt mit der Einladung des ersten Gastes.',
  TenderFreemiumDialogContent:
    'Senden Sie Einladungen an Ausschreibungsteams und Sie können mit der ersten Phase der Beschaffung live gehen . Sie können so viele Bieter einladen, wie Sie benötigen.',
  TenderFreemiumDialogSubContent:
    'Ansarada Sales wird sich an Ihr Team wenden und Ihnen bei diesem Prozess helfen.',
  TermsAndConditions: 'Allgemeine Bedingungen',
  TheSafestWayToEnterEmails:'Die sicherste Methode zum Eingeben von E-Mail-Adressen ist Kopieren und Einfügen. Stellen Sie sicher, dass jede E-Mail-Adresse durch ein Komma, ein Semikolon, ein Leerzeichen oder einen Zeilenumbruch getrennt ist.',
  ThirdPartyIntegrations_SentenceCase: 'Integrationen von Drittanbietern',
  ThirdPartyIntegrationsText:'Ermöglichen Sie alle, einige oder keine Integrationen von Drittanbietern.',
  ThisSubTeamCannotAccess: 'Dieses Unterteam kann nicht auf Ordner oder Dokumente zugreifen',
  ThisTeamCannotAccess: 'Dieses Team kann nicht auf Ordner oder Dokumente zugreifen',
  Thursday: 'Donnerstag',
  Time: 'Zeit',
  TimePeriodAm: 'AM',
  TimePeriodPm: 'PM',
  To: 'Bis:',
  ToBeAbleToInvitePeopleToYourDataRoom:'um Personen in Ihren Datenraum einladen zu können.',
  Today: 'Heute',
  ToInviteFirstGuestAndGoLive:', um Ihren ersten Gastbenutzer einzuladen und live gehen',
  Topic: '[topics]-Thema',
  Topics: '[topics]-Themen',
  Total: 'Insgesamt',
  Tuesday: 'Dienstag',
  Unknown: 'Unbekannt',
  Up: 'Auf',
  Update: 'Aktualisieren',
  UserCanLogInFromAnyIp: 'Benutzer kann sich von beliebigen IP-Adressen aus anmelden',
  UserCanLogInOnlyFromIpsBelow: 'Benutzer kann sich nur von den nachstehenden IP-Adressen aus anmelden',
  UserExistsWithEmail: 'Der Benutzer ist mit der E-Mail-Adresse [0] vorhanden',
  UserGroup: 'Benutzergruppe',
  UserInvitationLinkNote_Gen2:
    'Das Tag „[invitation_link]“ wird beim Senden der E-Mail durch einen generierten Hyperlink ersetzt. Es muss im Text der E-Mail enthalten sein.',
  UserInvitationLinkTagIsMissing:
    'Eine Einladungsmail kann nicht ohne einen Zugangslink gesendet werden. Kopieren Sie [invitation_link] mit Klammern und fügen Sie es der Nachricht hinzu, damit der Empfänger seinen Zugriff erstellen kann.',
  UserProperties: 'Benutzereigenschaften',
  UserStatus: 'Benutzerstatus',
  UserType: 'Benutzertyp',
  UserTypeError: 'Raumrolle ist erforderlich',
  UserUpdated: 'Personen wurden erfolgreich aktualisiert.',
  Verified: 'Bestätigt',
  Verified_Users_: 'Bestätigt',
  VerifyYourEmail:'Bestätigen Sie Ihre E-Mail',
  VerifyYourEmailAddressToInviteUsers: 'Bestätigen Sie Ihre E-Mail-Adresse, um Benutzer einzuladen.',
  View: 'Anzeigen',
  ViewDetails: 'Details anzeigen',
  ViewIndexAsGhost: 'Was können Sie sehen?',
  ViewSecurity: 'Sicherheit anzeigen',
  Warning: 'Warnung',
  Watermark: 'Wasserzeichen',
  Wednesday: 'Mittwoch',
  Weekly: 'wöchentlich',
  WeHaveSentTheVerificationLinkTo:"Wir haben den Bestätigungs-Link an [email] gesendet",
  Yes: 'Ja',
  YouCanAddDescriptionsAfterComma:
'Sie können durch Anhängen eines Kommas auch Beschreibungen hinzufügen. Beispiel: <b>1.1.1.1, IP-Adresse Niederlassung London</b>',
  YourCurrentIpAddress:
'<b>Hinweis:</b> Ihre aktuelle IP-Adresse ist [IP Address]. Um sie in die Liste aufzunehmen, <a [href]>klicken Sie hier</a>.',
  YourCurrentIpAddressSec2: 'Fügen Sie Ihre aktuelle IP-Adresse zur Whitelist hinzu.',
  YouWillReceiveAConfirmationInYourEmailAboutNextSteps:
    "Sie erhalten in Ihrer E-Mail eine Bestätigung mit den nächsten Schritten.",
};
