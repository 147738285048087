// @flow

import type { VerifyEmailData } from 'app/pages/teams/teamsTypes';
import { ACTION_TYPES } from 'app/constants';
import { loadState, saveState } from 'app/utils/local-storage';
import { getJSONFromScript } from 'app/utils/dom';

const data: any = getJSONFromScript('dataroom-session-data');
const key = data && `closeEmailVerificationMsg-${data.userProfileId}`;

export const initialState: VerifyEmailData = {
  isOpenVerifyDialog: false,
  isEmailVerified: !data.isRestricted,
  closeMessage: loadState(key) || false,
  isSubmitting: false,
  isEmailSent: false,
};

const verifyEmailReducer = (
  state: VerifyEmailData = initialState,
  action: Object,
): VerifyEmailData => {
  switch (action.type) {
    case ACTION_TYPES.FREEMIUM.TOGGLE_VERIFY_EMAIL_DIALOG: {
      const { open } = action.data;
      return {
        ...state,
        isOpenVerifyDialog: open,
      };
    }
    case ACTION_TYPES.FREEMIUM.CLOSE_VERIFY_EMAIL_MESSAGE: {
      saveState(key, true);
      return {
        ...state,
        closeMessage: true,
      };
    }
    case ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.data,
      };
    case ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.SET_EMAIL_SENT:
      return {
        ...state,
        isEmailSent: action.data,
      };
    default:
      return state;
  }
};

export default verifyEmailReducer;
