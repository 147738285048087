// @flow

import { openWindow } from 'app/utils/legacy-helpers';
import * as url from 'app/uri/url';
import { ACTION_TYPES } from 'app/constants';
import { select, takeEvery } from 'redux-saga/effects';

export function* openPage(action: Object): Generator<*, *, *> {
  const { data } = action;
  const { session } = yield select();
  const windowUrl = url.generateLegacyURL(data.urlString, session, null, data.query);
  openWindow(windowUrl, data.urlString);
}

export const locationSagas = [
  takeEvery(ACTION_TYPES.PAGE_OPEN, openPage), // TODO: This is only used to open ASPs so after Anagram should probably be removed
];
