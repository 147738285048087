// @flow
import { takeEvery, call, select } from 'redux-saga/effects';
import { ACTION_TYPES } from 'app/constants';
import {
  publishTeamPeopleAddedEvent,
  publishInvitationSentEvent,
} from 'app/components/premiumAuthorization/event';
import {
  publishSubscriptionActivateDialogShowed,
  publishSubscriptionTransferUpgradeDialogShowed,
  publishManageSubscriptionDialogShowed,
} from 'app/components/activateSubscriptionMessage/events';

export function* savePeopleSuccessfully(): Generator<*, *, *> {
  const { teams } = yield select();
  const { sendEmailInvitation, sendEmailInvitations } = teams.viewData.peopleForm.data;

  const eventPayload = {
    isGuest: teams.props.isGuest,
    hasSendInvitation: sendEmailInvitation || sendEmailInvitations,
  };
  yield call(publishTeamPeopleAddedEvent, eventPayload);
}

export function* acceptInviteRegistration(action: Object): Generator<*, *, *> {
  const eventPayload = {
    isGuest: true,
    hasSendInvitation: action.data.sendEmailInvitations,
  };
  yield call(publishTeamPeopleAddedEvent, eventPayload);
}

export function* sendInvitation(action: Object): Generator<*, *, *> {
  const eventPayload = {
    isGuest: action.data.isGuest,
  };
  yield call(publishInvitationSentEvent, eventPayload);
}

export function* openSubscriptionTransferUpgradeDialog(action: Object): Generator<*, *, *> {
  const eventPayload = action.data;
  yield call(publishSubscriptionTransferUpgradeDialogShowed, eventPayload);
}

export function* openSubscriptionActivateDialog(action: Object): Generator<*, *, *> {
  const eventPayload = action.data;
  yield call(publishSubscriptionActivateDialogShowed, eventPayload);
}

export function* openManageSubscriptionDialog(action: Object): Generator<*, *, *> {
  const eventPayload = action.data;
  yield call(publishManageSubscriptionDialogShowed, eventPayload);
}

export const eventSagas = [
  takeEvery(ACTION_TYPES.TEAMS.PEOPLE_SAVE_SUCCESSFULLY, savePeopleSuccessfully),
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.ACCEPT, acceptInviteRegistration),
  takeEvery(ACTION_TYPES.SEND_INVITATION.SET_SENT, sendInvitation),
  takeEvery(
    ACTION_TYPES.SUBSCRIPTION.TRANSFER_UPGRADE_DIALOG,
    openSubscriptionTransferUpgradeDialog,
  ),
  takeEvery(ACTION_TYPES.SUBSCRIPTION.ACTIVATE_DIALOG, openSubscriptionActivateDialog),
  takeEvery(ACTION_TYPES.SUBSCRIPTION.MANAGE_DIALOG, openManageSubscriptionDialog),
];
