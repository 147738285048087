// @flow

import * as R from 'ramda';
import * as url from 'app/uri/url';
import type { DisplayType } from 'app/components/autocompleteLegacy';
import type { Teams, ThirdPartyIntegrations } from 'app/types/teamsTypes';
import type { TeamFormData } from 'app/pages/teams/teamsTypes';
import type { SessionType } from 'app/types/globalTypes';
import { ERROR_TYPE } from 'app/utils/error';
import { randomArrayValue } from 'app/utils/helpers';
import { COLOR_HEX_CODES } from './teamsConstants';

export const makeTeamsItems = (data: Array<*>) =>
  data.reduce((teamsItems, team) => {
    const items = [
      {
        id: team.teamId,
        teamId: team.teamId,
        subteamId: team.primarySubteamId,
        text: team.name,
        name: team.name,
        data: {
          key: 'teamId',
        },
      },
    ];

    const subteamItems = team.subteams
      ? team.subteams.map(subteam => ({
          id: subteam.subteamId,
          teamId: subteam.teamId,
          subteamId: subteam.subteamId,
          name: subteam.name,
          text: `${team.name}: ${subteam.name}`,
          data: {
            key: 'subteamId',
          },
        }))
      : [];

    return [...teamsItems, ...items, ...subteamItems];
  }, []);

export const filterTeamsItem = (items: Array<Object>, value: string) =>
  items.filter(item => item.subteamId.toString() === value)[0];

export const fetchSubteamIdForAdmin = (teams: Teams, teamId: number) =>
  teams.filter(item => item.teamId === teamId)[0].primarySubteamId;

export const getFilteredTeamData = (teams: Teams, filter: DisplayType) => {
  if (filter.selected.length === 0) return teams;
  const { subteamIds, teamIds } = filter.selected.reduce(
    (acc, curr) => {
      const { data } = curr;
      if (!data) return acc;
      if (data.key === 'subteamId') {
        acc.subteamIds.push(parseInt(curr.id, 10));
      } else {
        acc.teamIds.push(parseInt(curr.id, 10));
      }
      return acc;
    },
    {
      subteamIds: [],
      teamIds: [],
    },
  );
  return teams.reduce((teamAcc, team) => {
    const subteams = team.subteams.reduce((subteamAcc, subteam) => {
      if (subteamIds.some(s => s === subteam.subteamId)) {
        subteamAcc.push(subteam);
      }
      return subteamAcc;
    }, []);
    const subteamsSelected = subteams.length > 0;
    if (teamIds.some(s => s === team.teamId) || subteamsSelected) {
      teamAcc.push({
        ...team,
        subteams: subteamsSelected ? subteams : team.subteams,
      });
    }
    return teamAcc;
  }, []);
};

export const viewDocumentIndexAsTeamRedirect = /* istanbul ignore next */ (
  session: SessionType,
  subTeamId: number,
) => {
  const windowUrl = url.viewDocumentIndexAsTeam(session, subTeamId);
  document.location.href = windowUrl;
};

export const getTeamData = (isGuest: boolean, formData: TeamFormData) => ({
  teamId: formData.teamId,
  isGuest,
  primarySubteamId: formData.subteamId, // for saving team
  subteamId: formData.subteamId, // for saving sub-team
  name: formData.name,
  colour: formData.colour,
  fullTextSearchAllowed: formData.fullTextSearchAllowed,
  useSod: formData.useSod,
  bulkDownloadAllowed: formData.bulkDownloadAllowed,
  isCopy: formData.isCopy,
});

const getFieldErrorModelName = (error: Object): string => {
  const fieldWithName = R.pathOr('', ['Source', 'Pointer'], error);
  return fieldWithName.split('.').pop();
};

const getFieldErrorForTranslation = (error: Object): string => {
  const errorValue = R.path(['Source', 'Pointer'], error);
  return `${error.Title},${errorValue}`;
};

export const handleAddPeopleErrors = (resp: {
  data: {
    Errors: Array<{
      Code: string,
      Title: string,
    }>,
  },
}) => {
  const { data: responseData, ...response } = resp;
  const { Errors: errors, ...data } = responseData || {};

  if (!Array.isArray(errors) || !errors.length) return resp;

  const modelErrors = errors
    .filter(error => error.Code === ERROR_TYPE.MODEL_ERROR)
    .map(error => ({
      name: getFieldErrorModelName(error),
      detail: error.Title,
    }));
  const customModelErrors = errors
    .filter(error => error.Code === ERROR_TYPE.USER_EXISTS_WITH_EMAIL)
    .map(error => ({
      name: 'emails',
      detail: getFieldErrorForTranslation(error),
    }));
  const unexpectedErrors = errors.filter(
    error =>
      error.Code !== ERROR_TYPE.MODEL_ERROR && error.Code !== ERROR_TYPE.USER_EXISTS_WITH_EMAIL,
  );

  const newResponse = {
    ...response,
    data: { ...data },
  };
  if (unexpectedErrors.length) {
    // $FlowFixMe
    newResponse.data.Errors = unexpectedErrors;
  }
  if (modelErrors.length || customModelErrors.length) {
    // $FlowFixMe
    newResponse.data.modelErrors = modelErrors.concat(customModelErrors);
  }

  return newResponse;
};

export const pickRandomColour = (): string =>
  /* $FlowFixMe: this won't be null. */
  randomArrayValue(COLOR_HEX_CODES);

export const pickAnotherColour = (originalColour: string): string =>
  /* $FlowFixMe: this won't be null. */
  randomArrayValue(COLOR_HEX_CODES.filter(colour => colour !== originalColour));

export const shouldUpdateThirdPartyIntegrationsSecurityGroup = (
  newThirdPartyIntegrations: ThirdPartyIntegrations[],
  thirdPartyIntegrationsSecurityGroups: { [k: number]: ThirdPartyIntegrations[] },
  subteamId: number,
) => {
  const oldThirdPartyIntegrationsSecurityGroup = thirdPartyIntegrationsSecurityGroups[subteamId];

  if (!oldThirdPartyIntegrationsSecurityGroup) return false;

  for (let index = 0; index < oldThirdPartyIntegrationsSecurityGroup.length; index += 1) {
    const item = oldThirdPartyIntegrationsSecurityGroup[index];
    if (item.isAllowed !== newThirdPartyIntegrations[index].isAllowed) {
      return true;
    }
  }
  return false;
};
