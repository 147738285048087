"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectSession = void 0;
var dom_1 = require("app/utils/dom");
var sessionReducer = function (state) {
    return state || (0, dom_1.getJSONFromScript)('dataroom-session-data') || {};
};
var selectSession = function (state) { return state.session; };
exports.selectSession = selectSession;
exports.default = sessionReducer;
