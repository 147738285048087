// prettier-ignore

export default {
  Access: 'Toegang',
  Actions: 'Acties',
  Add: 'Toevoegen',
  AdminSideBankruptcy: 'Verkopende partij',
  AdminSideFundraising: 'Verkopende partij',
  AdminSideMerger: 'Verkopende partij',
  AdminSidePlacement: 'Verkopende partij',
  AdminSidePublicOffer: 'Verkopende partij',
  AdminSideTender: 'Verkopende partij',
  AdminSideTender_InviteHeading: 'Send invites to client',
  All: 'Alle',
  Attachments: 'Bijlagen',
  BatchOperationCantBePerformed: 'De batchverwerking kan niet worden uitgevoerd omdat er geen gebruikers zijn in deze gegevensruimte. Gelieve eerst gebruikers in te voeren.',
  BulkDownload_SentenceCase: 'Bulk downloaden',
  BuySide_SentenceCase: 'kopende partij',
  Cancel: 'Annuleren',
  Change: 'Wijzigen',
  ChooseLanguage: 'Kies een taal',
  Close: 'Sluiten',
  CollapseAll_SentenceCase: 'Alles inklappen',
  Colour: 'Kleur:',
  ContactUs: 'Contact',
  Daily: 'Dagelijks',
  DataRooms: 'Datarooms',
  Delete: 'Wissen',
  Disable: 'Niet beschikbaar maken',
  Disabled: 'Onbeschikbaar',
  Document: 'Document',
  DocumentIndex: 'Document index',
  Documents: 'Documenten',
  Edit: 'Bewerken',
  EditMultipleUsers: 'Meerdere gebruikers bewerken',
  EditProfile: 'Bewerk profiel',
  Email: 'E-mail',
  EmailNotifications: 'E-mail notificaties',
  EnterIpAddressesRanges: 'IP-adressen of IP-bereiks invoeren, gescheiden door de Enter toets',
  ExpandAll_SentenceCase: 'Alles uitklappen',
  FileExtensionIsInList: 'Het bestand "[FileName]" kan niet worden geüpload, omdat zijn extensie zich bevindt in de lijst van niet toegestane extensies in deze gegevensruimte:\r\n\r\n[FileTypeList]\r\n\r\nGelieve dit bestand te converteren in één van de toegestane formaten.',
  FileExtensionIsNotInList: 'Het bestand "[FileName]" kan niet worden geüpload, omdat zijn extensie zich niet bevindt in de lijst van toegestane extensies in deze gegevensruimte:\r\n\r\n[FileTypeList]\r\n\r\nGelieve dit bestand te converteren in één van de toegestane formaten.',
  Filter: 'Filter',
  Folder: 'Map',
  ForRangesSeparateStartIp: 'Voor bereiken, het start IP en het eind IP scheiden door een scheidingsteken, bijv. <b>1.1.1.1-1.1.1.6</b>',
  Friday: 'Vrijdag',
  From: 'Van:',
  FullName: 'Volledige naam',
  Help: 'Hulp',
  History: 'Geschiedenis',
  Hourly: 'Ieder uur',
  Importance: 'Belang:',
  Interval: 'Interval',
  InvalidEmailAddress: 'Ongeldig e-mailadres',
  InvalidPdf: 'FOUT: Dit bestand is geen geldig pdf document. Gelieve een ander bestand te proberen.',
  InvitationEmail: 'Uitnodigings Email',
  Invited_Users_: 'Uitgenodigd',
  IpAccessList: 'IP witte toegangslijst',
  IpAddressInvalidIpAccessList: "IP '[IpAddress]' in de IP-toegangslijst is geen geldig IP-adres.",
  IpAddressInvalidRangeIpAccessList: "IP-bereik '[IpRange]' in de IP-toegangslijst heeft een hoger IP-startadres dan IP-eindadres. Start-IP-adres moet hoger of gelijk zijn aan eind-IP-adres.",
  IpLimitRangeIpAccessList: "Beperk de beschrijving van de IP-range '[IpRange]' in de IP-toegangslijst tot [NumOfChars] karakters. U hebt [UsedNumOfChars] karakters gebruikt.",
  IpUserAccessListLimit: 'Gelieve de IP-toegangslijst te beperken tot [MaxNumberOfCharacters] karakters. U gaf [EnteredNumberOfCharacters] karakters in.',
  LastMonth: 'Laatste maand',
  LastWeek: 'Vorige week',
  LoadingThreeDots: 'Bezig te laden…',
  Logout: 'Log uit',
  Manage: 'Beheren',
  Message: 'Bericht',
  Monday: 'Maandag',
  Name: 'Naam',
  Never: 'Nooit',
  No: 'Neen',
  NonAdminSideBankruptcy: 'kopende partij',
  NonAdminSideFundraising: 'kopende partij',
  NonAdminSideMerger: 'kopende partij',
  NonAdminSidePlacement: 'kopende partij',
  NonAdminSidePublicOffer: 'kopende partij',
  NonAdminSideTender: 'kopende partij',
  None: 'Geen',
  NoRecordsFound: 'Geen records gevonden.',
  Notifications: 'Notificaties',
  Off: 'Af',
  OkAllCaps: 'OK',
  On: 'Aan',
  OnEveryLogin: 'Bij elke aanmelding',
  Or: 'of',
  Period: 'Punt',
  PrintingAllowed: 'Printen toegestaan',
  PrintingDenied: 'Afdrukken geweigerd',
  Profile: 'Profiel',
  QnaNotificationSwitchOff: 'U ontvangt geen e-mails',
  QnaNotificationSwitchOn: 'U ontvangt updates onmiddellijk',
  QnaRole: 'Rol V&A',
  QnaRole_SentenceCase: 'V&A rol',
  RecipientsOfEmailNote_Gen2: 'De geadresseerden van deze <nowrap>e-mail</nowrap> zullen NIET in staat zijn elkaars adressen te zien.  Het is dus veilig hem te zenden naar de gebruikers van verschillende gebruikersgroepen.',
  Reset: 'Reset',
  Saturday: 'Zaterdag',
  Save: 'Opslaan',
  Saving: 'Opslaan',
  SavingAllowed: 'Opslaan toegestaan',
  SavingDenied: 'Opslaan geweigerd',
  Search: 'Zoeken',
  Security: 'Beveiliging',
  Select: 'Selecteren',
  SelectUsers_SentenceCase: 'Kies gebruikers',
  SelectUserType: 'Selecteer gebruikerstype.',
  SellSide_SentenceCase: 'Verkopende partij',
  Send: 'Zenden',
  SendUpdates: 'Stuur updates',
  SentDocumentChanged: 'Verzonden wanneer een document wordt toegevoegd of gewijzigd',
  ShouldBeEmailEmail: 'De e-mail die u hebt ingevoerd is geen geldig e-mailadres.',
  Status: 'Status',
  Subject: 'Onderwerpen',
  SubTeam: 'sub-team',
  Sunday: 'Zondag',
  SydneyTime: 'Sydney tijd',
  Team: 'team',
  Thursday: 'Donderdag',
  TimePeriodAm: 'AM',
  TimePeriodPm: 'PM',
  To: 'Tot:',
  Today: 'Vandaag',
  Tuesday: 'Dinsdag',
  UserCanLogInFromAnyIp: 'Deze gebruiker kan zich aanmelden vanaf elk IP-adres',
  UserCanLogInOnlyFromIpsBelow: 'Deze gebruiker kan zich ENKEL aanmelden vanaf het hieronder gespecificeerd adres',
  UserGroup: 'Gebruikersgroep',
  UserProperties: 'Gebruikerseigenschappen',
  Verified_Users_: 'Geverifieerd',
  View: 'Weergave',
  ViewIndexAsGhost: 'Wat kunnen ze bekijken?',
  Watermark: 'Watermerk',
  Wednesday: 'Woensdag',
  Weekly: 'Wekelijks',
  Yes: 'Ja',
  YouCanAddDescriptionsAfterComma: 'U kunt ook beschrijvingen toevoegen na de komma, bijv. <b>1.1.1.1, IP kantoor Londen</b>',
  YourCurrentIpAddress: '<b>Let op:</b> Uw huidig IP-adres is [IP Address]. <a [href]>Klik hier</a> om het aan de lijst toe te voegen.',
};
