// @flow

import { ACTION_TYPES } from 'app/constants';
import * as dataRoomService from 'app/services/dataRoom';
import * as personService from 'app/services/person';
import * as teamService from 'app/services/team';
import { safeRequest } from 'app/utils/saga-helpers';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import type { QueryType } from 'app/components/emailForm/EmailFormTypes';
import { filterPeopleWithQuery } from '../sendInvitation/sendInvitationHelper';

export function* fetchTeams(query: QueryType): Generator<*, *, *> {
  const { session } = yield select();
  const [teams, error] = yield safeRequest(teamService.getTeams, session, query);
  if (!error) {
    yield put({
      type: ACTION_TYPES.EMAIL_PEOPLE.TEAMS_UPDATE,
      data: teams,
    });
  }
}

export function* fetchPeople(query: QueryType): Generator<*, *, *> {
  const { session } = yield select();
  const [people, error] = yield safeRequest(personService.fetchPeople, session);
  if (!error) {
    yield put({
      type: ACTION_TYPES.EMAIL_PEOPLE.PEOPLE_UPDATE,
      data: filterPeopleWithQuery(people, query),
    });
  }
}

export function* fetchInitialData(action: Object): Generator<*, *, *> {
  yield all([
    call(fetchPeople, action.data),
    call(fetchTeams, { isGuest: action.data.isGuest === '1' }),
  ]);

  yield put({ type: ACTION_TYPES.EMAIL_PEOPLE.SET_IS_LOADED });
}

export function* uploadAttachment(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  const files = action.data;
  const formData = new FormData();
  formData.append('DocumentFile', files[0]);
  yield put({ type: ACTION_TYPES.EMAIL_PEOPLE.SET_IS_UPLOADING_ATTACHMENT, data: true });
  const [attachment, error] = yield safeRequest(dataRoomService.addAttachment, session, formData);
  yield put({ type: ACTION_TYPES.EMAIL_PEOPLE.SET_IS_UPLOADING_ATTACHMENT, data: false });
  if (!error) {
    yield put({
      type: ACTION_TYPES.EMAIL_PEOPLE.ADD_ATTACHMENT,
      data: attachment,
    });
  }
}

export function* submitEmail(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.EMAIL_PEOPLE.SET_IS_SUBMITTING, data: true });
  const [, error] = yield safeRequest(dataRoomService.emailPeople, session, action.data);
  if (!error) {
    yield put({ type: ACTION_TYPES.EMAIL_PEOPLE.SET_SENT });
  }
  yield put({ type: ACTION_TYPES.EMAIL_PEOPLE.SET_IS_SUBMITTING, data: false });
}

export const emailPeopleSagas = [
  takeEvery(ACTION_TYPES.EMAIL_PEOPLE.DATA_FETCH_INITIAL, fetchInitialData),
  takeEvery(ACTION_TYPES.EMAIL_PEOPLE.UPLOAD_ATTACHMENT, uploadAttachment),
  takeEvery(ACTION_TYPES.EMAIL_PEOPLE.SUBMIT_EMAIL, submitEmail),
];
