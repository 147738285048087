// @flow

import { difference, union, intersection } from 'ramda';
import { select, takeEvery, call, put, all } from 'redux-saga/effects';
import { ACTION_TYPES } from 'app/constants';
import { getRootFolders } from 'app/services/tenderSubmissions';
import { safeRequest } from 'app/utils/saga-helpers';
import { getDocuments, fetchFolderChildren, folderChildrenStats } from 'app/services/document';
import * as treeHelpers from 'app/pages/security/helpers/treeHelpers';
import { displayInfoMessage } from 'app/components/bannerMessages/bannerMessagesActions';
import { updateAccess, updateSecurity } from 'app/services/security';
import { wrapTaskWithLoadingBar, fetchSecurities, fetchTeams } from '../securitySagas';
import {
  fetchAllUnfoldedChildrenIds,
  getText,
  mapSecurityToApi,
  getExpandedFolderIds,
  getExpandedChildren,
} from '../helpers/securityHelpers';
import { folderToggled } from '../securityActions';
import type { Team, SubteamSetting } from '../securityTypes';
import {
  setExpandedFolders,
  updateSubmissionTreeIndex,
  setSubmissionIndex,
  updateSubmissionTree,
  setTeamContext,
  setRootFolders,
} from './tenderSubmissionsSecurityActions';

export function* getCurrentTeam(): Generator<*, *, *> {
  const { security } = yield select();
  const { subteamId } = security.props;

  let { teams } = security.documents.pageData;
  if (teams.length === 0) {
    teams = yield call(fetchTeams);
  }

  const team = teams.find(t => {
    if (t.subteamId === subteamId) return true;
    return t.subteams.some(st => st.id === subteamId);
  });

  return { team };
}

export const getSubteamSettings = (subteamId: number, team: Team): Array<SubteamSetting> => {
  if (team.subteamId === subteamId) {
    return [
      {
        id: subteamId,
        useSod: team.useSod,
      },
    ];
  }

  if (team.subteams) {
    const subteam = team.subteams.find(st => st.subteamId === subteamId);
    return [
      {
        id: subteamId,
        useSod: subteam ? subteam.useSod : false,
      },
    ];
  }

  return [
    {
      id: subteamId,
      useSod: false,
    },
  ];
};

export function* getRootFolderId(bidderTeamId: number): Generator<*, *, *> {
  const {
    security: {
      tenderSubmissions: { rootFolders },
    },
  } = yield select();
  const root = rootFolders.find(rootFolder => rootFolder.BidderTeamId === bidderTeamId);
  if (!root) return null;
  return root.Folder.FolderId;
}

export function* fetchIndexSecurities(action: Object): Generator<*, *, *> {
  const { session, bidderTeamId, subteamId, team } = action;
  const rootFolderId = yield call(getRootFolderId, bidderTeamId);
  if (!rootFolderId) return;

  const [index, serviceError] = yield safeRequest(fetchFolderChildren, session, rootFolderId, true);
  if (serviceError) return;

  const securities = yield call(fetchSecurities, [null, rootFolderId], 'TST', bidderTeamId);
  const subteamSettings = getSubteamSettings(subteamId, team);

  const rootNode = treeHelpers.getRootNode(rootFolderId, index, securities, subteamSettings);
  const initTree = treeHelpers.getTree(rootNode, index, securities, subteamSettings);
  yield put(setTeamContext({ selectedBidderId: bidderTeamId }));
  yield put(setSubmissionIndex(rootNode, initTree, [rootFolderId]));
}

export function* fetchInitial(): Generator<*, *, *> {
  const { session, security } = yield select();
  const { subteamId, isGuest } = security.props;
  const { team } = yield call(getCurrentTeam);
  if (!team) return;

  const [response, error] = yield safeRequest(getRootFolders, session);
  if (error) return;
  const rootFolders = response.data;
  yield put(setRootFolders(rootFolders));
  let bidderTeamId = team.id;
  if (!isGuest) {
    const bidders = rootFolders
      .sort((a, b) => a.BidderTeamName.localeCompare(b.BidderTeamName))
      .map(f => ({ teamId: f.BidderTeamId, teamName: f.BidderTeamName }));
    if (!bidders || !bidders.length) return;
    bidderTeamId = bidders[0].teamId;
    yield put(setTeamContext({ bidders, subteamId, team, selectedBidderId: bidderTeamId }));
  }
  yield* fetchIndexSecurities({ session, bidderTeamId, subteamId, team });
}

function getIndexObjectId(team, isGuest, context) {
  return isGuest ? team.id : context.selectedBidderId;
}

export function* toggleFolder(action: Object): Generator<*, *, *> {
  const folderId: number = action.payload;
  const {
    session,
    security: { tenderSubmissions, props },
  } = yield select();
  const { tree, index, expanded, context } = tenderSubmissions;
  const node = treeHelpers.getNodeById(tree, folderId, true);

  if (treeHelpers.shouldLoad(node) && !expanded.includes(folderId)) {
    const { team } = yield call(getCurrentTeam);
    if (!team) return;
    const indexObjectId = getIndexObjectId(team, props.isGuest, context);
    const [[children, error], securities] = yield all([
      safeRequest(fetchFolderChildren, session, folderId, true),
      call(fetchSecurities, folderId, 'TST', indexObjectId),
    ]);
    if (error) return;

    const { subteamId } = props;
    const folderTree = treeHelpers.getTree(
      node,
      children,
      securities,
      getSubteamSettings(subteamId, team),
    );
    const newIndex = [...index];
    const parentPosition = newIndex.findIndex(t => t === node.documentIndexItemId);
    const childrenId = Object.keys(folderTree);
    newIndex.splice(parentPosition + 1, 0, ...childrenId);
    const newTree = {
      ...folderTree,
      [node.documentIndexItemId]: {
        ...node,
        children: childrenId,
      },
    };

    yield put(updateSubmissionTreeIndex(newIndex, newTree));
  }

  let newExpanded = folderToggled(folderId, expanded);
  const childrenToCollapse = fetchAllUnfoldedChildrenIds(folderId, node, expanded, tree);

  if (childrenToCollapse && childrenToCollapse.length) {
    newExpanded = difference(
      union(childrenToCollapse, newExpanded),
      intersection(childrenToCollapse, newExpanded),
    );
  }

  yield put(setExpandedFolders(newExpanded));
}

export function* treeExpand(): Generator<*, *, *> {
  const {
    session,
    security: { tenderSubmissions, props },
  } = yield select();

  const { tree, context } = tenderSubmissions;
  const { selectedBidderId } = context;
  const rootFolderId = yield call(getRootFolderId, selectedBidderId);
  if (!rootFolderId) return;

  const data = yield safeRequest(getDocuments, session, rootFolderId);
  const [documents, error] = data;
  if (error) return;
  const rootNode = treeHelpers.getNodeById(tree, rootFolderId, true);
  if (!rootNode) {
    // This happens when a user clicks expand all before tree data is ready.
    return;
  }

  const { team } = yield call(getCurrentTeam);
  if (!team) return;

  const expandedFolderIds = getExpandedFolderIds(documents.data);
  const expandedChildren = getExpandedChildren(expandedFolderIds, documents.data);

  const indexObjectId = getIndexObjectId(team, props.isGuest, context);
  const securities = yield call(fetchSecurities, expandedFolderIds, 'TST', indexObjectId);

  const subteamSettings = getSubteamSettings(team.subteamId, team);
  const newTree = Object.assign(
    {
      [rootNode.documentIndexItemId]: rootNode,
    },
    treeHelpers.getTree(rootNode, expandedChildren, securities, subteamSettings, tree),
  );
  const newIndex = Object.keys(newTree);

  yield put(updateSubmissionTreeIndex(newIndex, newTree));
  yield put(setExpandedFolders(expandedFolderIds));
}

export function* treeCollapse(): Generator<*, *, *> {
  const {
    security: { tenderSubmissions },
  } = yield select();

  const { context } = tenderSubmissions;
  const { selectedBidderId } = context;
  const rootFolderId = yield call(getRootFolderId, selectedBidderId);
  if (!rootFolderId) return;
  yield put(setExpandedFolders([rootFolderId]));
}

export function* fetchFolderChildrenStats(action: Object): Generator<*, *, *> {
  const { documentIndexItemId, attr } = action.payload;
  if (documentIndexItemId[0] !== 'f') {
    return;
  }

  const { session } = yield select();
  const folderId = parseInt(documentIndexItemId.split('-')[1], 10);
  const [stats, error] = yield safeRequest(folderChildrenStats, session, folderId);

  if (!error) {
    const description = getText(stats.data, attr);
    if (description) {
      yield put(displayInfoMessage(description));
    }
  }
}

export function* saveSettings(): Generator<*, *, *> {
  const { session, security } = yield select();
  const { edited, tree } = security.tenderSubmissions;

  const { itemsWithChangedAccess, itemsWithChangedSecurity } = mapSecurityToApi(edited, tree);

  if (itemsWithChangedAccess.length) {
    yield safeRequest(updateAccess, session, itemsWithChangedAccess);
  }

  if (itemsWithChangedSecurity.length) {
    yield safeRequest(updateSecurity, session, itemsWithChangedSecurity);
  }

  const { subteamId } = security.props;
  yield put(updateSubmissionTree(subteamId));
}

export const tenderSubmissionsSecuritySagas = [
  takeEvery(
    ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.INIT_DATA,
    wrapTaskWithLoadingBar(fetchInitial),
  ),
  takeEvery(ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.TOGGLE_FOLDER, toggleFolder),
  takeEvery(
    ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_SECURITY_SETTINGS,
    fetchFolderChildrenStats,
  ),
  takeEvery(ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SAVE, saveSettings),
  takeEvery(
    ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SELECT_BIDDER,
    wrapTaskWithLoadingBar(fetchIndexSecurities),
  ),
  takeEvery(
    ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.EXPAND_TREE,
    wrapTaskWithLoadingBar(treeExpand),
  ),
  takeEvery(ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.COLLAPSE_TREE, treeCollapse),
];
