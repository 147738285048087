"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitGuestRegistration = exports.submitRegistration = exports.searchLocations = exports.fetchJobTitleGroups = exports.getAPIRoot = void 0;
var constants_1 = require("app/services/request/constants");
var api_1 = require("./request/api");
var DATAROOM_HOSTNAMES = [
    'dataroom.dev1.ansarada.com',
    'dataroom.dev2.ansarada.com',
    'dataroom.staging.ansarada.com',
    'dataroom.ansarada.com',
];
var getAPIRoot = function () {
    var _a = window.location, protocol = _a.protocol, hostname = _a.hostname;
    var rootHost = DATAROOM_HOSTNAMES.includes(hostname)
        ? hostname.replace('dataroom', 'api')
        : 'api.dev1.ansarada.com';
    return "".concat(protocol, "//").concat(rootHost);
};
exports.getAPIRoot = getAPIRoot;
var fetchJobTitleGroups = function (session) {
    return (0, api_1.get)(session, "".concat((0, exports.getAPIRoot)(), "/identity/reference-data-api/v1/jobtitlegroups"));
};
exports.fetchJobTitleGroups = fetchJobTitleGroups;
var searchLocations = function (session, searchTerm) {
    return (0, api_1.get)(session, "".concat((0, exports.getAPIRoot)(), "/identity/reference-data-api/v1/locations?search=").concat(searchTerm));
};
exports.searchLocations = searchLocations;
var submitRegistration = function (session, payload) {
    return (0, api_1.post)(session, "".concat(constants_1.API1B_ROOT, "/selfregistration/register"), payload);
};
exports.submitRegistration = submitRegistration;
var submitGuestRegistration = function (session, data) {
    return (0, api_1.post)(session, "".concat(constants_1.API1B_ROOT, "/guestregistration"), data);
};
exports.submitGuestRegistration = submitGuestRegistration;
