// prettier-ignore

export default {
  Access: '접근',
  Actions: '작업',
  AdminSideTender_InviteHeading: 'Send invites to client',
  Attachments: '첨부문서',
  BatchOperationCantBePerformed: '해당 데이터룸에 사용자가 없으므로 일괄작업이 수행될 수 없습니다. 먼저 사용자를 입력 하십시오.',
  Cancel: '취소',
  Change: '변경',
  Close: '닫기',
  Colour: '색상:',
  DataRooms: '데이터룸',
  Delete: '삭제',
  DemoAllCaps: '평가판',
  Document: '문서',
  DocumentIndex: '문서 인덱스',
  Documents: '문서',
  Edit: '편집',
  EditMultipleUsers: '다중 사용자 편집',
  EditProfile: '프로필 편집',
  Email: '이메일',
  EnterIpAddressesRanges: 'IP 주소나 IP 주소 범위를 엔터키를 눌러 구별하여 입력 하십시오.',
  FileExtensionIsInList: '파일 "[FileName]"은(는) 이 데이터룸에서 허용되지 않는 확장자 목록에 있기때문에 다운로드 될 수 없습니다. \r\n\r\n[FileTypeList]\r\n\r\n이 파일을 허용되는 포맷중 하나로 변경하십시오.',
  FileExtensionIsNotInList: '파일 "[FileName]"은(는) 이 데이터룸에서 허용되는 확장자 목록에 있지 않기 때문에 다운로드 될 수 없습니다. \r\n\r\n[FileTypeList]\r\n\r\n이 파일을 허용되는 포맷중 하나로 변경하십시오.',
  Filter: '필터',
  Folder: '폴더',
  ForRangesSeparateStartIp: '주소 범위는 IP 앞 부분과 뒷 부분을 줄표로 나누어 표시하십시오. (예) <b>1.1.1.1-1.1.1.6</b>',
  From: '부터:',
  FullName: '이름',
  Help: '도움말',
  InvalidEmailAddress: '올바르지 않은 이메일 주소',
  InvalidPdf: '오류: 이 파일은 올바른 pdf문서가 아닙니다. 다른 파일로 다시 시도하십시오.',
  Invited_Users_: '초대됨',
  IpAccessList: 'IP 접근 목록',
  IpAddressInvalidIpAccessList: "IP 접속 목록의 IP '[IpAddress]'는 유효하지 않습니다.",
  IpAddressInvalidRangeIpAccessList: "IP 접속 목록의 IP 범위 '[IpRange]'는 종료 IP 주소보다 값이 큰 시작 IP주소를 가지고 있습니다. 시작 IP는 종료  IP보다 같거나 작아야 합니다.",
  IpLimitRangeIpAccessList: "IP 접속 목록의 IP 범위 '[IpRange]'에 대한 설명을 [NumOfChars]자로 조정해 주십시오. 이미 [UsedNumOfChars]자를 사용하셨습니다.",
  IpUserAccessListLimit: 'IP 접근 목록은 최대 [MaxNumberOfCharacters]자까지 허용 됩니다. 현재 [EnteredNumberOfCharacters]자가 입력 되었습니다.',
  LastMonth: '지난 달',
  LoadingThreeDots: '로딩중…',
  Logout: '로그아웃',
  Manage: '관리',
  Message: '메시지',
  Never: '하지않음',
  No: '아니오',
  None: '없음',
  NoRecordsFound: '해당 사항이 없습니다.',
  Off: '사용하지 않음',
  OkAllCaps: 'OK',
  OnEveryLogin: '매 로그인마다',
  Or: '또는',
  PrintingDenied: '인쇄가 거부됨',
  QnaRole: 'Q&A 역할',
  RecipientsOfEmailNote_Gen2: '이 <nowrap>이메일</nowrap>을 받는 사람들은 서로의 이메일 주소를 볼 수 없으므로, 다른 사용자 그룹에 속한 사용자들에게 메일을 안전하게 전송할 수 있습니다.',
  Reset: '재설정',
  Save: '저장',
  SavingDenied: '저장이 거부됨',
  Search: '검색',
  Security: '보안',
  Select: '선택',
  Send: '보내기',
  ShouldBeEmailEmail: '입력하신 이메일 주소는 유효하지 않습니다.',
  Status: '상태',
  To: '까지:',
  Today: '오늘',
  UserCanLogInFromAnyIp: '이 사용자는 모든 IP주소에서 로그인 할 수 있습니다.',
  UserCanLogInOnlyFromIpsBelow: '이 사용자는 아래의 IP 주소에서만 로그인 할 수 있습니다.',
  UserGroup: '사용자 그룹',
  UserProperties: '사용자 속성',
  Verified_Users_: '확인됨',
  View: '보기',
  Watermark: '워터마크',
  Yes: '예',
  YouCanAddDescriptionsAfterComma: '쉼표 뒤에는 설명을 첨부할 수도 있습니다. (예) <b>1.1.1.1, 런던 사무소 IP</b>',
  YourCurrentIpAddress: '<b>참고:</b> 귀하의 현재 IP 주소는 [IP Address] 입니다. 목록에 추가하시려면 <a [href]>여기</a>를 클릭하십시오.',
};
