// @flow

import { ACTION_TYPES } from 'app/constants';
import * as personService from 'app/services/person';
import { safeRequest } from 'app/utils/saga-helpers';
import { call, all, put, takeEvery, select } from 'redux-saga/effects';
import type { UserHistoryViewedEventTrackingProps } from 'app/utils/segment/segment-events';
import { trackEvent } from 'app/utils/segment/segment-actions';

function* fetchPerson(personId: number, isGuest: boolean) {
  const { session } = yield select();
  const [people, error] = yield safeRequest(personService.fetchPeople, session, isGuest);
  const person = (people || []).find(p => p.personId === personId);

  if (!error) {
    yield put({
      type: ACTION_TYPES.PERSON_HISTORY.SET_PERSON,
      data: person,
    });
  }
}

function* fetchPersonHistory(personId: number) {
  const { session } = yield select();
  const [personHistory, error] = yield safeRequest(
    personService.getPersonHistory,
    session,
    personId,
  );
  if (!error) {
    yield put({
      type: ACTION_TYPES.PERSON_HISTORY.SET_PERSON_HISTORY,
      data: personHistory,
    });
  }
}

function* setIsLoaded(isLoaded: boolean) {
  yield put({
    type: ACTION_TYPES.PERSON_HISTORY.SET_IS_LOADED,
    data: { isLoaded },
  });
}

function* initialState(action) {
  const {
    data: { personId, isGuest },
  } = action;
  yield all([
    call(fetchPerson, personId, isGuest),
    call(fetchPersonHistory, personId),
    put({ type: ACTION_TYPES.USER_TYPES_FETCH, data: { isGuest } }),
  ]);
  yield call(setIsLoaded, true);
}

function* publishUserHistoryViewedSegmentEvent(action) {
  const {
    data: { personId },
  } = action;

  const eventPayload: UserHistoryViewedEventTrackingProps = {
    viewed_user_id: personId,
  };

  yield call(trackEvent, 'f_dataroom_user.history-viewed', eventPayload);
}

export const personHistorySagas = [
  takeEvery(ACTION_TYPES.PERSON_HISTORY.DATA_FETCH_INITIAL, initialState),
  takeEvery(ACTION_TYPES.SEGMENT_EVENTS.USER_HISTORY_VIEWED, publishUserHistoryViewedSegmentEvent),
];
