// @flow

import { ACTION_TYPES } from 'app/constants';
import * as personService from 'app/services/person';
import { safeRequest } from 'app/utils/saga-helpers';
import { put, takeEvery, select, call } from 'redux-saga/effects';
import { wait } from 'app/utils/helpers';

export function* setEmailSent(): Generator<*, *, *> {
  yield put({ type: ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.SET_EMAIL_SENT, data: true });
  yield call(wait, 3000);
  yield put({ type: ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.SET_EMAIL_SENT, data: false });
}

export function* resendVerificationLink(): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.SET_IS_SUBMITTING, data: true });
  yield safeRequest(personService.resendVerificationLink, session);
  yield put({ type: ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.SET_IS_SUBMITTING, data: false });
  yield call(setEmailSent);
}

export const verifyEmailDialogSaga = [
  takeEvery(
    ACTION_TYPES.FREEMIUM.VERIFY_EMAIL_DIALOG.RESEND_VERIFICATION_LINK,
    resendVerificationLink,
  ),
];
