// @flow

import type { SessionType } from 'app/types/globalTypes';
import { DataRoomFeatureKey } from 'app/types/globalTypes.ts';
import type { PermissionsType } from 'app/types/permissionsTypes';
import { USER_TYPE_CODES_ORDER } from 'app/constants';
import { actionCodes, objectCodes, userPermissionDefaults } from 'app/utils/constants';
import { API1_ROOT, API2_ROOT, API3_ROOT, API1B_ROOT } from './request/constants';
import { get, post } from './request/api';

export const fetchPermissions = (session: SessionType): Promise<PermissionsType> => {
  const { dataroomId, userId } = session;
  return get(session, `${API2_ROOT}/datarooms/${dataroomId}/users/${userId}/permission`).then(
    result =>
      result.data.ObjectActions.reduce((objectActionTable, userPermission) => {
        const object = objectCodes[userPermission.ObjectCode];
        const action = actionCodes[userPermission.ActionCode];
        if (object) {
          return {
            ...objectActionTable,
            [object]: {
              ...objectActionTable[object],
              [action]: true,
            },
          };
        }
        return objectActionTable;
      }, userPermissionDefaults),
  );
};

export const fetchQnaRoles = (session: SessionType) =>
  get(session, `${API1_ROOT}/shared/qnaRoles`).then(result => result.data);

const mapUserType = userType => ({
  userType: userType.UserType,
  userTypeCode: userType.UserTypeCode,
  isGuest: userType.IsGuest === 'True',
  qnaRoles: userType.QnaRoles,
});

export const fetchUserTypes = (session: SessionType) =>
  get(session, `${API1_ROOT}/shared/userTypes`).then(result => result.data.map(mapUserType));

export const getFilteredUserQnaRoles = (
  userQnaRoles: Array<Object>,
  filteredQnaRoles: Array<Object>,
): Array<Object> =>
  userQnaRoles.filter(
    role => !filteredQnaRoles || filteredQnaRoles.some(qnaRole => role.Code === qnaRole.Code),
  );

export const fetchAvailableUserTypesAndQnaRoles = (session: SessionType) =>
  Promise.all([fetchUserTypes(session), fetchQnaRoles(session)]).then(
    ([rawUserTypes, qnaRolesFiltered]) => {
      const userTypes = rawUserTypes.map(userType => ({
        ...userType,
        qnaRoles: getFilteredUserQnaRoles(userType.qnaRoles, qnaRolesFiltered),
      }));
      const sortedUserTypes = userTypes.sort(
        (a, b) =>
          USER_TYPE_CODES_ORDER.indexOf(a.userTypeCode) -
          USER_TYPE_CODES_ORDER.indexOf(b.userTypeCode),
      );
      return sortedUserTypes;
    },
  );

export const fetchDefaultInvite = (session: SessionType) =>
  get(session, `${API1B_ROOT}/datarooms/${session.dataroomId}/inviteEmail`).then(result => ({
    subject: result.data.Subject,
    body: result.data.Body,
    attachments: result.data.Attachments.map(attachment => ({
      id: attachment.AttachmentId,
      fileName: attachment.FileName,
      fileExtension: attachment.FileExtension,
    })),
  }));

export const sendInvitation = (session: SessionType, invitationPayload: Object) =>
  post(session, `${API3_ROOT}/people/invitePeople`, invitationPayload);

export const emailPeople = (session: SessionType, invitationPayload: Object) =>
  post(session, `${API3_ROOT}/people/emailPeople`, invitationPayload);

export const addAttachment = (session: SessionType, attachmentPayload: Object) =>
  post(
    session,
    `/DocumentProvider/${session.dataRoomProjectName}|${session.dataroomId}/${session.userId}/SaveAttachment.ashx`,
    attachmentPayload,
    {
      headers: {
        ReturnResultAsJson: '1',
        'Content-Type': 'multipart/form-data',
      },
    },
  ).then(result => ({
    id: result.data.AttachmentId,
    fileName: result.data.FileName,
    fileExtension: result.data.FileExtension,
  }));

export const fetchLicences = (session: SessionType) =>
  get(session, `${API1_ROOT}/licensing/dataroom/${session.dataroomId}`).then(result => {
    const allConsumables = result.data.consumablesWithDetails || [];
    const billingOnFirstGuestInvite = result.data.features.billingOnFirstGuestInvite || false;
    const licenses = allConsumables.find(item => item.name === 'Licenses');
    const features = result.data.features || {};
    if (!licenses) {
      throw new Error("Can't find Licenses");
    }
    const allowd = Number.parseInt(licenses.allowed, 10);
    return {
      allowed: Number.isNaN(allowd) ? null : allowd,
      consumed: licenses.consumed,
      billingOnFirstGuestInvite,
      features,
    };
  });

export const fetchThirdPartyIntegrationsForDataRoom = (session: SessionType) =>
  get(
    session,
    `${API1B_ROOT}/thirdpartyintegrations/forDataRoom?dataRoomId=${session.dataroomId}`,
  ).then(result =>
    result.data.map(provider => ({
      systemName: provider.SystemName,
      displayName: provider.DisplayName,
      isAllowed: provider.IsAllowed,
    })),
  );

export const fetchThirdPartyIntegrationsForSecurityGroup = (
  session: SessionType,
  subteamId: number,
) =>
  get(
    session,
    `${API1B_ROOT}/thirdpartyintegrations/forSecurityGroup?dataRoomId=${session.dataroomId}&securityGroupId=${subteamId}`,
  ).then(result =>
    result.data.map(provider => ({
      systemName: provider.SystemName,
      displayName: provider.DisplayName,
      isAllowed: provider.IsAllowed,
    })),
  );

export const fetchDataroomFeatures = async (
  session: SessionType,
  keys: DataRoomFeatureKey[],
): Promise<Object> => {
  const { data: features } = await get(
    session,
    `${API1B_ROOT}/datarooms/${session.dataroomId}/features`,
    undefined,
    { params: { keys } },
  );

  const { allowEnableGuestAccess, allowInviteGuest } = features || {};
  return { isAllowInviteGuest: allowInviteGuest, isAllowEnableGuestAccess: allowEnableGuestAccess };
};
