// @flow

import { ACTION_TYPES } from 'app/constants';
import translate from 'app/i18n/translate';
import type { Tree, Node, TenderSubmissionSecurityContextType } from '../securityTypes';
import type { EditableAttributeList } from '../helpers/treeHelpers';

export const expandFolder = (folderId: number) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.TOGGLE_FOLDER,
  payload: folderId,
});

export const setExpandedFolders = (newExpanded: Array<number>) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_EXPANDED_FOLDERS,
  payload: newExpanded,
});

export const updateSubmissionTreeIndex = (newIndex: Array<number | string>, newTree: Tree) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.UPDATE_SUBMISSION_TREE_INDEX,
  payload: {
    newIndex,
    newTree,
  },
});

export const setSubmissionIndex = (rootNode: Node, tree: Tree, expanded: Array<number>) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_SUBMISSION_INDEX,
  payload: {
    rootNode: { ...rootNode, name: translate('SubmissionIndex') },
    tree,
    expanded,
  },
});

export const initTenderSubmissionsData = () => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.INIT_DATA,
});

export const setSecuritySettings = (payload: {
  documentIndexItemId: string,
  subteamId: string,
  value: boolean | ?number,
  attr: EditableAttributeList,
}) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_SECURITY_SETTINGS,
  payload,
});

export const resetTenderSubmissionSecuritySettings = () => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.RESET,
});

export const saveTenderSubmissionSecuritySettings = () => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SAVE,
});

export const updateSubmissionTree = (payload: number) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.UPDATE_SUBMISSION_TREE,
  payload,
});

export const setTeamContext = (context: TenderSubmissionSecurityContextType) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_TEAM_CONTEXT,
  data: context,
});

export const selectBidder = (data: Object) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SELECT_BIDDER,
  ...data,
});

export const setRootFolders = (data: Object) => ({
  type: ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_ROOT_FOLDERS,
  data,
});
