// @flow

import type { RequestResponse } from 'app/types/requestTypes';
import type {
  SessionErrorType,
  SessionErrorsType,
  AppErrorTypes,
  FieldError,
} from 'app/types/errorTypes';

export const ERROR_TYPE = {
  SERVER_ERROR: 'ServerError',
  SESSION_ERROR: 'SessionTimeoutError',
  API_ERROR: 'ApiError',
  MODEL_ERROR: 'ModelError',
  USER_EXISTS_WITH_EMAIL: 'UserExistsWithEmail',
  BAD_REQUEST: 'BadRequest',
};

const defaultAction: SessionErrorType = {
  code: '0',
  actionUrl: 'SessionTimeout.asp',
};

const forceLoggedOut: SessionErrorType = {
  code: '3',
  actionUrl: 'ForceLoggedOut.asp',
};

const unhandledTermsOfAccess: SessionErrorType = {
  code: '5',
  actionUrl: 'Logout.asp',
};

const sessionReAuthentication: SessionErrorType = {
  code: '6',
  actionUrl: 'SessionReAuthentication.asp',
};

const accessDenied: SessionErrorType = {
  code: '9',
  actionUrl: 'Logout.asp',
};

export const sessionErrors: SessionErrorsType = {
  forceLoggedOut,
  accessDenied,
  unhandledTermsOfAccess,
  sessionReAuthentication,
  defaultAction,
};

export const SPA_ERROR = 'SPA_ERROR';

type AnsaradaErrorResult = {
  ErrorCode?: string,
  ErrorId?: string,
  Message: string,
};

type RequestError = RequestResponse<AnsaradaErrorResult>;

// TODO Change this weird function https://jira.ansarada.com/browse/DR-17975
export function AppError<T>(type: AppErrorTypes, details: T) {
  return {
    name: SPA_ERROR,
    type,
    details,
  };
}

export class BadRequestError<T> {
  name: any;

  type: AppErrorTypes;

  details: T;

  constructor(type: AppErrorTypes, details: T) {
    this.name = ERROR_TYPE.BAD_REQUEST;
    this.type = type;
    this.details = details;
  }
}

// $FlowFixMe
export const getAppError = (error: RequestError): Error<*> => {
  const data = error.data || {};

  switch (error.status) {
    case 401: {
      const code = data.ErrorCode || sessionErrors.accessDenied.code;
      throw new AppError(ERROR_TYPE.SESSION_ERROR, code);
    }
    default: {
      throw new AppError(ERROR_TYPE.SERVER_ERROR, data.ErrorId);
    }
  }
};

export const errorValidate = (field?: Array<FieldError>, details: string) => {
  const f = field || [];

  f.push({
    details,
  });

  return f;
};

type ParsedModelError = {
  detail: string,
  name: string,
};

export const mapErrors = (data: Object, errors: Array<ParsedModelError>) => {
  const keys = Object.keys(data);

  return errors.reduce((acc, error) => {
    const key = keys.find(k => k.toLowerCase() === error.name.toLowerCase());
    if (key) {
      const fieldError = { code: error.detail };
      if (acc[key]) {
        acc[key].push(fieldError);
      } else {
        acc[key] = [fieldError];
      }
    }
    return acc;
  }, {});
};

export function ModelError(errors: Object) {
  this.type = ERROR_TYPE.MODEL_ERROR;
  this.errors = errors;
  this.message = ERROR_TYPE.MODEL_ERROR;
}
