// @flow

import * as url from 'app/uri/url';
import translate from 'app/i18n/translate';
import { PAGE_SECTIONS, ACTION_TYPES, COPY_SECURITY_STATUSES } from 'app/constants';
import * as helpers from 'app/pages/security/helpers/securityHelpers';
import { collapseParentFolder, collapseChildrenFolder } from 'app/pages/security/securityActions';
import * as treeHelpers from 'app/pages/security/helpers/treeHelpers';
import * as actions from 'app/components/bannerMessages/bannerMessagesActions';
import { getFromId } from 'app/pages/security/copySecurityDialog/copySecurityDialogHelpers';
import type { Team } from 'app/types/teamsTypes';
import type { CopySecurityType } from 'app/pages/security/securityTypes';
import type { DocumentWatermarkEditedEventTrackingProps } from 'app/utils/segment/segment-events';
import * as documentService from 'app/services/document';
import * as securityService from 'app/services/security';
import * as teamService from 'app/services/team';
import { safeRequest } from 'app/utils/saga-helpers';
import { subjectSecuritySagas } from 'app/pages/security/subjectSecurityPage/subjectSecurityPageSagas';
import { select, spawn, call, put, all, takeEvery } from 'redux-saga/effects';
import { trackEvent } from 'app/utils/segment/segment-actions';
import { tenderSubmissionsSecuritySagas } from './tenderSubmissionsSecurityPage/tenderSubmissionsSecuritySagas';

export function* fetchWatermarks(): Generator<*, *, *> {
  const { session } = yield select();
  const [watermarkData, error] = yield safeRequest(securityService.fetchWatermarks, session);

  if (!error) {
    yield put({ type: ACTION_TYPES.SECURITY.WATERMARKS_FETCHED, data: watermarkData });
  }
}

export function* fetchTeams(bothSides?: boolean = false): Generator<*, Array<Team>, *> {
  const { session, security } = yield select();
  const params = bothSides ? {} : { isGuest: security.props.isGuest };
  const [teams, error] = yield safeRequest(teamService.getTeams, session, params);
  return error ? [] : teams;
}

export function* fetchSecurities(
  folderIds: ?number | Array<?number>,
  indexObjectCode: ?string,
  indexObjectId: ?number,
): Generator<*, *, *> {
  const { session, security } = yield select();
  const { subteamId } = security.props;

  const folders = folderIds instanceof Array ? folderIds : [folderIds];
  if (subteamId) {
    const [result, error] = yield safeRequest(
      securityService.getDocumentIndexSecurity,
      session,
      folders,
      [subteamId],
      indexObjectCode,
      indexObjectId,
    );
    if (!error) {
      return helpers.mapToSecurity(result.data);
    }
  }
  return {};
}

export function* toggleFolder(action: Object): Generator<*, *, *> {
  const folderId: number = action.data;
  const { session, security } = yield select();
  const { treeData, pageData, viewData } = security.documents;
  const node = treeHelpers.getNodeById(treeData.tree, folderId, true);

  if (treeHelpers.shouldLoad(node) && !viewData.expanded.includes(folderId)) {
    const [[children, error], securities] = yield all([
      safeRequest(documentService.fetchFolderChildren, session, folderId),
      call(fetchSecurities, folderId),
    ]);
    if (error) {
      return;
    }
    const { team } = pageData;
    const folderTree = treeHelpers.getTree(node, children, securities, [
      helpers.getPageSubteam(team),
    ]);
    const newIndex = [...treeData.index];
    const parentPosition = newIndex.findIndex(t => t === node.documentIndexItemId);
    const childrenId = Object.keys(folderTree);
    newIndex.splice(parentPosition + 1, 0, ...childrenId);

    yield put({
      type: ACTION_TYPES.SECURITY.TREE_EXPAND,
      data: {
        index: newIndex,
        childTree: {
          ...folderTree,
          [node.documentIndexItemId]: {
            ...node,
            children: childrenId,
          },
        },
      },
    });
  }

  const collapseSingleAction = collapseParentFolder(folderId, viewData.expanded);
  yield put(collapseSingleAction);

  const childrenToCollapse = helpers.fetchAllUnfoldedChildrenIds(
    folderId,
    node,
    viewData.expanded,
    treeData.tree,
  );

  const collapseMultipleAction =
    childrenToCollapse && childrenToCollapse.length
      ? collapseChildrenFolder(childrenToCollapse, collapseSingleAction.data)
      : null;
  if (collapseMultipleAction) {
    yield put(collapseMultipleAction);
  }
}

export function* fetchFolderChildrenStats(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  const { documentIndexItemId, attr } = action.data;
  const folderId: string = documentIndexItemId;
  if (folderId[0] !== 'f') {
    return;
  }
  const id = parseInt(folderId.split('-')[1], 10);
  const [stats, error] = yield safeRequest(documentService.folderChildrenStats, session, id);
  if (!error) {
    const description = helpers.getText(stats.data, attr);
    if (description) {
      yield put(actions.displayInfoMessage(description));
    }
  }
}

export function* saveSettings(): Generator<*, *, *> {
  const { session, security } = yield select();
  const { edited, tree } = security.documents.treeData;
  yield put({ type: ACTION_TYPES.SECURITY.TREE_SAVE_BEGIN });
  const { itemsWithChangedAccess, itemsWithChangedSecurity } = helpers.mapSecurityToApi(
    edited,
    tree,
  );

  if (itemsWithChangedAccess.length) {
    yield safeRequest(securityService.updateAccess, session, itemsWithChangedAccess);
  }

  if (itemsWithChangedSecurity.length) {
    yield safeRequest(securityService.updateSecurity, session, itemsWithChangedSecurity);
  }
  yield put({
    type: ACTION_TYPES.SECURITY.TREE_UPDATE,
    data: security.props.subteamId,
  });

  yield put({ type: ACTION_TYPES.SECURITY.TREE_SAVE_END });
}

export function* saveItemSettings(): any {
  const { security, session } = yield select();
  yield put({ type: ACTION_TYPES.SITE.DIALOG_SAVE_PROGRESS_BEGIN });
  const { edited, item, securities } = security.documents.itemData;
  const data = helpers.mapItemSecurityToApi(item, securities, edited);
  if (data.securityUpdated) {
    yield safeRequest(securityService.updateSecurity, session, data.security);
  }
  if (data.accessUpdated) {
    yield safeRequest(securityService.updateAccess, session, data.security);
  }
  yield put({ type: ACTION_TYPES.SITE.DIALOG_SAVE_PROGRESS_END });
  yield put({ type: ACTION_TYPES.SITE.DIALOG_TOGGLE });
  yield put({ type: ACTION_TYPES.SECURITY.ITEM_EDITED_CLEAR });
}

export function* publishSecurityEditedSegmentEvent(): any {
  const { security } = yield select();
  const { edited, item, securities } = security.documents.itemData;
  const data = helpers.mapItemSecurityToApi(item, securities, edited);
  for (const sec of data.security) {
    if (sec.isSecurityUpdated) {
      trackEvent('f_dataroom_document-index-item.security-edited', {
        item_type: sec.isFolder ? 'Folder' : 'Document',
        team_id: sec.securityGroupId,
        saving_status: sec.permissions.allowSaving ? 'enabled' : 'disabled',
        printing_status: sec.permissions.allowPrinting ? 'enabled' : 'disabled',
        editing_status: sec.permissions.allowEditing ? 'enabled' : 'disabled',
        copying_status: sec.permissions.allowCopying ? 'enabled' : 'disabled',
      });
    }
  }
}

export function* publishTeamAccessEditedSegmentEvent(): any {
  const { security } = yield select();
  const { edited, tree } = security.documents.treeData;
  const { itemsWithChangedAccess } = helpers.mapSecurityToApi(edited, tree, true);

  if (itemsWithChangedAccess.length > 0) {
    const teamId = itemsWithChangedAccess[0].securityGroupId;
    const setAccessStatuses = new Set(
      itemsWithChangedAccess.filter(item => item.isAccessUpdated).map(item => item.allowAccess),
    );
    const uniqueTrackingAccessStatuses = [...setAccessStatuses].map(item => item);

    for (const accessStatus of uniqueTrackingAccessStatuses) {
      const obj = {
        team_id: teamId,
        access_status: accessStatus ? 'enabled' : 'disabled',
      };
      trackEvent('f_dataroom_team.access-edited', obj);
    }
  }
}

export function* publishTeamWatermarkEditedSegmentEvent(): any {
  const { security } = yield select();
  const { edited, tree } = security.documents.treeData;
  const { itemsWithChangedSecurity } = helpers.mapSecurityToApi(edited, tree, true);

  const { watermarks } = security.documents.pageData;
  const watermarkById = {};
  watermarks.forEach(watermark => {
    watermarkById[watermark.id] = watermark;
  });
  const watermarkChangedIds = itemsWithChangedSecurity.filter(item => {
    return item.watermarkId > -1 && item.isWatermarkUpdated;
  });

  if (watermarkChangedIds.length > 0) {
    const teamId = itemsWithChangedSecurity[0].securityGroupId;
    const defaultWatermarkNames = ['Confidential', 'Standard'];
    const setWaterMarkSettings = new Set(
      watermarkChangedIds.map(item => {
        if (watermarkById[item.watermarkId]) {
          return defaultWatermarkNames.includes(watermarkById[item.watermarkId].name)
            ? watermarkById[item.watermarkId].name
            : 'Custom';
        }
        return 'None';
      }),
    );
    const uniqueWaterMarkSetting = [...setWaterMarkSettings];

    for (const watermarkSetting of uniqueWaterMarkSetting) {
      const obj = {
        team_id: teamId,
        watermark: watermarkSetting,
      };

      trackEvent('f_dataroom_team.watermark-edited', obj);
    }
  }
}
export function* publishTeamSecurityEditedSegmentEvent(): any {
  const { security } = yield select();
  const { edited, tree } = security.documents.treeData;
  const { itemsWithChangedSecurity } = helpers.mapSecurityToApi(edited, tree, true);
  const permissionsChanged = itemsWithChangedSecurity.filter(item => {
    return (
      Object.values(item.permissions).filter(permission => {
        return permission !== null;
      }).length > 0 && item.isSecurityUpdated
    );
  });

  if (permissionsChanged.length > 0) {
    const teamId = permissionsChanged[0].securityGroupId;
    for (const index in permissionsChanged) {
      if (Object.prototype.hasOwnProperty.call(permissionsChanged, index)) {
        const permissionChanged = permissionsChanged[index];
        if (permissionChanged.permissions.tracking === false) {
          permissionChanged.permissions.allowCopying = true;
          permissionChanged.permissions.allowEditing = true;
          permissionChanged.permissions.allowPrinting = true;
          permissionChanged.permissions.allowSaving = true;
        }
      }
    }
    const setPermissions = new Set(
      permissionsChanged.map(item => JSON.stringify(item.permissions)),
    );

    const uniqueTrackingPermissions = [...setPermissions].map(item => JSON.parse(item));

    for (const sec of uniqueTrackingPermissions) {
      const obj = {
        team_id: teamId,
        saving_status: sec.allowSaving ? 'enabled' : 'disabled',
        printing_status: sec.allowPrinting ? 'enabled' : 'disabled',
        editing_status: sec.allowEditing ? 'enabled' : 'disabled',
        copying_status: sec.allowCopying ? 'enabled' : 'disabled',
      };

      trackEvent('f_dataroom_team.security-edited', obj);
    }
  }
}

export function* publishDocumentSecurityEditedSegmentEvent(): Generator<*, *, *> {
  const { security } = yield select();
  const {
    itemData: { edited: editedSubteams, item },
    pageData: { watermarks },
  } = security.documents;

  const watermarkNames = {};
  watermarks.forEach(watermark => {
    let watermarkName = 'custom';
    if (watermark.name === 'Confidential') {
      watermarkName = 'confidential';
    } else if (watermark.name === 'Standard') {
      watermarkName = 'standard';
    }
    watermarkNames[String(watermark.id)] = watermarkName;
  });

  Object.keys(editedSubteams).forEach(subteamId => {
    const editedSubteam = editedSubteams[subteamId];
    const { watermarkId, allowAccess } = editedSubteam;

    // Document Management Event tracking - watermark edited
    if (watermarkId !== undefined) {
      const obj: DocumentWatermarkEditedEventTrackingProps = {
        item_type: item.isFolder ? 'Folder' : 'Document',
        team_id: Number(subteamId),
        watermark: watermarkId ? watermarkNames[String(watermarkId)] : 'none',
      };
      trackEvent('f_dataroom_document-index-item.watermark-edited', obj);
    }

    // Document Management Event tracking - access edited
    if (allowAccess !== undefined) {
      trackEvent('f_dataroom_document-index-item.access-edited', {
        item_type: item.isFolder ? 'Folder' : 'Document',
        team_id: Number(subteamId),
        access_status: allowAccess ? 'enabled' : 'disabled',
      });
    }
  });
}

// TODO Just use Team or SubTeam type instead of creating a new subSet of type. https://jira.ansarada.com/browse/DR-17997
export function* fetchTeamBySubteamId(id: number): Generator<*, *, *> {
  const { session } = yield select();
  const [subteam, subTeamError] = yield safeRequest(teamService.getSubteam, session, id);
  if (!subTeamError) {
    const [teamData, teamError] = yield safeRequest(teamService.getTeam, session, subteam.teamId);
    if (!teamError) {
      const team = {
        id: teamData.teamId,
        subteamId: teamData.primarySubteamId,
        name: teamData.name,
        colour: teamData.colour,
        useSod: teamData.useSod,
      };
      if (teamData.primarySubteamId === id) {
        return team;
      }
      return {
        ...team,
        subteams: [
          {
            id: subteam.subteamId,
            name: subteam.name,
            useSod: subteam.useSod,
          },
        ],
      };
    }
  }
  return {};
}

export function* initialState(action: Object): Generator<*, *, *> {
  const { subteamId } = action.data;

  if (!subteamId) return;

  yield spawn(fetchWatermarks);

  const { session } = yield select();

  const [folderId, error] = yield safeRequest(documentService.rootFolder, session);
  if (error) {
    return;
  }

  const [index, serviceError] = yield safeRequest(
    documentService.fetchFolderChildren,
    session,
    folderId,
  );
  if (serviceError) {
    return;
  }
  const [securities, teams, team] = yield all([
    call(fetchSecurities, [null, folderId]),
    call(fetchTeams),
    call(fetchTeamBySubteamId, subteamId),
  ]);
  const subteamSettings = [helpers.getPageSubteam(team)];
  const rootNode = treeHelpers.getRootNode(folderId, index, securities, subteamSettings);
  const initTree = treeHelpers.getTree(rootNode, index, securities, subteamSettings);
  yield put({ type: ACTION_TYPES.USER_PERMISSIONS_FETCH });
  yield put({
    type: ACTION_TYPES.SECURITY.STATE_INIT,
    data: {
      pageData: {
        teams,
        team,
        rootFolderId: folderId,
      },
      viewData: {
        expanded: [folderId],
      },
    },
  });
  yield put({
    type: ACTION_TYPES.SECURITY.TREE_INIT,
    data: {
      rootNode,
      tree: initTree,
    },
  });
}

export function* treeExpand(): Generator<*, *, *> {
  const { security, session } = yield select();
  const { rootFolderId, team } = security.documents.pageData;
  const subteam = helpers.getPageSubteam(team);

  const [index, error] = yield safeRequest(documentService.documentIndex, session);
  if (error) {
    return;
  }
  const expandedFolders = helpers.getExpandedFolderIds(index.data);
  const expandedChildren = helpers.getExpandedChildren(expandedFolders, index.data);
  const securities = yield call(fetchSecurities, expandedFolders);
  const { tree } = security.documents.treeData;
  const rootNode = treeHelpers.getNodeById(tree, rootFolderId, true);
  if (!rootNode) {
    // This happens when a user clicks expand all before tree data is ready.
    return;
  }

  const subteamSettings = [subteam];
  const newTree = Object.assign(
    {
      [rootNode.documentIndexItemId]: rootNode,
    },
    treeHelpers.getTree(rootNode, expandedChildren, securities, subteamSettings, tree),
  );

  yield put({
    type: ACTION_TYPES.SECURITY.TREE_EXPAND_ALL,
    data: {
      tree: newTree,
      index: Object.keys(newTree),
    },
  });

  yield put({
    type: ACTION_TYPES.SECURITY.TREE_TOGGLED,
    data: expandedFolders,
  });
}

export function* treeCollapse(): Generator<*, *, *> {
  const { security } = yield select();
  const { rootFolderId } = security.documents.pageData;
  yield put({
    type: ACTION_TYPES.SECURITY.TREE_TOGGLED,
    data: [rootFolderId],
  });
}

const getId = (itemId: string | Array<string>): number => {
  const id = Array.isArray(itemId) ? itemId[0] : itemId;
  return parseInt(id, 10);
};

export function* fetchItem(
  itemId: number,
  itemType: string,
  params: { isGuest: boolean },
): Generator<*, *, *> {
  const { session } = yield select();
  if (itemType === 'folder') {
    const [result, error] = yield safeRequest(
      documentService.fetchFolder,
      session,
      itemId,
      params.isGuest,
    );
    return error ? null : result;
  }
  if (itemType === 'document') {
    const [result, error] = yield safeRequest(documentService.fetchDocument, session, itemId);
    return error ? null : result;
  }
  return null;
}

// TODO  I guess it's useless like security/content. https://jira.ansarada.com/browse/DR-17997
export function* fetchItemQuery(): Generator<*, *, *> {
  const { location, session } = yield select();
  const hasQuery = !!location.query;
  const isFolder = hasQuery && location.query.folderId;
  const isDocument = hasQuery && location.query.documentId;

  if (isFolder) {
    const folderId = getId(location.query.folderId);
    const [result, error] = yield safeRequest(documentService.fetchFolder, session, folderId);
    return error ? null : result;
  }

  if (isDocument) {
    const documentId = getId(location.query.documentId);
    const [result, error] = yield safeRequest(documentService.fetchDocument, session, documentId);
    return error ? null : result;
  }

  return null;
}

export function* fetchDataForSecurityDialog(action: Object = {}): Generator<*, *, *> {
  const { itemId, itemType } = action.data;

  const [item, teams] = yield all([
    call(fetchItem, itemId, itemType, { isGuest: false }),
    call(fetchTeams, true),
  ]);

  yield put({
    type: ACTION_TYPES.SECURITY.TEAMS_FETCHED,
    data: teams,
  });
  yield put({ type: ACTION_TYPES.SECURITY.WATERMARKS_FETCH });

  if (item) {
    const teamAndSubteamIds = teams.reduce((acc, team) => {
      const subteamIds = team.subteams ? team.subteams.map(subteam => subteam.id) : [];
      return [...acc, team.subteamId, ...subteamIds];
    }, []);

    const { session } = yield select();
    const [itemSecurities, error] = yield safeRequest(
      securityService.getDocumentIndexSecurity,
      session,
      [item.parentId],
      teamAndSubteamIds,
    );

    if (error) {
      return;
    }

    const securities = helpers.getTeamSubTeamSecurities(item, teams, itemSecurities);

    yield put({ type: ACTION_TYPES.USER_PERMISSIONS_FETCH });

    yield put.resolve({
      type: ACTION_TYPES.SECURITY.ITEM_FETCHED,
      data: {
        item,
        securities,
      },
    });

    const itemTranslation = item.isFolder ? translate('Folder') : translate('Document');
    const securityTranslation = translate('Security').toLowerCase();
    const title = `${itemTranslation} ${securityTranslation}`;

    yield put({ type: ACTION_TYPES.SITE.DIALOG_SET_TITLE, title });
    yield put({ type: ACTION_TYPES.SITE.DIALOG_TOGGLE_SPINNER });
  }
}

export function* fetchItemSecurity(action: Object = {}): Generator<*, *, *> {
  const data = action.data || {};
  const { itemId, itemType } = data;
  const popDialog = itemId && itemType;
  const [item, teams] = yield all([
    popDialog ? call(fetchItem, itemId, itemType, { isGuest: false }) : call(fetchItemQuery),
    call(fetchTeams, true),
  ]);

  yield put({
    type: ACTION_TYPES.SECURITY.TEAMS_FETCHED,
    data: teams,
  });

  yield put({ type: ACTION_TYPES.SECURITY.WATERMARKS_FETCH });

  if (item) {
    const teamAndSubteamIds = teams.reduce((acc, team) => {
      const subteamIds = team.subteams ? team.subteams.map(subteam => subteam.id) : [];
      return [...acc, team.subteamId, ...subteamIds];
    }, []);

    const { session } = yield select();
    const [itemSecurities, error] = yield safeRequest(
      securityService.getDocumentIndexSecurity,
      session,
      [item.parentId],
      teamAndSubteamIds,
    );
    if (error) {
      return;
    }
    const securities = helpers.getTeamSubTeamSecurities(item, teams, itemSecurities);
    yield put({ type: ACTION_TYPES.USER_PERMISSIONS_FETCH });
    yield put.resolve({
      type: ACTION_TYPES.SECURITY.ITEM_FETCHED,
      data: {
        item,
        securities,
      },
    });
    if (popDialog) {
      const itemTranslation = item.isFolder ? translate('Folder') : translate('Document');
      const securityTranslation = translate('Security').toLowerCase();
      const title = `${itemTranslation} ${securityTranslation}`;
      yield put({ type: ACTION_TYPES.SITE.DIALOG_OPEN, data: title });
    }
  }
}

const getTeamBySubteamId = (teams, subteamId) =>
  teams
    .reduce((flattenedTeams, team) => flattenedTeams.concat(team).concat(team.subteams || []), [])
    .find(team => team.subteamId === subteamId);

export function* copySecurity(): Generator<*, *, *> {
  const { session, security, teams, page } = yield select();
  const copyData = security.documents.copySecurityDialog;
  if (!copyData.from) return;

  const fromId = getFromId(copyData.from);
  const fromTeam = getTeamBySubteamId(teams.pageData.teams, fromId);

  for (let i = 0; i < copyData.to.length; i += 1) {
    const to = copyData.to[i];
    if (to.selected) {
      const toId = to.id;
      yield put.resolve({
        type: ACTION_TYPES.SECURITY.COPY_FORM_STATUS_UPDATE,
        data: { id: toId, status: 'InProgress' },
      });
      if (page.section === PAGE_SECTIONS.TEAMS) {
        // When on the teams page
        // and the source team has security enabled
        // update that property in the target team
        if (fromTeam.isSecurityEdited) {
          yield put.resolve({
            type: ACTION_TYPES.TEAMS.UPDATE_TEAM,
            data: {
              subteamId: toId,
              isSecurityEdited: true,
            },
          });
        }
      }

      const [result, error] = yield safeRequest(
        securityService.copySecurity,
        session,
        {
          fromSubteamId: fromId,
          toSubteamId: toId,
        },
        copyData.copyOptions,
      );
      if (error) {
        break;
      }
      yield put.resolve({
        type: ACTION_TYPES.SECURITY.COPY_FORM_STATUS_UPDATE,
        data: {
          id: toId,
          status: result && result.err ? COPY_SECURITY_STATUSES.ERROR : COPY_SECURITY_STATUSES.DONE,
        },
      });
    }
  }
}

export function* copySecurityInit(actionData: CopySecurityType): Generator<*, *, *> {
  yield put.resolve({
    type: ACTION_TYPES.SECURITY.COPY_FORM_UPDATE,
    data: actionData.data,
  });
  yield put.resolve({
    type: ACTION_TYPES.TEAMS.COPY_SECURITY,
  });
  yield put({
    type: ACTION_TYPES.DIALOG_TOGGLE,
    data: translate('CopySecurity'),
  });
}

export function* exportSecurity(): Generator<*, *, *> {
  const { session, security } = yield select();
  const { subteamId } = security.props;

  const uri = url.generateLegacyURL('DocumentSecurityReport_Excel.asp', session, 'ExcelReports');
  helpers.openExportSecurityForm(uri, subteamId);
}

export function wrapTaskWithLoadingBar(task: () => *) {
  return function* withLoadingBar(action: ?Object): Generator<*, *, *> {
    yield put({ type: ACTION_TYPES.LOADINGBAR_LOADING_TOGGLE });
    yield call(task, action);
    yield put({ type: ACTION_TYPES.LOADINGBAR_LOADING_TOGGLE });
  };
}

export const securitySagas = [
  ...tenderSubmissionsSecuritySagas,
  ...subjectSecuritySagas,
  takeEvery(ACTION_TYPES.SECURITY.DATA_FETCH_INITIAL, wrapTaskWithLoadingBar(initialState)),
  takeEvery(ACTION_TYPES.SECURITY.TREE_FETCH_EXPAND_ALL, wrapTaskWithLoadingBar(treeExpand)),
  takeEvery(ACTION_TYPES.SECURITY.OPEN_SECURITY_DIALOG, fetchDataForSecurityDialog),
  takeEvery(ACTION_TYPES.SECURITY.ITEM_SECURITY_FETCH, fetchItemSecurity),
  takeEvery(
    ACTION_TYPES.SECURITY.ITEM_SECURITY_FETCH_INITIAL, // TODO same with security/content, can be removed. https://jira.ansarada.com/browse/DR-17997
    wrapTaskWithLoadingBar(fetchItemSecurity),
  ),
  takeEvery(ACTION_TYPES.SECURITY.SETTINGS_UPDATE, fetchFolderChildrenStats),
  takeEvery(ACTION_TYPES.SECURITY.FOLDER_TOGGLE, toggleFolder),
  takeEvery(ACTION_TYPES.SECURITY.TREE_FETCH_COLLAPSE_ALL, treeCollapse),
  takeEvery(ACTION_TYPES.SECURITY.SETTINGS_SAVE, saveSettings),
  takeEvery(ACTION_TYPES.SECURITY.ITEM_SETTINGS_SAVE, saveItemSettings),
  takeEvery(ACTION_TYPES.SEGMENT_EVENTS.SECURITY_EDITED, publishSecurityEditedSegmentEvent),
  takeEvery(
    ACTION_TYPES.SEGMENT_EVENTS.TEAM_SECURITY_EDITED,
    publishTeamSecurityEditedSegmentEvent,
  ),
  takeEvery(ACTION_TYPES.SEGMENT_EVENTS.TEAM_ACCESS_EDITED, publishTeamAccessEditedSegmentEvent),
  takeEvery(
    ACTION_TYPES.SEGMENT_EVENTS.TEAM_WATERMARK_EDITED,
    publishTeamWatermarkEditedSegmentEvent,
  ),
  takeEvery(
    ACTION_TYPES.SEGMENT_EVENTS.DOCUMENT_SECURITY_EDITED,
    publishDocumentSecurityEditedSegmentEvent,
  ),
  takeEvery(ACTION_TYPES.SECURITY.WATERMARKS_FETCH, fetchWatermarks),
  takeEvery(ACTION_TYPES.SECURITY.COPY_FORM_CONFIRM, copySecurity),
  takeEvery(ACTION_TYPES.SECURITY.COPY_SECURITY_INIT, copySecurityInit),
  takeEvery(ACTION_TYPES.SECURITY.EXPORT_SECURITY, exportSecurity),
];
