// @flow

import { put, takeEvery, select, call } from 'redux-saga/effects';
import { saveTeamThirdPartyIntegrations } from 'app/pages/teams/teamsSagas';
import { getFeature2Data } from 'app/utils/feature2';
import { ACTION_TYPES } from 'app/constants';
import { safeRequest } from 'app/utils/saga-helpers';
import * as manageRegistrationService from 'app/services/manageRegistration';
import * as teamService from 'app/services/team';
import { STATUSES } from './constants';

export function* fetchRegistrantList(): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.LOADING, data: true });
  const [data] = yield safeRequest(manageRegistrationService.fetchRegistrantList, session);
  if (Array.isArray(data.data)) {
    yield put({
      type: ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.SET,
      data: data.data,
    });
  }
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.LOADING, data: false });
}

export function* declineRegistration(action: Object): Generator<*, *, *> {
  const registrantId = action.data;
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.LOADING, data: true });
  yield safeRequest(manageRegistrationService.declineRegistration, session, registrantId);
  yield put({
    type: ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.SET,
    data: registrantId,
  });
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.LOADING, data: false });
  yield put({
    type: ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.TOGGLE_DIALOG,
    data: false,
  });
}

export function* deleteRegistration(action: Object): Generator<*, *, *> {
  const registrantId = action.data;
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.LOADING, data: true });
  yield safeRequest(manageRegistrationService.deleteRegistration, session, registrantId);
  yield put({
    type: ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.SET,
    data: registrantId,
  });
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.LOADING, data: false });
  yield put({
    type: ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.TOGGLE_DIALOG,
    data: false,
  });
}

export function* inviteRegistration(action: Object): Generator<*, *, *> {
  const { newTeamPayload, invitePayload } = action.data;
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.LOADING, data: true });
  const payload = invitePayload;
  let hasTeamError = false;
  if (newTeamPayload) {
    const [teamData, error] = yield safeRequest(
      teamService.saveTeam,
      session,
      newTeamPayload,
      newTeamPayload.isGuest,
    );
    if (!error) {
      payload.teamId = teamData.TeamId;
      payload.subteamId = teamData.PrimarySubteamId;
    } else {
      hasTeamError = true;
    }

    const isThirdPartyIntegrationsEnabled = getFeature2Data(
      'dil-1034-add-third-party-integration-checkbox-for-registration-form',
    );

    if (
      !hasTeamError &&
      isThirdPartyIntegrationsEnabled &&
      newTeamPayload.thirdPartyIntegrationsAllowed !== 'hidden'
    ) {
      const newFormData = teamService.mapTeamType(teamData);
      const { thirdPartyIntegrations } = newTeamPayload;

      yield call(
        saveTeamThirdPartyIntegrations,
        newFormData.subteamId,
        session,
        thirdPartyIntegrations,
      );
    }
  }

  if (payload.teamId && !hasTeamError) {
    const [response] = yield safeRequest(
      manageRegistrationService.inviteRegistration,
      session,
      payload,
    );
    if (response?.data) {
      const { data } = response;
      if (data.Status === STATUSES.INV) {
        yield put({
          type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.SET,
          data: data.Id,
        });
      }
    }
  }

  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.LOADING, data: false });
  if (!hasTeamError) {
    yield put({
      type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.TOGGLE_DIALOG,
      data: false,
    });
    yield put({
      type: ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.ACCEPT,
      data: payload,
    });

    yield put({ type: ACTION_TYPES.TEAMS.DATA_FETCH_INITIAL });
  }
}

export function* getRegistrationSettings(): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.GET_LOADING, data: true });
  const [data] = yield safeRequest(manageRegistrationService.getRegistrationSettings, session);
  if (data) {
    yield put({
      type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.SET,
      data: data.data,
    });
  }
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.GET_LOADING, data: false });
}

export function* updateRegistrationSettings(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.UPDATE_LOADING, data: true });
  const [data] = yield safeRequest(
    manageRegistrationService.updateRegistrationSettings,
    session,
    action.data,
  );
  if (data) {
    yield put({
      type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.SET,
      data: data.data,
    });
  }
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.TOGGLE_DIALOG, data: false });
  yield put({ type: ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.UPDATE_LOADING, data: false });
}

export const manageRegistrationSagas = [
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.FETCH, fetchRegistrantList),
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.FETCH, declineRegistration),
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.FETCH, deleteRegistration),
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.FETCH, inviteRegistration),
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.FETCH, getRegistrationSettings),
  takeEvery(ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.UPDATE, updateRegistrationSettings),
];
