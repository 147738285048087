// @flow

import { ACTION_TYPES } from 'app/constants';
import { omit } from 'ramda';
import type { Tree, TenderSubmissionSecurityContextType } from '../securityTypes';
import { setEditedState, clearEditedState, updateTreeSecurity } from '../helpers/treeHelpers';

type TenderSubmissionsSecurityState = {
  index: Array<number>,
  tree: Tree,
  edited: { [string]: boolean },
  expanded: Array<number>,
  isSaving: boolean,
  context: TenderSubmissionSecurityContextType,
  rootFolders: Array<Object>,
};

const initialState: TenderSubmissionsSecurityState = {
  index: [],
  tree: {},
  edited: {},
  expanded: [],
  isSaving: false,
  context: {
    bidders: [],
    subteamId: null,
    team: null,
    selectedBidderId: null,
  },
  rootFolders: [],
};

const tenderSubmissionsSecurity = (
  state: TenderSubmissionsSecurityState = initialState,
  action: Object,
) => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.INIT_DATA:
      return initialState;
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_SUBMISSION_INDEX: {
      const { rootNode, tree } = action.payload;
      const children = Object.keys(tree);
      const index = [rootNode.documentIndexItemId, ...children];
      return {
        ...state,
        index,
        tree: {
          ...tree,
          [rootNode.documentIndexItemId]: rootNode,
        },
        edited: {},
        expanded: [rootNode.id],
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.UPDATE_SUBMISSION_TREE_INDEX: {
      const { newIndex, newTree } = action.payload;
      return {
        ...state,
        index: newIndex,
        tree: {
          ...state.tree,
          ...newTree,
        },
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_EXPANDED_FOLDERS: {
      return {
        ...state,
        expanded: action.payload,
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_SECURITY_SETTINGS: {
      const { edited, tree } = state;
      const { documentIndexItemId, subteamId, value, attr } = action.payload;
      const { removeIds, nodes } = setEditedState(
        tree,
        documentIndexItemId,
        subteamId,
        attr,
        value,
      );

      return {
        ...state,
        tree: {
          ...tree,
          ...nodes,
        },
        edited: {
          ...omit(removeIds, edited),
          [`${documentIndexItemId}-${subteamId}`]: true,
        },
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.RESET: {
      const { tree } = state;
      const editedNodes = Object.keys(state.edited).map(id => clearEditedState(tree, id));
      const flatNodes = editedNodes.reduce((acc, cur) => ({ ...acc, ...cur }), {});
      return {
        ...state,
        tree: Object.assign({}, state.tree, flatNodes),
        edited: {},
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SAVE: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.UPDATE_SUBMISSION_TREE: {
      const subteamId = action.payload;

      return {
        ...state,
        tree: updateTreeSecurity(state.edited, state.tree, subteamId),
        edited: {},
        isSaving: false,
      };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_TEAM_CONTEXT: {
      return { ...state, context: { ...state.context, ...action.data } };
    }
    case ACTION_TYPES.SECURITY.TENDER_SUBMISSIONS.SET_ROOT_FOLDERS: {
      return { ...state, rootFolders: action.data };
    }
    default:
      return state;
  }
};

export default tenderSubmissionsSecurity;
