// @flow

import translate from 'app/i18n/translate';
import type { CopyTeamSecurityDataType } from 'app/pages/teams/teamsTypes';
import type { CopySecurityOptionsType } from 'app/pages/security/securityTypes';
import type { SessionType } from 'app/types/globalTypes';
import { API3_ROOT } from './request/constants';
import { get, post } from './request/api';

export const fetchWatermarks = (session: SessionType) =>
  get(session, `${API3_ROOT}/watermarks/getWatermarks`).then(result =>
    (result.data || []).map(watermark => ({
      id: watermark.Id,
      name: watermark.Name,
    })),
  );

export const getDocumentIndexSecurity = (
  session: SessionType,
  parentFolderIds: Array<?number>,
  securityGroupIds: Array<number>,
  indexObjectCode?: string = 'DRM',
  indexObjectId?: number = 0,
) =>
  post(session, `${API3_ROOT}/documentIndexSecurity/getDocumentIndexSecurity`, {
    parentFolderIds,
    securityGroupIds,
    indexObjectCode,
    indexObjectId,
  });

export const updateAccess = (session: SessionType, itemsWithChangedAccess: Array<Object>) =>
  post(
    session,
    `${API3_ROOT}/DocumentIndexSecurity/UpdateDocumentIndexAccess`,
    itemsWithChangedAccess,
  );

export const updateSecurity = (session: SessionType, itemsWithChangedSecurity: Array<Object>) =>
  post(
    session,
    `${API3_ROOT}/DocumentIndexSecurity/UpdateDocumentIndexSecurity`,
    itemsWithChangedSecurity,
  );

export const copySecurity = (
  session: SessionType,
  copyData: CopyTeamSecurityDataType,
  copyOptions?: CopySecurityOptionsType,
) =>
  post(session, `${API3_ROOT}/teams/security/CopySecurity`, {
    fromSubteamId: copyData.fromSubteamId,
    toSubteamIds: [copyData.toSubteamId],
    copyQnASubjectSecuritySettings: !copyOptions ? true : copyOptions.qnaSubjectSecuritySettings,
    copySubmissionIndexSecuritySettings: !copyOptions
      ? true
      : copyOptions.submissionIndexSecuritySettings,
    copyDocumentIndexSecuritySettings: !copyOptions
      ? true
      : copyOptions.documentIndexSecuritySettings,
  }).catch(() => {
    throw translate('BannerMessage', { TeamName: copyData.fromTeamName });
  });
