// @flow

import { window } from 'app/config';
import queryString from 'query-string';
import type { SessionType } from 'app/types/globalTypes';

export const generateLegacyURL = (
  value: string,
  session: SessionType,
  MvcArea: ?string,
  queries?: { [string]: string | number },
) => {
  //  FIXME: url encode validation needs to be added, DR-12566.
  const { origin } = window.location;
  const q = queryString.stringify(queries);
  const query = q ? `?${q}` : '';
  const { dataRoomProjectName, dataroomId, userId } = session;
  const exceptionAreas = ['ExcelReports', 'DocumentProvider'];

  let area = '';
  if (MvcArea) {
    area = exceptionAreas.includes(MvcArea) ? `${MvcArea}/` : `_mvc/${MvcArea}/`;
  }

  return `${origin}/${area}${dataRoomProjectName}%7C${dataroomId}/${userId}/${value}${query}`;
};

export const manageQnaSubjects = (session: SessionType) =>
  generateLegacyURL('ManageQuestionSubjects.asp', session);

export const questionListPageAsp = (session: SessionType) =>
  generateLegacyURL('ManageFilteredQuestions.asp', session);

// subTeamId is the security group ID
export const viewDocumentIndexAsTeam = (session: SessionType, subTeamId: number): string => {
  const { origin } = window.location;
  const { dataRoomProjectName, dataroomId, userId } = session;

  const url = `${origin}/_mvc/${dataRoomProjectName}%7C${dataroomId}/${userId}/spa/documents`;
  const params = [`view-as=${subTeamId}`].join('&');

  return `${url}?${params}`;
};

export const getLocationProps = (location?: Object): { [string]: string } => ({
  ...(location && queryString.parse(location.search)),
});

export const logout = (session: SessionType) => {
  const url = generateLegacyURL('Logout.asp', session);

  window.location.href = url;
};

// support URLs need to be updated according to the grapevine page
// https://ansarada.atlassian.net/wiki/spaces/OH/pages/121405887/OLH+used+in+the+product
export const fullTextSearch =
  'https://help.ansarada.com/collaborate-team-and-deal/access-transaction-room-files/search-for-files';
export const bulkDownload =
  'https://help.ansarada.com/high-performance-security-risk-and-compliance/transaction-room-security-controls/bulk-download-settings';
export const manageSOD =
  'https://help.ansarada.com/high-performance-security-risk-and-compliance/transaction-room-security-controls/manage-security-settings-for-microsoft-office';
export const roomRole =
  'https://help.ansarada.com/collaborate-team-and-deal/transaction-room-access/transaction-room-roles';
export const thirdpartyintegrations = 'https://help.ansarada.com/';
