// @flow

import * as R from 'ramda';
import { combineReducers } from 'redux';
import copySecurityDialogReducer from 'app/pages/security/copySecurityDialog/copySecurityDialogReducer';
import itemSecurityReducer from 'app/pages/security/itemSecurity/itemSecurityReducer';
import {
  setEditedState,
  clearEditedState,
  updateTreeSecurity,
} from 'app/pages/security/helpers/treeHelpers';
import { ACTION_TYPES } from 'app/constants';
import type {
  SecurityViewType,
  SecurityPageType,
  TreeReducerState,
} from 'app/pages/security/securityTypes';

const defaultViewData: SecurityViewType = {
  expanded: [],
  expandAll: false,
  dialogType: null,
};
export const viewDataReducer = (state: SecurityViewType = defaultViewData, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.FOLDER_TOGGLED: {
      return {
        ...state,
        expanded: action.data,
      };
    }
    case ACTION_TYPES.SECURITY.TREE_TOGGLED:
      return {
        ...state,
        expanded: action.data,
        expandAll: !state.expandAll,
      };
    case ACTION_TYPES.SECURITY.STATE_INIT:
      return {
        ...state,
        expanded: action.data.viewData.expanded,
      };
    case ACTION_TYPES.SECURITY.DIALOG_TYPE:
      return {
        ...state,
        dialogType: action.data,
      };
    default:
      return state;
  }
};

const defaultPageData: SecurityPageType = {
  watermarks: [],
  team: null,
  teams: [],
  rootFolderId: '',
};
export const pageDataReducer = (state: SecurityPageType = defaultPageData, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.STATE_INIT:
      return {
        ...state,
        ...action.data.pageData,
      };
    case ACTION_TYPES.SECURITY.TEAMS_FETCHED:
      return {
        ...state,
        teams: action.data,
      };
    case ACTION_TYPES.SECURITY.WATERMARKS_FETCHED:
      return {
        ...state,
        watermarks: action.data,
      };
    default:
      return state;
  }
};

const defaultTree: TreeReducerState = {
  tree: {},
  index: [],
  edited: {},
  isSaving: false,
};
export const treeReducer = (state: TreeReducerState = defaultTree, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.TREE_SAVE_BEGIN: {
      return {
        ...state,
        isSaving: !state.isSaving,
      };
    }
    case ACTION_TYPES.SECURITY.TREE_SAVE_END: {
      return {
        ...state,
        isSaving: false,
        edited: {},
      };
    }
    case ACTION_TYPES.SECURITY.TREE_INIT: {
      const { rootNode, tree } = action.data;
      const children = Object.keys(tree);
      const index = [rootNode.documentIndexItemId, ...children];
      return {
        ...state,
        index,
        tree: {
          ...tree,
          [rootNode.documentIndexItemId]: rootNode,
        },
        edited: {},
      };
    }
    case ACTION_TYPES.SECURITY.TREE_UPDATE: {
      const subteamId = action.data;
      return {
        ...state,
        tree: updateTreeSecurity(state.edited, state.tree, subteamId),
      };
    }
    case ACTION_TYPES.SECURITY.TREE_EXPAND: {
      const { index, childTree } = action.data;
      return {
        ...state,
        index,
        tree: {
          ...state.tree,
          ...childTree,
        },
      };
    }
    case ACTION_TYPES.SECURITY.TREE_EXPAND_ALL: {
      const { tree, index } = action.data;
      return {
        ...state,
        index,
        tree,
      };
    }
    case ACTION_TYPES.SECURITY.CLEAR_CHANGES: {
      const { tree } = state;
      const editedNodes = Object.keys(state.edited).map(id => clearEditedState(tree, id));
      const flatNodes = editedNodes.reduce((acc, cur) => ({ ...acc, ...cur }), {});
      return {
        ...state,
        tree: Object.assign({}, state.tree, flatNodes),
        edited: {},
      };
    }
    case ACTION_TYPES.SECURITY.SETTINGS_UPDATE: {
      const { edited, tree } = state;
      const { documentIndexItemId, subteamId, value, attr } = action.data;
      const { removeIds, nodes } = setEditedState(
        tree,
        documentIndexItemId,
        subteamId,
        attr,
        value,
      );
      const compoundId = `${documentIndexItemId}-${subteamId}`;

      return {
        ...state,
        tree: {
          ...tree,
          ...nodes,
        },
        edited: {
          ...R.omit(removeIds, edited),
          [compoundId]: true,
        },
      };
    }
    default:
      return state;
  }
};

const documentIndexReducer = combineReducers<_, *>({
  pageData: pageDataReducer,
  viewData: viewDataReducer,
  treeData: treeReducer,
  itemData: itemSecurityReducer,
  copySecurityDialog: copySecurityDialogReducer,
});

export default documentIndexReducer;
