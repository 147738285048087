// @flow

import type { SessionType } from 'app/types/globalTypes';
import type { People, Person, PersonHistoryRecord } from 'app/types/personTypes';
import { AppError, ERROR_TYPE, mapErrors, ModelError, SPA_ERROR } from 'app/utils/error';
import * as R from 'ramda';
import type { PeopleFormData } from '../pages/teams/teamsTypes';
import { PEOPLE_DIALOG_TYPES, USER_TYPE_CODES } from '../constants';
import { IP_FROM, PERSON_STATUS } from '../pages/teams/teamsConstants';
import { API3_ROOT, API1B_ROOT } from './request/constants';
import { get, post } from './request/api';

export const mapPersonType = (person: Object): Person => ({
  disabled: !!person.Disabled,
  email: person.Email,
  name: person.FullName,
  personId: person.PersonId,
  industry: person.Industry,
  invited: person.Invited,
  isGuest: person.IsGuest,
  title: person.JobTitle,
  lastLoginDate: person.LastLoginDate,
  loginCount: person.LoginCount,
  phoneNumber: person.PhoneNumber,
  qnaRoleCode: person.QnaRoleCode,
  subteamId: person.SubteamId,
  teamId: person.TeamId,
  userTypeCode: person.UserTypeCode ? person.UserTypeCode : 'None',
  termsOfAccess: person.TermsOfAccess,
  excludeFromReports: person.ExcludeFromReports,
  canEditTenderSubmissionBeforeClose: person.CanEditTenderSubmissionBeforeClose,
  canEditTenderSubmissionAfterClose: person.CanEditTenderSubmissionAfterClose,
  userLoggedInToDate: person.UserLoggedInToDate,
  isVerified: person.IsVerified,
  lastUserInvitationSentDate: person.LastUserInvitationSentDate,
});

export const fetchPeople = (session: SessionType) =>
  get(session, `${API3_ROOT}/people/getPeople`).then(result =>
    (result.data || []).map(mapPersonType),
  );

export const findPeople = (
  session: SessionType,
  userId?: number,
  teamId?: number,
  subTeamId?: number,
  isGuest?: boolean,
) => {
  const params = {};
  if (userId) {
    params.userId = userId;
  }
  if (teamId) {
    params.teamId = teamId;
  }
  if (subTeamId) {
    params.subTeamId = subTeamId;
  }
  if (isGuest === true || isGuest === false) {
    params.isGuest = isGuest;
  }

  return get(session, `${API3_ROOT}/people/findPeople`, params).then(result =>
    (result.data || []).map(mapPersonType),
  );
};

type IpAddressInfoType = {
  ipAddress: string,
  description: string,
};
export const parseIpAccessListFromString = (userIpAccessList: string): Array<IpAddressInfoType> =>
  userIpAccessList
    .split('\n')
    .map(ip => ip.trim())
    .filter(record => !!record)
    .map(record => {
      const splitRecords = record.split(',');
      return {
        ipAddress: splitRecords && splitRecords[0].trim(),
        description: splitRecords
          .slice(1)
          .join(',')
          .trim(),
      };
    })
    .filter(({ ipAddress }) => !!ipAddress)
    .map(({ ipAddress, description = '' }) => ({
      ipAddress,
      description,
    }));

const mapPeoplePayload = (data: PeopleFormData) => {
  const qnaRoleCode = data.qnaRoleCode === '' ? null : data.qnaRoleCode;
  const subteamId = data.userTypeCode === USER_TYPE_CODES.ADM ? null : data.subteamId;
  const { ipAccessFrom, userIpAccessList, ...rest } = data;
  const ipAccessList =
    ipAccessFrom === IP_FROM.SPECIFIED && userIpAccessList
      ? parseIpAccessListFromString(userIpAccessList)
      : [];
  return {
    ...rest,
    qnaRoleCode,
    subteamId,
    ipAccessList,
  };
};

const getFieldErrorModelName = (error: Object): string => {
  const fieldWithName = R.pathOr('', ['Source', 'Pointer'], error);
  return fieldWithName.split('.').pop();
};

const getFieldErrorForTranslation = (error: Object): string => {
  const errorValue = R.path(['Source', 'Pointer'], error);
  return `${error.Title},${errorValue}`;
};

const handleAddPeopleErrors = apiError => {
  const errors = apiError.details;
  const rawModelErrors = errors
    .filter(error => error.Code === ERROR_TYPE.MODEL_ERROR)
    .map(error => ({
      name: getFieldErrorModelName(error),
      detail: error.Title,
    }));
  const customModelErrors = errors
    .filter(error => error.Code === ERROR_TYPE.USER_EXISTS_WITH_EMAIL)
    .map(error => ({
      name: 'emails',
      detail: getFieldErrorForTranslation(error),
    }));
  const unexpectedErrors = errors.filter(
    error =>
      error.Code !== ERROR_TYPE.MODEL_ERROR && error.Code !== ERROR_TYPE.USER_EXISTS_WITH_EMAIL,
  );
  if (unexpectedErrors.length) {
    throw new AppError(ERROR_TYPE.API_ERROR, unexpectedErrors);
  }
  return rawModelErrors.concat(customModelErrors);
};

export const createPeople = (session: SessionType, data: PeopleFormData) =>
  post(session, `${API3_ROOT}/people/createPeople`, {
    ...mapPeoplePayload(data),
    createDuplicateUsers: data.sameEmail,
    disabled: 0,
  }).then(result => (result.data || []).map(mapPersonType));

const updatePerson = (session: SessionType, data: PeopleFormData) =>
  post(session, `${API3_ROOT}/people/updatePerson`, mapPeoplePayload(data)).then(
    result => result.data,
  );

export const savePeople = (session: SessionType, data: PeopleFormData): Promise<*> => {
  const requestSave = data.type === PEOPLE_DIALOG_TYPES.CREATE ? createPeople : updatePerson;
  return requestSave(session, data).catch(error => {
    if (error.name === SPA_ERROR && error.type === ERROR_TYPE.API_ERROR) {
      const modelErrors = handleAddPeopleErrors(error);
      throw new ModelError(mapErrors(data, modelErrors));
    }
    throw error;
  });
};

export const fetchIpList = (session: SessionType, userId: number) =>
  get(session, `${API3_ROOT}/PersonIp/GetPersonIpList`, { userId }).then(result => {
    const ipAccessList = result.data;
    let ipAccessFrom = IP_FROM.ANY;
    let userIpAccessList = '';
    if (ipAccessList && ipAccessList.length > 0) {
      ipAccessFrom = IP_FROM.SPECIFIED;
      userIpAccessList = ipAccessList
        .map(
          ({ IpAddress, Description }) =>
            `${IpAddress}${Description ? ',' : ''}${Description || ''}`,
        )
        .join('\n');
    }
    return {
      ipAccessFrom,
      userIpAccessList,
    };
  });

export const toggleDisablePerson = (session: SessionType, personData: Person) =>
  post(session, `${API3_ROOT}/people/updatePerson`, {
    ...personData,
    disabled: !personData.disabled,
  });

export const deletePerson = (session: SessionType, personData: Person) =>
  post(session, `${API3_ROOT}/people/updatePerson`, {
    ...personData,
    disabled: PERSON_STATUS.DELETED,
  });

export const getExportLink = (session: SessionType) =>
  get(session, `${API1B_ROOT}/datarooms/${session.dataroomId}/exportUsers/excel`);

export const fetchRemoteIp = (session: SessionType) =>
  get(session, `${API3_ROOT}/PersonIp/GetRemoteIp`).then(result => result.data);

const toUserHistoryRecord = (rawHistoryRecord: Object): PersonHistoryRecord => {
  const {
    PersonId,
    ModifiedDate,
    ModifiedByUser,
    Person: RawPerson,
    UpdatedFields,
  } = rawHistoryRecord;
  return {
    personId: PersonId,
    modifiedDate: ModifiedDate,
    modifiedByUser: {
      fullName: ModifiedByUser.FullName,
      userEmail: ModifiedByUser.UserEmail,
    },
    person: {
      email: RawPerson.Email,
      roomRole: RawPerson.RoomRole,
      qnaRole: RawPerson.QnaRole,
      teamName: RawPerson.TeamName,
      teamColour: RawPerson.TeamColour,
      termsOfAccess: RawPerson.TermsOfAccess,
      termsOfAccessOnChange: RawPerson.TermsOfAccessOnChange,
      showActivityInReports: RawPerson.ShowActivityInReports,
      disabled: RawPerson.Disabled,
      useUserIpAccessList: RawPerson.UseUserIpAccessList,
    },
    updatedFields: UpdatedFields.map((fullFieldName: string) =>
      fullFieldName.replace(/^(Person)\./, ''),
    ),
  };
};

export const getPersonHistory = (session: SessionType, personId: number): Promise<*> =>
  get(session, `${API3_ROOT}/Pages/PersonHistory/GetData`, { personId }).then(({ data }) =>
    data.map(toUserHistoryRecord),
  );

const mapPersonToUpdatePersonDto = (person: Person) => {
  const dto = R.pick(
    [
      'personId',
      'subteamId',
      'teamId',
      'disabled',
      'isGuest',
      'qnaRoleCode',
      'userTypeCode',
      'email',
      'SendEmailInvitation',
      'termsOfAccess',
      'ipAccessList',
      'excludeFromReports',
      'canEditTenderSubmissionBeforeClose',
      'canEditTenderSubmissionBeforeClose',
    ],
    person,
  );
  return {
    ...dto,
    fullName: person.name,
    sendEmailInvitation: false,
  };
};
export const updatePeople = (session: SessionType, data: People) =>
  post(session, `${API3_ROOT}/people/updatePeople`, data.map(mapPersonToUpdatePersonDto)).then(
    result => result.data,
  );

export const resendVerificationLink = (session: SessionType) =>
  post(session, `${API3_ROOT}/people/reinviteself`).then(result => result.data);
