// @flow

/* eslint-disable global-require */

import getLanguage from 'app/i18n/getLanguage';
import parseHtml from 'html-react-parser';
import en from './en.js';

const languages = {
  ar: require('./ar').default,
  cn: require('./zh-Hant').default,
  de: require('./de').default,
  es: require('./es').default,
  fr: require('./fr').default,
  it: require('./it').default,
  ja: require('./ja').default,
  ko: require('./ko').default,
  nl: require('./nl').default,
  pb: require('./pt-br').default,
  ru: require('./ru').default,
  sp: require('./es-419').default,
  vi: require('./vi').default,
  zh: require('./zh-Hans').default,
};

const warnings = [];

export type TranslationKeys = $Keys<typeof en>;

const translate = (key: TranslationKeys, replacements: ?Object) => {
  // start with the English version
  let result = en[key];

  const lang = getLanguage();

  // if an alternative language is being used, and exists, and has this key
  // then use it
  if (languages[lang] && languages[lang][key]) {
    result = languages[lang][key];
  }

  if (!result) {
    if (!warnings.includes(key)) {
      warnings.push(key);

      // eslint-disable-next-line no-console
      console.warn(`You must define a translation for "${key}"`);
    }

    return key.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  // apply any replacements
  if (replacements) {
    result = result.replace(
      /\[([^\s]*?)\]/gi,
      // $FlowFixMe Flow just doesn't get it
      (capture, tagName) => (tagName in replacements ? replacements[tagName] : 'Unknown'),
    );
  }

  return result;
};

export const translateWithFormat = (key: TranslationKeys, replacements: ?Object) => {
  return parseHtml(translate(key, replacements));
};

export const translatePlural = (
  count: number,
  single: TranslationKeys,
  plural: TranslationKeys,
  replacements: ?Object,
): string => (count === 1 ? translate(single, replacements) : translate(plural, replacements));

export default translate;
