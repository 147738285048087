// @flow

import translate from 'app/i18n/translate';
import * as helpers from 'app/pages/security/helpers/securityHelpers';
import * as treeHelpers from 'app/pages/security/helpers/treeHelpers';
import type { SessionType } from 'app/types/globalTypes';
import { API1B_ROOT, API2_ROOT } from './request/constants';
import { get, post } from './request/api';

export const documentIndex = (session: SessionType, options: Object = {}) => {
  const { dataroomId, userId } = session;
  const FOLDER_QUERY_OPTIONS = {
    MaxDisabled: 1,
    HideOrphanedFolders: false,
    CalculateFolderCanBeExpanded: true,
  };
  return get(session, `${API2_ROOT}/datarooms/${dataroomId}/users/${userId}/documentindex`, {
    ...FOLDER_QUERY_OPTIONS,
    ...options,
  });
};

export const getDocuments = (session: SessionType, RootFolderId: number) => {
  const { dataroomId, userId } = session;
  return post(session, `${API2_ROOT}/datarooms/${dataroomId}/users/${userId}/documentindex`, {
    HideOrphanedFolders: true,
    CalculateFolderCanBeExpanded: true,
    MaxDisabled: 0,
    RootFolderId,
  });
};

export const fetchFolderChildren = (
  session: SessionType,
  folderId: number,
  isGuest: boolean = false,
) => {
  const options = isGuest
    ? {
        MaxDisabled: 0,
        HideOrphanedFolders: true,
        folderIds: folderId,
      }
    : { folderIds: folderId };
  return documentIndex(session, options).then(result => {
    if (!result.data[folderId]) return {};
    return { [folderId]: helpers.mapToDocumentIndex(result.data[folderId]) };
  });
};

export const folderChildrenStats = (session: SessionType, folderId: number) => {
  const { dataroomId, userId } = session;
  return get(
    session,
    `${API2_ROOT}/datarooms/${dataroomId}/users/${userId}/folders/${folderId}/childrenStats`,
  );
};

export const rootFolder = (session: SessionType) => {
  const { dataroomId, userId } = session;
  return get(session, `${API1B_ROOT}/datarooms/${dataroomId}/users/${userId}/folders/root`).then(
    result => result.data.FolderId,
  );
};

const mapFolderType = (folder: Object) => ({
  id: folder.FolderId,
  documentIndexItemId: treeHelpers.makeDocumentItemId(folder.FolderId, true),
  sortNumber: 0,
  isFolder: true,
  folderCanBeExpanded: false,
  number: folder.FolderNumber,
  name: folder.FolderId ? folder.FolderName : translate('DocumentIndex'),
  isDisabled: !!folder.IsDisabled,
  parentId: folder.ParentFolderId,
  securities: {},
  isSecurable: folder.IsSecurable,
  isWatermarkable: folder.CanApplyWatermark,
});
export const fetchFolder = (session: SessionType, folderId: number, isGuest: boolean = false) => {
  const { dataroomId, userId } = session;
  return get(session, `${API1B_ROOT}/datarooms/${dataroomId}/users/${userId}/folders/${folderId}`, {
    MaxDisabled: isGuest ? 0 : 1,
  }).then(result => mapFolderType(result.data || {}));
};

const mapDocumentType = (document: Object) => ({
  id: document.DocumentId,
  documentIndexItemId: treeHelpers.makeDocumentItemId(document.DocumentId, false),
  sortNumber: 0,
  isFolder: false,
  number: document.Number,
  name: document.Name,
  isDisabled: !!document.IsDisabled,
  parentId: document.FolderId,
  fileExtension: document.Extension,
  documentCode: '',
  securities: {},
  isSecurable: document.IsSecurable,
  isWatermarkable: document.CanApplyWatermark,
});

export const fetchDocument = (session: SessionType, documentId: number) => {
  const { dataroomId, userId } = session;
  return get(
    session,
    `${API1B_ROOT}/datarooms/${dataroomId}/users/${userId}/documents/${documentId}`,
  ).then(result => mapDocumentType(result.data));
};
