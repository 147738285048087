"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.configurePremiumEvents = exports.publishPremiumStoreSubscribedEvent = exports.publishInvitationSentEvent = exports.publishTeamPeopleAddedEvent = exports.publishPremiumUpgradeDialogOpenedEvent = exports.showInviteGuestDialog = void 0;
var pubsub_1 = require("@ansarada/pubsub");
var constants_1 = require("app/constants");
var showInviteGuestDialog = function (visible) {
    if (visible === void 0) { visible = true; }
    return (0, pubsub_1.emit)({
        appName: constants_1.DATAROOM_SHELL_APP_NAME,
        eventName: constants_1.EVENT_NAME.TENDER.UPGRADE_PREMIUM_BUTTON_CLICK,
        payload: visible,
    });
};
exports.showInviteGuestDialog = showInviteGuestDialog;
var publishPremiumUpgradeDialogOpenedEvent = function () {
    return (0, pubsub_1.emit)({
        appName: constants_1.PLATFORM_HEADER_APP_NAME,
        eventName: constants_1.EVENT_NAME.DATA_ROOM.PREMIUM_UPGRADE_DIALOG_OPENED,
        payload: true,
    });
};
exports.publishPremiumUpgradeDialogOpenedEvent = publishPremiumUpgradeDialogOpenedEvent;
var publishTeamPeopleAddedEvent = function (payload) {
    (0, pubsub_1.emit)({
        appName: constants_1.PLATFORM_HEADER_APP_NAME,
        eventName: constants_1.EVENT_NAME.DATA_ROOM.TEAM_PEOPLE_ADDED,
        payload: payload,
    });
    (0, pubsub_1.emit)({
        appName: constants_1.DATAROOM_APP_NAME,
        eventName: constants_1.EVENT_NAME.DATA_ROOM.TEAM_PEOPLE_ADDED,
        payload: payload,
    });
};
exports.publishTeamPeopleAddedEvent = publishTeamPeopleAddedEvent;
var publishInvitationSentEvent = function (payload) {
    (0, pubsub_1.emit)({
        appName: constants_1.PLATFORM_HEADER_APP_NAME,
        eventName: constants_1.EVENT_NAME.DATA_ROOM.INVITATION_SENT,
        payload: payload,
    });
    (0, pubsub_1.emit)({
        appName: constants_1.DATAROOM_APP_NAME,
        eventName: constants_1.EVENT_NAME.DATA_ROOM.INVITATION_SENT,
        payload: payload,
    });
};
exports.publishInvitationSentEvent = publishInvitationSentEvent;
var publishPremiumStoreSubscribedEvent = function (_a) {
    var publisher = _a.publisher;
    return (0, pubsub_1.emit)({
        appName: constants_1.PLATFORM_HEADER_APP_NAME,
        eventName: constants_1.EVENT_NAME.DATA_ROOM.PREMIUM_STORE_SUBSCRIBED,
        payload: { publisher: publisher },
    });
};
exports.publishPremiumStoreSubscribedEvent = publishPremiumStoreSubscribedEvent;
var configurePremiumEvents = function (_a) {
    var setBannerContext = _a.setBannerContext, setSubscriptionStage = _a.setSubscriptionStage;
    var getPremiumSharedContext = function (details) {
        if ((details === null || details === void 0 ? void 0 : details.payload.publisher) === 'header') {
            if (details.payload.getPremiumStore) {
                var _a = details.payload.getPremiumStore(), bannerStore = _a.bannerStore, subscriptionStage = _a.subscriptionStage;
                setBannerContext(__assign({}, bannerStore));
                setSubscriptionStage(subscriptionStage);
                details.payload.subscribePremiumStore(function (state) { return setBannerContext(state.bannerStore); });
                details.payload.subscribePremiumStore(function (state) { return state.subscriptionStage; }, function (state) { return setSubscriptionStage(state); });
            }
        }
    };
    var listeners = [
        {
            eventName: constants_1.EVENT_NAME.DATA_ROOM.PREMIUM_STORE_SUBSCRIBED,
            callback: getPremiumSharedContext,
        },
    ].map(function (_a) {
        var callback = _a.callback, eventName = _a.eventName;
        return (0, pubsub_1.listen)({
            appName: constants_1.PLATFORM_HEADER_APP_NAME,
            callback: callback,
            eventName: eventName,
        });
    });
    return function () { return listeners.forEach(function (l) { return l.remove(); }); };
};
exports.configurePremiumEvents = configurePremiumEvents;
