// @flow

import { HTTP_METHODS } from 'app/constants';
import type { SessionType } from 'app/types/globalTypes';
import { formQueryString } from '../../utils/helpers';
import request from './request';
import type { RequestConfig } from './request';

export const post = (
  session: SessionType,
  url: string,
  data?: Object | Array<Object>,
  config?: RequestConfig,
) => request(session, url, HTTP_METHODS.POST, data, config);

export const put = (
  session: SessionType,
  url: string,
  data?: Object | Array<Object>,
  config?: RequestConfig,
) => request(session, url, HTTP_METHODS.PUT, data, config);

/*
  Support a plain object for params
  get(${API3_ROOT}/documentIndexSecurity/security, {
    parentFolderIds: folderIds,
    securityGroupIds: subteamIds,
  });
*/
export const get = (session: SessionType, url: string, data?: Object, config?: RequestConfig) => {
  let urlWithParams = url;
  const params = formQueryString(data || {});
  if (params) {
    urlWithParams = url.includes('?') ? `${url}&${params}` : `${url}?${params}`;
  }
  return request(session, urlWithParams, HTTP_METHODS.GET, undefined, config);
};

export const del = (session: SessionType, url: string, config?: RequestConfig) =>
  request(session, url, HTTP_METHODS.DELETE, undefined, config);
