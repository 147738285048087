// @flow

import { ACTION_TYPES } from 'app/constants';
import { EMAIL_IMPORTANCE } from 'app/components/emailForm/constants';
import type { EmailPeopleState } from './emailPeopleTypes';

export const emailPeopleDefaultState: EmailPeopleState = {
  attachments: [],
  hasBeenSent: false,
  importance: EMAIL_IMPORTANCE.NORMAL,
  isLoaded: false,
  isSubmitting: false,
  isUploadingAttachment: false,
  message: '',
  people: [],
  selectedPeopleIds: [],
  subject: '',
  teams: [],
  additionalRecipients: [],
};

const emailPeopleReducer = (
  state: EmailPeopleState = emailPeopleDefaultState,
  action: Object,
): EmailPeopleState => {
  switch (action.type) {
    case ACTION_TYPES.EMAIL_PEOPLE.PEOPLE_UPDATE:
      return {
        ...state,
        people: action.data,
      };
    case ACTION_TYPES.EMAIL_PEOPLE.TEAMS_UPDATE:
      return {
        ...state,
        teams: action.data,
      };
    case ACTION_TYPES.EMAIL_PEOPLE.SET_IS_LOADED:
      return {
        ...state,
        isLoaded: true,
      };
    case ACTION_TYPES.EMAIL_PEOPLE.ADD_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.concat(action.data),
      };
    case ACTION_TYPES.EMAIL_PEOPLE.REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(attachment => attachment.id !== action.data),
      };
    case ACTION_TYPES.EMAIL_PEOPLE.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.data,
      };
    case ACTION_TYPES.EMAIL_PEOPLE.SET_IS_UPLOADING_ATTACHMENT:
      return {
        ...state,
        isUploadingAttachment: action.data,
      };
    case ACTION_TYPES.EMAIL_PEOPLE.SET_SENT:
      return {
        ...state,
        hasBeenSent: true,
      };
    case ACTION_TYPES.EMAIL_PEOPLE.RESET:
      return emailPeopleDefaultState;
    default:
      return state;
  }
};

export default emailPeopleReducer;
