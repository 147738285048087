"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataRoomType = void 0;
var DataRoomType;
(function (DataRoomType) {
    DataRoomType[DataRoomType["Deal"] = 1] = "Deal";
    DataRoomType[DataRoomType["Irlm"] = 2] = "Irlm";
    DataRoomType[DataRoomType["Tender"] = 3] = "Tender";
    DataRoomType[DataRoomType["ManagementArea"] = 4] = "ManagementArea";
})(DataRoomType = exports.DataRoomType || (exports.DataRoomType = {}));
