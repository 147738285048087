// @flow

import type { RequestResponse } from 'app/types/requestTypes';
import { ERROR_TYPE, AppError } from './error';

type AnsaradaApiError = {
  Id: string,
  Status: string,
  Code: string,
  Title: string,
  Detail: string,
  Source: {
    Pointer: string,
  },
};

type ApiResult = {
  Data: mixed,
  Errors?: Array<AnsaradaApiError>,
};

type ApiParsed = {
  data: mixed,
  errors?: Array<*>,
};

const parseErrors = (errors: Array<AnsaradaApiError>) => {
  const details = errors.map(error => ({
    id: `api-${error.Id}`,
    code: error.Code,
    description: error.Detail,
  }));

  throw new AppError(ERROR_TYPE.API_ERROR, details);
};

const parseResponse = (response: Object): ?ApiParsed => {
  if (typeof response.data !== 'object' || response.data.Data === undefined) {
    return { data: response.data };
  }

  const { Data, ...responseData } = response.data;
  return {
    ...responseData,
    data: Data,
  };
};

export const parseResult = (response: ?RequestResponse<ApiResult>): ?ApiParsed => {
  if (!response || (typeof response.data !== 'boolean' && !response.data)) return null;

  const errors = response.data.Errors;
  if (errors && errors.length) return parseErrors(errors);

  return parseResponse(response);
};
