// @flow
import { AppError, ERROR_TYPE } from 'app/utils/error';
import type { AxiosXHR } from 'axios';
import * as R from 'ramda';

type AnsaradaApiError = {
  Id: string,
  Status: string,
  Code: string,
  Title: string,
  Detail: string,
  Source: {
    Pointer: string,
  },
};

type ApiResult = {
  Data: mixed,
  Errors?: Array<AnsaradaApiError>,
};

type ApiParsed = {
  data: mixed,
  errors?: Array<*>,
};

export const parseResult = (response: AxiosXHR<ApiResult>): ApiParsed => {
  if (response.data) {
    const errors = response.data.Errors;
    if (errors && errors.length) {
      const details = errors.map(error => ({
        ...error,
        id: `api-${error.Id}`,
        code: error.Code,
        description: error.Detail,
      }));
      throw new AppError(ERROR_TYPE.API_ERROR, details);
    }
    if (R.type(response.data) === 'Object' && response.data.Data) {
      return {
        ...response.data,
        data: response.data.Data,
      };
    }
  }
  return { data: response.data };
};
