// @flow

import { ACTION_TYPES } from 'app/constants';
import * as teamService from 'app/services/team';
import { safeRequest } from 'app/utils/saga-helpers';
import { call, put, takeEvery, select } from 'redux-saga/effects';

function* requestAndSetTeamHistory(service, id: number) {
  const { session } = yield select();
  const [teamHistory, error] = yield safeRequest(service, session, id);
  if (!error) {
    yield put({
      type: ACTION_TYPES.TEAM_HISTORY.SET_TEAM_HISTORY,
      data: teamHistory,
    });
  }
}

function* initialState(action) {
  const { session } = yield select();
  const subTeamId = action.data.id;
  const [subTeam, error] = yield safeRequest(teamService.getSubteamWithTeam, session, subTeamId);
  if (!error) {
    yield put({
      type: ACTION_TYPES.TEAM_HISTORY.SET_TEAM,
      data: subTeam,
    });
    if (subTeam.isPrimary) {
      yield call(requestAndSetTeamHistory, teamService.getTeamHistory, subTeam.teamId);
    } else {
      yield call(requestAndSetTeamHistory, teamService.getSubTeamHistory, subTeam.id);
    }
  }
}

export const teamHistorySagas = [
  takeEvery(ACTION_TYPES.TEAM_HISTORY.DATA_FETCH_INITIAL, initialState),
];
