// @flow

import { ACTION_TYPES } from 'app/constants';
import { fetchData } from 'app/pages/teams/teamsSagas';
import * as personService from 'app/services/person';
import { safeRequest } from 'app/utils/saga-helpers';
import { put, takeEvery, call, select } from 'redux-saga/effects';

export function* fetchInitialData(action: Object): Generator<*, *, *> {
  yield call(fetchData, action);
  yield put({ type: ACTION_TYPES.EDIT_PEOPLE.SET_IS_LOADED });
}

export function* updatePeople(action: Object): Generator<*, *, *> {
  const { session } = yield select();
  yield put({ type: ACTION_TYPES.EDIT_PEOPLE.SET_IS_SUBMITTING, data: true });
  const [, error] = yield safeRequest(personService.updatePeople, session, action.data);
  yield put({ type: ACTION_TYPES.EDIT_PEOPLE.SET_IS_SUBMITTING, data: false });
  if (!error) {
    yield put({ type: ACTION_TYPES.EDIT_PEOPLE.SET_UPDATED });
  }
}

export const editPeopleSagas = [
  takeEvery(ACTION_TYPES.EDIT_PEOPLE.DATA_FETCH_INITIAL, fetchInitialData),
  takeEvery(ACTION_TYPES.EDIT_PEOPLE.UPDATE_PEOPLE, updatePeople),
];
