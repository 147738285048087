// @flow

import { ACTION_TYPES } from 'app/constants';
import type { SiteDialogStateType } from './siteDialogTypes';

const initialState: SiteDialogStateType = {
  open: false,
  title: '',
  isSaving: false,
  isLoading: false,
};
export default function reducer(
  state: SiteDialogStateType = initialState,
  action: Object,
): SiteDialogStateType {
  switch (action.type) {
    case ACTION_TYPES.SITE.DIALOG_OPEN: {
      return {
        ...state,
        open: true,
        title: action.data,
      };
    }

    case ACTION_TYPES.SECURITY.OPEN_SECURITY_DIALOG: {
      return {
        ...state,
        open: true,
        isLoading: true,
        title: '',
      };
    }

    case ACTION_TYPES.SITE.DIALOG_SET_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }

    case ACTION_TYPES.SITE.DIALOG_TOGGLE_SPINNER: {
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    }

    case ACTION_TYPES.SITE.DIALOG_TOGGLE: {
      return {
        ...state,
        open: !state.open,
      };
    }

    case ACTION_TYPES.SITE.DIALOG_SAVE_PROGRESS_BEGIN: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case ACTION_TYPES.SITE.DIALOG_SAVE_PROGRESS_END: {
      return {
        ...state,
        isSaving: false,
      };
    }

    default:
      return state;
  }
}
