// @flow

export const Sides = {
  BuySide: 'BUY_SIDE',
  SellSide: 'SELL_SIDE',
};

export const TERMS_OF_ACCESS = Object.freeze({
  NEVER: 0,
  EVERY: 1,
  ONCE: 2,
  NA: null,
});

export const PERSON_STATUS = Object.freeze({
  ENABLED: 0,
  DISABLED: 1,
  DELETED: 2,
});

export const COLOR_HEX_CODES = [
  '#F5A9A9',
  '#F78181',
  '#FA5858',
  '#FF0000',
  '#B40404',
  '#610B0B',
  '#F5D0A9',
  '#F7BE81',
  '#FAAC58',
  '#FF8000',
  '#B45F04',
  '#61380B',
  '#F2F5A9',
  '#F3F781',
  '#F4FA58',
  '#FFFF00',
  '#AEB404',
  '#5E610B',
  '#D0F5A9',
  '#BEF781',
  '#ACFA58',
  '#80FF00',
  '#5FB404',
  '#38610B',
  '#A9F5A9',
  '#81F781',
  '#58FA58',
  '#00FF00',
  '#04B404',
  '#0B610B',
  '#A9F5D0',
  '#81F7BE',
  '#58FAAC',
  '#00FF80',
  '#04B45F',
  '#0B6138',
  '#A9F5F2',
  '#81F7F3',
  '#58FAF4',
  '#00FFFF',
  '#04B4AE',
  '#0B615E',
  '#A9D0F5',
  '#81BEF7',
  '#58ACFA',
  '#0080FF',
  '#045FB4',
  '#0B3861',
  '#A9A9F5',
  '#8181F7',
  '#5858FA',
  '#0000FF',
  '#0404B4',
  '#0B0B61',
  '#D0A9F5',
  '#BE81F7',
  '#AC58FA',
  '#8000FF',
  '#5F04B4',
  '#380B61',
  '#F5A9F2',
  '#F781F3',
  '#FA58F4',
  '#FF00FF',
  '#B404AE',
  '#610B5E',
  '#D8D8D8',
  '#BDBDBD',
  '#A4A4A4',
  '#6E6E6E',
  '#424242',
  '#1C1C1C',
];
export const IP_FROM = Object.freeze({
  ANY: 'Any',
  SPECIFIED: 'Specified',
});

export const AreaCodes = {
  Answer: 'ANS',
  Comment: 'COM',
  Question: 'QUE',
  Disclose: 'DIL',
  NewAnsApprovers: 'NAP',
  NewAnsAuthors: 'NAU',
  Undisclose: 'ULA',
  DeleteUndisclose: 'DUA',
};

export const QnaRoles = Object.freeze({
  QuestionAuthor: 'QAU',
  QuestionApprover: 'QAP',
  QnaAdmin: 'QAA',
  QnaObserverFullControl: 'QAO',
  QnaObserverViewOnly: 'QOV',
  QuestionViewer: 'QVW',
  AnswerApprover: 'AAP',
  AnswerAuthor: 'AAU',
  AnswerDrafter: 'ADR',
  BidderAuthor: 'BAA',
  BidderApprover: 'BAP',
  BidderMainContact: 'BMC',
  InternalAuthor: 'IAA',
  InternalApprover: 'IAP',
  InternalMainContact: 'IMC',
  InternalObserver: 'IOB',
});
