const TRANSLATION_COOKIE = 'LanguageCode';
const DEFAULT_LANGUAGE_CODE = 'en';

export const getCookie = (cookie, name) => {
  const langMatch = cookie.match(new RegExp(`(^|;) *${name}=([^;]+)`));
  return langMatch ? langMatch[2].toLowerCase() : null;
};
const getLanguage = () => getCookie(document.cookie, TRANSLATION_COOKIE) || DEFAULT_LANGUAGE_CODE;

export default getLanguage;
