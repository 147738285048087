// @flow

import dialogReducer from 'app/components/dialog/dialogReducer';
import loadingBarReducer from 'app/components/loadingBar/loadingBarReducer';
import { ACTION_TYPES } from 'app/constants';
import type { PageType } from '../types/globalTypes';

const initialState: PageType = {
  section: '',
  userTypes: [],
  qnaRoles: [],
  userPermissions: null,
  isFooterVisible: false,
  currentUserQnaRole: '',
  navigate: null,
  dialog: dialogReducer(undefined, {}),
  loadingBar: loadingBarReducer(undefined, {}),
  remoteIp: '',
  showSubmissionLimitsRemainingLink: undefined,
  licences: {
    allowed: null,
    consumed: 0,
    features: {},
  },
};

const pageReducer = (state: PageType = initialState, action: Object): PageType => {
  switch (action.type) {
    case ACTION_TYPES.LOADINGBAR_LOADING_TOGGLE:
    case ACTION_TYPES.LOADINGBAR_VALUE_UPDATE:
      return {
        ...state,
        loadingBar: loadingBarReducer(state.loadingBar, action),
      };
    case ACTION_TYPES.DIALOG_SET_TITLE:
    case ACTION_TYPES.DIALOG_TOGGLE:
    case ACTION_TYPES.DIALOG_SAVE_PROGRESS_BEGIN:
    case ACTION_TYPES.DIALOG_SAVE_PROGRESS_END:
      return {
        ...state,
        dialog: dialogReducer(state.dialog, action),
      };

    case ACTION_TYPES.USER_TYPES_UPDATE:
      return {
        ...state,
        userTypes: action.data,
      };

    case ACTION_TYPES.PAGE.QNA_ROLES_UPDATE:
      return {
        ...state,
        qnaRoles: action.data,
      };

    case ACTION_TYPES.USER_PERMISSIONS_UPDATE:
      return {
        ...state,
        userPermissions: action.data,
      };
    case ACTION_TYPES.PAGE.SECTION_SET:
      return {
        ...state,
        section: action.data,
      };
    case ACTION_TYPES.PAGE.IS_FOOTER_VISIBLE: {
      const { isFooterVisible } = state;
      if (action.data !== isFooterVisible) {
        return {
          ...state,
          isFooterVisible: action.data,
        };
      }
      return state;
    }
    case ACTION_TYPES.PAGE.CURRENT_USER_QNA_ROLE_SET:
      return {
        ...state,
        currentUserQnaRole: action.data,
      };
    case ACTION_TYPES.PAGE.SET_SHOW_SUBMISSION_LIMITS_REMAINING_LINK: {
      if (state.showSubmissionLimitsRemainingLink !== action.data) {
        return {
          ...state,
          showSubmissionLimitsRemainingLink: action.data,
        };
      }
      return state;
    }
    case ACTION_TYPES.PAGE.SET_REMOTE_IP:
      return {
        ...state,
        remoteIp: action.data,
      };
    case ACTION_TYPES.PAGE.SET_LICENSES:
      return {
        ...state,
        licences: action.data,
      };
    default:
      return state;
  }
};

export default pageReducer;
