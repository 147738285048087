// @flow

// prettier-ignore

export default {
  Accept: 'تقبل',
  AcceptRegistration: 'قبول التسجيل',
  AcceptTermsOfAccess_SentenceCase: 'قبول شروط الوصول',
  Access: 'ولوج',
  AccessAllowed: 'الوصول مسموح به',
  AccessDenied: 'تم رفض الوصول',
  AccessedAgo: 'تم الوصول إليه منذ [وقت]',
  Actions: 'الاجراءات',
  Add: 'جمع',
  AddAndInvite: 'إضافة ودعوة',
  AddAndInvitePeople: 'إضافة أشخاص ودعوتهم',
  AdditionalRecipients: 'مستلمون إضافيون',
  AdditionalRecipients_SentenceCase: 'مستلمون إضافيون',
  AdditionalRecipientsHint: 'أدخل كل عنوان بريد إلكتروني في سطر منفصل',
  AddPeople: 'إضافة أشخاص',
  AddPeopleTo: 'إضافة أشخاص إلى',
  AddPeopleToSubteam: 'إضافة أشخاص إلى [الفريق]/[الفريق الفرعي]',
  AddPeopleToTeam: 'إضافة أشخاص إلى [الفريق]',
  AddPeopleWillHaveTheSameAccess: 'سيكون للأشخاص المضافين نفس حق الوصول',
  AddToExistingTeam: 'إضافة إلى الفريق الحالي',
  Administration: 'إدارة',
  AdminSideAudit: 'افرقه',
  AdminSideBankruptcy: 'جانب البيع',
  AdminSideBankruptcy_EmailHeading: 'البريد الإلكتروني بيع الأشخاص الجانبيين',
  AdminSideBankruptcy_InviteHeading: 'إرسال دعوات لبيع الأشخاص الجانبيين',
  AdminSideDebt: 'المصدر',
  AdminSideDebt_EmailHeading: 'جهات إصدار البريد الإلكتروني',
  AdminSideDebt_InviteHeading: 'إرسال دعوات إلى جهات الإصدار',
  AdminSideDocumentRepository: 'داخلي',
  AdminSideDocumentRepository_EmailHeading: 'البريد الإلكتروني الداخلي',
  AdminSideDocumentRepository_InviteHeading: 'إرسال دعوات إلى العناصر الداخلية',
  AdminSideFundraising: 'عميل',
  AdminSideFundraising_EmailHeading: 'عميل البريد الإلكتروني',
  AdminSideFundraising_InviteHeading: 'إرسال دعوات إلى العميل',
  AdminSideInternalAudit: 'افرقه',
  AdminSideJointVenture: 'داخلي',
  AdminSideJointVenture_EmailHeading: 'البريد الإلكتروني الداخلي',
  AdminSideJointVenture_InviteHeading: 'إرسال دعوات إلى العناصر الداخلية',
  AdminSideMerger: 'جانب البيع',
  AdminSideMerger_EmailHeading: 'البريد الإلكتروني بيع الأشخاص الجانبيين',
  AdminSideMerger_InviteHeading: 'إرسال دعوات لبيع الأشخاص الجانبيين',
  AdminSideOther: 'داخلي',
  AdminSideOther_EmailHeading: 'البريد الإلكتروني الداخلي',
  AdminSideOther_InviteHeading: 'إرسال دعوات إلى العناصر الداخلية',
  AdminSidePlacement: 'المصدر',
  AdminSidePlacement_EmailHeading: 'جهات إصدار البريد الإلكتروني',
  AdminSidePlacement_InviteHeading: 'إرسال دعوات إلى جهات الإصدار',
  AdminSidePublicOffer: 'داخلي',
  AdminSidePublicOffer_EmailHeading: 'البريد الإلكتروني الداخلي',
  AdminSidePublicOffer_InviteHeading: 'إرسال دعوات إلى العناصر الداخلية',
  AdminSideTargeted: 'الفريق الداخلي',
  AdminSideTargeted_EmailHeading: 'إرسال بريد إلكتروني إلى الفريق الداخلي',
  AdminSideTargeted_InviteHeading: 'إرسال دعوات إلى الفريق الداخلي',
  AdminSideTax: 'افرقه',
  AdminSideTender: 'عميل',
  AdminSideTender_EmailHeading: 'عميل البريد الإلكتروني',
  AdminSideTender_InviteHeading: 'إرسال دعوات للعملاء',
  All: 'كل',
  Allow: 'جوز',
  Allowed: 'سمح',
  And_SentenceCase: 'و',
  AndGoLive: 'وابدأ البث المباشر',
  AreYouSureToDeclineRegistration: 'هل أنت متأكد من أنك تريد رفض المسجل [المسجل]؟',
  AreYouSureToDeleteRegistration: 'هل أنت متأكد من أنك تريد حذف المسجل [المسجل]؟',
  AreYouSureToDeleteRequest: 'هل أنت متأكد من رغبتك في حذف الطلب [المسجل]؟',
  At: 'في',
  Attachments: 'المرفقات',
  BackToTeamsPage: 'العودة إلى صفحة الفرق',
  BannerMessage: "تم إنشاء الفريق باستخدام إعدادات [TeamName]",
  BatchOperationCantBePerformed:
    'لا يمكن تنفيذ العملية الدفعية نظرا لعدم وجود مستخدمين في غرفة البيانات هذه. الرجاء إدخال المستخدمون أولا.' +
'قبل أن تتمكن من إرسال بريد إلكتروني إلى [teamType]، تحتاج إلى التحقق من بريدك الإلكتروني.' +
'قبل أن تتمكن من إرسال دعوات إلى [teamType]، تحتاج إلى التحقق من بريدك الإلكتروني.' +
'تبدأ الفوترة عند دعوة الضيف الأول.',
  BulkDownload_SentenceCase: 'تحميل بالجملة',
  BulkDownloadText:
    'يسمح للأشخاص بتنزيل ملفات متعددة في ملف .zip واحد. تتوفر تنزيلات Zip لفترة محدودة فقط.',
  BuySide: 'جانب الشراء',
  BuySide_InviteHeading: 'إرسال دعوات لشراء أشخاص جانبيين',
  BuySide_SentenceCase: 'جانب الشراء',
  BySubmittingThisForm: 'من خلال إرسال هذا النموذج ، فإنك توافق على',
  Cancel: 'إلغاء الأمر',
  Change: 'تغيير',
  ChangeTeamAccess: 'تغيير وصول الفريق',
  ChooseLanguage: 'اختر اللغة',
  ClickAndPreview: '2. انقر ومعاينة',
  Close: 'غلق',
  CollapseAll_SentenceCase: 'طي الكل',
  Colour: 'لون',
  Company: 'شركة',
  Confirm: 'أكد',
  ContactUs: 'اتصل بنا',
  Copied: 'نسخ',
  CopiedSharedLinkInfo: 'شارك الرابط مع فرق المزايدين الخارجيين أو الضيوف حتى يتمكنوا من التسجيل.',
  CopyEmailsOfSelectedUsers: 'نسخ رسائل البريد الإلكتروني للمستخدمين المحددين',
  CopyEmailsOfSelectedUsersSec2: 'نسخ رسائل البريد الإلكتروني المحددة',
  CopyingAllowed: 'النسخ مسموح به',
  CopyingDenied: 'تم رفض النسخ',
  CopyingSecuritySettings: 'نسخ إعدادات الأمان',
  CopyLink: 'نسخ الرابط',
  CopyOf: 'نسخة من',
  CopyRegistrationLink: 'نسخ رابط التسجيل',
  CopySecurity: 'أمان النسخ',
  CopySharedLinkToClipboard: 'نسخ الرابط إلى الحافظة',
  CopyTeam: 'فريق النسخ',
  CreateArchive: 'إنشاء أرشيف',
  CreateNewTeam: 'إنشاء فريق جديد',
  CreatePeopleWithSameEmail: 'إنشاء أشخاص لديهم نفس البريد الإلكتروني كشخص موجود',
  CreateSideTeam: 'إنشاء فريق [جانبي]',
  CreateSubteam: 'إنشاء فريق فرعي',
  CreateTeam: 'إنشاء فريق',
  CreateTeamEmptyState: 'إنشاء فريق لتنظيم الأشخاص وإعداد الوصول',
  CreateWatermark: 'إنشاء علامة مائية',
  Daily: 'يومي',
  DataRoom: 'غرفة البيانات',
  DataroomRole: 'دور الغرفة',
  DataRooms: 'غرف البيانات',
  DataRoomStatus: 'حالة غرفة البيانات',
  DataRoomUserNotVerified1: 'للوصول إلى غرفة البيانات:',
  DataRoomUserNotVerified2: 'فتح البريد الإلكتروني للدعوة',
  DataRoomUserNotVerified3: 'انقر على رابط "التحقق من التفاصيل الخاصة بك"',
  DataRoomUserNotVerified4: 'تسجيل الدخول',
  DataRoomUserNotVerified5:
    "إذا لم تتلق دعوة عبر البريد الإلكتروني، يرجى الاتصال بمسؤول غرفة البيانات.",
  DataRoomUserNotVerifiedTitle: "لم يتم التحقق من وصولك",
  DateModified: 'تاريخ التعديل',
  DateSent: 'تاريخ الإرسال',
  Decline: 'رفض',
  Declined: 'انخفض',
  DeclineRegistration: 'رفض التسجيل',
  Delete: 'حذف',
  DeleteBidderInformation:
    "لا يمكن حذف الفريق إلا عندما لا يكون مقدم عرض مؤهلا في عملية التقييم. قم بإزالة هذا الفريق من قائمة مقدمي العطاءات المؤهلين.",
  DeleteBidderTeamInformationLine1:
    "لا يمكن حذف الفريق إلا عندما يكون فارغا وليس مقدم عرض مؤهل في عملية التقييم.",
  DeleteBidderTeamInformationLine2:
    'انقل الأشخاص من هذا الفريق وقم بإزالة هذا الفريق من قائمة مقدمي العروض المؤهلين.',
  DeletePerson: 'حذف شخص',
  DeletePersonConfirm: 'حذف شخص',
  DeletePersonHeader: 'حذف [البريد الإلكتروني]؟',
  DeletePersonMessage:
    'سيؤدي حذف [البريد الإلكتروني] إلى إزالتها نهائيا من الغرفة وجميع تقارير الأنشطة والسجل. لا يمكن التراجع عن هذا الإجراء.',
  DeleteRegistration: 'حذف التسجيل',
  DeleteRequest: 'حذف الطلب',
  DeleteSubteam: 'حذف الفريق الفرعي',
  DeleteSubteamInformation:
    'لا يمكن حذف الفريق الفرعي إلا عندما يكون فارغا. انقل الأشخاص من هذا الفريق الفرعي لحذفه.',
  DeleteTeam: 'حذف الفريق',
  DeleteTeamInformation:
    'لا يمكن حذف الفريق إلا عندما يكون فارغا. انقل الأشخاص من هذا الفريق لحذفه.',
  DeletingTeamContainsPeople:
    'لا يمكن حذف الفريق إلا عندما يكون فارغا. انقل الأشخاص من هذا الفريق لحذفه.',
  DemoAllCaps: 'عرض',
  Deny: 'أنكر',
  DenyCopyingDisabled: 'رفض النسخ معطل',
  DenyEditingDisabled: 'رفض تعطيل التحرير',
  DenyPrintingDisabled: 'رفض تعطيل الطباعة',
  DenySavingDisabled: 'رفض الحفظ معطل',
  DidntReceiveTheEmail: "لم تتلق البريد الإلكتروني؟",
  Disable: 'تعطيل',
  DisableAccess: 'تعطيل الوصول',
  Disabled: 'ذوي الاحتياجات الخاصه',
  DisabledUser: 'مستخدم معطل',
  DisableRegistrations: 'تعطيل التسجيلات',
  DisableRegistrations_SentenceCase: 'تعطيل التسجيلات',
  DisableRegistrationsAction: 'تعطيل',
  DisableRegistrationsInformation: 'عند تعطيل رابط التسجيل لم يعد نشطا.',
  Disallowed: 'مسموح',
  Document: 'مستند',
  DocumentIndex: 'فهرس الوثائق',
  DocumentIndexSecurity: 'أمان فهرس المستندات',
  Documents: 'الوثائق',
  Down: 'اسفل',
  Draft: 'مسودة',
  Edit: 'حرر',
  EditAfterCloseDate: 'تحرير فهرس التقديم بعد تاريخ الإغلاق',
  EditDocumentSecurityTeam:
    'لم يتم تعيين أي عناصر تحكم أمنية للطباعة أو الحفظ أو الوصول أو العلامات المائية لهذا الفريق.',
  EditDocumentSecurityTeamSubteam:
    'لم يتم تعيين أي عناصر تحكم أمنية للطباعة أو الحفظ أو الوصول أو العلامات المائية لهذا الفريق الفرعي.',
  EditingAllowed: 'التحرير مسموح به',
  EditingDenied: 'تم رفض التحرير',
  EditMultipleUsers: 'تحرير عدة مستخدمين',
  EditPeople: 'تعديل الأشخاص',
  EditPerson: 'تحرير شخص',
  EditPersonFormInvitationMessage:
    'أرسل دعوة جديدة إلى عنوان البريد الإلكتروني المحدث بالنقر فوق مربع الاختيار أدناه',
  EditProfile: 'تعديل الملف الشخصي',
  EditSecurity_SentenceCase: 'تحرير الأمان',
  EditSecurityFor: 'تحرير الأمان ل',
  EditSubteam: 'تحرير الفريق الفرعي',
  EditTeam: 'تحرير الفريق',
  Email: 'البريد الإلكتروني',
  EmailAddress: 'عنوان البريد الإلكتروني',
  EmailCanNotBeEmpty: 'لا يمكن أن يكون البريد الإلكتروني فارغا',
  EmailHasBeenSent: 'تم إرسال البريد الإلكتروني.',
  EmailMessage: 'رسالة البريد الإلكتروني حقل مطلوب',
  EmailNotifications: 'إشعارات البريد الإلكتروني',
  EmailNotValid: 'البريد الإلكتروني غير صالح',
  EmailPeople: 'إرسال بريد إلكتروني إلى الأشخاص',
  EmailPeopleSubjectRequiredSentenceCase: 'مطلوب موضوع للبريد الإلكتروني',
  Emails: 'البريد الإلكتروني',
  EmailsNotValid: 'البريد الإلكتروني [البريد الإلكتروني] غير صالح',
  EmailSuccessMessage: 'تم إرسال بريدك الإلكتروني بنجاح.',
  EnableAccess: 'تمكين الوصول',
  Enabled: 'تمكين',
  EnableRegistrations: 'تمكين التسجيلات',
  EnableRegistrations_SentenceCase: 'تمكين التسجيلات',
  EnableRegistrationsAction: 'تمكين',
  EnableRegistrationsInformation:
    'عند تمكين رابط التسجيل نشط ويمكن مشاركته بشكل عام.',
  EnterIpAddressesRanges: 'أدخل عناوين IP أو نطاقات عناوين IP مفصولة بالمفتاح Enter',
  EnterOneOrMoreEmailAddresses: 'بريد إلكتروني لكل شخص',
  EnterYourLocationOrClosestCity: 'أدخل موقعك أو أقرب مدينة',
  Every: 'كل',
  ExpandAll_SentenceCase: 'توسيع الكل',
  ExportToExcel_SentenceCase: 'تصدير إلى Excel',
  FieldIsRequired: '[الحقل] مطلوب',
  FieldMustBeAtMostMaxCharacters: 'يجب أن يتكون [الحقل] من أحرف [كحد أقصى] على الأكثر.',
  FileExtensionIsInList:
    'لا يمكن تحميل الملف "[FileName]" ، لأن امتداده موجود في قائمة امتدادات الملفات غير المسموح بها لغرفة البيانات هذه:\n\n[FileTypeList]\n\nالرجاء تحويل هذا الملف إلى أحد التنسيقات المسموح بها.',
  FileExtensionIsNotInList:
    'لا يمكن تحميل الملف "[FileName]" ، لأن امتداده غير موجود في قائمة امتدادات الملفات المسموح بها لغرفة البيانات هذه:\n\n[FileTypeList]\n\nالرجاء تحويل هذا الملف إلى أحد التنسيقات المسموح بها.',
  Filter: 'راووق',
  FirstName: 'الاسم الأول',
  Folder: 'مجلد',
  ForRangesSeparateStartIp:
    'بالنسبة للنطاقات ، افصل بين عنوان IP للبدء وعنوان IP النهائي بشرطة، على سبيل المثال <b>1.1.1.1-1.1.1.6</b>',
  Friday: 'الجمعة',
  From: 'من:',
  FullName: 'الاسم الكامل',
  FullText: 'البحث عن النص الكامل',
  FullTextText: 'السماح للأشخاص بالبحث داخل المستندات.',
  GDPRInformation: 'معلومات اللائحة العامة لحماية البيانات (GDPR)',
  GlobalUnexpectedErrorMessage:
    "هناك مشكلة في غرفة البيانات الخاصة بك.  يرجى الاتصال بفريق خدمة العملاء لدينا.",
  GlobalUnexpectedErrorWithIdMessage:
    "هناك مشكلة في غرفة البيانات الخاصة بك.  اقتبس هذا الرمز <[ErrorId]> لفريق خدمة العملاء لدينا.",
  GotIt: 'فهمت ذلك',
  Guest: 'ضيف',
  GuestUserRegistration: 'تسجيل المستخدم الضيف',
  GuideToUsingLink: 'دليل استخدام الرابط',
  Help: 'تعليمات',
  HelpAndSupport: 'المساعدة والدعم',
  HideMenu: 'إخفاء القائمة',
  High: 'عال',
  History: 'تاريخ',
  Hourly: 'الساعه',
  ID: 'معرف',
  Importance: 'اهميه' +
'مهم!',
  Information: 'معلومات',
  Interval: 'فترة',
  InvalidEmailAddress: 'عنوان البريد الإلكتروني غير صالح',
  InvalidField: '[حقل] غير صالح',
  InvalidPdf: 'خطأ: هذا الملف ليس مستند pdf صالح. يرجى محاولة ملف آخر.',
  Invitation: 'دعوة',
  InvitationEmail: 'البريد الإلكتروني للدعوة',
  InvitationSuccessMessage: 'تم إرسال دعوتك بنجاح.',
  Invite: 'دعا',
  InviteAGuest: 'دعوة ضيف',
  Invited: 'مدعو',
  Invited_Users_: 'مدعو',
  InvitePeople: 'دعوة أشخاص',
  IpAccessList: 'قائمة الوصول إلى IP',
  IpAccessList_SentenceCase: 'قائمة الوصول إلى IP',
  IpAccessListCanNotBeEmpty: 'لا يمكن أن تكون قائمة الوصول إلى IP فارغة',
  IpAddressInvalidIpAccessList: "IP '[IpAddress]' في قائمة الوصول إلى IP ليس عنوان IP صالحا.",
  IpAddressInvalidRangeIpAccessList:
    "نطاق IP '[IpRange]' في قائمة الوصول إلى IP له عنوان IP للبدء أكبر من عنوان IP النهائي. يجب أن يكون عنوان IP للبدء أقل من أو يساوي عنوان IP النهائي.",
  IpLimitRangeIpAccessList:
    "يرجى قصر وصف نطاق IP '[IpRange]' في قائمة الوصول إلى IP على أحرف [NumOfChars]. لقد استخدمت أحرف [UsedNumOfChars].",
  IpUserAccessListLimit:
    'يرجى قصر قائمة الوصول إلى IP على أحرف [MaxNumberOfCharacters]. لقد أدخلت أحرف [تم إدخال عدد الشخصيات].' +
"سأدعو لاحقا",
  JobTitle: 'المسمى الوظيفي',
  JobTitleProfession: 'المسمى الوظيفي / المهنة',
  Last30days: 'آخر 30 يوم',
  Last7days: 'آخر 7 أيام',
  LastMonth: 'الشهر الماضي',
  LastName: 'اسم العائلة',
  LastWeek: 'الأسبوع الماضي',
  LearnMore: 'التعرف على المزيد',
  LicenceChatHelp: 'الدردشة مع متخصص.',
  LicenceErrorHelp: 'هل تحتاج إلى مساعدة؟ ',
  LicenceErrorMessage:
    'أنت خارج التراخيص. ستتم ترقية اشتراكك إذا تجاوزت.',
  LicencePack: 'حزمة الدفع المسبق لفريق المستخدم',
  LicencePrompt:
    'قد يكون لديك دعوات معلقة ، ولن يتم استخدام التراخيص إلا بعد قبول الدعوة. ستتم ترقية اشتراكك إذا تجاوزت.',
  LicenceSectionPlan: 'خطتك',
  LicenceTooltip: 'يتم استهلاك الترخيص عند وجود شخص في النظام الأساسي بالطريقة التالية',
  LicenceTooltipStep1: 'فقط في مسارات النظام الأساسي',
  LicenceTooltipStep2: 'فقط في غرفة بيانات النظام الأساسي',
  LicenceTooltipStep3: 'كلا',
  LicenseMessage:
    'ستستخدم الدعوات المقبولة ترخيصا من اشتراكك. ستتم ترقية اشتراكك إذا تجاوزت.',
  LimitedCharacterLength: '[1] لا يمكن أن يتجاوز [0] حرفا.',
  LinkCopied: 'تم نسخ الرابط',
  LinkCopiedToClipboard: 'تم نسخ الرابط إلى الحافظة',
  LinkedDocuments: 'المستندات المرتبطة',
  Loading: 'تحميل',
  LoadingThreeDots: 'تحميل...',
  Location: 'مكان',
  LoggedInToDate_SentenceCase: 'تسجيل الدخول حتى الآن',
  LoginIpAccess: 'تسجيل الدخول الوصول إلى IP',
  LoginLowerCase: 'تسجيل الدخول',
  LoginsLowerCase: 'تسجيلات الدخول',
  Logout: 'تسجيل الخروج',
  Low: 'منخفض',
  Manage: 'أدار',
  ManageQnaSubjects: 'إدارة مواضيع الأسئلة والأجوبة',
  Medium: 'متوسط',
  Message: 'رسالة',
  MessageEmpty: 'لا يمكن أن تكون الرسالة فارغة',
  Minute: 'دقيقة',
  Minutes: 'محضر',
  ModifiedAgo: 'تم التعديل منذ [الوقت]',
  ModifiedBy: 'تم التعديل بواسطة',
  Monday: 'الاثنين',
  MyAccount: 'حسابي',
  Name: 'اسم',
  NavigateAwayConfirm: 'ترك',
  NavigateAwayWarning: 'مغادرة الصفحة؟',
  NavigateAwayWarningMessage: 'لن يتم حفظ التغييرات التي أجريتها.',
  Never: 'أبدا',
  NeverLoggedIn_SentenceCase: 'لم تقم بتسجيل الدخول مطلقا',
  No: 'لا',
  NoCompanyContact: 'لا يوجد اتصال بالشركة',
  NoDemonstration: 'لا مظاهرة',
  NonAdminSideBankruptcy: 'جانب الشراء',
  NonAdminSideBankruptcy_EmailHeading: 'البريد الإلكتروني شراء الأشخاص الجانبيين',
  NonAdminSideBankruptcy_InviteHeading: 'إرسال دعوات لشراء أشخاص جانبيين',
  NonAdminSideDebt: 'مستثمر',
  NonAdminSideDebt_EmailHeading: 'المستثمرين عبر البريد الإلكتروني',
  NonAdminSideDebt_InviteHeading: 'إرسال دعوات للمستثمرين',
  NonAdminSideDocumentRepository: 'خارجي',
  NonAdminSideDocumentRepository_EmailHeading: 'البريد الإلكتروني الخارجي',
  NonAdminSideDocumentRepository_InviteHeading: 'إرسال دعوات إلى جهات خارجية',
  NonAdminSideFundraising: 'الممولين',
  NonAdminSideFundraising_EmailHeading: 'ممولي البريد الإلكتروني',
  NonAdminSideFundraising_InviteHeading: 'إرسال دعوات إلى الممولين',
  NonAdminSideJointVenture: 'خارجي',
  NonAdminSideJointVenture_EmailHeading: 'البريد الإلكتروني الخارجي',
  NonAdminSideJointVenture_InviteHeading: 'إرسال دعوات إلى جهات خارجية',
  NonAdminSideMerger: 'جانب الشراء',
  NonAdminSideMerger_EmailHeading: 'البريد الإلكتروني شراء الأشخاص الجانبيين',
  NonAdminSideMerger_InviteHeading: 'إرسال دعوات لشراء أشخاص جانبيين',
  NonAdminSideOther: 'خارجي',
  NonAdminSideOther_EmailHeading: 'البريد الإلكتروني الخارجي',
  NonAdminSideOther_InviteHeading: 'إرسال دعوات إلى جهات خارجية',
  NonAdminSidePlacement: 'مستثمر',
  NonAdminSidePlacement_EmailHeading: 'المستثمرين عبر البريد الإلكتروني',
  NonAdminSidePlacement_InviteHeading: 'إرسال دعوات للمستثمرين',
  NonAdminSidePublicOffer: 'خارجي',
  NonAdminSidePublicOffer_EmailHeading: 'البريد الإلكتروني الخارجي',
  NonAdminSidePublicOffer_InviteHeading: 'إرسال دعوات إلى جهات خارجية',
  NonAdminSideTargeted: 'الفريق المستهدف',
  NonAdminSideTargeted_EmailHeading: 'فريق البريد الإلكتروني المستهدف',
  NonAdminSideTargeted_InviteHeading: 'إرسال دعوات إلى الفريق المستهدف',
  NonAdminSideTender: 'مقدمي العروض',
  NonAdminSideTender_EmailHeading: 'مقدمي العروض عبر البريد الإلكتروني',
  NonAdminSideTender_InviteHeading: 'إرسال دعوات إلى مقدمي العروض',
  None: 'اي',
  NoQuestionPermission: "ليس لديك إذن لتعيين مواضيع الأسئلة.",
  NoQuestionSubjects: "ليس لديك أي مواضيع أسئلة حتى الآن.",
  NoRecordsFound: 'لم يتم العثور على سجلات.',
  Normal: 'عادي',
  NoRooms: "ليس لديك حاليا أي غرف",
  NoRoomsMatch: 'لم يتم العثور على غرف',
  NoScorecards: "ليس لديك حاليا أي بطاقات أداء",
  NoScorecardsMatch: 'لم يتم العثور على بطاقات أداء',
  Notes: 'تلاحظ',
  Notifications: 'الاخطارات',
  NotInvited: 'غير مدعو',
  NotInvited_Users__SentenceCase: 'غير مدعو',
  NotVerified_Users__SentenceCase: 'لم يتم التحقق',
  Off: 'قباله',
  Ok: 'موافق',
  OK: 'موافق',
  OkAllCaps: 'موافق',
  On: 'على',
  OnceOnlyAndWhenTermsAreUpdated: 'مرة واحدة فقط وعندما يتم تحديث الشروط',
  OnEveryLogin: 'كل تسجيل دخول',
  OnfirstLogin: 'عند تسجيل الدخول الأول',
  OnlyEnabledUsersCanBeInvited: 'يمكن دعوة المستخدمين الممكنين فقط.',
  OnlyEnabledUsersCanBeInvitedSec2: 'يمكن دعوة الأشخاص الذين تم تمكين الوصول إليهم فقط.',
  Optional: 'اختياري',
  Or: 'أو',
  OtherTeamMembers: '[الحجم] أعضاء الفريق الآخرين',
  Pending: 'المعلقه',
  PendingScorecards: 'بطاقات الأداء المعلقة',
  PeopleFormWarningText:
    "يؤدي تطبيق دور الغرفة 'المشرف' إلى منح الأشخاص إمكانية الوصول غير المقيد. لا تقتصر على إعدادات الأمان الخاصة بفريقهم",
  PeopleLowerCase: 'شعب',
  PerDay: 'في اليوم',
  Period: 'مرحلة زمنية',
  PerMonth: 'شهريا',
  PersonHistory: 'تاريخ الشخص',
  PersonHistoryFor: 'تاريخ ل',
  PersonLowerCase: 'شخص',
  PerWeek: 'في الأسبوع',
  Phone: 'الهاتف',
  PhoneNumber: 'رقم الهاتف',
  PhoneNumber_SentenceCase: 'رقم الهاتف',
  PrintingAllowed: 'الطباعة مسموح بها',
  PrintingDenied: 'تم رفض الطباعة',
  Priority: 'أولوية',
  PrivacyPolicy: 'سياسة الخصوصية',
  Profession: 'مهنة',
  Profile: 'ملف تعريف',
  Properties: 'خصائص' +
'تقديم تفاصيل الفوترة الخاصة بك' +
'قدم تفاصيل الدفع الخاصة بك',
  Qna: 'سؤال وجواب',
  QnaNotificationSwitchOff: 'لن تتلقى أي رسائل بريد إلكتروني',
  QnaNotificationSwitchOn: 'سوف تتلقى التحديثات على الفور',
  QnaRole: 'دور الأسئلة والأجوبة',
  QnaRole_SentenceCase: 'دور الأسئلة والأجوبة',
  QnaSubjects: 'مواضيع الأسئلة والأجوبة',
  QnaSubjectSecurity: 'سؤال وجواب موضوع الأمن',
  QnaSubmissionsLimit: 'حدود إرسال الأسئلة',
  QnaSubmissionsOnWeekends: 'تقديم الأسئلة في عطلات نهاية الأسبوع',
  Ready: 'جاهز',
  ReasonForRequest: 'سبب الطلب',
  Recipients: 'المستلمين',
  RecipientsOfEmailNote_Gen2:
    "لن يتمكن مستلمو هذا البريد الإلكتروني من رؤية عناوين بعضهم البعض ، لذلك من الآمن إرساله إلى المستخدمين من مجموعات مستخدمين مختلفة",
  Register: 'سجل',
  RegisterYourInterest: 'سجل اهتمامك',
  RegisterYourInterest_SentenceCase:
    'أرسل طلبا إلى مسؤول <strong>[dataRoomName]</strong> للمراجعة. إذا تم قبول تسجيلك ، فستتم دعوتك إلى المشروع.',
  Registration: 'تسجيل',
  Registration_LowerCase: 'تسجيل',
  RegistrationDetails: 'تفاصيل التسجيل',
  RegistrationLinkDisabled: 'رابط التسجيل معطل',
  RegistrationRequestSubmitted: 'تم تقديم طلب التسجيل',
  Registrations: 'التسجيلات',
  Registrations_LowerCase: 'التسجيلات',
  RegistrationTextCopy:
  'لقد قمت بتمكين فرق مقدمي العروض أو الضيوف من تسجيل الاهتمام. انسخ الرابط المقدم للسماح للأشخاص بالتسجيل. سيظهر المسجلون الجدد في هذه القائمة لتتمكن من مراجعتها ودعوتها. لا يمكن للأشخاص في هذه القائمة الوصول حتى تقوم بدعوتهم وإضافتهم إلى فريق.',
  Request: 'طلب',
  RequestAccess: 'طلب الوصول',
  RequestAccessDialogDescription: 'قدم تفاصيل للشخص الذي يحتاج إلى وصول إضافي. سيتم مراجعة طلبك من قبل المسؤول.',
  RequestAccessDialogTitle: 'طلب وصول إضافي',
  RequestAccessEmptyStateText: 'سيظهر المسجلون الجدد في هذه القائمة لتتمكن من مراجعتها. لا يمكن للأشخاص في هذه القائمة الوصول حتى يقوم المسؤول بإضافتهم إلى فريق.',
  RequestDetails: 'تفاصيل الطلب',
  RequestorEmail: 'البريد الإلكتروني لمقدم الطلب',
  Required: 'مطلوب',
  ResendEmail: 'إعادة إرسال البريد الإلكتروني',
  ResendVerificationLink: 'إعادة إرسال رابط التحقق',
  Reset: 'اعاده تعيين',
  Review: 'استعراض',
  RoomRole_SentenceCase: 'دور الغرفة',
  RoomRoleAdminSubteamHelp: 'لا يمكن إضافة المسؤولين إلى الفرق الفرعية.',
  RoomRoleHelp: "يحدد دور الغرفة مستوى وصول الشخص إلى الغرفة.",
  Rooms: 'غرف',
  RoomsAdvert1:
    "باستخدام Dropbox Showcase ، يمكنك مشاركة عملك مع الشركاء والعملاء بشكل جميل ، وتتبع التقدم والاحتفاظ بعلامات تبويب على من شاهد ماذا - كل ذلك في مكان واحد.",
  RoomsAdvert2: 'العرض متاح لمستخدمي Dropbox Business Advanced و Enterprise.',
  RoomsAdvertLink: 'اكتشف المزيد',
  RoomsAdvertTitle: 'تشغيل صفقة عالية الأداء',
  Saturday: 'السبت',
  Save: 'أنقذ',
  SaveAndInvitePeople: 'حفظ الأشخاص ودعوتهم',
  Saving: 'حفظ',
  SavingAllowed: 'التوفير مسموح به',
  SavingDenied: 'رفض الادخار',
  SavingFailed: 'فشل الحفظ',
  Scorecards: 'بطاقات الأداء',
  ScorecardsAdvert1:
    "باستخدام Dropbox Showcase ، يمكنك مشاركة عملك مع الشركاء والعملاء بشكل جميل ، وتتبع التقدم والاحتفاظ بعلامات تبويب على من شاهد ماذا - كل ذلك في مكان واحد.",
  ScorecardsAdvert2: 'العرض متاح لمستخدمي Dropbox Business Advanced و Enterprise.',
  ScorecardsAdvertLink: 'اكتشف المزيد',
  ScorecardsAdvertTitle: 'تشغيل صفقة عالية الأداء',
  Search: 'بحث',
  Secure: 'آمن',
  SecureByDefault: 'آمن بشكل افتراضي',
  SecureOffice: 'مكتب آمن',
  SecureOfficeText:
    'يتتبع Secure Office طرق العرض ويمكنه تعطيل الحفظ والنسخ والطباعة في ملفات Microsoft Word و Excel و PowerPoint.',
  SecureOfficeTitle: 'الأمان لمستندات Microsoft Office (Secure Office)',
  SecureOfficeWarning:
    'لا يتوافق Secure Office مع العديد من الأجهزة الشائعة، بما في ذلك أجهزة Apple iOS وAndroid. يمنع هذا الإعداد الأشخاص الذين ليس لديهم نظام متوافق من عرض هذه الملفات.',
  Security: 'أمن',
  SecurityControls: 'الضوابط الأمنية',
  SecurityControlsOff: 'إيقاف تشغيل عناصر التحكم الأمنية',
  SecurityControlsOffDescription:
    'عند إيقاف تشغيل عناصر التحكم في الأمان، لا يتم تقييد استخدام هذه المستندات بعد التنزيل.',
  SecurityControlsOn: 'الضوابط الأمنية على',
  SecurityControlsOnDescription:
    'عند تشغيل عناصر التحكم في الأمان، يتم تعقب استخدام مستندات Microsoft Office وPDF. سيتم تعطيل النسخ المحفوظة عن بعد عند إبطال الوصول لفريق أو عند تعطيل الغرفة.',
  SecurityControlsOnExtraDescription:
    'يمكنك أيضا تقييد الحفظ والطباعة والتحرير والنسخ (تتوفر قيود النسخ فقط لمستندات Microsoft Office).',
  SecurityGroup_SentenceCase: 'مجموعة الأمان',
  SecurityPermissions: 'أذونات الأمان',
  Select: 'اختار',
  SelectPeople_SentenceCase: 'حدد الأشخاص',
  SelectSubTeam: 'الرجاء اختيار الفريق الفرعي.',
  SelectTeam: 'الرجاء اختيار الفريق.',
  SelectUsers_SentenceCase: 'حدد الأشخاص',
  SelectUserType: 'يرجى تحديد دور الغرفة.',
  SelfRegistrationHeader: 'التسجيل الذاتي',
  SelfRegistrationToolIntroDialogHeading:
    'السماح للمستخدمين الضيوف بالتسجيل',
  SelfRegistrationToolIntroDialogHelpLink:
    'عرض المساعدة والدعم بشأن تسجيل مقدمي العطاءات',
  SelfRegistrationToolIntroDialogTitle1:
    'قم بتنشيط تسجيلات المستخدمين الضيوف في قائمة الإجراءات في صفحة جانب الشراء / مقدمي العطاءات.',
  SelfRegistrationToolIntroDialogTitle2:
    'شارك الرابط المقدم على صفحة ويب أو في رسالة بريد إلكتروني تقوم بإنشائها.',
  SelfRegistrationToolIntroDialogTitle3:
    'يمكن مراجعة المستخدمين المسجلين ودعوتهم.',
  SellSide: 'جانب البيع',
  SellSide_InviteHeading: 'إرسال دعوات لبيع الأشخاص الجانبيين',
  SellSide_SentenceCase: 'جانب البيع',
  Send: 'ارسل',
  SendEmailInvitation: 'إرسال دعوة عبر البريد الإلكتروني',
  SendEmailInvitations: 'إرسال دعوات عبر البريد الإلكتروني' +
'إرسال دعوات المستخدمين الضيوف',
  SendInvitationSubjectRequiredSentenceCase: 'مطلوب موضوع للدعوة عبر البريد الإلكتروني',
  SendInvite_SentenceCase: 'إرسال دعوة',
  SendInvites: 'إرسال دعوات',
  SendInviteToSubscription: 'دعوة إلى اشتراكك',
  SendUpdates: 'إرسال التحديثات',
  SentAgo: 'تم الإرسال منذ [الوقت]',
  SentDocumentChanged: 'يتم الإرسال عند إضافة مستند أو تغييره',
  SessionTimeout: 'مهلة الجلسة',
  SetSecurityControls: 'تعيين عناصر التحكم في الأمان',
  SetSecurityControlsBeforeAddingPeople: 'قم بتعيين عناصر التحكم في الأمان قبل إضافة الأشخاص.',
  SetSubmissionLimits: 'تعيين حدود الإرسال',
  SetSubmissionLimitsDescription:
    'تقييد عدد الأسئلة التي يمكن للفريق طرحها في اليوم أو الأسبوع أو الشهر',
  ShouldBeEmailEmail: 'البريد الإلكتروني الذي أدخلته ليس عنوان بريد إلكتروني صالح.',
  ShowActivityInReports_SentenceCase: 'إظهار النشاط في التقارير',
  ShowDetails: 'اظهر التفاصيل',
  ShowMore: 'استعراض المزيد',
  Status: 'حالة',
  StayOnPage: 'يرجى البقاء على هذه الشاشة أثناء نسخ الإعدادات',
  Subject: 'موضوع',
  Subject_Email_: 'موضوع',
  SubjectEmailHint: 'موضوع البريد الإلكتروني هو حقل مطلوب',
  SubjectGroupNotSet: 'لم يتم تعيين مجموعة الموضوع',
  SubmissionIndex: 'فهرس التقديم',
  SubmissionIndexesSecurity: 'أمان فهارس التقديم',
  SubmissionIndexTopLevelFoldersSecurity: 'فهرس التقديم مجلدات المستوى الأعلى الأمان',
  Submissions: 'الطلبات',
  SubTeam: 'الفريق الفرعي',
  SubteamName: 'اسم الفريق الفرعي',
  SubTeamNameAlreadyExists: 'يوجد بالفعل فريق فرعي يحمل نفس الاسم.',
  SubteamNameValidation: 'لا يمكن أن يكون اسم الفريق الفرعي فارغا',
  Success: 'نجاح',
  Sunday: 'الأحد',
  SwitchTeam_Confirm: 'نعم ، قم بتبديل الفريق',
  SwitchTeam_Title: 'تبديل الفريق',
  SwitchTeam_WarningMessage:
    'يبدو أنك أجريت بعض التغييرات. إذا غادرت دون حفظ ، فستفقد تغييراتك.',
  SydneyTime: 'سيدني وقت',
  Team: 'فريق',
  TeamColour: 'لون الفريق - [اللون]',
  TeamHistory: 'تاريخ الفريق',
  TeamHistoryFor: 'تاريخ الفريق ل',
  TeamMemberEmails: 'رسائل البريد الإلكتروني لأعضاء الفريق',
  TeamMemberEmailsInfo: 'رسائل البريد الإلكتروني لأعضاء فريقك ، مفصولة بفاصلة منقوطة أو فاصلة. سيتم دعوتهم جميعا إذا تم قبول التسجيل.',
  TeamName: 'اسم الفريق',
  TeamNameAlreadyExists: 'فريق يحمل نفس الاسم موجود بالفعل.',
  TeamNameValidation: 'لا يمكن أن يكون اسم الفريق فارغا',
  TeamPermissions: 'أذونات الفريق',
  TermsAndConditions: 'الشروط والأحكام' +
'الطريقة الأكثر أمانا لإدخال البريد الإلكتروني هي النسخ واللصق. تأكد من فصل كل عنوان بريد إلكتروني بفاصلة أو فاصلة منقوطة أو مسافة أو فاصل أسطر.',
  ThirdPartyIntegrations_SentenceCase: 'تكامل الطرف الثالث' +
'السماح بكل عمليات تكامل الجهات الخارجية أو بعضها أو عدم السماح بأي منها.',
  ThisSubTeamCannotAccess: 'لا يمكن لهذا الفريق الفرعي الوصول إلى أي مجلدات أو مستندات',
  ThisTeamCannotAccess: 'لا يمكن لهذا الفريق الوصول إلى أي مجلدات أو مستندات',
  Thursday: 'الخميس',
  Time: 'الوقت',
  TimePeriodAm: 'ص',
  TimePeriodPm: 'م',
  To: 'ل:' +
'لتتمكن من دعوة الأشخاص إلى غرفة البيانات الخاصة بك.',
  Today: 'اليوم' +
'لدعوة أول مستخدم ضيف وبدء البث المباشر',
  Topic: '[المواضيع] الموضوع',
  Topics: '[المواضيع] المواضيع',
  Total: 'مجموع',
  Tuesday: 'الثلاثاء',
  Unknown: 'مجهول',
  Up: 'فَوْق',
  Update: 'تحديث',
  UserCanLogInFromAnyIp: 'يمكن لهذا المستخدم تسجيل الدخول من أي عنوان IP',
  UserCanLogInOnlyFromIpsBelow: 'يمكن لهذا المستخدم تسجيل الدخول فقط من عناوين IP المحددة أدناه',
  UserExistsWithEmail: 'المستخدم موجود مع البريد الإلكتروني [0]',
  UserGroup: 'مجموعة المستخدمين',
  UserInvitationLinkNote_Gen2:
    'يتم استبدال العلامة "[invitation_link]" بارتباط تشعبي تم إنشاؤه عند إرسال البريد الإلكتروني. يجب أن يكون في نص البريد الإلكتروني.',
  UserInvitationLinkTagIsMissing:
    'لا يمكن إرسال بريد إلكتروني للدعوة بدون رابط وصول. انسخ [invitation_link] بين قوسين وأضفه إلى الرسالة حتى يتمكن المستلم من إنشاء وصوله.',
  UserProperties: 'خصائص المستخدم',
  UserStatus: 'حالة المستخدم',
  UserType: 'نوع المستخدم',
  UserTypeError: 'دور الغرفة مطلوب',
  UserUpdated: 'تم تحديث الأشخاص بنجاح.',
  Verified: 'التحقق',
  Verified_Users_: 'التحقق' +
'تحقق من بريدك الإلكتروني',
  VerifyYourEmailAddressToInviteUsers: 'تحقق من عنوان بريدك الإلكتروني لدعوة المستخدمين.',
  View: 'منظر',
  ViewDetails: 'عرض التفاصيل',
  ViewIndexAsGhost: 'ما الذي يمكنهم رؤيته؟',
  ViewSecurity: 'عرض الأمان',
  Warning: 'تحذير',
  Watermark: 'العلامه المائيه',
  Wednesday: 'الأربعاء',
  Weekly: 'أسبوعي' +
"لقد أرسلنا رابط التحقق إلى [البريد الإلكتروني]",
  Yes: 'نعم',
  YouCanAddDescriptionsAfterComma:
    'يمكنك أيضا إضافة أوصاف بعد الفاصلة ، على سبيل المثال 1.1.1.1 <b>، IP لمكتب لندن</b>',
  YourCurrentIpAddress:
    '<b>ملاحظه:</b> عنوان IP الحالي الخاص بك هو [عنوان IP]. <a class="ace-link" [href]>انقر هنا</a> لإضافته إلى القائمة.',
  YourCurrentIpAddressSec2: 'أضف عنوان IP الحالي الخاص بك إلى القائمة البيضاء.',
  YouWillReceiveAConfirmationInYourEmailAboutNextSteps:
    "ستتلقى تأكيدا في بريدك الإلكتروني حول الخطوات التالية.",
};
