// @flow
import { combineReducers } from 'redux';
import teamFormDialogReducer from 'app/pages/teams/teamFormDialog/teamFormDialogReducer';
import peopleFormReducer from 'app/pages/teams/peopleFormDialog/peopleFormDialogReducer';
import teamsFilterReducer from 'app/pages/teams/filter/filterReducer';
import formHelpersReducer from 'app/pages/teams/reducers/formHelpersReducer';
import { ACTION_TYPES, SITE_DIALOG_TYPES } from 'app/constants';
import type { Team } from 'app/types/teamsTypes';
import type { PageData, ViewData } from 'app/pages/teams/teamsTypes';
import type { AppStateType } from 'app/types/appStateTypes';

export type PropsStateType = {|
  isGuest?: boolean,
|};

const propsState: PropsStateType = {
  isGuest: undefined,
};

const propsReducer = (state: PropsStateType = propsState, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.SET_PROPS:
      return {
        ...state,
        isGuest: action.data.isGuest,
      };
    default:
      return state;
  }
};

const collapsedTeamsReducer = (state?: Object = {}, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.COLLAPSED_ALL:
    case ACTION_TYPES.TEAMS.EXPANDER_TOGGLE:
      return action.data.collapsedTeams;
    case ACTION_TYPES.TEAMS.EXPAND_ALL:
      return {};
    default:
      return state;
  }
};

const dialogTypeReducer = (state = null, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.PEOPLE_FORM_EDIT:
    case ACTION_TYPES.TEAMS.PEOPLE_FORM_ADD:
      return SITE_DIALOG_TYPES.PEOPLE_FORM;

    case ACTION_TYPES.TEAMS.TEAM_FORM_EDIT:
    case ACTION_TYPES.TEAMS.TEAM_FORM_ADD_TEAM:
    case ACTION_TYPES.TEAMS.TEAM_FORM_ADD_SUBTEAM:
    case ACTION_TYPES.TEAMS.TEAM_FORM_COPY:
      return SITE_DIALOG_TYPES.TEAM_FORM;

    case ACTION_TYPES.TEAMS.COPY_SECURITY:
      return SITE_DIALOG_TYPES.COPY_SECURITY_FORM;

    case ACTION_TYPES.TEAMS.DELETE_TEAM_DIALOG:
    case ACTION_TYPES.TEAMS.TOGGLE_REGISTRATIONS_DIALOG:
      return action.data.dialogType;
    case ACTION_TYPES.FREEMIUM.TOGGLE_VERIFY_EMAIL_DIALOG:
      return action.data.dialogType;

    default:
      return state;
  }
};

const inviteGuestUpgradeDialogReducer = (state = {}, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.TOGGLE_TENDER_FREEMIUM_INVITE_GUEST_UPGRADE_DIALOG:
      return { isTenderFreemiumInviteGuestUpgradeDialogOpen: action.data.open };
    case ACTION_TYPES.TEAMS.TOGGLE_ALWAYS_INVITE_GUEST_UPGRADE_DIALOG:
      return { isAlwaysInviteGuestUpgradeDialogOpen: action.data.open };
    default:
      return state;
  }
};

const otherReducer = (state: ViewData, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.SET_FOCUS_TEAM:
      return {
        ...state,
        focusTeam: action.data,
      };
    case ACTION_TYPES.TEAMS.DELETE_TEAM_DIALOG: {
      const { isSubteam, hasPeople, isQualifiedBidder } = action.data;
      return {
        ...state,
        isSubteam,
        hasPeople,
        isQualifiedBidder,
      };
    }
    case ACTION_TYPES.COLOURPICKER_TOGGLE:
      return {
        ...state,
        colourPickerOn: !state.colourPickerOn,
      };

    default:
      return state;
  }
};

const defaultViewData: ViewData = {
  collapsedTeams: collapsedTeamsReducer(undefined, {}),
  dialogType: dialogTypeReducer(undefined, {}),
  formHelpers: formHelpersReducer(undefined, {}),
  peopleForm: peopleFormReducer(undefined, {}),
  teamForm: teamFormDialogReducer(undefined, {}),
  guestFilterData: teamsFilterReducer(undefined, {}),
  nonGuestFilterData: teamsFilterReducer(undefined, {}),
  focusTeam: {},
  isSubteam: undefined,
  colourPickerOn: false,
  inviteGuestUpgradeDialog: inviteGuestUpgradeDialogReducer(undefined, {}),
};

export const viewDataReducer = (state?: ViewData = defaultViewData, action: Object) => {
  let newState: ViewData = {
    ...state,
    collapsedTeams: collapsedTeamsReducer(state.collapsedTeams, action),
    dialogType: dialogTypeReducer(state.dialogType, action),
    formHelpers: formHelpersReducer(state.formHelpers, action),
    peopleForm: peopleFormReducer(state.peopleForm, action),
    teamForm: teamFormDialogReducer(state.teamForm, action),
    inviteGuestUpgradeDialog: inviteGuestUpgradeDialogReducer(
      state.inviteGuestUpgradeDialog,
      action,
    ),
  };
  if (action.isGuest) {
    newState = {
      ...newState,
      guestFilterData: teamsFilterReducer(state.guestFilterData, action),
    };
  } else {
    newState = {
      ...newState,
      nonGuestFilterData: teamsFilterReducer(state.nonGuestFilterData, action),
    };
  }
  return otherReducer(newState, action);
};

const updateTeamById = (teams: Array<Team>, newData) =>
  teams.map(team => {
    if (team.subteamId === newData.subteamId) {
      return {
        ...team,
        ...newData,
      };
    }

    let updatedSubteam = false;
    // if the parent subteamId doesn't match, a subteam might
    const newSubteams = team.subteams.map(subteam => {
      if (subteam.subteamId === newData.subteamId) {
        updatedSubteam = true;
        return {
          ...subteam,
          ...newData,
        };
      }

      return subteam;
    });

    if (updatedSubteam) {
      return {
        ...team,
        subteams: newSubteams,
      };
    }

    return team;
  });

const defaultPageData: PageData = {
  teams: [],
  people: [],
  thirdPartyIntegrationsDataRoom: [],
  thirdPartyIntegrationsSecurityGroups: {},
};

const pageDataReducer = (state?: PageData = defaultPageData, action) => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.VIEW_DATA_UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case ACTION_TYPES.TEAMS.UPDATE_TEAM:
      return {
        ...state,
        teams: updateTeamById(state.teams, action.data),
      };
    case ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_DATA_ROOM.SET_DATA:
      return {
        ...state,
        thirdPartyIntegrationsDataRoom: action.data.thirdPartyIntegrationsDataRoom,
      };
    case ACTION_TYPES.THIRD_PARTY_INTEGRATIONS_SECURITY_GROUPS
      .UPDATE_THIRD_PARTY_INTEGRATIONS_SECURITY_GROUP:
      return {
        ...state,
        thirdPartyIntegrationsSecurityGroups: {
          ...state.thirdPartyIntegrationsSecurityGroups,
          [action.data.subteamId]: action.data.thirdPartyIntegrations,
        },
      };

    default:
      return state;
  }
};

const teamsReducer = combineReducers<*, *>({
  props: propsReducer,
  viewData: viewDataReducer,
  pageData: pageDataReducer,
});
export default teamsReducer;

export const selectTeamProps = (state: AppStateType) => state.teams.props;
export const selectInviteGuestUpgradeDialog = (state: AppStateType) =>
  state.teams.viewData.inviteGuestUpgradeDialog;
