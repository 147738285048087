// @flow

import * as React from 'react';
import { Dropdown as ACEDropdown } from '@ansarada/ace-react';

type Props = $Exact<{
  children: React.Node,
  trigger: React.Element<any>,
  'data-test-id'?: string,
}>;

type State = {
  open: boolean,
};

class Dropdown extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    this.componentIsMounted = true;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUnmount() {
    this.componentIsMounted = false;
  }

  componentIsMounted: boolean;

  render() {
    return (
      <ACEDropdown
        open={this.state.open}
        trigger={this.props.trigger}
        data-test-id={this.props['data-test-id']}
        onToggle={open => {
          // ace-react will sometimes call this AFTER the component has
          // unmounted, so we must first check before calling setState
          if (this.componentIsMounted) this.setState({ open });
        }}
      >
        {/* $FlowFixMe: ace-react Dropdown expects a DropdownGroup */}
        {this.props.children}
      </ACEDropdown>
    );
  }
}

export default Dropdown;
