// @flow

import { ACTION_TYPES } from 'app/constants';
import { STATUSES } from 'app/pages/teams/teamList/registrationPanel/constants';
import type { ManageRegistrationStateType } from './manageRegistrationTypes';

const defaultSettings = {
  IsEnabled: false,
  SharedSelfRegistrationLink: null,
};

export const defaultManageRegistration: ManageRegistrationStateType = {
  registrants: [],
  getRegistrantsLoading: false,
  declineRegistrationLoading: false,
  deleteRegistrationLoading: false,
  inviteRegistrationLoading: false,
  declineDialogVisible: false,
  deleteDialogVisible: false,
  inviteDialogVisible: false,
  registrationSettings: defaultSettings,
  getRegistrationSettingsLoading: false,
  updateRegistrationSettingsLoading: false,
  toggleSettingDialogVisible: false,
  expanded: true,
};

const manageRegistrationReducer = (
  state: ManageRegistrationStateType = defaultManageRegistration,
  action: Object,
): ManageRegistrationStateType => {
  switch (action.type) {
    case ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.LOADING:
      return {
        ...state,
        getRegistrantsLoading: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.SET:
      return {
        ...state,
        registrants: (action.data || []).sort((a, b) => {
          return new Date(b.InsertDateUtc) - new Date(a.InsertDateUtc);
        }),
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.RESET:
      return {
        ...state,
        registrants: [],
      };

    case ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.LOADING:
      return {
        ...state,
        declineRegistrationLoading: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.TOGGLE_DIALOG:
      return {
        ...state,
        declineDialogVisible: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.DECLINE_REGISTRATION.SET:
      return {
        ...state,
        registrants: state.registrants.map(registrant => {
          if (registrant.Id === action.data) {
            return {
              ...registrant,
              Status: STATUSES.DEC,
            };
          }
          return registrant;
        }),
      };

    case ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.LOADING:
      return {
        ...state,
        deleteRegistrationLoading: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.TOGGLE_DIALOG:
      return {
        ...state,
        deleteDialogVisible: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.DELETE_REGISTRATION.SET:
      return {
        ...state,
        registrants: state.registrants.filter(registrant => registrant.Id !== action.data),
      };

    // INVITE_REGISTRATION
    case ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.TOGGLE_DIALOG: {
      return {
        ...state,
        inviteDialogVisible: action.data,
      };
    }
    case ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.LOADING:
      return {
        ...state,
        inviteRegistrationLoading: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.INVITE_REGISTRATION.SET:
      return {
        ...state,
        registrants: state.registrants.map(registrant => {
          if (registrant.Id === action.data) {
            return {
              ...registrant,
              Status: STATUSES.INV,
            };
          }
          return registrant;
        }),
      };

    case ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.GET_LOADING:
      return {
        ...state,
        getRegistrationSettingsLoading: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.SET:
      return {
        ...state,
        registrationSettings: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.RESET:
      return {
        ...state,
        registrationSettings: defaultSettings,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.UPDATE_LOADING:
      return {
        ...state,
        updateRegistrationSettingsLoading: action.data,
      };
    case ACTION_TYPES.MANAGE_REGISTRATION.SETTINGS.TOGGLE_DIALOG:
      return {
        ...state,
        toggleSettingDialogVisible: action.data,
      };
    case ACTION_TYPES.TEAMS.COLLAPSED_ALL:
      return { ...state, expanded: false };
    case ACTION_TYPES.TEAMS.EXPAND_ALL:
      return { ...state, expanded: true };
    case ACTION_TYPES.MANAGE_REGISTRATION.REGISTRANTS.TOGGLE:
      return { ...state, expanded: !state.expanded };

    default:
      return state;
  }
};

export default manageRegistrationReducer;
