// @flow

const API1_ROOT = '/_api/v1';
const API2_ROOT = '/_api/api/v2';
export const API3B_ROOT = '/_api/api/v3';

const apiURIs = {
  getUser: (dataRoomId: number, userId: number) =>
    `${API1_ROOT}/shared/getuserqnarolecode?userId=${userId}&dataRoomId=${dataRoomId}`,

  getSubjectSecurity: (dataRoomId: number, userId: number, teamId: number) =>
    `${API2_ROOT}/datarooms/${dataRoomId}/users/${userId}/questions/subjectsbysecuritygroup?securitygroupid=${teamId}`,

  saveSubjectSecurity: (dataRoomId: number, userId: number) =>
    `${API2_ROOT}/datarooms/${dataRoomId}/users/${userId}/questions/setdeniedsecuritygroupquestionsubjectgroups`,
};

export default apiURIs;
