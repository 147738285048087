// @flow

import * as R from 'ramda';
import { ACTION_TYPES, TEAM_DIALOG_TYPES } from 'app/constants';
import type { ThirdPartyIntegrations } from 'app/types/teamsTypes';

type TeamsData = {
  data: {
    name: string,
    colour: string,
    fullTextSearchAllowed: boolean,
    bulkDownloadAllowed: boolean,
    useSod: boolean,
    isSubteam: boolean,
    isCopy: boolean,
    thirdPartyIntegrations?: ThirdPartyIntegrations[],
  },
  errors: Object,
};

export const initialState = {
  data: {
    type: TEAM_DIALOG_TYPES.CREATE,
    name: '',
    colour: '',
    fullTextSearchAllowed: true,
    bulkDownloadAllowed: true,
    useSod: true,
    isSubteam: false,
    isCopy: false,
  },
  errors: {},
};

const teamFormDialogReducer = (state: TeamsData = initialState, action: Object): TeamsData => {
  switch (action.type) {
    case ACTION_TYPES.TEAMS.TEAM_FORM_CHANGE:
      return {
        data: {
          ...state.data,
          [action.data.key]: action.data.value,
        },
        errors: R.omit([action.data.key], state.errors),
      };

    case ACTION_TYPES.TEAMS.TEAM_FORM_EDIT:
      return {
        ...state,
        data: {
          ...action.data,
          type: TEAM_DIALOG_TYPES.EDIT,
        },
      };

    case ACTION_TYPES.TEAMS.TEAM_FORM_ADD_TEAM:
      return {
        ...state,
        data: {
          ...initialState.data,
          ...action.data,
        },
        errors: initialState.errors,
      };

    case ACTION_TYPES.TEAMS.TEAM_FORM_ADD_SUBTEAM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          isSubteam: true,
          type: TEAM_DIALOG_TYPES.CREATE,
          name: '',
        },
        errors: initialState.errors,
      };

    case ACTION_TYPES.TEAMS.TEAM_FORM_COPY:
      return {
        ...state,
        data: action.data,
      };

    case ACTION_TYPES.TEAMS.TEAM_FORM_ERRORS:
      return {
        ...state,
        errors: action.data,
      };
    case ACTION_TYPES.CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
};

export default teamFormDialogReducer;
