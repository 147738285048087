// @flow

import { TEAM_DIALOG_TYPES } from 'app/constants';
import type { TeamRecord } from 'app/pages/teamHistory/teamHistoryTypes';
import { getTeamData } from 'app/pages/teams/teamsHelpers';
import type { SessionType } from 'app/types/globalTypes';
import type { SubTeamFormType } from 'app/types/teamsTypes';
import * as R from 'ramda';
import type { Subteam, Team, Bidder, ThirdPartyIntegrations } from '../types/teamsTypes';
import type { DeleteTeamDataType, TeamDialogTypes, TeamFormData } from '../pages/teams/teamsTypes';
import { API3_ROOT, API1B_ROOT } from './request/constants';
import { post, get, del } from './request/api';

export const mapSubTeamType = (subteam: Object): Subteam => ({
  bulkDownloadAllowed: subteam.BulkDownloadAllowed,
  bulkDownloadExpirationInterval: subteam.BulkDownloadExpirationInterval,
  dataRoomId: subteam.DataRoomId,
  fullTextSearchAllowed: subteam.FullTextSearchAllowed,
  name: subteam.Name,
  subteamId: subteam.SubteamId,
  teamId: subteam.TeamId,
  id: subteam.SubteamId, // Remove this in. https://jira.ansarada.com/browse/DR-18006
  useSod: subteam.UseSod,
  isPrimary: subteam.IsPrimary,
  isSecurityEdited: subteam.IsSecurityEdited,
  isGuest: subteam.IsGuest, // subTeam don't have this field, but I won't create a new type.
});

export const mapTeamType = (team: Object): Team => ({
  id: team.TeamId, // Remove this in. https://jira.ansarada.com/browse/DR-18006
  teamId: team.TeamId,
  subteamId: team.PrimarySubteamId ?? team.SubteamId,
  name: team.Name,
  colour: team.Colour,
  dataroomId: team.DataRoomId,
  bulkDownloadAllowed: team.BulkDownloadAllowed,
  bulkDownloadExpirationInterval: team.BulkDownloadExpirationInterval,
  fullTextSearchAllowed: team.FullTextSearchAllowed,
  useSod: team.UseSod,
  primarySubteamId: team.PrimarySubteamId,
  isSecurityEdited: team.IsSecurityEdited,
  subteams: (team.Subteams || []).map(mapSubTeamType),
  isGuest: team.IsGuest,
});

const mapBidderType = (bidder: Object): Bidder => ({
  userGroupId: bidder.UserGroupId,
  name: bidder.Name,
  colour: bidder.Colour,
});

const getTeamApiUrl = (type: TeamDialogTypes, isSubteam: boolean) => {
  let url;
  if (isSubteam) {
    url =
      type === TEAM_DIALOG_TYPES.CREATE
        ? `${API3_ROOT}/teams/createSubteam`
        : `${API3_ROOT}/teams/updateSubteam`;
  } else {
    url =
      type === TEAM_DIALOG_TYPES.CREATE || type === TEAM_DIALOG_TYPES.COPY
        ? `${API3_ROOT}/teams/createTeam`
        : `${API3_ROOT}/teams/updateTeam`;
  }
  return url;
};

const sortByNameProp = R.sortBy(R.compose(R.toLower, R.prop('name')));
const sortSubTeamNames = (team: Team) => ({
  ...team,
  subteams: team.subteams && sortByNameProp(team.subteams),
});
export const getTeams = (session: SessionType, params?: Object): Promise<Array<Team>> =>
  get(session, `${API3_ROOT}/teams/getTeams`, params).then(result => {
    const teams = (result.data || []).map(mapTeamType);
    return sortByNameProp(teams).map(sortSubTeamNames);
  });

export const getBidders = (session: SessionType): Promise<Array<Team>> =>
  get(session, `${API1B_ROOT}/datarooms/${session.dataroomId}/evaluation/qualifiedbidders`).then(
    result => {
      return (result.data || []).map(mapBidderType);
    },
  );

export const deleteTeam = (session: SessionType, data: DeleteTeamDataType) => {
  const teamId = data.deleteData;
  const url = data.isSubteam
    ? `${API3_ROOT}/teams/deleteSubteam?subteamId=${teamId}`
    : `${API3_ROOT}/teams/deleteTeam?teamId=${teamId}`;
  return del(session, url);
};

export const updateSubteam = (session: SessionType, subteam: Subteam, teamData: Object) =>
  post(session, `${API3_ROOT}/teams/updateSubteam`, {
    SubteamId: subteam.subteamId,
    TeamId: subteam.teamId,
    DataRoomId: teamData.dataroomId,
    Name: subteam.name,
    FullTextSearchAllowed: teamData.fullTextSearchAllowed,
    BulkDownloadAllowed: teamData.bulkDownloadAllowed,
    UseSod: teamData.useSod,
  });

export const createSubteam = (
  session: SessionType,
  subteam: SubTeamFormType,
  teamId: number,
  isCopy: boolean,
): Promise<Subteam> =>
  post(session, `${API3_ROOT}/teams/createSubteam`, {
    name: subteam.name,
    fullTextSearchAllowed: subteam.fullTextSearchAllowed,
    useSod: subteam.useSod,
    bulkDownloadAllowed: subteam.bulkDownloadAllowed,
    teamId,
    isCopy,
  }).then(result => {
    if (result.err) {
      throw result.err;
    }
    return mapSubTeamType(result.data);
  });

export const createTeam = (session: SessionType, team: TeamFormData): Team =>
  post(session, '/_api/v3/teams/createTeam', team).then(result => mapTeamType(result.data));

export const saveTeam = (
  session: SessionType,
  formData: TeamFormData,
  isGuest: boolean,
): Promise<*> => {
  const url = getTeamApiUrl(formData.type, formData.isSubteam);
  return post(session, url, getTeamData(isGuest, formData)).then(result => {
    if (result.err) {
      throw result.err;
    }
    const resultData = result.data;
    const teamData = result.data || {};
    if (!R.isNil(resultData)) {
      if (!R.isNil(teamData.TeamId)) {
        teamData.teamId = teamData.TeamId;
      }
      if (!R.isNil(teamData.SubteamId)) {
        teamData.subteamId = teamData.SubteamId;
      }
    }
    return teamData;
  });
};

export const saveTeamThirdPartyIntegrations = (
  session: SessionType,
  subteamId: number,
  thirdPartyIntegrations: ThirdPartyIntegrations[],
) =>
  post(session, `${API1B_ROOT}/thirdpartyintegrations/forSecurityGroup`, {
    DataRoomId: session.dataroomId,
    UserAccessLogID: session.userAccessLogId,
    SecurityGroupID: subteamId,
    UpdateStatusProviders: thirdPartyIntegrations.map(provider => ({
      IntegrationProviderName: provider.systemName,
      IsAllowed: provider.isAllowed,
    })),
  });

export const getSubteam = (session: SessionType, subteamId: number): Promise<Subteam> =>
  get(session, `${API3_ROOT}/teams/GetSubteam`, { subteamId }).then(result =>
    mapSubTeamType(result.data || {}),
  );

export const getTeam = (session: SessionType, teamId: number): Promise<Team> =>
  get(session, `${API3_ROOT}/teams/GetTeam`, { teamId }).then(result =>
    mapTeamType(result.data || {}),
  );

export const getSubteamWithTeam = (session: SessionType, subteamId: number): Promise<Subteam> =>
  getSubteam(session, subteamId).then(subTeam =>
    getTeam(session, subTeam.teamId).then(team => ({
      ...subTeam,
      teamName: team.name,
      colour: team.colour,
      isGuest: team.isGuest,
    })),
  );

const mapTeamHistoryType = (rawTeamHistory: Object): TeamRecord => {
  let qnaDetails;
  if (rawTeamHistory.Team.QnaDetails) {
    const rawQnaDetails = rawTeamHistory.Team.QnaDetails;
    let maxQuestionsFrequency = null;
    if (rawQnaDetails.MaxQuestionsFrequency) {
      maxQuestionsFrequency = {
        allPriority: rawQnaDetails.MaxQuestionsFrequency.AllPriority,
        highPriority: rawQnaDetails.MaxQuestionsFrequency.HighPriority,
        lowPriority: rawQnaDetails.MaxQuestionsFrequency.LowPriority,
        mediumPriority: rawQnaDetails.MaxQuestionsFrequency.MediumPriority,
      };
    }
    let maxQuestionsTotal = null;
    if (rawQnaDetails.MaxQuestionsTotal) {
      maxQuestionsTotal = {
        allPriority: rawQnaDetails.MaxQuestionsTotal.AllPriority,
        highPriority: rawQnaDetails.MaxQuestionsTotal.HighPriority,
        lowPriority: rawQnaDetails.MaxQuestionsTotal.LowPriority,
        mediumPriority: rawQnaDetails.MaxQuestionsTotal.MediumPriority,
      };
    }
    qnaDetails = {
      allowQuestionsOnWeekends: rawQnaDetails.AllowQuestionsOnWeekends,
      maxQuestionsFrequencyInterval: rawQnaDetails.MaxQuestionsFrequencyInterval,
      maxQuestionsFrequency,
      maxQuestionsTotal,
    };
  }
  return {
    teamId: rawTeamHistory.TeamId,
    modifiedDate: rawTeamHistory.ModifiedDate,
    modifiedByUser: {
      fullName: rawTeamHistory.ModifiedByUser.FullName,
      userEmail: rawTeamHistory.ModifiedByUser.UserEmail,
    },
    team: {
      name: rawTeamHistory.Team.Name,
      colour: rawTeamHistory.Team.Colour,
      fullTextSearchAllowed: rawTeamHistory.Team.FullTextSearchAllowed,
      useSod: rawTeamHistory.Team.UseSod,
      bulkDownloadAllowed: rawTeamHistory.Team.BulkDownloadAllowed,
      qnaDetails,
    },
    updatedFields: rawTeamHistory.UpdatedFields.map((fullFieldName: string) =>
      fullFieldName.replace(/^(Team|SubTeam)\./, ''),
    ),
  };
};

export const getTeamHistory = (session: SessionType, teamId: number): Promise<*> =>
  get(session, `${API3_ROOT}/Pages/TeamHistory/GetData`, {
    teamId,
  }).then(result => (result.data || []).map(mapTeamHistoryType));

export const getSubTeamHistory = (session: SessionType, subTeamId: number): Promise<*> =>
  get(session, `${API3_ROOT}/Pages/SubTeamHistory/GetData`, { subTeamId }).then(result =>
    (result.data || []).map(record => {
      const { SubTeamId, SubTeam, ModifiedDate, ModifiedByUser, UpdatedFields } = record;
      return mapTeamHistoryType({
        TeamId: SubTeamId,
        Team: SubTeam,
        ModifiedDate,
        ModifiedByUser,
        UpdatedFields,
      });
    }),
  );
