// @flow

import type { AppDispatch, AppStateType } from 'app/types/appStateTypes';
import * as React from 'react';
import { connect } from 'react-redux';
import { LoadingIndicator } from '@ansarada/ace-react';
import translate from 'app/i18n/translate';
import { Dialog } from 'app/components/dialog/Dialog';
import ItemSecurity from 'app/pages/security/itemSecurity/ItemSecurity';
import { ACTION_TYPES } from 'app/constants';
import style from 'app/layout/siteDialog/siteDialog.scss';
import { getFeature2Data } from 'app/utils/feature2';
import type { SiteDialogType } from './siteDialogTypes';

export const SiteDialog = (props: SiteDialogType) => {
  const { data, dispatch, siteDialogType, permissions } = props;

  let content = (
    <div className={style.loadingContainer}>
      <LoadingIndicator dark />
    </div>
  );

  if (!data.isLoading && permissions) {
    content = <ItemSecurity />;
  }

  const handleClickSave = () => {
    dispatch({ type: ACTION_TYPES.SECURITY.ITEM_SETTINGS_SAVE });

    const enabledDocumentManagementEventTracking: boolean = getFeature2Data(
      'deals-52-dil-document-management-event-tracking',
    );
    if (enabledDocumentManagementEventTracking) {
      dispatch({ type: ACTION_TYPES.SEGMENT_EVENTS.DOCUMENT_SECURITY_EDITED });
      dispatch({ type: ACTION_TYPES.SEGMENT_EVENTS.SECURITY_EDITED });
    }
  };

  return (
    data.open && (
      <div className={`dr-spa dr-spa-dialog ${style.container}`}>
        <Dialog
          data-test-id="securityDialog"
          data={data}
          dispatch={dispatch}
          size="Large"
          triggerButton={{
            children: translate('Save'),
            variant: 'Primary',
            onClick: handleClickSave,
          }}
          triggerCloseAction={ACTION_TYPES.SITE.DIALOG_TOGGLE}
          siteDialogType={siteDialogType}
        >
          {content}
        </Dialog>
      </div>
    )
  );
};

export const mapStateToProps = (state: AppStateType): Object => ({
  data: state.dialog,
  permissions: state.page.userPermissions,
});

export default connect<AppStateType, AppDispatch, *, *>(mapStateToProps)(SiteDialog);
