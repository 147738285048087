// @flow

// prettier-ignore

export default {
  Accept: 'Chấp nhận',
  AcceptRegistration: 'Chấp nhận đăng ký',
  AcceptTermsOfAccess_SentenceCase: 'Chấp nhận điều khoản truy cập',
  Access: 'Truy cập',
  AccessAllowed: 'Truy cập được cho phép',
  AccessDenied: 'Quyền truy cập bị từ chối',
  AccessedAgo: 'Đã truy cập [thời gian] trước',
  Actions: 'hành động',
  Add: 'Thêm vào',
  AddAndInvite: 'Thêm và mời',
  AddAndInvitePeople: 'Thêm và mời mọi người',
  AdditionalRecipients: 'Người nhận bổ sung',
  AdditionalRecipients_SentenceCase: 'Người nhận bổ sung',
  AdditionalRecipientsHint: 'Nhập từng địa chỉ email trên một dòng riêng',
  AddPeople: 'Thêm người',
  AddPeopleTo: 'Thêm người vào',
  AddPeopleToSubteam: 'Thêm người vào [Nhóm]/[Nhóm phụ]',
  AddPeopleToTeam: 'Thêm người vào [Nhóm]',
  AddPeopleWillHaveTheSameAccess: 'Những người được thêm sẽ có cùng quyền truy cập',
  AddToExistingTeam: 'Thêm vào nhóm hiện có',
  Administration: 'Sự quản lý',
  AdminSideAudit: 'Đội',
  AdminSideBankruptcy: 'Bên bán',
  AdminSideBankruptcy_EmailHeading: 'Gửi email bên bán cho mọi người',
  AdminSideBankruptcy_InviteHeading: 'Gửi lời mời bán người bên cạnh',
  AdminSideDebt: 'Tổ chức phát hành',
  AdminSideDebt_EmailHeading: 'Nhà phát hành email',
  AdminSideDebt_InviteHeading: 'Gửi lời mời đến nhà phát hành',
  AdminSideDocumentRepository: 'Nội bộ',
  AdminSideDocumentRepository_EmailHeading: 'Email nội bộ',
  AdminSideDocumentRepository_InviteHeading: 'Gửi lời mời đến nội bộ',
  AdminSideFundraising: 'Khách hàng',
  AdminSideFundraising_EmailHeading: 'Email khách hàng',
  AdminSideFundraising_InviteHeading: 'Gửi lời mời tới khách hàng',
  AdminSideInternalAudit: 'Đội',
  AdminSideJointVenture: 'Nội bộ',
  AdminSideJointVenture_EmailHeading: 'Email nội bộ',
  AdminSideJointVenture_InviteHeading: 'Gửi lời mời đến nội bộ',
  AdminSideMerger: 'Bên bán',
  AdminSideMerger_EmailHeading: 'Gửi email bên bán cho mọi người',
  AdminSideMerger_InviteHeading: 'Gửi lời mời bán người bên cạnh',
  AdminSideOther: 'Nội bộ',
  AdminSideOther_EmailHeading: 'Email nội bộ',
  AdminSideOther_InviteHeading: 'Gửi lời mời đến nội bộ',
  AdminSidePlacement: 'Tổ chức phát hành',
  AdminSidePlacement_EmailHeading: 'Nhà phát hành email',
  AdminSidePlacement_InviteHeading: 'Gửi lời mời đến nhà phát hành',
  AdminSidePublicOffer: 'Nội bộ',
  AdminSidePublicOffer_EmailHeading: 'Email nội bộ',
  AdminSidePublicOffer_InviteHeading: 'Gửi lời mời đến nội bộ',
  AdminSideTargeted: 'Đội ngũ nội bộ',
  AdminSideTargeted_EmailHeading: 'Gửi email cho nhóm nội bộ',
  AdminSideTargeted_InviteHeading: 'Gửi lời mời đến nhóm nội bộ',
  AdminSideTax: 'Đội',
  AdminSideTender: 'Khách hàng',
  AdminSideTender_EmailHeading: 'Email khách hàng',
  AdminSideTender_InviteHeading: 'Gửi lời mời tới khách hàng',
  All: 'Tất cả',
  Allow: 'Cho phép',
  Allowed: 'Cho phép',
  And_SentenceCase: 'Và',
  AndGoLive: 'và phát trực tiếp',
  AreYouSureToDeclineRegistration: 'Bạn có chắc chắn muốn từ chối người đăng ký [người đăng ký] không?',
  AreYouSureToDeleteRegistration: 'Bạn có chắc chắn muốn xóa người đăng ký [người đăng ký] không?',
  AreYouSureToDeleteRequest: 'Bạn có chắc chắn muốn xóa yêu cầu [người đăng ký] không?',
  At: 'Tại',
  Attachments: 'Tệp đính kèm',
  BackToTeamsPage: 'Quay lại trang đội',
  BannerMessage: "Nhóm được tạo bằng cài đặt của [TeamName]",
  BatchOperationCantBePerformed:
    'Không thể thực hiện thao tác hàng loạt vì không có Người dùng nào trong Phòng Dữ liệu này. Vui lòng nhập Người dùng trước.',
  BulkDownload_SentenceCase: 'Tải xuống hàng loạt',
  BulkDownloadText:
    'Cho phép mọi người tải xuống nhiều tệp trong một tệp .zip. Tải xuống zip chỉ có sẵn trong một thời gian giới hạn.',
  BuySide: 'bên mua',
  BuySide_InviteHeading: 'Gửi lời mời mua hàng bên mọi người',
  BuySide_SentenceCase: 'bên mua',
  BySubmittingThisForm: 'Bằng cách gửi biểu mẫu này, bạn đồng ý với chúng tôi',
  Cancel: 'Hủy bỏ',
  Change: 'Thay đổi',
  ChangeTeamAccess: 'Thay đổi quyền truy cập của nhóm',
  ChooseLanguage: 'Chọn ngôn ngữ',
  ClickAndPreview: '2. Nhấp và xem trước',
  Close: 'Đóng',
  CollapseAll_SentenceCase: 'Thu gọn tất cả',
  Colour: 'Màu sắc',
  Company: 'Công ty',
  Confirm: 'Xác nhận',
  ContactUs: 'Liên hệ chúng tôi',
  Copied: 'Đã sao chép',
  CopiedSharedLinkInfo: 'Chia sẻ liên kết với các nhóm đấu thầu bên ngoài hoặc khách để họ có thể đăng ký.',
  CopyEmailsOfSelectedUsers: 'Sao chép email của người dùng đã chọn',
  CopyEmailsOfSelectedUsersSec2: 'Sao chép các email đã chọn',
  CopyingAllowed: 'Cho phép sao chép',
  CopyingDenied: 'Sao chép bị từ chối',
  CopyingSecuritySettings: 'Sao chép cài đặt bảo mật',
  CopyLink: 'Sao chép đường dẫn',
  CopyOf: 'Bản sao của',
  CopyRegistrationLink: 'Sao chép liên kết đăng ký',
  CopySecurity: 'Sao chép bảo mật',
  CopySharedLinkToClipboard: 'Sao chép liên kết vào clipboard',
  CopyTeam: 'Sao chép đội',
  CreateArchive: 'Tạo kho lưu trữ',
  CreateNewTeam: 'Tạo đội mới',
  CreatePeopleWithSameEmail: 'Tạo những người có cùng email với một người hiện có',
  CreateSideTeam: 'Tạo nhóm [Bên]',
  CreateSubteam: 'Tạo nhóm phụ',
  CreateTeam: 'Tạo nhóm',
  CreateTeamEmptyState: 'Tạo một nhóm để sắp xếp mọi người và thiết lập quyền truy cập',
  CreateWatermark: 'Tạo hình mờ',
  Daily: 'Hằng ngày',
  DataRoom: 'Phòng dữ liệu',
  DataroomRole: 'Vai trò phòng',
  DataRooms: 'Phòng dữ liệu',
  DataRoomStatus: 'Tình trạng phòng dữ liệu',
  DataRoomUserNotVerified1: 'Để truy cập vào phòng dữ liệu:',
  DataRoomUserNotVerified2: 'Mở email mời của bạn',
  DataRoomUserNotVerified3: 'Nhấp vào liên kết &quot;Xác minh chi tiết của bạn&quot;',
  DataRoomUserNotVerified4: 'Đăng nhập',
  DataRoomUserNotVerified5:
    "Nếu bạn chưa nhận được email mời, vui lòng liên hệ với quản trị viên phòng dữ liệu của bạn.",
  DataRoomUserNotVerifiedTitle: "Quyền truy cập của bạn chưa được xác minh",
  DateModified: 'Ngày sửa đổi',
  DateSent: 'Ngày gửi',
  Decline: 'Sự suy sụp',
  Declined: 'Suy giảm',
  DeclineRegistration: 'Từ chối đăng ký',
  Delete: 'Xóa bỏ',
  DeleteBidderInformation:
    "Một nhóm chỉ có thể bị xóa khi nhóm đó không phải là nhà thầu đủ điều kiện trong quá trình Đánh giá. Loại bỏ đội này khỏi danh sách nhà thầu đủ điều kiện.",
  DeleteBidderTeamInformationLine1:
    "Một nhóm chỉ có thể bị xóa khi nhóm đó trống và không phải là nhà thầu đủ điều kiện trong quá trình Đánh giá.",
  DeleteBidderTeamInformationLine2:
    'Chuyển mọi người ra khỏi nhóm này và xóa nhóm này khỏi danh sách nhà thầu đủ điều kiện.',
  DeletePerson: 'Xóa người',
  DeletePersonConfirm: 'Xóa người',
  DeletePersonHeader: 'Xóa [email]?',
  DeletePersonMessage:
    'Xóa [email] sẽ xóa vĩnh viễn họ khỏi Phòng, tất cả báo cáo và lịch sử hoạt động. Hành động này không thể được hoàn tác.',
  DeleteRegistration: 'Xóa đăng ký',
  DeleteRequest: 'Xóa yêu cầu',
  DeleteSubteam: 'Xóa nhóm phụ',
  DeleteSubteamInformation:
    'Một nhóm phụ chỉ có thể bị xóa khi nó trống. Di chuyển mọi người ra khỏi nhóm phụ này để xóa nó.',
  DeleteTeam: 'Xóa đội',
  DeleteTeamInformation:
    'Một đội chỉ có thể bị xóa khi nó trống. Di chuyển mọi người ra khỏi nhóm này để xóa nó.',
  DeletingTeamContainsPeople:
    'Đội chỉ có thể bị xóa khi trống. Di chuyển mọi người ra khỏi nhóm này để xóa nó.',
  DemoAllCaps: 'THỬ NGHIỆM',
  Deny: 'Từ chối',
  DenyCopyingDisabled: 'Từ chối sao chép bị vô hiệu hóa',
  DenyEditingDisabled: 'Từ chối chỉnh sửa bị vô hiệu hóa',
  DenyPrintingDisabled: 'Từ chối in bị vô hiệu hóa',
  DenySavingDisabled: 'Từ chối lưu bị vô hiệu hóa',
  DidntReceiveTheEmail: "Bạn không nhận được email?",
  Disable: 'Vô hiệu hóa',
  DisableAccess: 'Vô hiệu hóa quyền truy cập',
  Disabled: 'Tàn tật',
  DisabledUser: 'người dùng bị vô hiệu hóa',
  DisableRegistrations: 'Vô hiệu hóa đăng ký',
  DisableRegistrations_SentenceCase: 'Vô hiệu hóa đăng ký',
  DisableRegistrationsAction: 'Vô hiệu hóa',
  DisableRegistrationsInformation: 'Khi bị vô hiệu hóa, liên kết đăng ký không còn hoạt động.',
  Disallowed: 'Không được phép',
  Document: 'Tài liệu',
  DocumentIndex: 'Mục lục tài liệu',
  DocumentIndexSecurity: 'Bảo mật chỉ mục tài liệu',
  Documents: 'Các tài liệu',
  Down: 'Xuống',
  Draft: 'Bản nháp',
  Edit: 'Biên tập',
  EditAfterCloseDate: 'Chỉnh sửa chỉ mục gửi sau ngày đóng',
  EditDocumentSecurityTeam:
    'Không có kiểm soát in hoặc lưu bảo mật, quyền truy cập hoặc hình mờ nào được đặt cho nhóm này.',
  EditDocumentSecurityTeamSubteam:
    'Không có kiểm soát in hoặc lưu bảo mật, quyền truy cập hoặc hình mờ nào được đặt cho nhóm phụ này.',
  EditingAllowed: 'Cho phép chỉnh sửa',
  EditingDenied: 'Chỉnh sửa bị từ chối',
  EditMultipleUsers: 'Chỉnh sửa nhiều người dùng',
  EditPeople: 'Chỉnh sửa người',
  EditPerson: 'Chỉnh sửa người',
  EditPersonFormInvitationMessage:
    'Gửi lời mời mới đến địa chỉ email đã cập nhật bằng cách nhấp vào hộp kiểm bên dưới',
  EditProfile: 'Chỉnh sửa hồ sơ',
  EditSecurity_SentenceCase: 'Chỉnh sửa bảo mật',
  EditSecurityFor: 'Chỉnh sửa bảo mật cho',
  EditSubteam: 'Chỉnh sửa nhóm phụ',
  EditTeam: 'Chỉnh sửa đội',
  Email: 'E-mail',
  EmailAddress: 'địa chỉ email',
  EmailCanNotBeEmpty: 'Email không được để trống',
  EmailHasBeenSent: 'Email đã được gửi.',
  EmailMessage: 'Tin nhắn email là trường bắt buộc',
  EmailNotifications: 'Những thông báo thư điện tử',
  EmailNotValid: 'Email không hợp lệ',
  EmailPeople: 'Gửi email cho mọi người',
  EmailPeopleSubjectRequiredSentenceCase: 'Cần có chủ đề cho email',
  Emails: 'Email',
  EmailsNotValid: 'Email [Email] không hợp lệ',
  EmailSuccessMessage: 'Email của bạn đã được gửi thành công.',
  EnableAccess: 'Cho phép truy cập',
  Enabled: 'Đã bật',
  EnableRegistrations: 'Kích hoạt đăng ký',
  EnableRegistrations_SentenceCase: 'Cho phép đăng ký',
  EnableRegistrationsAction: 'Cho phép',
  EnableRegistrationsInformation:
    'Khi được bật, liên kết đăng ký sẽ hoạt động và có thể được chia sẻ công khai.',
  EnterIpAddressesRanges: 'Nhập địa chỉ IP hoặc dải địa chỉ IP được phân tách bằng phím Enter',
  EnterOneOrMoreEmailAddresses: 'Email của mỗi người',
  EnterYourLocationOrClosestCity: 'Nhập vị trí của bạn hoặc thành phố gần nhất',
  Every: 'Mọi',
  ExpandAll_SentenceCase: 'Mở rộng tất cả',
  ExportToExcel_SentenceCase: 'Xuất sang Excel',
  FieldIsRequired: '[lĩnh vực được yêu cầu',
  FieldMustBeAtMostMaxCharacters: '[trường] phải có tối đa [tối đa] ký tự.',
  FileExtensionIsInList:
    'Không thể tải lên tệp &quot;[Tên tệp]&quot; vì phần mở rộng của nó nằm trong danh sách các phần mở rộng tệp không được phép đối với phòng dữ liệu này:\n\n[FileTypeList]\n\nVui lòng chuyển đổi tệp này sang một trong các định dạng được phép.',
  FileExtensionIsNotInList:
    'Không thể tải lên tệp &quot;[FileName]&quot; vì phần mở rộng của nó không nằm trong danh sách phần mở rộng tệp được phép cho phòng dữ liệu này:\n\n[FileTypeList]\n\nVui lòng chuyển đổi tệp này sang một trong các định dạng được phép.',
  Filter: 'Lọc',
  FirstName: 'Tên đầu tiên',
  Folder: 'Thư mục',
  ForRangesSeparateStartIp:
    'Đối với các phạm vi, hãy phân tách IP bắt đầu và IP kết thúc bằng dấu gạch ngang, ví dụ: <b>1.1.1.1-1.1.1.6</b>',
  Friday: 'Thứ sáu',
  From: 'Từ:',
  FullName: 'Họ và tên',
  FullText: 'Tìm kiếm toàn văn',
  FullTextText: 'Cho phép mọi người tìm kiếm trong tài liệu.',
  GDPRInformation: 'Thông tin GDPR',
  GlobalUnexpectedErrorMessage:
    "Có vấn đề với phòng dữ liệu của bạn. Vui lòng liên hệ với nhóm Dịch vụ khách hàng của chúng tôi.",
  GlobalUnexpectedErrorWithIdMessage:
    "Có vấn đề với phòng dữ liệu của bạn. Trích dẫn mã này &lt;[ErrorId]&gt; cho nhóm Dịch vụ khách hàng của chúng tôi.",
  GotIt: 'Hiểu rồi',
  Guest: 'Khách mời',
  GuestUserRegistration: 'Đăng ký người dùng khách',
  GuideToUsingLink: 'Hướng dẫn sử dụng Liên kết',
  Help: 'Giúp đỡ',
  HelpAndSupport: 'Trợ giúp &amp; hỗ trợ',
  HideMenu: 'Ẩn menu',
  High: 'Cao',
  History: 'Lịch sử',
  Hourly: 'Hàng giờ',
  ID: 'NHẬN DẠNG',
  Importance: 'Tầm quan trọng',
  Information: 'Thông tin',
  Interval: 'Khoảng thời gian',
  InvalidEmailAddress: 'địa chỉ email không hợp lệ',
  InvalidField: '[trường] không hợp lệ',
  InvalidPdf: 'LỖI: Tệp này không phải là tài liệu pdf hợp lệ. Vui lòng thử một tập tin khác.',
  Invitation: 'Lời mời',
  InvitationEmail: 'Email mời',
  InvitationSuccessMessage: 'Lời mời của bạn đã được gửi thành công.',
  Invite: 'Mời',
  InviteAGuest: 'Mời một vị khách',
  Invited: 'Được mời',
  Invited_Users_: 'Được mời',
  InvitePeople: 'Mời mọi người',
  IpAccessList: 'Danh sách truy cập IP',
  IpAccessList_SentenceCase: 'Danh sách truy cập IP',
  IpAccessListCanNotBeEmpty: 'Danh sách truy cập IP không được để trống',
  IpAddressInvalidIpAccessList: "IP &#39;[IpAddress]&#39; trong Danh sách truy cập IP không phải là địa chỉ IP hợp lệ.",
  IpAddressInvalidRangeIpAccessList:
    "Dải IP &#39;[IpRange]&#39; trong Danh sách truy cập IP có địa chỉ IP bắt đầu lớn hơn địa chỉ IP cuối. IP bắt đầu phải nhỏ hơn hoặc bằng IP cuối.",
  IpLimitRangeIpAccessList:
    "Vui lòng giới hạn mô tả dải IP &#39;[IpRange]&#39; trong Danh sách truy cập IP ở [NumOfChars] ký tự. Bạn đã sử dụng ký tự [UsedNumOfChars].",
  IpUserAccessListLimit:
    'Vui lòng giới hạn Danh sách truy cập IP ở [MaxNumberOfCharacters] ký tự. Bạn đã nhập ký tự [EnteredNumberOfCharacters].',
  JobTitle: 'Chức danh',
  JobTitleProfession: 'Chức danh/nghề nghiệp',
  Last30days: '30 ngày trước',
  Last7days: '7 ngày qua',
  LastMonth: 'Tháng trước',
  LastName: 'Họ',
  LastWeek: 'Tuần trước',
  LearnMore: 'Tìm hiểu thêm',
  LicenceChatHelp: 'Trò chuyện với chuyên gia.',
  LicenceErrorHelp: 'Cần giúp đỡ?',
  LicenceErrorMessage:
    'Bạn đã hết giấy phép. Đăng ký của bạn sẽ được nâng cấp nếu bạn đi qua.',
  LicencePack: 'Gói trả trước của nhóm người dùng',
  LicencePrompt:
    'Bạn có thể có lời mời đang chờ xử lý, giấy phép sẽ chỉ được sử dụng sau khi lời mời được chấp nhận. Đăng ký của bạn sẽ được nâng cấp nếu bạn đi qua.',
  LicenceSectionPlan: 'Kế hoạch của bạn',
  LicenceTooltip: 'Giấy phép được sử dụng khi một người tồn tại trong nền tảng theo cách sau',
  LicenceTooltipStep1: 'Chỉ trong Lộ trình nền tảng',
  LicenceTooltipStep2: 'Chỉ có trong Phòng Dữ liệu Nền tảng',
  LicenceTooltipStep3: 'Cả hai',
  LicenseMessage:
    'Lời mời được chấp nhận sẽ sử dụng giấy phép từ đăng ký của bạn. Đăng ký của bạn sẽ được nâng cấp nếu bạn đi qua.',
  LimitedCharacterLength: '[1] không thể vượt quá [0] ký tự.',
  LinkCopied: 'Đã sao chép liên kết',
  LinkCopiedToClipboard: 'Liên kết được sao chép vào khay nhớ tạm',
  LinkedDocuments: 'Tài liệu được liên kết',
  Loading: 'Đang tải',
  LoadingThreeDots: 'Đang tải...',
  Location: 'Vị trí',
  LoggedInToDate_SentenceCase: 'Đã đăng nhập cho đến nay',
  LoginIpAccess: 'Đăng nhập truy cập IP',
  LoginLowerCase: 'đăng nhập',
  LoginsLowerCase: 'thông tin đăng nhập',
  Logout: 'Đăng xuất',
  Low: 'Thấp',
  Manage: 'Quản lý',
  ManageQnaSubjects: 'Quản lý chủ đề hỏi đáp',
  Medium: 'Trung bình',
  Message: 'Tin nhắn',
  MessageEmpty: 'Tin nhắn không thể trống',
  Minute: 'phút',
  Minutes: 'phút',
  ModifiedAgo: 'Đã sửa đổi [thời gian] trước',
  ModifiedBy: 'Được sửa đổi bởi',
  Monday: 'Thứ hai',
  MyAccount: 'Tài khoản của tôi',
  Name: 'Tên',
  NavigateAwayConfirm: 'Rời khỏi',
  NavigateAwayWarning: 'Rời khỏi trang?',
  NavigateAwayWarningMessage: 'Những thay đổi bạn đã thực hiện sẽ không được lưu.',
  Never: 'Không bao giờ',
  NeverLoggedIn_SentenceCase: 'Chưa bao giờ đăng nhập',
  No: 'KHÔNG',
  NoCompanyContact: 'Không có liên hệ với công ty',
  NoDemonstration: 'Không có cuộc biểu tình',
  NonAdminSideBankruptcy: 'bên mua',
  NonAdminSideBankruptcy_EmailHeading: 'Gửi email cho người mua bên',
  NonAdminSideBankruptcy_InviteHeading: 'Gửi lời mời mua hàng bên mọi người',
  NonAdminSideDebt: 'Nhà đầu tư',
  NonAdminSideDebt_EmailHeading: 'Gửi email cho nhà đầu tư',
  NonAdminSideDebt_InviteHeading: 'Gửi lời mời đến nhà đầu tư',
  NonAdminSideDocumentRepository: 'Bên ngoài',
  NonAdminSideDocumentRepository_EmailHeading: 'Gửi email ra bên ngoài',
  NonAdminSideDocumentRepository_InviteHeading: 'Gửi lời mời ra bên ngoài',
  NonAdminSideFundraising: 'Nhà tài chính',
  NonAdminSideFundraising_EmailHeading: 'Nhà tài trợ email',
  NonAdminSideFundraising_InviteHeading: 'Gửi lời mời đến các nhà tài trợ',
  NonAdminSideJointVenture: 'Bên ngoài',
  NonAdminSideJointVenture_EmailHeading: 'Gửi email ra bên ngoài',
  NonAdminSideJointVenture_InviteHeading: 'Gửi lời mời ra bên ngoài',
  NonAdminSideMerger: 'bên mua',
  NonAdminSideMerger_EmailHeading: 'Gửi email cho người mua bên',
  NonAdminSideMerger_InviteHeading: 'Gửi lời mời mua hàng bên mọi người',
  NonAdminSideOther: 'Bên ngoài',
  NonAdminSideOther_EmailHeading: 'Gửi email ra bên ngoài',
  NonAdminSideOther_InviteHeading: 'Gửi lời mời ra bên ngoài',
  NonAdminSidePlacement: 'Nhà đầu tư',
  NonAdminSidePlacement_EmailHeading: 'Gửi email cho nhà đầu tư',
  NonAdminSidePlacement_InviteHeading: 'Gửi lời mời đến nhà đầu tư',
  NonAdminSidePublicOffer: 'Bên ngoài',
  NonAdminSidePublicOffer_EmailHeading: 'Gửi email ra bên ngoài',
  NonAdminSidePublicOffer_InviteHeading: 'Gửi lời mời ra bên ngoài',
  NonAdminSideTargeted: 'Đội mục tiêu',
  NonAdminSideTargeted_EmailHeading: 'Nhóm mục tiêu email',
  NonAdminSideTargeted_InviteHeading: 'Gửi lời mời đến nhóm mục tiêu',
  NonAdminSideTender: 'Nhà thầu',
  NonAdminSideTender_EmailHeading: 'Người đặt giá thầu qua email',
  NonAdminSideTender_InviteHeading: 'Gửi lời mời đến các nhà thầu',
  None: 'Không có',
  NoQuestionPermission: "Bạn không có quyền đặt chủ đề câu hỏi.",
  NoQuestionSubjects: "Bạn chưa có bất kỳ chủ đề câu hỏi nào.",
  NoRecordsFound: 'Không có dữ liệu được tìm thấy.',
  Normal: 'Bình thường',
  NoRooms: "Hiện tại bạn chưa có phòng nào",
  NoRoomsMatch: 'Không tìm thấy phòng nào',
  NoScorecards: "Hiện tại bạn không có Thẻ điểm nào",
  NoScorecardsMatch: 'Không tìm thấy Thẻ điểm',
  Notes: 'Ghi chú',
  Notifications: 'Thông báo',
  NotInvited: 'Không được mời',
  NotInvited_Users__SentenceCase: 'Không được mời',
  NotVerified_Users__SentenceCase: 'Chưa xác minh',
  Off: 'Tắt',
  Ok: 'Được rồi',
  OK: 'ĐƯỢC RỒI',
  OkAllCaps: 'ĐƯỢC RỒI',
  On: 'TRÊN',
  OnceOnlyAndWhenTermsAreUpdated: 'Chỉ một lần và khi các điều khoản được cập nhật',
  OnEveryLogin: 'Mỗi lần đăng nhập',
  OnfirstLogin: 'Vào lần đăng nhập đầu tiên',
  OnlyEnabledUsersCanBeInvited: 'Chỉ những người dùng đã kích hoạt mới có thể được mời.',
  OnlyEnabledUsersCanBeInvitedSec2: 'Chỉ những người có quyền truy cập mới có thể được mời.',
  Optional: 'Không bắt buộc',
  Or: 'hoặc',
  OtherTeamMembers: '[kích thước] các thành viên khác trong nhóm',
  Pending: 'Chưa giải quyết',
  PendingScorecards: 'Thẻ điểm đang chờ xử lý',
  PeopleFormWarningText:
    "Áp dụng vai trò phòng &#39;Quản trị viên&#39; giúp mọi người có quyền truy cập không hạn chế. Họ không bị giới hạn bởi cài đặt bảo mật của nhóm họ",
  PeopleLowerCase: 'mọi người',
  PerDay: 'mỗi ngày',
  Period: 'Giai đoạn',
  PerMonth: 'Mỗi tháng',
  PersonHistory: 'Lịch sử cá nhân',
  PersonHistoryFor: 'Lịch sử cho',
  PersonLowerCase: 'người',
  PerWeek: 'Mỗi tuần',
  Phone: 'Điện thoại',
  PhoneNumber: 'số điện thoại',
  PhoneNumber_SentenceCase: 'Số điện thoại',
  PrintingAllowed: 'Cho phép in ấn',
  PrintingDenied: 'Việc in bị từ chối',
  Priority: 'Sự ưu tiên',
  PrivacyPolicy: 'Chính sách bảo mật',
  Profession: 'Nghề nghiệp',
  Profile: 'Hồ sơ',
  Properties: 'Của cải',
  Qna: 'Hỏi đáp',
  QnaNotificationSwitchOff: 'Bạn sẽ không nhận được bất kỳ email nào',
  QnaNotificationSwitchOn: 'Bạn sẽ nhận được thông tin cập nhật ngay lập tức',
  QnaRole: 'Vai trò hỏi đáp',
  QnaRole_SentenceCase: 'Vai trò hỏi đáp',
  QnaSubjects: 'Chủ đề hỏi đáp',
  QnaSubjectSecurity: 'Chủ đề Hỏi đáp Bảo mật',
  QnaSubmissionsLimit: 'Giới hạn gửi câu hỏi',
  QnaSubmissionsOnWeekends: 'Gửi câu hỏi vào cuối tuần',
  Ready: 'Sẵn sàng',
  ReasonForRequest: 'Lý do yêu cầu',
  Recipients: 'Người nhận',
  RecipientsOfEmailNote_Gen2:
    "Những người nhận email này sẽ KHÔNG thể nhìn thấy địa chỉ của nhau, vì vậy việc gửi nó cho người dùng từ các nhóm người dùng khác nhau là an toàn",
  Register: 'Đăng ký',
  RegisterYourInterest: 'Đăng ký sở thích của bạn',
  RegisterYourInterest_SentenceCase:
    'Gửi yêu cầu đến Quản trị viên <strong>[dataRoomName]</strong> để xem xét. Nếu đăng ký của bạn được chấp nhận, bạn sẽ được mời vào dự án.',
  Registration: 'Sự đăng ký',
  Registration_LowerCase: 'sự đăng ký',
  RegistrationDetails: 'Chi tiết đăng ký',
  RegistrationLinkDisabled: 'Liên kết đăng ký bị vô hiệu hóa',
  RegistrationRequestSubmitted: 'Đã gửi yêu cầu đăng ký',
  Registrations: 'Đăng ký',
  Registrations_LowerCase: 'đăng ký',
  RegistrationTextCopy:
    'Bạn đã cho phép các nhóm đấu thầu hoặc khách đăng ký quan tâm. Sao chép liên kết được cung cấp để cho phép mọi người đăng ký. Người đăng ký mới sẽ xuất hiện trong danh sách này để bạn xem xét và mời. Những người trong danh sách này không có quyền truy cập cho đến khi bạn mời và thêm họ vào nhóm.',
  Request: 'Lời yêu cầu',
  RequestAccess: 'Yêu cầu quyền truy cập',
  RequestAccessDialogDescription: 'Cung cấp thông tin chi tiết cho người yêu cầu quyền truy cập bổ sung. Yêu cầu của bạn sẽ được Quản trị viên xem xét.',
  RequestAccessDialogTitle: 'Yêu cầu quyền truy cập bổ sung',
  RequestAccessEmptyStateText: 'Người đăng ký mới sẽ xuất hiện trong danh sách này để bạn xem xét. Những người trong danh sách này không có quyền truy cập cho đến khi Quản trị viên thêm họ vào nhóm.',
  RequestDetails: 'Yêu cầu chi tiết',
  RequestorEmail: 'Email người yêu cầu',
  Required: 'Yêu cầu',
  ResendEmail: 'Gửi lại email',
  ResendVerificationLink: 'Gửi lại liên kết xác minh',
  Reset: 'Cài lại',
  Review: 'Ôn tập',
  RoomRole_SentenceCase: 'Vai trò phòng',
  RoomRoleAdminSubteamHelp: 'Không thể thêm quản trị viên vào nhóm phụ.',
  RoomRoleHelp: "Vai trò Phòng xác định cấp độ truy cập của một người trong phòng.",
  Rooms: 'Phòng',
  RoomsAdvert1:
    "Với Dropbox Showcase, bạn có thể chia sẻ công việc của mình với đối tác và khách hàng một cách đẹp mắt, theo dõi tiến trình và theo dõi xem ai đã xem nội dung gì - tất cả ở cùng một nơi.",
  RoomsAdvert2: 'Showcase có sẵn cho người dùng Dropbox Business Advanced và Enterprise.',
  RoomsAdvertLink: 'Tìm hiểu thêm',
  RoomsAdvertTitle: 'Chạy một thỏa thuận hiệu suất cao',
  Saturday: 'Thứ bảy',
  Save: 'Cứu',
  SaveAndInvitePeople: 'Lưu và mời mọi người',
  Saving: 'Tiết kiệm',
  SavingAllowed: 'Cho phép lưu',
  SavingDenied: 'Lưu bị từ chối',
  SavingFailed: 'Lưu không thành công',
  Scorecards: 'Thẻ điểm',
  ScorecardsAdvert1:
    "Với Dropbox Showcase, bạn có thể chia sẻ công việc của mình với đối tác và khách hàng một cách đẹp mắt, theo dõi tiến trình và theo dõi xem ai đã xem nội dung gì - tất cả ở cùng một nơi.",
  ScorecardsAdvert2: 'Showcase có sẵn cho người dùng Dropbox Business Advanced và Enterprise.',
  ScorecardsAdvertLink: 'Tìm hiểu thêm',
  ScorecardsAdvertTitle: 'Chạy một thỏa thuận hiệu suất cao',
  Search: 'Tìm kiếm',
  Secure: 'Chắc chắn',
  SecureByDefault: 'Bảo mật theo mặc định',
  SecureOffice: 'Văn phòng an toàn',
  SecureOfficeText:
    'Office bảo mật theo dõi các chế độ xem và có thể vô hiệu hóa việc lưu, sao chép và in trong các tệp Microsoft Word, Excel và PowerPoint.',
  SecureOfficeTitle: 'Bảo mật cho tài liệu Microsoft Office (Secure Office)',
  SecureOfficeWarning:
    'Secure Office không tương thích với nhiều thiết bị phổ biến, bao gồm cả thiết bị Apple iOS và Android. Cài đặt này ngăn những người không có hệ thống tương thích có thể xem các tệp này.',
  Security: 'Bảo vệ',
  SecurityControls: 'Kiểm soát an ninh',
  SecurityControlsOff: 'Kiểm soát an ninh tắt',
  SecurityControlsOffDescription:
    'Khi tắt các biện pháp kiểm soát bảo mật, việc sử dụng các tài liệu này không bị hạn chế sau khi tải xuống.',
  SecurityControlsOn: 'Kiểm soát an ninh bật',
  SecurityControlsOnDescription:
    'Khi bật các biện pháp kiểm soát bảo mật, việc sử dụng tài liệu Microsoft Office và PDF sẽ được theo dõi. Các bản sao đã lưu sẽ bị vô hiệu hóa từ xa khi quyền truy cập của một nhóm bị thu hồi hoặc khi Phòng bị vô hiệu hóa.',
  SecurityControlsOnExtraDescription:
    'Bạn cũng có thể hạn chế việc lưu, in, chỉnh sửa và sao chép (các hạn chế sao chép chỉ khả dụng cho các tài liệu Microsoft Office).',
  SecurityGroup_SentenceCase: 'Nhóm bảo mật',
  SecurityPermissions: 'Quyền bảo mật',
  Select: 'Lựa chọn',
  SelectPeople_SentenceCase: 'Chọn người',
  SelectSubTeam: 'Vui lòng chọn Nhóm phụ.',
  SelectTeam: 'Vui lòng chọn Đội.',
  SelectUsers_SentenceCase: 'Chọn người',
  SelectUserType: 'Vui lòng chọn vai trò phòng.',
  SelfRegistrationHeader: 'Tự đăng ký',
  SelfRegistrationToolIntroDialogHeading:
    'Cho phép người dùng khách đăng ký',
  SelfRegistrationToolIntroDialogHelpLink:
    'Xem Trợ giúp và Hỗ trợ về Đăng ký Nhà thầu',
  SelfRegistrationToolIntroDialogTitle1:
    'Kích hoạt đăng ký người dùng khách trong menu hành động trên trang Bên mua/Người đặt giá thầu.',
  SelfRegistrationToolIntroDialogTitle2:
    'Chia sẻ liên kết được cung cấp trên trang web hoặc trong email bạn tạo.',
  SelfRegistrationToolIntroDialogTitle3:
    'Người dùng đã đăng ký có thể được xem xét và mời.',
  SellSide: 'Bên bán',
  SellSide_InviteHeading: 'Gửi lời mời bán người bên cạnh',
  SellSide_SentenceCase: 'Bên bán',
  Send: 'Gửi',
  SendEmailInvitation: 'Gửi email mời',
  SendEmailInvitations: 'Gửi lời mời qua email',
  SendInvitationSubjectRequiredSentenceCase: 'Cần có chủ đề cho email mời',
  SendInvite_SentenceCase: 'Gửi lời mời',
  SendInvites: 'Gửi những lời mời',
  SendInviteToSubscription: 'Mời đăng ký của bạn',
  SendUpdates: 'Gửi thông tin cập nhật',
  SentAgo: 'Đã gửi [thời gian] trước',
  SentDocumentChanged: 'Được gửi khi tài liệu được thêm hoặc thay đổi',
  SessionTimeout: 'Thời gian chờ của phiên',
  SetSecurityControls: 'Đặt kiểm soát bảo mật',
  SetSecurityControlsBeforeAddingPeople: 'Đặt các biện pháp kiểm soát bảo mật trước khi thêm người.',
  SetSubmissionLimits: 'Đặt giới hạn gửi',
  SetSubmissionLimitsDescription:
    'Hạn chế số lượng câu hỏi mà một nhóm có thể hỏi mỗi ngày, tuần hoặc tháng',
  ShouldBeEmailEmail: 'Email bạn đã nhập không phải là địa chỉ email hợp lệ.',
  ShowActivityInReports_SentenceCase: 'Hiển thị hoạt động trong báo cáo',
  ShowDetails: 'Hiển thị chi tiết',
  ShowMore: 'Cho xem nhiều hơn',
  Status: 'Trạng thái',
  StayOnPage: 'Vui lòng ở lại màn hình này trong khi cài đặt đang được sao chép',
  Subject: 'Chủ thể',
  Subject_Email_: 'Chủ thể',
  SubjectEmailHint: 'Tiêu đề email là trường bắt buộc',
  SubjectGroupNotSet: 'Nhóm chủ đề chưa được đặt',
  SubmissionIndex: 'chỉ mục gửi',
  SubmissionIndexesSecurity: 'Bảo mật chỉ mục gửi',
  SubmissionIndexTopLevelFoldersSecurity: 'Chỉ mục gửi thư mục cấp cao nhất Bảo mật',
  Submissions: 'bài nộp',
  SubTeam: 'Đội phụ',
  SubteamName: 'Tên đội phụ',
  SubTeamNameAlreadyExists: 'Một nhóm phụ có cùng tên đã tồn tại.',
  SubteamNameValidation: 'Tên nhóm phụ không được để trống',
  Success: 'Thành công',
  Sunday: 'Chủ nhật',
  SwitchTeam_Confirm: 'Có, chuyển đội',
  SwitchTeam_Title: 'Chuyển đổi đội',
  SwitchTeam_WarningMessage:
    'Có vẻ như bạn đã thực hiện một số thay đổi. Nếu bạn rời đi mà không lưu, những thay đổi của bạn sẽ bị mất.',
  SydneyTime: 'giờ Sydney',
  Team: 'Đội',
  TeamColour: 'Màu của đội – [màu]',
  TeamHistory: 'Lịch sử đội',
  TeamHistoryFor: 'Lịch sử đội cho',
  TeamMemberEmails: 'Email thành viên nhóm',
  TeamMemberEmailsInfo: 'Email của các thành viên trong nhóm của bạn, được phân tách bằng dấu chấm phẩy hoặc dấu phẩy. Tất cả họ sẽ được mời nếu việc đăng ký được chấp nhận.',
  TeamName: 'Tên nhóm',
  TeamNameAlreadyExists: 'Một đội có cùng tên đã tồn tại.',
  TeamNameValidation: 'Tên đội không được để trống',
  TeamPermissions: 'Quyền của nhóm',
  TermsAndConditions: 'Các điều khoản và điều kiện',
  ThirdPartyIntegrations_SentenceCase: 'Tích hợp bên thứ ba',
  ThisSubTeamCannotAccess: 'Nhóm phụ này không thể truy cập bất kỳ thư mục hoặc tài liệu nào',
  ThisTeamCannotAccess: 'Nhóm này không thể truy cập bất kỳ thư mục hoặc tài liệu nào',
  Thursday: 'Thứ năm',
  Time: 'thời gian',
  TimePeriodAm: 'LÀ',
  TimePeriodPm: 'BUỔI CHIỀU',
  To: 'ĐẾN:',
  Today: 'Hôm nay',
  Topic: '[chủ đề] chủ đề',
  Topics: '[chủ đề] chủ đề',
  Total: 'Tổng cộng',
  Tuesday: 'Thứ ba',
  Unknown: 'không xác định',
  Up: 'Hướng lên',
  Update: 'Cập nhật',
  UserCanLogInFromAnyIp: 'Người dùng này có thể đăng nhập từ bất kỳ địa chỉ IP nào',
  UserCanLogInOnlyFromIpsBelow: 'Người dùng này CHỈ có thể đăng nhập từ các địa chỉ IP được chỉ định bên dưới',
  UserExistsWithEmail: 'Người dùng tồn tại với email [0]',
  UserGroup: 'Nhóm người dùng',
  UserInvitationLinkNote_Gen2:
    'Thẻ &quot;[invitation_link]&quot; được thay thế bằng siêu liên kết được tạo khi email được gửi. Nó phải nằm trong nội dung của email.',
  UserInvitationLinkTagIsMissing:
    'Không thể gửi email mời nếu không có liên kết truy cập. Sao chép [invitation_link] trong ngoặc và thêm vào tin nhắn để người nhận tạo quyền truy cập.',
  UserProperties: 'Thuộc tính người dùng',
  UserStatus: 'Tâm trạng người dùng',
  UserType: 'Loại người dùng',
  UserTypeError: 'Vai trò phòng là bắt buộc',
  UserUpdated: 'Mọi người đã được cập nhật thành công.',
  Verified: 'Đã xác minh',
  Verified_Users_: 'Đã xác minh',
  VerifyYourEmailAddressToInviteUsers: 'Xác minh địa chỉ email của bạn để mời người dùng.',
  View: 'Xem',
  ViewDetails: 'Xem chi tiết',
  ViewIndexAsGhost: 'Họ có thể xem những gì?',
  ViewSecurity: 'Xem bảo mật',
  Warning: 'Cảnh báo',
  Watermark: 'Chữ ký ảnh',
  Wednesday: 'Thứ Tư',
  Weekly: 'hàng tuần',
  Yes: 'Đúng',
  YouCanAddDescriptionsAfterComma:
    'Bạn cũng có thể thêm mô tả sau dấu phẩy, ví dụ: <b>1.1.1.1, IP văn phòng Luân Đôn</b>',
  YourCurrentIpAddress:
    '<b>Lưu ý:</b> Địa chỉ IP hiện tại của bạn là [Địa chỉ IP]. <a class="ace-link" [href]>Nhấn vào đây</a> để thêm nó vào danh sách.',
  YourCurrentIpAddressSec2: 'Thêm địa chỉ IP hiện tại của bạn vào danh sách trắng.',
  YouWillReceiveAConfirmationInYourEmailAboutNextSteps:
    "Bạn sẽ nhận được xác nhận trong email của mình về các bước tiếp theo.",
};
