// @flow

import { safeRequest } from 'app/utils/saga-helpers';
import { ACTION_TYPES } from 'app/constants';
import * as dataRoomService from 'app/services/dataRoom';
import * as R from 'ramda';
import { put, select, takeEvery } from 'redux-saga/effects';
import { actions as featuresActions } from 'app/reducers/featuresReducer';
import { FEATURE_KEYS } from 'app/types/globalTypes.ts';

export function* fetchUserPermissions(): Generator<*, *, *> {
  const { session } = yield select();
  const [userPermissions, error] = yield safeRequest(dataRoomService.fetchPermissions, session);
  if (!error) {
    yield put({ type: ACTION_TYPES.USER_PERMISSIONS_UPDATE, data: userPermissions });
  }
}

export function* fetchUserTypes(action: Object): Generator<*, *, *> {
  const { isGuest } = action.data;
  const { session } = yield select();
  const [userTypes, error] = yield safeRequest(
    dataRoomService.fetchAvailableUserTypesAndQnaRoles,
    session,
  );
  if (!error) {
    const qnaRoles = userTypes
      .reduce((arr, curr) => (curr.isGuest === isGuest ? arr.concat(curr.qnaRoles) : arr), [])
      .map(qnaRole => ({ qnaRoleCode: qnaRole.Code, qnaRoleName: qnaRole.Name }));

    const uniqueQnaRoles = R.uniqBy(role => role.qnaRoleCode, qnaRoles);
    yield put({ type: ACTION_TYPES.USER_TYPES_UPDATE, data: userTypes });
    yield put({ type: ACTION_TYPES.PAGE.QNA_ROLES_UPDATE, data: uniqueQnaRoles });

    yield put({
      type: ACTION_TYPES.TEAMS.FILTER_DATA_MAP,
      data: {
        userTypes,
        qnaRoles: uniqueQnaRoles,
      },
      isGuest,
    });
  }
}

export function* fetchDataroomFeatures(): Generator<*, *, *> {
  const { session } = yield select();
  const [features, error] = yield safeRequest(
    dataRoomService.fetchDataroomFeatures,
    session,
    FEATURE_KEYS,
  );
  if (!error) {
    yield put(featuresActions.setFeaturesData(features));
  }
}

export const globalSagas = [
  takeEvery(ACTION_TYPES.USER_TYPES_FETCH, fetchUserTypes),
  takeEvery(ACTION_TYPES.USER_PERMISSIONS_FETCH, fetchUserPermissions),
  takeEvery(ACTION_TYPES.FETCH_DATAROOM_FEATURES, fetchDataroomFeatures),
];
