// @flow

import * as React from 'react';
import classnames from 'classnames';
import { Icon, Button, Tooltip } from '@ansarada/ace-react';
import styles from './iconView.scss';

const IconView = (props: {
  glyph: string,
  glyphDisabled: string,
  glyphInactive: string,
  policyValue: boolean,
  text: string,
  textDisabled: string,
  displayed?: boolean,
  disabled?: boolean,
  onClick?: (e: SyntheticMouseEvent<>) => void,
  isTracking: boolean,
}) => {
  const {
    glyph,
    glyphDisabled,
    glyphInactive,
    policyValue,
    text,
    textDisabled,
    displayed = true,
    disabled,
    onClick,
    isTracking,
  } = props;
  const glyphActive = policyValue ? glyph : glyphDisabled;
  const title = isTracking ? text : textDisabled;

  const className = classnames(styles.button, {
    [styles.userAllowedTrackingOff]: !disabled && !isTracking,
    [styles.userNotAllowedTrackingOff]: disabled && !isTracking,
  });

  return (
    displayed && (
      <Tooltip id="text" defaultOpen={false} text={title}>
        <Button
          size="Small"
          variant="Ghost"
          className={className}
          onClick={onClick}
          icon={
            <Icon
              glyph={isTracking ? glyphActive : glyphInactive}
              text={title}
              colour={disabled ? '#FAFAFC' : ''}
              size="Large"
            />
          }
          disabled={!isTracking || disabled}
        />
      </Tooltip>
    )
  );
};

export default IconView;
