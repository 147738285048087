// @flow

import type { People, Person } from 'app/types/personTypes';
import * as R from 'ramda';
import type {
  FilterType,
  PersonWithRole,
  PeopleWithRole,
  QueryType,
} from 'app/components/emailForm/EmailFormTypes';
import { FILTER_TYPES } from 'app/components/emailForm/constants';

export const filterPeopleWithQuery = (
  people: People,
  { userId, teamId, isGuest, subTeamId }: QueryType,
): People =>
  people.filter(person => {
    let matched = true;
    if (userId) {
      matched = matched && person.personId === Number(userId);
    }
    if (teamId) {
      matched = matched && person.teamId === Number(teamId);
    }
    if (subTeamId) {
      matched = matched && person.subteamId === Number(subTeamId);
    }
    if (isGuest) {
      matched = matched && person.isGuest === Boolean(isGuest === '1');
    }
    return matched;
  });

export const filterPeopleWithFilterType = (people: People, filterType: FilterType): People => {
  switch (filterType) {
    case FILTER_TYPES.LOGGED_IN_TO_DATE:
      return people.filter(person => person.userLoggedInToDate);
    case FILTER_TYPES.NEVER_LOGGED_IN:
      return people.filter(person => !person.userLoggedInToDate);
    case FILTER_TYPES.VERIFIED:
      return people.filter(person => person.isVerified);
    case FILTER_TYPES.NOT_VERIFIED:
      return people.filter(person => !person.isVerified);
    case FILTER_TYPES.INVITED:
      return people.filter(person => person.invited);
    case FILTER_TYPES.NOT_INVITED:
      return people.filter(person => !person.invited);
    default:
      return people;
  }
};

export const getEmailAddresses = (people: People | PeopleWithRole, ids: Array<number>): string =>
  R.pipe(
    R.filter(person => ids.includes(person.personId)),
    R.map(person => person.email),
    R.uniq,
    R.join(';'),
  )(people);

export const sortByNameEmail = R.sortWith([R.ascend(R.prop('name')), R.ascend(R.prop('email'))]);

type UserType = {
  userTypeCode: string,
  userType: string,
  qnaRoles: Array<*>,
};
export const getUserTypeMap = (userTypes: Array<UserType>) =>
  userTypes.reduce(
    (map, userType) => ({
      ...map,
      [userType.userTypeCode]: userType.userType,
    }),
    {},
  );

export const getQnaTypeMap = (userTypes: Array<UserType>) =>
  R.flatten(userTypes.map(userType => userType.qnaRoles)).reduce(
    (map, qnaType) => ({
      ...map,
      [qnaType.Code]: [qnaType.Name],
    }),
    {},
  );

export const addTypeOnPerson = (
  person: Person,
  userTypeMap: QueryType,
  qnaRoleMap: QueryType,
): PersonWithRole => ({
  ...person,
  userType: userTypeMap[person.userTypeCode] || '',
  qnaRole: qnaRoleMap[person.qnaRoleCode] || '',
});
