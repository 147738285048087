// prettier-ignore

export default {
  Access: 'Доступ',
  Actions: 'Действия',
  AdminSideTender_InviteHeading: 'Send invites to client',
  Cancel: 'Отмена',
  Close: 'Закрыть',
  Delete: 'Удалить',
  DemoAllCaps: 'ДЕМО',
  Document: 'Документ',
  DocumentIndex: 'Индекс документов',
  Edit: 'Редактировать',
  Email: 'Электронный адрес',
  EnterIpAddressesRanges: 'Введите IP-адреса или блоки IP-адресов, разделённые клавишей Ввод',
  FileExtensionIsInList: 'Файл "[FileName]" не может быть загружен, потому что его расширение содержится в списке расширений, запрещённых для этой комнаты данных:\r\n\r\n[FileTypeList]\r\n\r\nПожалуйста сконвертируйте этот файл в один из разрешённых форматов.',
  FileExtensionIsNotInList: 'Файл "[FileName]" не может быть загружен, потому что его расширение не содержится в списке расширений, разрешённых для этой комнаты данных:\r\n\r\n[FileTypeList]\r\n\r\nПожалуйста сконвертируйте этот файл в один из разрешённых форматов.',
  Filter: 'Фильтр',
  Folder: 'Папка',
  ForRangesSeparateStartIp: 'Для блоков, разделите начальный и конечный IP-адреса дефисом, например <b>1.1.1.1-1.1.1.6</b>',
  From: 'От:',
  FullName: 'Полное имя',
  HelpAndSupport: 'Помощь и поддержка',
  History: 'История',
  InvalidEmailAddress: 'Ошибочный электронный адрес',
  InvalidPdf: 'ОШИБКА: Этот файл не является PDF документом. Пожалуйста попробуйте другой файл.',
  IpAddressInvalidIpAccessList: "IP-адрес '[IpAddress]', введённый в список IP-доступа, является ошибочным.",
  IpAddressInvalidRangeIpAccessList: "Началный адрес блока IP-адресов '[IpRange]' в списке IP-доступа больше конечного адреса. Начальный адрес должен быть меньше или равен конечному.",
  IpLimitRangeIpAccessList: "Описание блока IP-адресов в списке IP-доступа не должно превышать [NumOfChars] символов. Блок '[IpRange]' содержит [UsedNumOfChars] символов.",
  IpUserAccessListLimit: 'Размер списка IP-доступа не должен превышать [MaxNumberOfCharacters] символов. Вы ввели [EnteredNumberOfCharacters] символов.',
  LastMonth: 'Предыдущий месяц',
  Manage: 'Управление',
  Message: 'Сообщение',
  Never: 'Никогда',
  None: 'Нет',
  Off: 'Выкл',
  Or: 'или',
  PrintingDenied: 'Печать запрещена',
  QnaRole: 'Роль В/О',
  Reset: 'Сбросить',
  Save: 'Сохранить',
  SavingDenied: 'Сохранение запрещено',
  Search: 'Поиск',
  Security: 'Безопасность',
  Select: 'Выбрать',
  Status: 'Статус',
  To: 'До:',
  Today: 'Сегодня',
  UserCanLogInFromAnyIp: 'Этот пользователь может заходить с любого IP-адреса',
  UserCanLogInOnlyFromIpsBelow: 'Этот пользователь может заходить ТОЛЬКО с IP-адресов указанных ниже',
  View: 'Смотреть',
  Watermark: 'Водяной знак',
  YouCanAddDescriptionsAfterComma: 'Вы также можете добавить описание после запятой, например <b>1.1.1.1, IP Лондонского офиса</b>',
};
