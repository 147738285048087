// @flow

import { combineReducers } from 'redux';
import { ACTION_TYPES } from 'app/constants';
import tenderSubmissionsSecurityReducer from './tenderSubmissionsSecurityPage/tenderSubmissionsSecurityReducer';
import documentIndexReducer from './documentIndex/documentIndexReducer';
import subjectsSecurityReducer from './subjectSecurityPage/subjectSecurityPageReducer';

const initialPageState = {
  isGuest: null,
  subteamId: null,
};

const propsReducer = (state: Object = initialPageState, action: Object) => {
  switch (action.type) {
    case ACTION_TYPES.SECURITY.DATA_FETCH_INITIAL:
      return {
        ...state,
        isGuest: action.data.isGuest,
        subteamId: action.data.subteamId,
      };
    case ACTION_TYPES.SECURITY.RESET_STATE:
      return initialPageState;
    default:
      return state;
  }
};

export default combineReducers<_, *>({
  props: propsReducer,
  documents: documentIndexReducer,
  subjects: subjectsSecurityReducer,
  tenderSubmissions: tenderSubmissionsSecurityReducer,
});
