// @flow

import { ACTION_TYPES } from 'app/constants';
import type { PersonHistoryState } from 'app/pages/personHistory/personHistoryTypes.js';

const defaultTeamHistoryState: PersonHistoryState = {
  isLoaded: false,
  person: undefined,
  personHistory: [],
};

const personHistoryReducer = (
  state: PersonHistoryState = defaultTeamHistoryState,
  action: Object,
) => {
  switch (action.type) {
    case ACTION_TYPES.PERSON_HISTORY.SET_PERSON:
      return {
        ...state,
        person: action.data,
      };
    case ACTION_TYPES.PERSON_HISTORY.SET_PERSON_HISTORY:
      return {
        ...state,
        personHistory: action.data,
      };
    case ACTION_TYPES.PERSON_HISTORY.SET_IS_LOADED:
      return {
        ...state,
        isLoaded: action.data,
      };
    case ACTION_TYPES.PERSON_HISTORY.RESET_STATE:
      return defaultTeamHistoryState;
    default:
      return state;
  }
};

export default personHistoryReducer;
