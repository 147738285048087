// prettier-ignore

export default {
  Access: 'Accesso',
  Actions: 'Azioni',
  AdminSideTender_InviteHeading: 'Send invites to client',
  Attachments: 'Allegati',
  BatchOperationCantBePerformed: 'Impossibile eseguire l\'operazione in blocco in quanto non vi sono utenti in questa data room. Immettere prima gli utenti.',
  Cancel: 'Annulla',
  Change: 'Modifica',
  Close: 'Chiudi',
  Colour: 'Colore:',
  DataRooms: 'Data room',
  Delete: 'Elimina',
  Document: 'Documento',
  DocumentIndex: 'Indice documenti',
  Documents: 'Documenti',
  Edit: 'Modifica',
  EditMultipleUsers: 'Modifica più utenti',
  EditProfile: 'Modifica profilo',
  Email: 'E-mail',
  EnterIpAddressesRanges: 'Immettere gli indirizzi IP o gli intervalli di indirizzi IP separandoli con il tasto Invio',
  EnterSubjectText: "Immettere il testo dell'oggetto.",
  FileExtensionIsInList: 'Impossibile caricare il file "[FileName]": la sua estensione è compresa nell\'elenco di estensioni non consentite per questa data room:\r\n\r\n[FileTypeList]\r\n\r\nConvertire il file in uno dei formati consentiti.',
  FileExtensionIsNotInList: 'Impossibile caricare il file "[FileName]": la sua estensione non è compresa nell\'elenco di estensioni consentite per questa data room:\r\n\r\n[FileTypeList]\r\n\r\nConvertire il file in uno dei formati consentiti.',
  Filter: 'Filtro',
  Folder: 'Cartella',
  ForRangesSeparateStartIp: "Per gli intervalli, separare l'indirizzo IP iniziale e quello finale utilizzando un trattino, ad es. <b>1.1.1.1-1.1.1.6</b>",
  From: 'Da:',
  FullName: 'Nome completo',
  Help: 'Guida',
  InvalidEmailAddress: 'Indirizzo e-mail non valido',
  InvalidPdf: 'ERRORE: Il file non è un documento pdf valido. Provare a utilizzare un altro file.',
  Invited_Users_: 'Invitati',
  IpAccessList: 'Elenco accessi IP',
  IpAddressInvalidIpAccessList: 'L\'IP "[IpAddress]" dell\'elenco accessi IP non è un indirizzo IP valido.',
  IpAddressInvalidRangeIpAccessList: "L'indirizzo IP iniziale dell'intervallo di indirizzi IP \"[IpRange]\" dell'elenco accessi IP è maggiore dell'indirizzo IP finale. L'indirizzo IP iniziale deve essere minore o uguale all'indirizzo IP finale.",
  IpLimitRangeIpAccessList: 'La descrizione dell\'intervallo di indirizzi IP "[IpRange]"  dell\'elenco accessi IP non può contenere più di [NumOfChars] caratteri. Sono stati utilizzati [UsedNumOfChars] caratteri.',
  IpUserAccessListLimit: "L'elenco accessi IP non può contenere più di [MaxNumberOfCharacters] caratteri. Sono stati utilizzati [EnteredNumberOfCharacters] caratteri.",
  LastMonth: 'Mese precedente',
  LoadingThreeDots: 'Caricamento in corso...',
  Logout: 'Esci',
  Manage: 'Gestione',
  Message: 'Messaggio',
  Never: 'Mai',
  No: 'No',
  None: 'Nessuno',
  NoRecordsFound: 'Nessun record trovato.',
  Off: 'Off',
  OkAllCaps: 'OK',
  OnEveryLogin: 'A ogni accesso',
  Or: 'o',
  PrintingDenied: 'Stampa non consentita',
  QnaRole: 'Ruolo D&R',
  RecipientsOfEmailNote_Gen2: 'i destinatari di questa <nowrap>e-mail</nowrap> NON visualizzeranno i reciproci indirizzi, perciò può essere inviata agli utenti di gruppi utenti diversi.',
  Reset: 'Ripristina',
  Save: 'Salva',
  SavingDenied: 'Salvataggio negato',
  Search: 'Ricerca',
  Security: 'Protezione',
  Select: 'Seleziona',
  SelectActionToPerform: "Selezionare l'operazione da eseguire.",
  Send: 'Invia',
  ShouldBeEmailEmail: 'L\'e-mail immessa non è un\'e-mail valida.',
  Status: 'Stato',
  To: 'A:',
  Today: 'Oggi',
  UserCanLogInFromAnyIp: 'Questo utente può accedere con qualsiasi indirizzo IP',
  UserCanLogInOnlyFromIpsBelow: 'Questo utente può accedere SOLO con gli indirizzi IP specificati di seguito',
  UserGroup: 'Gruppo utenti',
  UserProperties: 'Proprietà utente',
  Verified_Users_: 'Verificati',
  View: 'Visualizza',
  Watermark: 'Filigrana',
  Yes: 'Sì',
  YouCanAddDescriptionsAfterComma: 'È anche possibile aggiungere una descrizione, separata da una virgola, ad es. <b>1.1.1.1, IP ufficio Roma</b>',
  YourCurrentIpAddress: '<b>Nota:</b> l\'indirizzo IP attuale è [IP Address]. <a [href]>Fare clic qui</a> per aggiungerlo all\'elenco.',
};
