// @flow

import { PERIOD, STATUS, WEEKDAYS } from 'app/pages/notification/constants';
import setDay from 'date-fns/set_day';
import setHours from 'date-fns/set_hours';
import subDays from 'date-fns/sub_days';
import format from 'date-fns/format';
import type {
  NotificationSettingPayload,
  DocumentSettingState,
} from 'app/pages/notification/notificationTypes';
import { API3_ROOT } from 'app/services/request/constants';
import type { SessionType } from 'app/types/globalTypes';
import { get, post } from './request/api';

export const parseConfig = (config: *): DocumentSettingState => {
  const startTime = new Date(config.AutoNotificationStartTime);
  const originHour = Number(startTime.getHours());
  let period = PERIOD.AM;
  let hour = Number(originHour);
  if (hour >= 12) {
    hour -= 12;
    period = PERIOD.PM;
  }
  return {
    status: config.UseAutoNotification ? STATUS.ON : STATUS.OFF,
    intervalType: config.AutoNotificationIntervalType,
    weekday: WEEKDAYS[startTime.getDay()],
    hour,
    period,
  };
};

export const getSetting = (session: SessionType): Promise<NotificationSettingPayload> =>
  get(session, `${API3_ROOT}/Pages/UserNotification/GetData`).then(result => {
    const notificationConfigs = result.data || [];
    const documentConfig = notificationConfigs.find(
      setting => setting.AutoNotificationType === 'Documents',
    );
    const qnaConfig = notificationConfigs.find(setting => setting.AutoNotificationType === 'Qna');
    if (!documentConfig) {
      throw new Error('Either document notification config is not found');
    }
    return {
      documentSetting: parseConfig(documentConfig),
      qnaSetting: qnaConfig
        ? {
            status: qnaConfig.UseAutoNotification ? STATUS.ON : STATUS.OFF,
          }
        : undefined,
    };
  });

const getLastStartTime = (weekday, period, hourIn12) => {
  const day = WEEKDAYS.indexOf(weekday);
  const hour = period === PERIOD.AM ? hourIn12 : Number(hourIn12) + 12;
  const now = new Date();
  const startDate = setHours(setDay(now, day), hour);
  return startDate > now ? subDays(startDate, 7) : startDate;
};
export const saveSetting = (session: SessionType, payload: NotificationSettingPayload) => {
  const { qnaSetting, documentSetting } = payload;
  const requests = [
    post(session, `${API3_ROOT}/Pages/UserNotification/SaveData`, {
      DataRoomId: session.dataroomId,
      UserId: session.userId,
      AutoNotificationType: 'Documents',
      AutoNotificationStartTime: format(
        getLastStartTime(documentSetting.weekday, documentSetting.period, documentSetting.hour),
        'YYYY-MM-DDTHH:mm:ssZ',
      ),
      UseAutoNotification: documentSetting.status === STATUS.ON,
      AutoNotificationIntervalType: documentSetting.intervalType,
    }),
  ];
  if (qnaSetting) {
    requests.push(
      post(session, `${API3_ROOT}/Pages/UserNotification/SaveData`, {
        DataRoomId: session.dataroomId,
        UserId: session.userId,
        AutoNotificationType: 'Qna',
        UseAutoNotification: qnaSetting.status === STATUS.ON,
        AutoNotificationStartTime: new Date().toISOString(),
      }),
    );
  }
  return Promise.all(requests);
};
