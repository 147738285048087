"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MOCK_REGISTRATION_SETTINGS = exports.MOCK_REGISTRANT = exports.STATUS_ICONS = exports.STATUS_TEXTS = exports.STATUSES = void 0;
exports.STATUSES = {
    PEN: 'PEN',
    INV: 'INV',
    DEC: 'DEC',
};
exports.STATUS_TEXTS = {
    PEN: 'Pending',
    INV: 'Verified',
    DEC: 'Declined',
};
exports.STATUS_ICONS = {
    PEN: 'StatusInformation',
    INV: 'StatusYes',
    DEC: 'StatusNo',
};
exports.MOCK_REGISTRANT = {
    Id: 1,
    FirstName: 'First',
    LastName: 'Last',
    FullName: 'First last',
    Email: 'test@gmail.com',
    PhoneNumber: '1234567890',
    LocationId: 456,
    LocationName: '',
    JobTitleId: 123,
    JobTitleName: '',
    JobTitleGroupOther: '',
    JobTitleOther: '',
    Status: exports.STATUSES.PEN,
    InsertDateUtc: '',
    Company: '',
    RequestedByEmail: '',
    AdditionalEmails: [],
};
exports.MOCK_REGISTRATION_SETTINGS = {
    IsEnabled: true,
};
